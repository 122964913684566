import styled from "styled-components";
import { Col as defaultCol, Row as defaultRow} from "antd";


export const Col = styled(defaultCol)`
`

export const Row = styled(defaultRow)`

`

import React from 'react';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';

interface SpinLoadingProps {
  isProjectHeader?: boolean
}

function SpinLoading({ isProjectHeader }: SpinLoadingProps) {
  const theme = useTheme();
  
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: isProjectHeader ? '' : 'white',
        padding: isProjectHeader ? 6 : 24,
        borderRadius: theme.border.radius.sm,
        marginTop: isProjectHeader ? 0 : 12,
      }}>
      <ReactLoading type="spin" width={isProjectHeader ? 32 : 64} height={isProjectHeader ? 32 : 64} color={theme.colors.primary[900]} />
    </div>
  )
}

export default SpinLoading;
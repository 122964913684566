import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';
import moment from "moment";

import { Button, Select, Input, ButtonCalendarInput } from "shared/components";
import { TaskData } from "../../index";

import { getUser } from "services";
import { GetListTaskManagementResponse } from "bto_now/api/taskManagement/getListTask";
import { updateTaskManagement, UpdateTaskManagementRequest } from "bto_now/api/taskManagement/updateTask";
import { GetListTaskStatusResponse } from "bto_now/api/taskStatus/getListTaskStatus";

import { convertStatusTaskIdToStatus } from "utils/convertStatusTask/convertStatusTaskIdToStatusTask";
import { convertStatusTaskToStatusId } from "utils/convertStatusTask/convertStatusTaskToStatusTaskId";

import * as S from './styles';

interface ModalEditTaskProps {
  projectId: string;
  taskId: number | null;
  editTaskModal: boolean;
  updateTrigger: boolean;
  taskListData: GetListTaskManagementResponse[];
  taskStatusList: GetListTaskStatusResponse[];
  setEditTaskModal: (state: boolean) => void;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTasks: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface TaskDataEdit {
  taskId: number;
  name: string;
  description: string;
  owner: string;
  startDate: string;
  endDate: string;
  taskStatus: string;
}

const ModalEditTask: React.FC<ModalEditTaskProps> = (props) => {
  const { projectId, editTaskModal, setEditTaskModal, setUpdateTrigger, taskId, updateTrigger, setSelectedTaskId, setSelectedTasks, taskListData, taskStatusList } = props;
  const { t } = useTranslation();

  const [creatingLoading, setCreatingLoading] = useState(false);
  const [dataTask, setDataTask] = useState<TaskDataEdit>({
    taskId: 0,
    name: '',
    description: '',
    owner: '',
    startDate: '',
    endDate: '',
    taskStatus: ''
  });
  const [errors, setErrors] = useState({
    taskStatus: false,
  });

  useEffect(() => {
    if (taskId !== null) {
      const taskData = taskListData.find(task => task.taskId === taskId);
  
      if (taskData) {
        setDataTask({
          taskId: taskData.taskId,
          name: taskData.name,
          description: taskData.description,
          owner: taskData.owner,
          startDate: moment(taskData.startDate).format('DD/MM/YYYY'), 
          endDate: moment(taskData.endDate).format('DD/MM/YYYY'), 
          taskStatus: convertStatusTaskIdToStatus(taskStatusList, taskData.taskStatusId)
        });
      }
    }
  }, [taskId, updateTrigger, editTaskModal]);


  const handleSelectChange = (name: keyof TaskData, value: string) => {
    setDataTask(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const updateTaskDatabase = async () => {
    try {
      const newItem: UpdateTaskManagementRequest = {
        projectId: Number(projectId),
        taskId: dataTask.taskId,
        ritualNumber: 0,
        name: dataTask.name,
        description: dataTask.description,
        owner: dataTask.owner,
        startDate: new Date(moment(dataTask.startDate, 'YYYY/MM/DD').toISOString()), 
        endDate: new Date(moment(dataTask.endDate, 'YYYY/MM/DD').toISOString()),
        taskStatusId: convertStatusTaskToStatusId(taskStatusList, dataTask.taskStatus),
        user: getUser(),
      };

      const response = await updateTaskManagement(newItem);

      if (response.status === 200) {
        console.log('Task updated successfully:', response.data);

        setUpdateTrigger(prev => !prev);
        setSelectedTasks(prevSelected => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(dataTask.taskId);
          return newSelected;
        });
        setSelectedTaskId(null);
      } else {
        console.error('Error updating task:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const handleSubmit = () => {
    setCreatingLoading(true)

    const newErrors = {
      taskStatus: !dataTask.taskStatus,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      updateTaskDatabase();
      setDataTask({
        taskId: 0,
        name: '',
        description: '',
        owner: '',
        startDate: '',
        endDate: '',
        taskStatus: ''
      });

      setEditTaskModal(false);
    }

    setCreatingLoading(false);
  };

  console.log(dataTask)
  return (
    <Modal
      title={t(`Edit Task Mng: Task ${taskId}`)}
      open={editTaskModal}
      onCancel={() => setEditTaskModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={() => setEditTaskModal(false)} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading}
          >
            {t("Edit")}
          </Button>
        </div>
      }
    >
      <Input
        label={t('Task Name') + ' *'}
        name='name'
        value={dataTask.name}
        readOnly
      />
      <Input
        label={t('Task Description') + ' *'}
        maxLength={1000}
        name='description'
        value={dataTask.description}
        readOnly
      />
      <Input
        label={t('Task Owner') + ' *'}
        name='owner'
        value={dataTask.owner}
        readOnly
      />
      <ButtonCalendarInput
        placeholder={t('Start Date')}
        name='startDate'
        value={dataTask.startDate}
        readOnly
      />
      <ButtonCalendarInput
        placeholder={t('Deadline')}
        name='endDate'
        value={dataTask.endDate}
        readOnly
      />
      <Select
        options={taskStatusList.map(status => ({ label: status.taskStatus, value: status.taskStatus}))}
        label={t('Task Status') + ' *'}
        name='taskStatus'
        value={dataTask.taskStatus}
        onChange={(e) => handleSelectChange('taskStatus', e.target.value)}
        error={errors.taskStatus}
      />
      {errors.taskStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalEditTask;
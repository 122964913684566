import { btoApi } from "../client"
import { AxiosResponse } from "axios";

export interface GetGeneralDataSupportIssuesResponse {
  entidade: string;
  tipos: Array<{
    descricao: string;
    opcoes: string[];
  }>;
}

export function getGeneralDataSupportIssues(): Promise<AxiosResponse<GetGeneralDataSupportIssuesResponse>> {
  return btoApi
    .get('/api/GeneralDataSupport/GetGeneralDataSupportIssues');
}
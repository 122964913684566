import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface FinalizeRitualPayload {
  projectId:    string;
  ritualNumber: number;
  user:         string;
}

export interface FinalizeRitualResponse {
  sucesso: boolean;
  mensagemErro: string;
}


export function finalizeRitual(data: FinalizeRitualPayload): Promise<AxiosResponse<FinalizeRitualResponse>> {
  return btoApi.post('/api/Ritual/FinalizeRitual', data);
}
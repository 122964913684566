import ButtonIcon from '../ButtonIcon';
import { faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';

import * as S from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ButtonsActionsPage {
  isDisabledButtonDelete?: boolean;
  isDisabledButtonEdit?: boolean;
  isDisabledButtonNew?: boolean;
  removeButtonDelete?: boolean;
  removeButtonEdit?: boolean;
  removeButtonNew?: boolean;
  handlePlus?: () => void;
  handleEdit?: () => void;
  handleTrash?: () => void;
}

const ButtonsActionsPage = (props: ButtonsActionsPage) => {
  const { 
    handlePlus, 
    handleEdit, 
    handleTrash, 
    isDisabledButtonDelete = true, 
    isDisabledButtonEdit = true, 
    isDisabledButtonNew = true,
    removeButtonDelete = false, 
    removeButtonEdit = false, 
    removeButtonNew = false  
  } = props;

  return (
    <S.ContainerButtons>
      {!removeButtonNew && (
        <ButtonIcon onClick={handlePlus} disabled={isDisabledButtonNew} >
          <FontAwesomeIcon 
            icon={faPlus}
            style={{ fontSize: '16px' }} 
          />
        </ButtonIcon>
      )}
      {!removeButtonEdit && (
        <ButtonIcon onClick={handleEdit} disabled={isDisabledButtonEdit}>
          <FontAwesomeIcon 
            icon={faEdit}
            style={{ fontSize: '16px' }} 
          />
        </ButtonIcon>
      )}
      {!removeButtonDelete && (
        <ButtonIcon onClick={handleTrash} disabled={isDisabledButtonDelete}>
          <FontAwesomeIcon 
            icon={faTrash}
            style={{ fontSize: '16px' }} 
          />
        </ButtonIcon>
      )}
    </S.ContainerButtons>
  )
}

export default ButtonsActionsPage;
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FaPlus } from "react-icons/fa";
import { Button, CheckBox, ModalError, ModalSuccess, Row, Select, SpinLoading, StatusText, Table, Typography, Input } from 'shared/components';
import { getUser } from 'services';
import { dateWithHours, getUrlSearchParams, RitualManagementStatusRitual } from 'utils';
import { Modal } from 'antd';
import { useTranslation } from "react-i18next";


import {
  createRisk,
  CreateRiskPayload,
  deleteRisk,
  DeleteRiskPayload,
  getArchivedRisks,
  getRisks,
  GetRisksResponse,
  updateRisk,
  UpdateRiskPayload
} from 'bto_now/api';
import { getGeneralDataSupportRisks } from 'bto_now/api/generalSupport/getGeneralDatSupportRisks';

function RitualRisksUpdate() {
  const { t } = useTranslation();

  const theme = useTheme();
  const { projectId } = useParams();

  const statusRitual = getUrlSearchParams('statusRitual') as RitualManagementStatusRitual;
  const isNotDraft = statusRitual === 'Active' || statusRitual === 'History';

  const [data, setData] = useState<GetRisksResponse>();
  const [archived, setArchived] = useState(false);
  const [savingLoading, setSavingLoading] = useState(false);

  const [checkedL, setCheckedL] = React.useState<Array<string>>([]);
  const [deletingLoading, setDeletingLoading] = React.useState(false);

  const [typeOptions, setTypeOptions] = useState<Array<string>>([]);
  const [impactScaleOptions, setImpactScaleOptions] = useState<Array<string>>([]);
  const [probabilityScaleOptions, setProbabilityScaleOptions] = useState<Array<string>>([]);
  const [statusMitigationOptions, setStatusMitigationOptions] = useState<Array<string>>([]);

  const [newRiskModal, setNewRiskModal] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);
  const [riskName, setRiskName] = useState('');
  const [riskDescription, setRiskDescription] = useState('');
  const [riskType, setRiskType] = useState('');
  const [riskStatusMitigation, setRiskStatusMitigation] = useState('');
  const [riskProbability, setRiskProbability] = useState('');
  const [riskImpactScale, setRiskImpactScale] = useState('');
  const [riskHistorical, setRiskObs] = useState('');

  const [modalSuccess, setModalSucess] = useState('');
  const [modalError, setModalError] = useState('');

  useEffect(() => {
    getGeneralDataSupportRisks()
      .then(({ data }) => {
        if (data) {
          const type = data.tipos.find((v: any) => v.descricao === "TYPE");
          const probabilityScale = data.tipos.find((v: any) => v.descricao === "PROBABILITY SCALE");
          const impactScale = data.tipos.find((v: any) => v.descricao === "IMPACT SCALE");
          const statusMitigation = data.tipos.find((v: any) => v.descricao === "STATUS MITIGATION");
          if (type) setTypeOptions(type.opcoes);
          if (impactScale) setImpactScaleOptions(impactScale.opcoes);
          if (probabilityScale) setProbabilityScaleOptions(probabilityScale.opcoes);
          if (statusMitigation) setStatusMitigationOptions(statusMitigation.opcoes);
        }
      })
      .catch(() => null);
  }, []);

  useEffect(fetchAPI, [projectId, archived]);

  function fetchAPI() {
    setData(undefined);
    (archived ? getArchivedRisks : getRisks)({ User: getUser(), ProjectId: projectId as string })
      .then(({ data }) => {
        setData(data);
      })
      .catch(err => console.log(err));
  }

  function handleIfIsChecked(i: string) {
    const index = checkedL.find(v => v === i);
    if (!index) return false;
    else return true;
  }

  function handleCheck(i: string) {
    const index = checkedL.findIndex(v => v === i);

    if (Number(index) > -1) {
      checkedL.splice(Number(index), 1);
      setCheckedL([...checkedL]);
    }
    else setCheckedL(checkedL.concat(i));
  }

  function handleScaleColor(status: string) {
    switch (status) {
      case 'HIGH':
        return theme.colors.utility.red[500];
      case 'MODERATE':
        return theme.colors.utility.yellow[500];
      case 'LOW':
        return theme.colors.utility.darkGreen[500];
      case 'VERY HIGH':
        return theme.colors.neutral.white[900];
      case 'CRITICAL':
        return theme.colors.neutral.white[900];
    }
  }

  const statusColor = useCallback((color: string) => {
    switch (color) {
      case 'Archived': return 'yellow';
      case 'Active': return 'green';
    }
  }, []);

  function updateField(value: string, index: number, field: string) {
    if (data && data[index]) {
      const newData = [...data];
      newData[index][field] = value;
      setData([...newData]);
    }
  }

  const updateHistoricalMonitoring = (value: string, index: number) => updateField(value, index, 'historicalMonitoring');
  const updateType = (value: string, index: number) => updateField(value, index, 'type');
  const updateStatusMitigation = (value: string, index: number) => updateField(value, index, 'statusMitigation');
  const updateProbabilityScale = (value: string, index: number) => updateField(value, index, 'probabilityScale');
  const updateImpactScale = (value: string, index: number) => updateField(value, index, 'impactScale');
  const updateName = (value: string, index: number) => updateField(value, index, 'name')
  const updateDescription = (value: string, index: number) => updateField(value, index, 'description');

  function deleteSelectedRisks(checkedL: Array<string>) {
    setDeletingLoading(true);
    if (data) {
      const buffer: GetRisksResponse = new Array(0);
      for (let ind in checkedL) {
        const index = data.findIndex((v, i) => String(i) === checkedL[ind]);
        if (index > -1) {
          buffer.push(data[index]);
        }
      }
      const promisses = buffer.map((v) => {
        const deletePayload: DeleteRiskPayload = {
          ...v,
          user: getUser() as string,
          projectId: projectId as string,
        }
        deleteRisk(deletePayload);
      })

      Promise.allSettled(promisses)
        .then((responses: Array<PromiseSettledResult<void>>) => {
          setTimeout(fetchAPI, 1000);
          const rejected = responses.find((response) => response.status === 'rejected');
          if (rejected) setModalError('Unable to delete risk')
          else setModalSucess('Risks deleted successfully')
        })
        .finally(() => {
          setDeletingLoading(false);
          setCheckedL([]);
        });
    }
  }

  function newRisk() {
    if (
      !riskName.length 
      || !riskDescription.length 
      || !riskType.length 
      || !riskStatusMitigation.length 
      || !riskProbability.length 
      || !riskImpactScale.length 
      // || !riskHistorical.length
    ) {
      setModalError('Please fill in all required fields');
      return;
    }

    const payload: CreateRiskPayload = {
      riskId: 0,
      name: riskName,
      description: riskDescription,
      type: riskType,
      statusMitigation: riskStatusMitigation,
      probabilityScale: riskProbability,
      impactScale: riskImpactScale,
      historicalMonitoring: riskHistorical,
      lastUpdate: new Date().toJSON(),
      user: getUser() as string,
      companyId: 0,
      owner: '',
      projectId: projectId as string,
    };

    setCreatingLoading(true);
    createRisk(payload)
      .then(({ data }) => {
        setNewRiskModal(false);
        if (data.sucesso) {
          setTimeout(fetchAPI, 1000);
          setModalSucess('Risk created successfully');
        } else {
          setModalError('Unable to create risk');
        }
      })
      .catch(() => setModalError('Unable to create risk'))
      .finally(() => {
        setCreatingLoading(false);
      });
  }

  function saveRisks() {
    setSavingLoading(true);
    if (data) {
      const promisses = data.map((v) => {
        const payload: UpdateRiskPayload = {
          ...v,
          user: getUser() as string,
          projectId: projectId as string,
        }
        updateRisk(payload);
      })

      Promise.allSettled(promisses)
        .then((responses: Array<PromiseSettledResult<void>>) => {
          setTimeout(fetchAPI, 1000);
          const rejected = responses.find((response) => response.status === 'rejected');
          if (rejected) setModalError('Unable to update risk')
          else setModalSucess('Risks updated successfully')
        })
        .finally(() => {
          setSavingLoading(false);
        });
    }
  }

  return (
    <S.Container>
      <Row style={{ justifyContent: 'space-between', marginBottom: 22 }}>
        <Typography weight='bold' size='medium'>{t("Risks Management")}</Typography>
        <Row>
          <Button type='plain' style={{ marginRight: 12 }} disabled={isNotDraft} onClick={() => setArchived(!archived)}>{archived ? 'Show active' : 'Show Archived'}</Button>
          {!archived && <Button style={{ marginRight: 6 }} disabled={isNotDraft} onClick={() => deleteSelectedRisks(checkedL)} loading={deletingLoading}>{t("Delete")}</Button>}
          {!archived && <Button style={{ marginLeft: 6 }} disabled={isNotDraft} onClick={saveRisks} loading={savingLoading}>{t("Save")}</Button>}
        </Row>
      </Row>

      {
        !data ? (
          <SpinLoading />
        ) : (
          <S.ScrollX>
            <Table style={{ marginTop: theme.spacing.sm, width: '100%' }}>
              <Table.Tr header>
                {!archived && <Table.Td></Table.Td>}
                {/* <Table.Td>{t("Id")}</Table.Td> */}
                <Table.Td>{t("Risk Name")}</Table.Td>
                <Table.Td>{t("Risk Description")}</Table.Td>
                <Table.Td style={{ minWidth: 150 }}>{t("Type")}</Table.Td>
                <Table.Td style={{ minWidth: 150 }}>{t("Status Mitigation")}</Table.Td>
                <Table.Td style={{ minWidth: 130 }}>{t("Probability Scale")}</Table.Td>
                <Table.Td style={{ minWidth: 130 }}>{t("Impact Scale")}</Table.Td>
                <Table.Td>{t("Obs")}</Table.Td>
                {/* <Table.Td>{t("Status")}</Table.Td> */}
                <Table.Td>{t("Last Update")}</Table.Td>
              </Table.Tr>
              {data.map((m, i) => (
                <Table.Tr key={i + '-RiskUpdateTable'}>
                  {!archived && <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(String(i))} checked={handleIfIsChecked(String(i))} size="medium" /></Table.Td>}
                  {/* <Table.Td>{m.riskId}</Table.Td> */}
                  <Table.Td title={m.name} style={{ maxWidth: 220 }}>
                    <Table.Input disabled={isNotDraft} value={m.name} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateName(ev.target.value, i)} />
                  </Table.Td>
                  <Table.Td title={m.description} style={{ maxWidth: 220 }}>
                  <Table.TextArea
                      disabled={isNotDraft}
                      value={m.description}
                      maxLength={450}
                      onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => updateDescription(ev.target.value, i)}/> 
                  </Table.Td>
                  <Table.Td>
                    <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateType(ev.currentTarget.value, i)}>
                      {typeOptions.length > 0 &&
                        typeOptions.map((type, index) => (
                          <Table.Option key={index} value={type} selected={type === m.type}>{t(type)}</Table.Option>
                        ))
                      }
                    </Table.SelectBox>
                  </Table.Td>
                  <Table.Td>
                    <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateStatusMitigation(ev.currentTarget.value, i)}>
                      {statusMitigationOptions.length > 0 &&
                        statusMitigationOptions.map((status, index) => (
                          <Table.Option key={index} value={status} selected={status === m.statusMitigation}>{t(status)}</Table.Option>
                        ))
                      }
                    </Table.SelectBox>
                  </Table.Td>
                  <Table.Td>
                    <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateProbabilityScale(ev.currentTarget.value, i)} style={{ backgroundColor: handleScaleColor(m.probabilityScale), color: 'white' }}>
                      {probabilityScaleOptions.length > 0 &&
                        probabilityScaleOptions.map((probability, index) => (
                          <Table.Option key={index} value={probability} selected={probability === m.probabilityScale}>{t(probability)}</Table.Option>
                        ))
                      }
                    </Table.SelectBox>
                  </Table.Td>
                  <Table.Td>
                    <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateImpactScale(ev.currentTarget.value, i)} style={{ backgroundColor: handleScaleColor(m.impactScale), color: 'white' }}>
                      {impactScaleOptions.length > 0 &&
                        impactScaleOptions.map((impact, index) => (
                          <Table.Option key={index} value={impact} selected={impact === m.impactScale}>{t(impact)}</Table.Option>
                        ))
                      }
                    </Table.SelectBox>
                  </Table.Td>
                  <Table.Td>
                    <Table.TextArea maxLength={300} value={t(m.historicalMonitoring)} disabled={isNotDraft} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => updateHistoricalMonitoring(ev.target.value, i)} />
                  </Table.Td>
                  {/* <Table.Td><StatusText color={statusColor(archived ? 'Archived' : 'Active')} size='extraSmall' weight='bold'>{archived ? 'Archived' : 'Active'}</StatusText></Table.Td> */}
                  <Table.Td>{dateWithHours(m.lastUpdate)}</Table.Td>
                </Table.Tr>
              ))}
              {!archived && !isNotDraft &&
                (<Table.Tr onClick={() => setNewRiskModal(true)} style={{ background: 'white', cursor: 'pointer' }}>
                  <Table.Td><FaPlus size={18} /></Table.Td>
                  <Table.Td colSpan={10}>{t("New Risk")}</Table.Td>
                </Table.Tr>)
              }
            </Table>
          </S.ScrollX>
        )
      }

      <Modal
        title={t("New Risk")}
        open={newRiskModal}
        onCancel={() => setNewRiskModal(false)}
        centered
        destroyOnClose
        footer={
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setNewRiskModal(false)}>{t("Cancel")}</Button>
            <Button style={{ marginLeft: 12 }} onClick={newRisk} loading={creatingLoading}>{t("Create")}</Button>
          </div>
        }
      >
        <Input containerStyle={{ marginBottom: 12 }}
          label={t("Risk Name")}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setRiskName(ev.target.value)}
        />

        <Input containerStyle={{ marginBottom: 12 }}
          label={t("Risk Description")}
          maxLength={450}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setRiskDescription(ev.target.value)}
        />
        <Select
          containerStyle={{ marginBottom: 12 }}
          label={t("Type")} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setRiskType(ev.target.value)}
          options={typeOptions.map(op => ({ label: op, value: op }))}
        />

        <Select containerStyle={{ marginBottom: 12 }}
          label="Status Mitigation"
          onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setRiskStatusMitigation(ev.target.value)}
          options={statusMitigationOptions.map(op => ({ label: op, value: op }))}
        />

        <Select containerStyle={{ marginBottom: 12 }}
          label="Probability Scale"
          onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setRiskProbability(ev.target.value)}
          options={probabilityScaleOptions.map(op => ({ label: op, value: op }))}
        />

        <Select containerStyle={{ marginBottom: 12 }}
          label="Impact Scale"
          onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setRiskImpactScale(ev.target.value)}
          options={impactScaleOptions.map(op => ({ label: op, value: op }))}
        />

        <Input containerStyle={{ marginBottom: 24 }}
          label="Obs"
          maxLength={300}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setRiskObs(ev.target.value)}
        />
      </Modal>

      <ModalSuccess autoClose={3000} open={modalSuccess.length > 0} title='Success!' description={modalSuccess} onOk={() => setModalSucess('')} />
      <ModalError autoClose={3000} open={modalError.length > 0} title='Error!' description={modalError} onOk={() => setModalError('')} />
    </S.Container>
  )
}

export default RitualRisksUpdate;

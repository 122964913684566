import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetIssuesPayload {
  User?:              string | null;
  ProjectId?:         string | null;
}

export type GetIssuesResponse = Array<{
  [key: string]:        any;
  issueId:              number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  status:               string;
  urgency:              string;
  impact:               string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}>

export function getIssues(params: GetIssuesPayload): Promise<AxiosResponse<GetIssuesResponse>> {
  return btoApi
    .get('/api/ProjectIssue/GetIssues', { params });
};

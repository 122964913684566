import React, { useEffect, useState } from 'react';
import * as S from './styles';

import { Button, TabBar } from 'shared/components';
import type { TabBarProps } from 'shared/components';

import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

import { getURLPathName } from 'utils';
import { useTheme } from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  navList?: TabBarProps['list'];
  topRight?: React.ReactNode;
  bottomRight?: React.ReactNode;
  navButtons?: Array<{ label: string, value: string, disabled: boolean }>;
  onNavClick?(value: string): void;
  containerStyle?: React.CSSProperties;
}

function PageHeader({
  title,
  bottomRight,
  navList,
  navButtons,
  topRight,
  onNavClick,
  containerStyle,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [url, setUrl] = useState(getURLPathName());

  useEffect(() => {
    setUrl(getURLPathName());
  }, [pathname]);

  return (
    <S.Container style={containerStyle}>
      <S.Row style={{ justifyContent: 'space-between' }}>
        <S.Row><S.Title>{title}</S.Title></S.Row>
        <S.Row>
          {
            topRight
              ? topRight
              : Array.isArray(navButtons) && navButtons.map((btn, index) => (
                <Button 
                  size="small" 
                  style={{ marginLeft: 10 }} 
                  disabled={btn.disabled}
                  onClick={() => navigate(btn.value)}
                  key={index}
                >
                  {btn.label}
                </Button>
              ))
          }
        </S.Row>
      </S.Row>
      <S.Row style={{ justifyContent: 'space-between', marginTop: 12 }}>
        {Array.isArray(navList) ? navList.length > 0 ? (
          <TabBar
            upperCase
            selected={url} 
            list={navList.map((item, index) => ({ ...item, key: index.toString() }))}
            onClick={onNavClick || navigate}
          />
        ) : <ReactLoading type='cylon' color={theme.colors.primary[900]} height={22} width={22}/> : null}
        {bottomRight && <S.Row key="bottomRight">{bottomRight}</S.Row>}
      </S.Row>
    </S.Container>
  );
}

export default PageHeader;
import { useState } from "react";
import DescriptionDiv, { } from "./components/descriptionDiv";
import { Header } from "./components/header";
import * as S from "./styles";
import { DetailTable } from "./components/detailTable";
import { currency } from "utils";
import { GetAllInitiativesByKanbanResponse } from 'bto_now/api';

export interface IKanbanColumns {
  ActualPhase: boolean;
  GoliveDate: boolean;
  BudgetReal: boolean;
  BudgetPlan: boolean;
  Operational: boolean;
  Financial: boolean;
  Geral: boolean;
  Time: boolean;
  Cost: boolean;
  Forecast: boolean;
}

interface Props {
  data: GetAllInitiativesByKanbanResponse['kanbans'][0];
  containerStyle?: React.CSSProperties;
  columnsToShow: IKanbanColumns;
}

const DetailsProject = ({
  data,
  containerStyle,
  columnsToShow,
}: Props) => {
  const [minimized, setMinimized] = useState(false);

  return (
    <S.DivContainer minimized={minimized} style={containerStyle}>
      <Header totalForecast={data.totalForecast} budgetPlan={data.budgetPlan} budgetReal={data.budgetReal} title={data.kanban} minimized={minimized} count={data.qty} onToggle={value => setMinimized(value === 'collapsed')} />
      <S.Main minimized={minimized}>
        {data.businessDrivers.map((projectData, index) => (
          <S.StatusProject key={index}>
            <DescriptionDiv
              program={projectData.businessDriver}
              status={projectData.projectHealth}
              budgetPlan={currency(projectData.budgetPlan)}
              budgetReal={currency(projectData.budgetReal)}
              totalForecast={currency(projectData.totalForecast)}
              count={projectData.qty}
              title={data.kanban}
            />
            <DetailTable data={projectData.initiatives} columnsToShow={columnsToShow}/>
          </S.StatusProject>
        ))}
      </S.Main>
    </S.DivContainer>
  );
};

export default DetailsProject;
import Background from "shared/theme/components/Background";

interface getCustomStylesProps {
  menuIsOpen: boolean,
  error: boolean
}

export const getCustomStyles = ({ menuIsOpen, error }: getCustomStylesProps) => ({
  container: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    minWidth: '200px',
    height: '39.78px',
    borderRadius: '8px',
    fontSize: '12px',
    color: 'black',
    overflow: 'auto',
    borderColor: error ? 'red' : '#C2C7CA',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: '#C2C7CA',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'wrap',  
    flexWrap: 'wrap',    
    '&::-webkit-scrollbar': {
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    margin: '5px 4px 0 0',
    overflow: 'visible', 
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    display: 'flex',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    whiteSpace: 'nowrap', 
    overflow: 'visible',    
    textOverflow: 'clip', 
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ccc', 
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: menuIsOpen ? 'transparent' : 'black',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '4px 10px',
    color: '#4d5c73',
    fontWeight: 500,
    backgroundColor: state.isSelected ? '#C2C7CA' : state.isFocused ? '#F3F4F4' : 'white',
    cursor: 'pointer',
    fontSize: '12px',
    margin: 0,
    width: '100%',
    zIndex: 3,
    transition: 'background-color 0.2s ease',
  }),
  menu: (provided: any) => ({
    ...provided,
    position: 'absolute',
    maxHeight: '180px',
    overflowY: 'auto',
    width: '100%',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    marginTop: '5px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
});
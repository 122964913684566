import styled, { css } from "styled-components";
import { Row as DefaultRow } from 'shared/components'
import { BsArrowsAngleContract } from "react-icons/bs";
import { BsArrowsAngleExpand } from "react-icons/bs";


export const Title = styled.p<{ minimized: boolean }>`
  font-size: ${p => p.theme.font.size.md};
  width: 100px;
  font-weight: ${p => p.theme.font.weight.bold};
  color: ${p => p.theme.colors.text};
  margin: 0px 10px;
  /* transition: 100ms; */
  white-space: nowrap;
  text-transform: uppercase;

  ${p => p.minimized && css`
    transform: rotateZ(-90deg);
  `}
`;

export const HeaderDiv = styled.div<{ minimized: boolean, titleWidth: number, title: string }>`
  display: flex;
  align-items: center;
  background-color:
    ${p => p.title === 'BACKLOG' ? 'rgb(236,236,236)'
    : p.title === 'PLANNING' ? 'rgb(207, 216, 220)'
    : p.title === 'EXECUTION' ? 'rgb(158, 193, 207)'
     : 'rgb(108, 142, 191)'};

  color: #000000;
  /* width: 695px; */
  // height: 36px;
  padding: 10px; 
  font-weight: bold;
  /* border-radius: 32px; */
  /* transition: all 0.5s ease; */


  ${p => p.minimized && css`
    width: 40px;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
    flex-direction: column;
  `}

  &:hover{
    background-color:
      ${p => p.title === 'BACKLOG' ? 'rgb(198, 197, 197)'
      : p.title === 'PLANNING' ? 'rgb(145, 158, 163)'
      : p.title === 'EXECUTION' ? 'rgb(105, 135, 147)'
      : 'rgb(67, 94, 132)'};
    
    & ${Title} {
      color: white;
    }
  }

`;

export const Row = styled(DefaultRow)`
  text-align: center;
`;


export const IcoContainer = styled.div`
  padding: 0px;
  background-color: ${p => p.theme.colors.neutral.white[500]};

  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IcoCollapse = styled(BsArrowsAngleContract).attrs({
  size: 12,
  color: 'white',
})`
  transform: rotateZ(45deg);
`;

export const IcoExpand = styled(BsArrowsAngleExpand).attrs({
  size: 12,
  color: 'white',
})`
  transform: rotateZ(45deg);
`;

export const BudgetContainer = styled.div<{ minimized: boolean }>`
  display: block;
  display: flex;
  margin-left: 12px;
  flex: 1;
  justify-content: flex-end;
  padding-right: 17px;

  ${p => p.minimized && css`
    display: none;
  `}
`;

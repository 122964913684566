import { Col, Progress, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import KPIComponent from "shared/components/KPI";
import GraphComponent from "shared/components/Graph";
import { PALETE, STATUS, STATUS_XS, getUser } from "../../../../../services";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';


import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { getBusinessDriver, GetBusinessDriverResponse } from 'bto_now/api';
import { currency } from "utils";
import { SpinLoading } from 'shared/components';
import { ComponentHeader } from 'shared/layouts';
import Semaphore from "shared/components/SemaphoreVariant";

enum ProgrammeIds {
  Automatizacoes = 1,
  DemaisIniciativas = 2,
  SistemasIndustriais = 3,
  NovasPlataformas = 4,
  Estruturantes = 5,
  Leozinha = 6,
  MigracaoSaaS = 7,
  Dados = 8,
  BonusTrackEstruturantes = 9,
  BonusTrackDados = 10,
  EstruturanteBusiness = 11,
  MitigacaoDeRiscos = 12,
}

const BusinessDetail = () => {

  // const theme = useTheme();
  const { BUSINESS: bs } = useParams();
  const BUSINESS = bs || '';

  const [data, setData] = useState<GetBusinessDriverResponse>();
  const [programmes, setProgrammes] = useState<Array<GetBusinessDriverResponse['initiatives']>>();

  const [newSelect, setNewSelect] = useState(null);

  function groupByProgramme(id: number, data: GetBusinessDriverResponse['initiatives']) {
    return data.filter(d => id === Number(d.programmeId));
  }

  function sumBudgetPlan(data: GetBusinessDriverResponse['initiatives']) {
    return data.map(v => v.totalRequested).reduce((a, c) => a + c, 0);
  }

  function sumTotalForecast(data: GetBusinessDriverResponse['initiatives']) {
    return data.map(v => v.forecast).reduce((a, c) => a + c, 0);
  }

  function sumBudgetReal(data: GetBusinessDriverResponse['initiatives']) {
    return data.map(v => v.totalInvestiment).reduce((a, c) => a + c, 0);
  }

  const onNewSelect = (index: any) => {

    var items: any = newSelect;

    if (typeof newSelect?.[index] !== 'undefined') {
      items = { ...items, [index]: !items[index] }
    } else {
      items = { ...items, [index]: true };
    }

    setNewSelect(items)

  }

  useEffect(() => {
    setData(undefined);
    setNewSelect(null);
    getBusinessDriver({ User: getUser(), BusinessDriverId: BUSINESS })
      .then(({ data }) => {
        setData(data);
        setProgrammes([
          groupByProgramme(ProgrammeIds.Automatizacoes, data.initiatives),
          groupByProgramme(ProgrammeIds.DemaisIniciativas, data.initiatives),
          groupByProgramme(ProgrammeIds.SistemasIndustriais, data.initiatives),
          groupByProgramme(ProgrammeIds.NovasPlataformas, data.initiatives),
          groupByProgramme(ProgrammeIds.Estruturantes, data.initiatives),
          groupByProgramme(ProgrammeIds.Leozinha, data.initiatives),
          groupByProgramme(ProgrammeIds.MigracaoSaaS, data.initiatives),
          groupByProgramme(ProgrammeIds.Dados, data.initiatives),
          groupByProgramme(ProgrammeIds.BonusTrackEstruturantes, data.initiatives),
          groupByProgramme(ProgrammeIds.BonusTrackDados, data.initiatives),
          groupByProgramme(ProgrammeIds.EstruturanteBusiness, data.initiatives),
          groupByProgramme(ProgrammeIds.MitigacaoDeRiscos, data.initiatives),
        ].filter(data => data.length))
      });

  }, [BUSINESS]);

  function getOperationalProgressMedia(data: GetBusinessDriverResponse['initiatives']): number {
    return Math.round(data.map(d => d.operationalProgress).reduce((a, c) => a + c, 0) / data.length);
  }
  function getFinancialProgressMedia(data: GetBusinessDriverResponse['initiatives']): number {
    return Math.round(data.map(d => d.financialProgress).reduce((a, c) => a + c, 0) / data.length);
  }
  
  const { t } = useTranslation();
  return (
    <Row gutter={[12, 12]}>
      {
        data ? (
          <>
            <Col span={24}>
              <Row gutter={[12, 12]} style={{ flexWrap: 'nowrap' }}>
                <Col style={{ flex: 1 }}>
                    <KPIComponent title={t("Total Programs")} value={data?.totalPrograms} type="project" />
                </Col>
                <Col style={{ flex: 1 }}>
                    <KPIComponent title={t("Total Projects")} value={data?.totalProjects} type="project" />
                </Col>
                <Col style={{ flex: 1 }}>
                    <KPIComponent title={t("To Start")} value={data?.toStart} type="project" />
                </Col>
                <Col style={{ flex: 1 }}>
                    <KPIComponent title={t("In Progress")} value={data?.inProgress} type="project" />
                </Col>
                <Col style={{ flex: 1 }}>
                    <KPIComponent title={t("Finished")} value={data?.finished} type="project" />
                </Col>
                {/* <Col flex={'10%'} style={{ minWidth: '10%', maxWidth: '10%' }}>
                    <KPIComponent title={t("Status Time")} value={STATUS_XS[data?.statusTime || 0]} type="time" />
                </Col>
                <Col flex={'10%'} style={{ minWidth: '10%', maxWidth: '10%' }}>
                    <KPIComponent title={t("Status Cost")} value={STATUS_XS[data?.statusCost || 0]} type="money" />
                </Col>
                <Col flex={'10%'} style={{ minWidth: '10%', maxWidth: '10%' }}>
                    <KPIComponent title={t("Status Scope")} value={STATUS_XS[data?.statusScope || 0]} type="target" />
                </Col>
                <Col flex={'10%'} style={{ minWidth: '10%', maxWidth: '10%' }}>
                    <KPIComponent title={t("Status Risk")} value={STATUS_XS[data?.statusRisk || 0]} type="warn" />
                </Col>
                <Col flex={'10%'} style={{ minWidth: '10%', maxWidth: '10%' }}>
                    <KPIComponent title={t("Status Issues")} value={STATUS_XS[data?.statusIssues || 0]} type="list" />
                </Col> */}
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} style={{ flexWrap: 'nowrap' }}>
                <Col flex={'25%'}>
                    <KPIComponent title={t("Total Budget Plan")} value={currency(data?.totalBudgetPlan)} type="money" />
                </Col>
                <Col flex={'25%'}>
                    <KPIComponent title={t("Total Forecast")} value={currency(data?.totalForecast)} type="money" />
                </Col>
                <Col flex={'25%'}>
                    <KPIComponent title={t("Total Budget Real")} value={currency(data?.totalBudgetReal)} type="money" />
                </Col>
                <Col flex={'25%'}>
                    <KPIComponent title={t("% Consumed")} value={data?.consumed} type="percent" />
                </Col>
              </Row>
            </Col>
          </>
        ) : <SpinLoading />
      }
      <Col span={24}>
        {
          !data ? (
            <SpinLoading />
          ) : Array.isArray(programmes) && (

            <div style={{ background: 'white', padding: 12, borderRadius: 8 }}>
              <ComponentHeader
                title='List Overview'
                menuList={[
                  { label: 'Program', value: 'p', onClick: () => { } }
                ]}
              />
              {programmes.map((v, i) => newSelect?.[i] ? (
                <>
                  <Col span={24} key={0} className="standard-col select" style={{ borderLeftColor: PALETE.C06, backgroundColor: `${PALETE.C06}1a` }} onClick={() => onNewSelect(i)}>
                    <Row className="standard-row">
                      <Col span={24} className="standard-col-col first">
                        <IoChevronDown style={{ color: PALETE.C06 }} className="standard-col-col-arrow" />
                        <Typography className="standard-title" style={{ color: PALETE.C06 }}>{v[0].programme}</Typography>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} className="standard-col item" style={{ borderLeftColor: PALETE.C06, backgroundColor: `${PALETE.C06}1a`, paddingLeft: 0 }} >
                    <Row className="standard-row">
                      <Col flex={'auto'} style={{ minWidth: 300 }} className="table-col-subcol">
                          <Typography className="table-data">{t("Project name")}</Typography>
                      </Col>
                      <Col flex={'100px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Status Time")}</Typography>
                      </Col>
                      <Col flex={'100px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Status Cost")}</Typography>
                      </Col>
                      {/* <Col flex={'100px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Status Scope")}</Typography>
                      </Col> */}
                      <Col flex={'100px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Status Risk")}</Typography>
                      </Col>
                      <Col flex={'100px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Status Issues")}</Typography>
                      </Col>
                      <Col flex={'150px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Budget Plan")}</Typography>
                      </Col>
                      <Col flex={'150px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Budget Real")}</Typography>
                      </Col>
                      <Col flex={'180px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Operational Progress")}</Typography>
                      </Col>
                      <Col flex={'180px'} className="table-col-subcol">
                          <Typography className="table-data">{t("Financial Progress")}</Typography>
                      </Col>
                    </Row>
                    <Row className="table-row body resume">
                      <Col flex={'auto'} style={{ minWidth: 300 }} className="table-col-subcol">
                        <Typography className="table-data item center">{t("Resume")}</Typography>
                      </Col>
                      <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                        {/* <Typography className="table-data item center">{STATUS[v[0].statusTime]}</Typography> */}
                        <Semaphore value={v[0].statusTime} />

                      </Col>
                      <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                        {/* <Typography className="table-data item center">{STATUS[v[0].statusCoast]}</Typography> */}
                        <Semaphore value={v[0].statusCoast} />

                      </Col>
                      {/* <Col flex={'100px'} className="table-col-subcol">
                        <Typography className="table-data item center">{STATUS[v[0].statusScope]}</Typography>
                      </Col> */}
                      <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                        {/* <Typography className="table-data item center">{STATUS[v[0].statusRisk]}</Typography> */}
                        <Semaphore value={v[0].statusRisk} />

                      </Col>
                      <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                        {/* <Typography className="table-data item center">{STATUS[v[0].statusIssue]}</Typography> */}
                        <Semaphore value={v[0].statusIssue} />

                      </Col>
                      <Col flex={'150px'} className="table-col-subcol">
                        <Typography className="table-data item money">{currency(sumBudgetPlan(v))}</Typography>
                      </Col>
                      <Col flex={'150px'} className="table-col-subcol">
                        <Typography className="table-data item money">{currency(sumBudgetReal(v))}</Typography>
                      </Col>
                      <Col flex={'180px'} className="table-col-subcol bar">
                        <Progress size={['100%', 13]} percent={getOperationalProgressMedia(v)} showInfo={false} strokeColor={`${PALETE.C06}3a`} />
                        <Typography className="standard-data item center absolute">{getOperationalProgressMedia(v)}%</Typography>
                      </Col>
                      <Col flex={'180px'} className="table-col-subcol bar">
                        <Progress size={['100%', 13]} percent={getFinancialProgressMedia(v)} showInfo={false} strokeColor={`${PALETE.C06}3a`} />
                        <Typography className="standard-data item center absolute">{getFinancialProgressMedia(v)}%</Typography>
                      </Col>
                    </Row>
                    {v.map((vj, ij) => (
                      <Row className={`table-row body ${ij % 2 !== 0 ? 'alt' : ''}`} key={ij}>
                        <Col flex={'auto'} style={{ minWidth: 300 }} className="table-col-subcol">
                          <Typography className="table-data item">{t(vj.initiativeName)}</Typography>
                        </Col>
                        <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                          {/* <Typography className="table-data item center">{STATUS[vj.statusTime]}</Typography> */}
                          <Semaphore value={vj.statusTime}/>
                        </Col>
                        <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                          {/* <Typography className="table-data item center">{STATUS[vj.statusCoast]}</Typography> */}
                          <Semaphore value={vj.statusCoast}/>
                        </Col>
                        {/* <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                          <Typography className="table-data item center">{STATUS[vj.statusScope]}</Typography>
                        </Col> */}
                        <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                          {/* <Typography className="table-data item center">{STATUS[vj.statusRisk]}</Typography> */}
                          <Semaphore value={vj.statusRisk}/>
                        </Col>
                        <Col flex={'100px'} style={{alignItems: 'center'}} className="table-col-subcol">
                          {/* <Typography className="table-data item center">{STATUS[vj.statusIssue]}</Typography> */}
                          <Semaphore value={vj.statusIssue}/>
                        </Col>
                        <Col flex={'150px'} className="table-col-subcol">
                          <Typography className="table-data item money">{currency(vj.totalRequested)}</Typography>
                        </Col>
                        <Col flex={'150px'} className="table-col-subcol">
                          <Typography className="table-data item money">{currency(vj.totalInvestiment)}</Typography>
                        </Col>
                        <Col flex={'180px'} className="table-col-subcol bar">
                          <Progress size={['100%', 13]} percent={vj.operationalProgress} showInfo={false} strokeColor={`${PALETE.C06}3a`} />
                          <Typography className="standard-data item center absolute">{vj.operationalProgress}%</Typography>
                        </Col>
                        <Col flex={'180px'} className="table-col-subcol bar">
                          <Progress size={['100%', 13]} percent={vj.financialProgress} showInfo={false} strokeColor={`${PALETE.C06}3a`} />
                          <Typography className="standard-data item center absolute">{vj.financialProgress}%</Typography>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </>
              ) : (
                <Col span={24} key={i} className="standard-col" style={{ borderLeftColor: PALETE.C06, backgroundColor: `${PALETE.C06}1a` }} onClick={() => onNewSelect(i)}>
                  <Row className="standard-row">
                    <Col span={6} className="standard-col-col first">
                      <div style={{ display: 'flex' }}>
                        <IoChevronForward style={{ color: PALETE.C06 }} className="standard-col-col-arrow" />
                        <div>
                          <Typography className="standard-title" style={{ color: PALETE.C06 }}>{v[0].programme}</Typography>
                          <Typography className="standard-subtitle">{v.length} Projects</Typography>
                        </div>
                      </div>
                    </Col>
                    <Col span={2} style={{alignItems: 'center'}} className="standard-col-col">
                      {/* <Typography className="standard-data">{t("Status Time")}<br /><span>{STATUS[v[0].statusTime]}</span></Typography> */}
                      <Semaphore value={v[0].statusTime}/>
                    </Col>
                    <Col span={2} style={{alignItems: 'center'}} className="standard-col-col">
                      {/* <Typography className="standard-data">{t("Status Cost")}<br /><span>{STATUS[v[0].statusCoast]}</span></Typography> */}
                      <Semaphore value={v[0].statusCoast}/>
                    </Col>
                    {/* <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Status Scope")}<br /><span>{STATUS[v[0].statusScope]}</span></Typography>
                    </Col> */}
                    <Col span={2} style={{alignItems: 'center'}} className="standard-col-col">
                      {/* <Typography className="standard-data">{t("Status Risk")}<br /><span>{STATUS[v[0].statusRisk]}</span></Typography> */}
                      <Semaphore value={v[0].statusRisk}/>
                    </Col>
                    <Col span={2} style={{alignItems: 'center'}} className="standard-col-col">
                      {/* <Typography className="standard-data">{t("Status Issues")}<br /><span>{STATUS[v[0].statusIssue]}</span></Typography> */}
                      <Semaphore value={v[0].statusIssue}/>
                    </Col>
                    <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Budget Plan")}<br /><span>{currency(sumBudgetPlan(v))}</span></Typography>
                    </Col>
                    <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Total Forecast")}<br /><span>{currency(sumTotalForecast(v))}</span></Typography>
                    </Col>
                    <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Budget Real")}<br /><span>{currency(sumBudgetReal(v))}</span></Typography>
                    </Col>
                    <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Operational")}<br /><span><Progress size={['100%', 13]} percent={getOperationalProgressMedia(v)} showInfo={false} strokeColor={`${PALETE.C06}3a`} /></span></Typography>
                    </Col>
                    <Col span={2} className="standard-col-col">
                      <Typography className="standard-data">{t("Financial")}<br /><span><Progress size={['100%', 13]} percent={getFinancialProgressMedia(v)} showInfo={false} strokeColor={`${PALETE.C06}3a`} /></span></Typography>
                    </Col>

                  </Row>
                </Col>
              ))}
            </div>
          )
        }
      </Col>
    </Row>
  )

}

export default BusinessDetail;
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import * as S from './styles';
import { debounce } from "utils";
import Loading from "react-loading";

export interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  /** 
   * **Os valores declarados aqui são conforme configurado no arquivo de Tema**
   * - **default** = 16px (when not declared);
   * - **extraSmall** = 10px;
   * - **small** = 12px;
   * - **medium** = 14px;
   * - **large** = 20px;
   * - **extraLarge** = 24px;
   * - **extraExtraLarge** = 32px;
   */
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'extraExtraLarge';
  type?: 'primary' | 'secondary' | 'plain';
  loading?: boolean;
  /**
   * ** O valor passado aqui significa quanto tempo em MiliSegundos tera a duração da barra de progresso**
   * - Ex: autoClick={2000}
   * Isso significa que a barra de progresso demorará 2 segundos para concluir
   */
  autoClick?: number;
}

export interface ButtonRef {
  addEventListener(ev: Event["type"], listner: EventListener): void;
  removeEventListener(ev: Event["type"], listner: EventListener): void;
  setIsLoading(arg: boolean): void;
}

const Button = forwardRef<ButtonRef, Props>(function({
  autoClick,
  ...props
}: Props, forwardRef) {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [myLoading, setMyLoading] = useState(false);
  const [autoClickFID, setAutoClickFID] = useState<NodeJS.Timeout>();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const setXY = (e: MouseEvent) => setMousePos({ x: e.offsetX, y: e.offsetY });

  function hoverEvent() {
    clearTimeout(autoClickFID);
    setAutoClickFID(undefined);
  }

  useLayoutEffect(() => {
    if (buttonRef.current) buttonRef.current.addEventListener('mousemove', setXY);
    if (buttonRef.current) buttonRef.current.addEventListener('mouseover', hoverEvent);
    return () => {
      if (buttonRef.current) buttonRef.current.removeEventListener('mousemove', setXY);
      if (buttonRef.current) buttonRef.current.removeEventListener('mouseover', hoverEvent);
    };
  }, []);

  useEffect(() => {
    if(props.loading !== myLoading) {
      setMyLoading(props.loading || false);
    }
  }, [props.loading]);

  useEffect(() => {
    if(autoClick && !autoClickFID) {
      const timeOutFID = setTimeout(() => {
        buttonRef?.current?.click();
      }, autoClick);
      console.log('timeOutId', timeOutFID);
      setAutoClickFID(timeOutFID);
    }
  }, [autoClick])

  function clickDelay(ev: React.MouseEvent<HTMLButtonElement>) {
    if (props.onClick && !myLoading) {
      setTimeout(props.onClick, 50);
    }
  }

  useImperativeHandle(forwardRef, () => ({
    addEventListener(ev: Event['type'], listner: EventListener) {
      buttonRef?.current?.addEventListener(ev, listner);
    },
    removeEventListener(ev: Event['type'], listner: EventListener) {
      buttonRef?.current?.removeEventListener(ev, listner);
    },
    setIsLoading(arg: boolean) {
      setMyLoading(arg);
    }
  }), [setMyLoading]);

  return (
    <S.Button
      ref={buttonRef}
      {...mousePos}
      {...props}
      size={props.size || 'small'}
      type={props.type || 'primary'}
      onClick={debounce(clickDelay, 50)}
    >
      {myLoading ? <Loading type="spin" width={17.8} height={17.8} /> : props.children}
      {autoClick && autoClickFID && <S.AutoClickProgressBar duration={autoClick}/>}
    </S.Button>
  );
})

export default Button;

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Label from './components/Label';
import Value from './components/Value';

import { SpinLoading } from "shared/components";

import {
  getAllInitiatives,
  GetAllInitiativesResponse,
} from 'bto_now/api';
 
import { getUser } from 'services';
import { debounce } from 'utils';

import * as S from './styles';

interface Props {
  style: React.CSSProperties;
  projectName?: string;
  projectId?: string;
  startDate?: string;
  goLiveDate?: string;
  actualStatus?: string;
  sponsor?: string;
  itBusinessPartner?: string;
  projectManager?: string;
  providerSolution?: string;
}

function ProjectHeader({
  style,
  projectName,
  projectId,
  startDate,
  goLiveDate,
  actualStatus,
  sponsor,
  itBusinessPartner,
  projectManager,
  providerSolution,
}: Props) {

  const { t } = useTranslation();

  const [loading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState<GetAllInitiativesResponse>();

  // TODO: Trazer a chamada da API pra dentro do componente
  useEffect(() => {
    setIsLoading(true);
    getAllInitiatives({ user: getUser() })
    .then(({ data }) => {
      const response = data.filter(project => project.projectId === projectId)
      if (response.length > 0) {
        setProjectData(response)
        setIsLoading(false)
      }
    })
    .catch(err => console.log(err))
  }, [projectId]);

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), 'dd.MM.yyyy');
  };

  return (
    <S.Container style={style}>
      <S.GrayContainer>
        <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {loading ? (
            <SpinLoading isProjectHeader />
          ) :
            projectData?.map(project => (
              <React.Fragment key={projectId}>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project Name")}: </Label><Value>{project.initiativeName || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project ID")}: </Label><Value>{project.projectId || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Start Date")}: </Label><Value>{project.startDate ? formatDate(project.startDate) : ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Go-Live Date")}: </Label><Value>{project.goLiveDate ? formatDate(project.goLiveDate) : ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Actual Status")}: </Label><Value>{project.actualStatusDescription || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Sponsor")}: </Label><Value>{project.sponsor || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("IT Business Partner")}: </Label><Value>{project.itBusinessPatner || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project Manager")}: </Label><Value>{project.projectManager || ''}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Provider Solution")}: </Label><Value>{project.providers || ''}</Value></Col>
              </React.Fragment>
          ))
        }
        </Row>
      </S.GrayContainer>
    </S.Container>
  );
}

export default ProjectHeader;
import { Row } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';

const GraphComponent = ( { title, icon = null, children } ) => {

    const { t } = useTranslation();
    return (
        <div className="graph-card">
            <Row justify={'space-between'}>
                <p className='graph-title'>{t(title)}</p>
                {icon}
            </Row>
            {children}
        </div>
    )

}

export default GraphComponent;
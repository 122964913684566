import React, { useState, useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useTable } from 'react-table';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport.ts';
import { dateMask } from 'utils';
import { useTranslation } from "react-i18next";
const { useParams } = require("react-router-dom");

const Risks = () => {  
  const { id } = useParams();
  const { t } = useTranslation();
  const { getStatusReport} = useStatusReportStore();
  const { typeStatusReport } = useTypeStatusReportStore();
  
  const [dataApi, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      getStatusReport(Number(id), typeStatusReport)
      .then((data) => {
        if (data) {
          setData(data.projectRisks);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
}, []);

  const columns = React.useMemo(() => [
    {
      Header: t('Description'),
      accessor: 'description',
    },
    {
      Header: t('Status'),
      accessor: 'statusMitigation'
    },
    {
      Header: t('Last Update'),
      accessor: 'lastUpdate',
      Cell: ({ value }) => dateMask(value)
    }
  ], []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: dataApi });

  if (!dataApi.projectRisks & loading) {
    return (
      <Card style={{ height: '350px' }}> 
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: '350px' }}> 
      <Card.Header>
        <h3 style={{ marginTop: '0.5rem', marginBottom: '0', fontSize: '16px' }}>
          {t('Risks')}
        </h3>
      </Card.Header>
      <Card.Body className="p-0" style={{ overflowY: 'auto', paddingBottom: '0' }}>
        <table {...getTableProps()} className="table table-striped">
          <thead style={{ fontSize: '13px' }}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const { key, ...remainingProps } = column.getHeaderProps(); 
                  return (
                    <th key={key} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} {...remainingProps}>{column.render('Header')}</th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{fontSize: '13px'}}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr key={row.original.scopeId} {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td 
                    key={cell.column.id} 
                    title={cell.value}
                    style={{ 
                      maxWidth: '270px', 
                      whiteSpace: 'nowrap', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis' 
                    }} 
                    className={cell.getCellProps().className}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
          </tbody>
        </table>
      </Card.Body>
  </Card>
  );
};

export default Risks;

import {
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    Modal,
    Row,
    Typography,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// STYLES
import "./style.css";

// IMAGENS
import logo from "shared/assets/img/BTONow_logo-sem_assinatura_Terciaria-Negativo.png";

// ICONES
import {
    IoLockClosedOutline,
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoTwitter,
    IoMailOutline,
} from "react-icons/io5";

// API
import { POST_API, POST_CATCH, setToken, setUser, setUserName } from '../../../services';
import ForgotPassword from './components/ForgotPassword';
import { useTranslation } from "react-i18next";

const Login = () => {
    const { t } = useTranslation();

    const navigate = useNavigate()

    const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
    const [load, setLoad] = useState(false);


    const showForgotPasswordModal = () => {
        setForgotPasswordVisible(true);
    };
    const onSend = (values) => {

        setLoad(true)
        POST_API('/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                setUser(res.user.email)
                setUserName(res.user.name)
                navigate('dashboard/mng')
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(POST_CATCH).finally(() => setLoad(false))

    }


    return (
        <Row className="login-content" align={'middle'} justify={'center'}>
            <Col md={12} xs={24} className='login-logo-col'>
                <Image src={logo} preview={false} className='login-logo' />
                <Row gutter={[16, 16]}>
                    <Col><div className='login-social'><IoLogoFacebook /></div></Col>
                    <Col><div className='login-social'><IoLogoInstagram /></div></Col>
                    <Col><div className='login-social'><IoLogoTwitter /></div></Col>
                </Row>
            </Col>
            <Col md={12} xs={24}>
                <div className='login-form'>
                    <Typography className='title-login'>{t("Login")}</Typography>
                    <Typography className='subtitle-login'>{t("Por favor, preencha suas informações abaixo")}</Typography>
                    <Form layout='vertical' onFinish={onSend}>
                        <Form.Item className='login-input' name='EMAIL' rules={[{ required: true, message: t('Campo obrigatório.') }, { type: 'email', message: t('E-mail inválido') }]}>
                            <Input addonBefore={<IoMailOutline />} size='large' placeholder='E-mail' className='input-outlined' />
                        </Form.Item>
                        <Form.Item className='login-input' name="PASSWORD" rules={[{ required: true, message: t('Campo obrigatório.') }]}>
                            <Input addonBefore={<IoLockClosedOutline />} type='password' size='large' placeholder='Senha' className='input-outlined' />
                        </Form.Item>
                        <center><Button loading={load} htmlType='submit' className='login-button' type='primary'>{t("Entrar")}</Button></center>
                    </Form>
                    <Divider />
                    <Typography className='subtitle-login forgot' onClick={showForgotPasswordModal}>Esqueceu a senha?</Typography>
                    <ForgotPassword visible={forgotPasswordVisible} onClose={() => setForgotPasswordVisible(false)} />
                </div>
            </Col>
        </Row>
    )
}


export default Login;


import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;

  .table-cell {
    padding: 8px 16px; 
    width: 150px;
  }

  .status-text {
    width: 100%;
  } 
`;

export const FormContainer = styled.div`
  display: flex; 
  padding-top: 7;
  flex-wrap: wrap;
  gap: 12px;
`;
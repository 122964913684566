import styled from "styled-components";
import { Col as defaultCol, ColProps as DefaultColProps } from "antd";

type SemaphoreColor = 'gray' | 'success' | 'warning' | 'danger';

interface ColProps extends DefaultColProps {
  semaphoreColor?: SemaphoreColor;
  size?: string;  // Propriedade size para definir o tamanho
}

export const Col = styled(defaultCol)<ColProps>`
  display: flex;
  align-items: center;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  background-color: ${({ semaphoreColor }) => {
    switch (semaphoreColor) {
      case 'gray':
        return 'gray';
      case 'success':
        return 'green';
      case 'warning':
        return 'yellow';
      case 'danger':
        return 'red';
      default:
        return 'gray';
    }
  }};
`;
import { Col, Modal, Progress, Row, Typography } from "antd";
import * as echarts from 'echarts';
import { useEffect, useState } from "react";
import KPIComponent from "shared/components/KPI";
import { getToken, getUser, PALETE, POST_API, STATUS } from "../../../../../services";
import { IoChevronDown, IoChevronForward, IoPencil } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { IoIosLink } from "react-icons/io";
import { useTranslation } from 'react-i18next';


import { getBusinessDriver, GetBusinessDriverResponse } from 'bto_now/api';
import { currency } from "utils";
import { SpinLoading } from 'shared/components';
import { ComponentHeader } from 'shared/layouts';
import Semaphore from "shared/components/SemaphoreVariant";


const General = () => {
    const { t } = useTranslation();

  const { BUSINESS } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<GetBusinessDriverResponse>();


  const loadGraphBar01 = () => {

    var chartDom = document.getElementById('graphBar01');
    if (!chartDom) return;

    var myChart = echarts.init(chartDom);
    var option;

    const toStart = data?.businessDriverOperational.map(v => v.toStart)
    const inProgress = data?.businessDriverOperational.map(v => v.inProgress)
    const finished = data?.businessDriverOperational.map(v => v.finished)

    option = {
      textStyle: { fontFamily: 'Poppins, sans-serif' },
      color: [PALETE.C06, PALETE.C03, PALETE.C02],
      // dataZoom: [{ type: 'inside' }],
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      grid: { left: '-10px', right: '10px', bottom: '0px', top: '10px', containLabel: true },
      xAxis: [{ type: 'category', show: true, axisLabel: { fontSize: '.7em', width: 70, interval: 0 }, data: data?.businessDriverOperational.map(d => d.businessDriver) }],
      yAxis: [{ type: 'value', show: false }],
      series: [
        { name: t('To Start'), barWidth: 40, type: 'bar', stack: 'total', data: toStart, label: { show: false, position: 'inside', fontSize: '.7em' }, showBackground: true, },
        { name: t('In Progress'), barWidth: 40, type: 'bar', stack: 'total', data: inProgress, label: { show: false, position: 'inside', fontSize: '.7em' }, showBackground: true, },
        { name: t('Finished'), barWidth: 40, type: 'bar', stack: 'total', data: finished, label: { show: false, position: 'inside', fontSize: '.7em' }, showBackground: true, },
      ]
    };

    option && myChart.setOption(option);
  }

  const loadGraphBar02 = () => {

    var chartDom = document.getElementById('graphBar02');
    if (!chartDom) return;

    var myChart = echarts.init(chartDom);
    var option;

    const budgetPlan = data?.businessDriverFinancial.map(v => v.budgetPlan);
    const budgetReal = data?.businessDriverFinancial.map(v => v.budgetReal);

    option = {
      textStyle: { fontFamily: 'Poppins, sans-serif' },
      color: [PALETE.C06, PALETE.C03, PALETE.C02],
      // dataZoom: [{ type: 'inside' }],
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      grid: { left: '-30px', right: '10px', bottom: '0px', top: '10px', containLabel: true },
      xAxis: [{ type: 'category', axisLabel: { fontSize: '.7em', width: 70, interval: 0 }, data: data?.businessDriverFinancial.map(d => d.businessDriver) }],
      yAxis: [{ type: 'value', show: false, axisLabel: { fontSize: '.7em', formatter: (v: any) => `$ ${v}` } }],
      series: [
        { name: 'Budget Plan', barWidth: 40, type: 'bar', data: budgetPlan, label: { show: false, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: [5, 10, 5, 10], fontSize: '.7em', formatter: currency, }, showBackground: true },
        { name: 'Budget Real', barWidth: 40, type: 'bar', data: budgetReal, label: { show: false, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: [5, 10, 5, 10], fontSize: '.7em', formatter: currency, }, showBackground: true },
      ]
    };

    option && myChart.setOption(option);
  }

  useEffect(() => {
    loadGraphBar01();
    loadGraphBar02();

    // window.addEventListener('resize', () => {
    //   loadGraphBar01();
    //   loadGraphBar02();
    //   console.log('redmensionando')
    // });
    
    // return () => {
    //   window.removeEventListener('resize', () => {
    //     loadGraphBar01();
    //     loadGraphBar02();
    //     console.log('redmensionando')
    //   });
    // }
  }, [loading]);

  const [load, setLoad] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [select, setSelect] = useState(null);

  const onSelect = (index: any) => {

    var items: any = select;

    if (typeof select?.[index] !== 'undefined') {
      items = { ...items, [index]: !items[index] }
    } else {
      items = { ...items, [index]: true };
    }

    setSelect(items)

  }

  const onload = () => {
    POST_API('/load.php', { token: getToken(), user: getUser(), period: 'Quarter', size: 50, groupBy: 'businessDrivers', search: '' }).then(rs => rs.json()).then(res => {
      if (res.return) {
        setBlocks(res.data)
      } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
    }).catch(() => null).finally(() => setLoad(false))
  }

  useEffect(() => {
    onload();
    getBusinessDriver({ User: getUser(), BusinessDriverId: BUSINESS })
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [])

  return (
    <Col>
      {
        loading ? (
          <SpinLoading />
        ) : (
          <Row gutter={[12, 12]} style={{ flexWrap: 'wrap' }}>
            <Col style={{ width: '100%' }}>
              <Row gutter={[12, 12]} style={{ flexWrap: 'wrap' }}>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Total Programs")} value={data?.totalPrograms} type="project" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Total Projects")} value={data?.totalProjects} type="project" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("To Start")} value={data?.toStart} type="project" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("In Progress")} value={data?.inProgress} type="project" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Finished")} value={data?.finished} type="project" size="sm" />
                </Col>
              </Row>
              <Row gutter={[12, 12]} style={{ marginTop: 12, flexWrap: 'nowrap' }}>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Total Budget Plan")} value={currency(data?.totalBudgetPlan)} type="money" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Total Forecast")} value={currency(data?.totalForecast)} type="money" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Total Budget Real")} value={currency(data?.totalBudgetReal)} type="money" size="sm" />
                </Col>
                <Col style={{ flex: 1 }}>
                  <KPIComponent title={t("Consumed")} value={data?.consumed} type="percent" size="sm" />
                </Col>
              </Row>
            </Col>
            {/* <div style={{ width: '100%', display: 'flex', padding: '0 6px' }}>
              <div style={{ width: '100%', backgroundColor: 'white', paddingBottom: 54, paddingTop: 12, borderRadius: 8, marginRight: 6 }}>
                <div style={{ display: 'block', width: '100%', height: 202 }}>
                  <Typography style={{ marginLeft: 12, marginBottom: 18, fontWeight: 'bold', fontSize: 12 }}>{t("Operational Analysis")}</Typography>
                  <div id="graphBar01" style={{ height: 202 }} />
                </div>
              </div>

              <div style={{ width: '100%', backgroundColor: 'white', paddingBottom: 54, paddingTop: 12, borderRadius: 8, marginLeft: 6 }}>
                <div style={{ display: 'block', width: '100%', height: 202 }}>
                  <Typography style={{ marginLeft: 12, marginBottom: 18, fontWeight: 'bold', fontSize: 12 }}>{t("Financial Analysis")}</Typography>
                  <div id="graphBar02" style={{ height: 202 }} />
                </div>
              </div>
            </div> */}
          </Row>
        )
      }

      <div style={{ background: 'white', marginTop: 12, padding: 12, borderRadius: 8 }}>
        <ComponentHeader
            title={t('List Overview')}
            menuList={[
              { label: t('Business Drivers'), value: 'l', onClick: () => {} }
            ]}
          />
          {load ? <SpinLoading /> : (
            <>
              {blocks.map((v: any, i) => (
                <>
                  {select?.[i] ? (
                    <>
                      <Col span={24} key={i} className="standard-col select" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} onClick={() => onSelect(i)}>
                        <Row className="standard-row">
                          <Col span={24} className="standard-col-col first">
                            <IoChevronDown style={{ color: v.color }} className="standard-col-col-arrow" />
                            <Typography className="standard-title" style={{ color: v.color }}>{v.name}</Typography>
                          </Col>
                        </Row>
                      </Col>
                      {select?.[i] ? (
                        <Col span={24} className="standard-col item" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} >
                          <Row className="standard-row">
                            <Col flex={'40px'} className="standard-col-subcol"></Col>
                            <Col flex={'40px'} className="standard-col-subcol"></Col>
                            <Col flex={'400px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Project name")}</Typography>
                            </Col>
                            <Col flex={'150px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Project ID")}</Typography>
                            </Col>
                            <Col flex={'100px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Status Time")}</Typography>
                            </Col>
                            <Col flex={'100px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Status Cost")}</Typography>
                            </Col>
                            <Col flex={'250px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Program Name")}</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Sponsor")}</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("IT Business Partner")}</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Business Area")}</Typography>
                            </Col>
                            <Col flex={'130px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Start Date")}</Typography>
                            </Col>
                            <Col flex={'130px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Go-Live Date")}</Typography>
                            </Col>
                            <Col flex={'130px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("End Date")}</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Actual Phase")}</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Operational Progress")}(%)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Total Budget")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Extra Budget")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Forecast")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Consumed")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Available")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Total Investment")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Total Capex Real")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Total Opex Real")}($)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Financial Progress")}(%)</Typography>
                            </Col>
                            <Col flex={'180px'} className="standard-col-subcol">
                              <Typography className="standard-data">{t("Total Running Cost")}($)</Typography>
                            </Col>
                          </Row>
                          {v.items.map((v1: any, i1: any) => (
                            <Row className="standard-row" style={{ width: '4130px' }}>
                              <Col flex={'40px'} className="standard-col-subcol">
                                <Link to={`/dashboard/initiative/${v.id}/${v1.initiativeId}`}><Typography style={{ width: '20px' }} className="standard-data item center"><IoIosLink className="standard-data-action" size={16} /></Typography></Link>
                              </Col>
                              <Col flex={'40px'} className="standard-col-subcol">
                                <Link to={`/dashboard`}>
                                  <Typography style={{ width: '20px' }} className="standard-data item center">
                                    <IoPencil className="standard-data-action" size={16} />
                                  </Typography>
                                </Link>
                              </Col>
                              <Col flex={'400px'} className="standard-col-subcol">
                                <Typography className="standard-data item" style={{ width: '379px' }}>{v1.initiativeName}</Typography>
                              </Col>
                              <Col flex={'150px'} className="standard-col-subcol">
                                <Typography className="standard-data item center" style={{ width: '129px' }}>{v1.initiativeId}</Typography>
                              </Col>
                              <Col flex={'100px'} style={{alignItems: 'center',}} className="standard-col-subcol">
                                <Semaphore value={v1.statusTime} />
                              </Col>
                              <Col flex={'100px'} style={{alignItems: 'center',}} className="standard-col-subcol">
                                <Semaphore value={v1.statusTime} />
                              </Col>
                              <Col flex={'250px'} className="standard-col-subcol">
                                <Typography className="standard-data item" style={{ width: '229px' }}>{v1.mainInitiative}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item" style={{ width: '159px' }}>{v1.sponsor}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item" style={{ width: '159px' }}>{v1.itBusinessPatner}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item" style={{ width: '159px' }}>{v1.businessArea}</Typography>
                              </Col>
                              <Col flex={'130px'} className="standard-col-subcol">
                                <Typography className="standard-data item center" style={{ width: '109px' }}>{v1.startDate_format}</Typography>
                              </Col>
                              <Col flex={'130px'} className="standard-col-subcol">
                                <Typography className="standard-data item center" style={{ width: '109px' }}>{v1.goLiveDate_format}</Typography>
                              </Col>
                              <Col flex={'130px'} className="standard-col-subcol">
                                <Typography className="standard-data item center" style={{ width: '109px' }}>{v1.endDate_format}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item">{v1.actualPhaseDescription}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol bar">
                                <Progress size={['100%', 13]} percent={v1.operationalProgress} showInfo={false} strokeColor={`${v.color}3a`} />
                                <Typography className="standard-data item center absolute">{v1.operationalProgress}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.totalRequested ? (v1.totalRequested).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.extraBudget ? (v1.extraBudget).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.forecast ? (v1.forecast).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.consumed ? (v1.consumed).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.available ? (v1.available).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.totalInvestiment ? (v1.totalInvestiment).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.totalInvestimentCapex ? (v1.totalInvestimentCapex).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {v1.totalInvestimentOpex ? (v1.totalInvestimentOpex).toLocaleString('pt-BR') : 0}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol bar">
                                <Progress size={['100%', 13]} percent={v1.financialProgress} showInfo={false} strokeColor={`${v.color}3a`} />
                                <Typography className="standard-data item center absolute">{v1.financialProgress}</Typography>
                              </Col>
                              <Col flex={'180px'} className="standard-col-subcol">
                                <Typography className="standard-data item money"><span>$</span> {(v1.totalRunningCoast).toLocaleString('pt-BR')}</Typography>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Col span={24} key={i} className="standard-col" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} onClick={() => onSelect(i)}>
                        <Row className="standard-row">
                          <Col span={16} className="standard-col-col first">
                            <div style={{ display: 'flex' }}>
                              <IoChevronForward style={{ color: v.color }} className="standard-col-col-arrow" />
                              <div>
                                <Typography className="standard-title" style={{ color: v.color }}>{v.name}</Typography>
                                <Typography className="standard-subtitle">{`${v.items.length} ${t("Initiatives")}`}</Typography>
                              </div>
                            </div>
                          </Col>
                          <Col span={2} className="standard-col-col">
                            <center><Link to={`/dashboard/initiative/${v.id}`}><Typography style={{ width: '20px' }} className="standard-data-title item center"><IoIosLink className="standard-data-title-action" size={22} /></Typography></Link></center>
                          </Col>
                          <Col span={3} className="standard-col-col">
                            <Typography className="standard-data">{t("Budget Plan")}<br /><span>{currency(v.budgetPlan)}</span></Typography>
                          </Col>
                          <Col span={3} className="standard-col-col">
                            <Typography className="standard-data">{t("Total Forecast")}<br /><span>{currency(v.totalForecast)}</span></Typography>
                          </Col>
                          <Col span={3} className="standard-col-col">
                            <Typography className="standard-data">{t("budget Real")}<br /><span>{currency(v.budgetReal)}</span></Typography>
                          </Col>
                          <Col span={3} className="standard-col-col">
                            <Typography className="standard-data">{t("Start date")}<br /><span>{v.startDate_format}</span></Typography>
                          </Col>
                          <Col span={3} className="standard-col-col">
                            <Typography className="standard-data">{t("End date")}<br /><span>{v.endDate_format}</span></Typography>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </>

              ))}
            </>
          )}
      </div>
    </Col>
  )

}

export default General;
// src/shared/components/GraphBar/GraphBar.tsx

import React, { useCallback, useEffect } from 'react';
import * as echarts from 'echarts';
import { PALETE } from 'services';

interface GraphBarProps {
  eixoX: string[] | undefined;
  data01: number[] | undefined;
  data02: number[] | undefined;
  height?: number;
}

const simulatedData = {
  data: {
      '01': [120, 200, 150, 80, 70, 110, 130],
      '02': [2, 1, 3, 5, 2, 0, 3]  
  }
};
const GraphBarOperational: React.FC<GraphBarProps> = ({ eixoX, data01, data02, height = 500 }) => {

  const loadGraphBar = useCallback(() => {
    const chartDom = document.getElementById('graphBar01');
    if (!chartDom) return;
    
    const myChart = echarts.init(chartDom);

    const option = {
      textStyle: { fontFamily: 'Poppins' },
      color: [PALETE.C03, PALETE.C01],
      legend: {
        center: 'center',
        itemWidth: 14,
        bottom: '-5px',
        width: '100%'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
      },
      grid: {
        left: '5px',
        right: '5px',
        bottom: '30px',
        top: '20px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: eixoX,
          axisTick: { alignWithLabel: true },
          axisLabel: {
            fontSize: 10,
            interval: 0,
            rotate: 0,
            width: 80,
            overflow: 'truncate'
          }
        }
      ],
      yAxis: [
        { 
          type: 'value',
          axisTick: false,
          axisLine: false,
        }
      ],
      // dataZoom: [
      //   {
      //     type: 'inside',
      //     start: 0,
      //     end: 80,
      //   },
      //   {
      //     type: 'slider',
      //     backgroundColor: 'none',
      //     showDataShadow: false,
      //     borderColor: 'none',
      //     handleStyle: {
      //       color: PALETE.C03,
      //     },
      //     moveHandleStyle: {
      //       color: '#988efe',
      //     },
      //   }
      // ],
      series: [
        {
          name: 'On-Going',
          barWidth: 110,
          type: 'bar',
          stack: 'total',
          data: data01,
          label: {
            show: true,
            position: 'inside',
            formatter: (params: any) => {
              return params.value !== 0 ? params.value : '';
            },
          },
          itemStyle: { 
            borderRadius: [0, 0, 0, 0],
            color: PALETE.C04,
          },
        },
        {
          name: 'Finished',
          barWidth: 80,
          type: 'bar',
          stack: 'total',
          data: data02,
          label: {
            show: true,
            position: 'inside',
            formatter: (params: any) => {
              return params.value !== 0 ? params.value : '';
            },
          },
          itemStyle: { 
            borderRadius: [0, 0, 0, 0],
            color: PALETE.C03,
          },
        }
      ]
      
    };

    option && myChart.setOption(option);
    myChart.resize();
  }, [eixoX, data01, data02]);

  useEffect(() => {
    loadGraphBar();
  }, [eixoX, data01, data02, loadGraphBar]);

  return <div id="graphBar01" style={{ height }} />;
};

export default GraphBarOperational;

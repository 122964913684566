import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetArchitectureInformationPayload {
  name?: string;
  mainCapabilities?: string;
  mainPlatformMng?: string;
  processMngScope?: string;
  softwareProvider?: string;
  gartnerConcept?: string;
  user: string;
}

export type GetArchitectureInformationResponse = Array<{
  companyId: number
  plataformId: number
  name: string
  description: string
  mainCapabilities: string
  type: string
  status: string
  responsibleTeams: string
  mainPlatformMng: string
  mainProcessScope: string
  processMngScope: string
  businessStructure: string
  transactionalVolumetry: string
  softwareProvider: string
  servicesProvider: string
  gartnerConcept: string
  integrationType: string
  integrationPoints: string
  platformMngInput: string
  platformMngOutput: string
  platformMngBiDirection: string
  sapIntegration: string
  upgradePlans: string
  geographicalScope: string
  complianceRequirements: string
  riskManagement: string
  performanceMetrics: string
  stakeholders: string
  businessArea: string
  businessDriver: string
  budget: number
  costs: number
  roi: number
  financialImpact: string
  implementationTimeline: string
  maintenanceSchedule: string
  decomissioningDate: string
}>

export function getArchitectureInformation(body: GetArchitectureInformationPayload): Promise<AxiosResponse<GetArchitectureInformationResponse>> {
  return btoApi
    .post('/api/ArchitectureInformation/GetList', body);
}

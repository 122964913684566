import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { Col, ModalError, ModalSuccess, Row, SpinLoading, StatusText, Table, Typography, ButtonCalendarInput } from 'shared/components';
import { useTheme } from 'styled-components';
import { getUser } from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import { getRitualOperational, GetRitualOperationalDraftsResponse, updateDraftTimeline } from 'bto_now/api';
import { buildNavigateString, dateBrToISO, getUrlSearchParams } from 'utils';
import { ButtonRef } from 'shared/components/Button'
import { RitualManagementStatusRitual } from 'utils';
import { formatDateGetDatabase } from 'utils/dates/formatDateGetDatabase';
import { useFineshedTimeline } from 'shared/store-zustand/old-timeline/finishedTimeline';
import { isDateFormatValid } from 'utils/dates/isDateFormatValid';
import { formatDateBeforeSendToBack } from 'utils/dates/formatDateBeforeSendToBack';

interface Props {
  /**
   * **Referência do botão que fica no header do component**
   * 
   * Essa referencia serve para ser interceptado o event de click do botão
   * para dentro do componente de update seja disparado o evento que monta o payload
   * e chama a API para salvar os dados
   */
  saveButtonRef: React.RefObject<ButtonRef>,
}

function RitualOperationalUpdate({
  saveButtonRef
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, ritualNumber } = useParams();
  const { getOperationalData } = useFineshedTimeline();

  const [isNotDraft, setIsNotDraft] = useState(false);

  const [data, setData] = useState<GetRitualOperationalDraftsResponse>();

  const [successShow, setSuccessShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);

  const statusColor = useCallback((color: string) => {
    switch (color) {
      case 'TO START': return 'red';
      case 'ON GOING': return 'yellow';
      case 'FINISHED': return 'green';
    }
  }, []);

  // function calcDevious(phasePlan: number, phaseReal: number): number {
  //   const desviates = phasePlan - phaseReal
  //   return desviates; 
  // }

  // function updateProgressPhaseReal(ev: React.ChangeEvent<HTMLInputElement>, index: number) {
  //   const { value } = ev.target;
  //   if (data && Number(value) <= 100) {
  //     const newData = [...data.phaseTimelineUpdate];
  //     newData[index].percentProgressPhaseReal = Number(value);
  //     if (newData[index]) {
  //       switch (newData[index].percentProgressPhaseReal) {
  //         case 0: {
  //           newData[index].statusPhase = 'TO START';
  //           break;
  //         }
  //         case 100: {
  //           newData[index].statusPhase = 'FINISHED';
  //           break;
  //         }
  //         default: {
  //           newData[index].statusPhase = 'ON GOING';
  //           break;
  //         }
  //       }
  //     }
  //     setData({ ...data, phaseTimelineUpdate: newData });
  //   }
  // }

  function updateDate(ev: React.ChangeEvent<HTMLInputElement>, index: number, field: 'endDateReal' | 'startDateReal') {
    const { value } = ev.target;
    if (data) {
      const newData = [...data.phaseTimelineUpdate];
      newData[index][field] = formatDateBeforeSendToBack(value);
      setData({ ...data, phaseTimelineUpdate: newData });

    }
  }

  // function calcProgressPhasePlan(index: number) {
  //   try {
  //     const line = data?.phaseTimelineUpdate[index];
  //     let toReturn = 0;
  //     if (line) {
  //       const startDate = dateBrToISO(line?.startDate);
  //       const endDate = dateBrToISO(line?.endDate);

  //       if (startDate && endDate) {
  //         if (Date.now() > new Date(endDate).getTime()) toReturn = 100;
  //         else if (new Date(startDate).getTime() > Date.now()) toReturn = 0;
  //         else toReturn = Number(((Date.now() - new Date(startDate).getTime()) / (new Date(endDate).getTime() - new Date(startDate).getTime()) * 100).toFixed());
  //       }

  //       if (data && data.phaseTimelineUpdate[index].percentProgressPhasePlan !== toReturn) {
  //         data.phaseTimelineUpdate[index].percentProgressPhasePlan = toReturn;
  //         setData({ ...data });
  //       }
  //     }

  //     return toReturn;
  //   }
  //   catch (err) {
  //     console.error(new Error('Erro ao tentar calcular ProgressPhasePlan'), err);
  //     return 0;
  //   }
  // }

  // function olderDate() {
  //   if (data?.phaseTimelineUpdate && Array.isArray(data?.phaseTimelineUpdate)) {
  //     const timeline = data.phaseTimelineUpdate;
  //     const dates = timeline.map(v => v.startDate).filter(v => isValidDate(v)).map(v => new Date(dateBrToISO(v)).getTime());
  //     if (dates.length) {
  //       const older = dates.reduce((c, a) => c < a ? c : a);
  //       return older ? date(new Date(older).toISOString()) : '';
  //     } else return '';
  //   } return '';
  // }

  // function mostRecentDate() {
  //   if (data?.phaseTimelineUpdate && Array.isArray(data?.phaseTimelineUpdate)) {
  //     const timeline = data.phaseTimelineUpdate;
  //     const dates = timeline.map(v => v.endDate).filter(v => isValidDate(v)).map(v => new Date(dateBrToISO(v)).getTime());
  //     if (dates.length) {
  //       const mostRecent = dates.reduce((c, a) => c > a ? c : a);
  //       return mostRecent ? date(new Date(mostRecent).toISOString()) : '';
  //     } else return '';
  //   } return '';
  // }

  // function calcProjectPlan() {
  //   if (data?.phaseTimelineUpdate && Array.isArray(data?.phaseTimelineUpdate)) {
  //     const dates = data.phaseTimelineUpdate
  //       .filter(v => v.endDate && v.startDate)
  //       .map(v => ({ endDate: dateBrToISO(v.endDate), startDate: dateBrToISO(v.startDate), progressPhasePlan: v.progressPhasePlan }));

  //     const results = dates.map(dt => {
  //       const startDate = new Date(dt.startDate).getTime();
  //       const endDate = new Date(dt.endDate).getTime();
  //       const older = new Date(dateBrToISO(olderDate())).getTime();
  //       const mostRecent = new Date(dateBrToISO(mostRecentDate())).getTime();

  //       const oneDay = 86400000;
  //       const bias = (endDate + oneDay - startDate) / (mostRecent + oneDay - older);

  //       return dt.progressPhasePlan * bias;
  //     });

  //     return results.map(v => Number(v.toPrecision(3))).reduce((a, c) => a + c, 0);
  //   }
  //   return 0;
  // }

  // function calcProjectReal() {
  //   if (data?.phaseTimelineUpdate && Array.isArray(data?.phaseTimelineUpdate)) {
  //     const dates = data.phaseTimelineUpdate
  //       .filter(v => v.endDate && v.startDate)
  //       .map(v => ({ endDate: dateBrToISO(v.endDate), startDate: dateBrToISO(v.startDate), progressPhaseReal: v.progressPhaseReal }));

  //     const results = dates.map(dt => {
  //       const startDate = new Date(dt.startDate).getTime();
  //       const endDate = new Date(dt.endDate).getTime();
  //       const older = new Date(dateBrToISO(olderDate())).getTime();
  //       const mostRecent = new Date(dateBrToISO(mostRecentDate())).getTime();

  //       const oneDay = 86400000;
  //       const bias = (endDate + oneDay - startDate) / (mostRecent + oneDay - older);

  //       return dt.progressPhaseReal * bias;
  //     });

  //     return results.map(v => Number(v.toPrecision(3))).reduce((a, c) => a + c, 0);
  //   }
  //   return 0;
  // }


  
  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  const saveButton = useCallback(function () {
    if (data?.phaseTimelineUpdate) {
      const payload = data.phaseTimelineUpdate
        // .filter(v => v.endDateReal && v.startDateReal)
        .map(v => ({
          user: getUser() as string,
          projectId: projectId as string,
          phaseExecutionId: v.phaseExecutionId,
          startDate: isDateFormatValid(v.startDateReal) ? dateBrToISO(v.startDateReal) : v.startDateReal,
          endDate: isDateFormatValid(v.endDateReal) ? dateBrToISO(v.endDateReal!) : v.endDateReal,
          progressPhaseReal: v.percentProgressPhaseReal,
          dateType: 'R',
        }))

      saveButtonRef?.current?.setIsLoading(true);

      const params = {
        user: getUser(),
        projectId: projectId,
        ritualNumber: Number(ritualNumber)
      }

      updateDraftTimeline(payload, params)
        .then(({ data }) => {
          if (data) {
            console.log(data)
            setSuccessShow(true);
          } else {
            setErrorShow(true);
          }
        })
        .catch(() => setErrorShow(true))
        .finally(() => {
          saveButtonRef?.current?.setIsLoading(false);
        });
    }
  }, [data]);

  function navToFinancial() {
    setSuccessShow(false);
    const ritualName = getUrlSearchParams('ritualName');
    const url = buildNavigateString({
      path: document.location.pathname.replace('operational', 'financial'),
      params: { ritualName }
    });

    navigate(url);
  }

  useEffect(() => {
    const statusRitual = getUrlSearchParams('statusRitual') as RitualManagementStatusRitual;
    setIsNotDraft(statusRitual === 'Active' || statusRitual === 'History');

    getRitualOperational({ User: getUser(), ProjectId: projectId as string, RitualNumber: Number(ritualNumber) })
      .then(({ data }) => {
        setData(data)
        // if (Array.isArray(data.phaseTimelineUpdate)) {
        //   const transformedData = data.phaseTimelineUpdate.map(v => ({
        //     ...v,
        //     startDate: date(v.startDate),
        //     endDate: date(v.endDate)
        //   }));

        //   setData({
        //     ...data,
        //     phaseTimelineUpdate: transformedData
        //   });
        // }
      });
  }, [projectId, ritualNumber]);

  useEffect(() => {
    saveButtonRef.current?.addEventListener('click', saveButton);
    if (data) getOperationalData(data)

    return () => {
      saveButtonRef.current?.removeEventListener('click', saveButton);
    };
  }, [saveButtonRef, data]);

  return (
    <S.Container>

      <ModalSuccess autoClose={3000} open={successShow} title='Success!' description='Data updated successfully' onOk={navToFinancial} />
      <ModalError autoClose={3000} open={errorShow} title='Error!' description='Unable to update data' onOk={() => setErrorShow(false)} />

      <Typography weight='bold' size='medium'>Timeline Update</Typography>

      {
        !data ? (
          <SpinLoading />
        ) : (
          <S.ScrollX>
            <Row style={{ width: '100%', }}>
              <Col style={{ width: '100%', marginRight: theme.spacing.sm }}>
                <Table style={{ flex: 1 }}>

                  <Table.Tr header>
                    <Table.Td style={{ padding: '4px 16px'}}>Phase</Table.Td>
                    <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Status</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>Start Date Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>End Date Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>% Progress Phase Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>Start Date Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>End Date Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>% Progress Phase Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px'}}>% Deviation</Table.Td>
                  </Table.Tr>
                  {data.phaseTimelineUpdate.map((d, index) => (
                    <Table.Tr key={index}>
                      <Table.Td style={{ padding: '4px 16px'}}>{d.phase}</Table.Td>
                      <Table.Td style={{ padding: '4px 16px'}}>
                        <StatusText color={statusColor(d.statusPhase)} size='extraSmall' weight='bold'>{d.statusPhase}</StatusText>
                      </Table.Td>
                      <Table.Td style={{ width: 100, padding: '4px 16px' }}>
                        <ButtonCalendarInput
                          disabled={isNotDraft}
                          value={(formatDate(d.startDatePlan))}
                          // onChange={(ev) => updateDate(ev, index, 'startDate')}
                          readOnly
                        />
                      </Table.Td>
                      <Table.Td style={{ width: 100, padding: '4px 16px' }}>
                        <ButtonCalendarInput
                          disabled={isNotDraft}
                          value={(formatDate(d.endDatePlan))}
                          // onChange={(ev) => updateDate(ev, index, 'endDate')}
                          readOnly
                        />
                      </Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>
                        <Table.ProgressBar disabled value={String(d.percentProgressPhasePlan)} progress={d.percentProgressPhasePlan} />
                      </Table.Td>
                      <Table.Td style={{ width: 100, padding: '4px 16px' }}>
                        <ButtonCalendarInput
                          disabled={isNotDraft}
                          value={(formatDateGetDatabase(d.startDateReal))}
                          maxDate={formatDateGetDatabase(d.endDateReal)}
                          onChange={(ev) => updateDate(ev, index, 'startDateReal')}
                          readOnly={d.statusPhase !== 'ON GOING'}
                        />
                      </Table.Td>
                      <Table.Td style={{ width: 100, padding: '4px 16px' }}>
                        <ButtonCalendarInput
                          disabled={isNotDraft}
                          value={(formatDateGetDatabase(d.endDateReal))}
                          minDate={formatDateGetDatabase(d.startDateReal)}
                          onChange={(ev) => updateDate(ev, index, 'endDateReal')}
                          readOnly={d.statusPhase !== 'ON GOING'}
                        />
                      </Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>
                        <Table.ProgressBar disabled value={String(d.percentProgressPhaseReal)} progress={d.percentProgressPhaseReal} />
                      </Table.Td>
                      <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{d.percentAtraso}%</Table.Td>
                    </Table.Tr>
                  ))}

                  <Table.Tr header>
                    <Table.Td style={{ padding: '4px 16px'}}>Project</Table.Td>
                    <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Status</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>Start Date Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>End Date Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>% Progress Phase Plan</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>Start Date Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>End Date Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>% Progress Phase Real</Table.Td>
                    <Table.Td style={{ padding: '4px 16px' }}>% Deviation</Table.Td>
                  </Table.Tr>
                  {data.projectTimelineUpdate.map((m, index) => (
                    <Table.Tr key={index} style={{ height: 71 }}>
                      <Table.Td style={{ padding: '4px 16px' }}>{m.project}</Table.Td>
                      <Table.Td style={{ width: 200, padding: '4px 16px' }}>
                        <StatusText color={statusColor(m.statusPhase)} size='extraSmall' weight='bold'>{m.statusPhase}</StatusText>
                      </Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>{formatDateGetDatabase(m.startDatePlan)}</Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>{formatDateGetDatabase(m.endDatePlan)}</Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>
                        <Table.ProgressBar disabled value={String(m.percentProgressPhasePlan)} progress={m.percentProgressPhasePlan} /></Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>{formatDateGetDatabase(m.startDateReal)}</Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>{formatDateGetDatabase(m.endDateReal)}</Table.Td>
                      <Table.Td style={{ padding: '4px 16px' }}>
                        <Table.ProgressBar disabled value={String(m.percentProgressPhaseReal)} progress={m.percentProgressPhaseReal} />
                      </Table.Td>
                      <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{m.percentAtraso}%</Table.Td>
                    </Table.Tr>
                  ))}

                </Table>
              </Col>

              {/* <Table.Tr>
                <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
              </Table.Tr> */}
              {/* <Col>
                <Table style={{ flex: 1, marginLeft: '-16px' }}>
                  <Table.Tr header>
                    <Table.Td>% Progress Phase Plan</Table.Td>
                    <Table.Td>% Progress Phase Real</Table.Td>
                    <Table.Td>% Devious</Table.Td>
                  </Table.Tr>
                  {data.phaseTimelineUpdate.map((m, index) => (
                    <Table.Tr key={index}>
                      <Table.Td><Table.ProgressBar disabled value={String(calcProgressPhasePlan(index))} progress={calcProgressPhasePlan(index)} /></Table.Td>
                      <Table.Td><Table.ProgressBar disabled={isNotDraft} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateProgressPhaseReal(ev, index)} value={String(m.progressPhaseReal)} progress={m.progressPhaseReal} /></Table.Td>
                      <Table.Td>{calcDevious(m.progressPhasePlan, m.progressPhaseReal).toFixed(2)}%</Table.Td>
                    </Table.Tr>
                  ))}

                  <Table.Tr>
                    <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                    <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                    <Table.Td style={{ backgroundColor: 'white', height: 8, border: 'none' }}></Table.Td>
                  </Table.Tr>

                  <Table.Tr header>
                    <Table.Td>% Progress Project Plan</Table.Td>
                    <Table.Td>% Progress Project Real</Table.Td>
                    <Table.Td>% Deviation</Table.Td>
                  </Table.Tr>
                  {data.projectTimelineUpdate.map((m, index) => (
                    <Table.Tr key={index}>
                      <Table.Td><Table.ProgressBar disabled value={calcProjectPlan().toFixed(1)} progress={calcProjectPlan()} /></Table.Td>
                      <Table.Td><Table.ProgressBar disabled value={calcProjectPlanMedia().toFixed(2)} progress={calcProjectPlanMedia()} /></Table.Td>
                      <Table.Td><Table.ProgressBar disabled value={calcProjectReal().toFixed(1)} progress={calcProjectReal()} /></Table.Td>
                      <Table.Td><Table.ProgressBar disabled value={calcProjectRealMedia().toFixed(2)} progress={calcProjectRealMedia()} /></Table.Td>
                      <Table.Td>{calcDeviationsMedia().toFixed(2)}%</Table.Td>
                    </Table.Tr>
                  ))}
                </Table>
              </Col> */}
            </Row>
          </S.ScrollX>
        )
      }

    </S.Container>
  )
}

export default RitualOperationalUpdate;
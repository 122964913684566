import styled, { css } from 'styled-components';

import { Props } from './index';

// background-color: #393556;
//   border: none;
//   color: white;
//   padding: 4px 16px;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   font-size: 14px;
//   margin-left: 8px;
//   cursor: pointer;
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #4c4672;
//   }

//   &:disabled {
//     background-color: #d9d9d9;
//     cursor: not-allowed;
//   }

export const Button = styled.button<{ y: number, x: number, size: Props['size'], type: Props['type'] }>`
  
  border-radius: 4px;

  font-weight: ${p => p.theme.font.weight.medium};
  position: relative;
  transition: background-color .3s ease;
  overflow: hidden;
  cursor: pointer;
  /* box-shadow: 5px 5px 3px rgb(0 0 0 / 0.4); */
  /* box-shadow: ${p => p.theme.colors.blackBoxShadow}; */
  /* border: 1px solid ${p => p.theme.colors.primary['blue']}; */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    background: ${p => p.theme.colors.neutral.white[0]};
    opacity: .2;
    position: absolute;
    width: 0;
    height: 0;
    top: ${p => p.y + 'px'};
    left: ${p => p.x + 'px'};
    transform-style: flat;
    transform: translate3d(-50%,-50%,0);
    border-radius: 100%;
  };
  
  &:active {
    &:after {
      transition: .2s ease-in;
      width: 600px;
      height: 600px;
    }
  };

  ${p => p.type === 'primary' ? css`
    background: #393556;
    /* background: ${p => p.theme.colors.primary['blue']}; */
    color: white;
    &:after {
      /* background: #4c4672; */
      background: ${p => p.theme.colors.neutral.white[0]};
    }
    &:hover {
      background: #4c4672;
      /* background: ${p => p.theme.colors.primary['600']}; */
    }
  ` : p.type === 'secondary' ? css`
      background: #9d94c9;
      color: white;

  /* background: white; */
    /* color: ${p => p.theme.colors.primary['blue']}; */
    &:after {
      background: ${p => p.theme.colors.primary[300]};
    }
    &:hover {
      background: #887fb8;
      /* background: ${p => p.theme.colors.neutral.white['white']}; */
    }
    ` : p.type === 'plain' && css`
      background: transparent;
      border: transparent;
      color: ${p => p.theme.colors.neutral.gray[900]};
      box-shadow: none;
      &:after {
        background: transparent;
      }
      &:hover {
        background: transparent;
        color: ${p => p.theme.colors.neutral.gray[700]};
      }
  `};

  ${p => p.size === 'extraSmall' ? css`
          padding: 0px 12px;
          min-width: 80px;
          height: 20px;
          font-size: ${p => p.theme.font.size.xs};
        ` : p.size === 'small' ? css`
          padding: 0px 12px;
          min-width: 100px;
          height: 25px;
          font-size: ${p => p.theme.font.size.xs};
          ` : p.size === 'medium' ? css`
          padding: 0px 12px;
          min-width: 120px;
          height: 32px;
          font-size: ${p => p.theme.font.size.md};
        ` : p.size === 'large' ? css`
          padding: 0px 12px;
          min-width: 140px;
          height: 40px;
          font-size: ${p => p.theme.font.size.lg};
        ` : css``
  };

  ${p => p.disabled && css`
    background-color: ${p.theme.colors.neutral.gray[100]};
    border-color: ${p.theme.colors.neutral.gray[100]};
    color: ${p.theme.colors.neutral.gray[400]};
    &:after {
      background: ${p.theme.colors.neutral.gray[100]};
    }
    &:hover, &:active {
      color: ${p.theme.colors.neutral.gray[400]};
      background-color: ${p.theme.colors.neutral.gray[100]};
      cursor: not-allowed;
    }
  `}
`;

export const AutoClickProgressBar = styled.div<{ duration?: number }>`
  width: 80%;
  height: 2px;
  background-color: transparent;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  position: absolute;
  margin: auto;
  bottom: 0px;

  &::after {
    content: '';
    display: block;
    background-color: white;
    border-radius: 3px;
    height: 100%;
    width: 100%;
    
    animation-name: autoClickProgress;
    animation-duration: ${p => p.duration}ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes autoClickProgress {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
  
`;

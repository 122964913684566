import { Col, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { IoGrid } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';

import { MdAttachMoney, MdTimelapse } from "react-icons/md";
import { Link } from "react-router-dom";
import GraphComponent from "shared/components/Graph";
import { POST_API, STATUS, getToken, getUser, space } from "../../../../../services";

import { ComponentHeader } from "shared/layouts";

import { getURLPathName } from '../../../../../utils';
import { useTranslation } from "react-i18next";
import { Breadcrumb, Input } from "shared/components";

const MainInitiative = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { MAIN } = useParams();
    const [blocks, setBlocks] = useState([]);

    const onload = () => {
        POST_API('/program.php', { token: getToken(), user: getUser(), main: MAIN, period: 'Quarter', size: 50 })
            .then(rs => rs.json())
            .then(res => {
                if (res.return) {
                    setBlocks(res.data);
                } else {
                    Modal.warning({ title: 'Atenção', content: res.msg });
                }
            })
            .catch(() => null);
    };

    useEffect(() => {
        onload();
    }, []);

    const routes = [
        { title: <IoGrid size={20} style={{ margin: 0 }} /> },
        { title: <Link to="/dashboard/mng">{t("Home")}</Link> },
        { title: <Link to="/dashboard">{t("Portfolio Mng")}</Link> },
        { title: t('Program Mng') }
    ];

    const menuList = [
        { label: t('Home'), value: 'home', onClick: () => navigate(`${getURLPathName()}`) },

    ];

    const navButtons = [
        { value: `${getURLPathName()}/financial`, label: t('Program Financial') },
        { value: `${getURLPathName()}/operational`, label: t('Program Operational') }
    ];

    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
                <ComponentHeader 
                    title={t('Program Mng')}
                    menuList={menuList}
                    buttons={navButtons}
                />
                <Row gutter={space} style={{ marginTop: space[0] }} align={'top'}>
                    <Col span={24} >
                        <GraphComponent>
                            <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>{t("Program Information")}</Typography>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <Input label={t("Program Name")} value={blocks?.programmeGeneralInfo?.programme} disabled containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Business Drivers")} value={blocks?.programmeGeneralInfo?.businessDriver} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                {/* <Col span={12}>
                                    <Input label={t("Business Areas Involved")} value={blocks?.programmeGeneralInfo?.businessArea} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Business Benefits Maps")} value={blocks?.programmeGeneralInfo?.businessBenefits} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Technology Benefits Maps")} value={blocks?.programmeGeneralInfo?.techonologyBenefits} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Sponsorship Initiative")} value={blocks?.programmeGeneralInfo?.sponsor} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("IT Partner Leader")} value={blocks?.programmeGeneralInfo?.itPartnerLeader} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Resource Involved")} value={blocks?.programmeGeneralInfo?.resourceEnvolved} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col>
                                <Col span={12}>
                                    <Input label={t("Provider Involved")} value={blocks?.programmeGeneralInfo?.providerEnvolved} readOnly containerStyle={{ marginTop: 3 }} />
                                </Col> */}
                            </Row>
                            <Typography style={{ fontWeight: 'bold', marginTop: 20 }}>{t("Initiatives")}</Typography>
                            <div style={{ height: '100%', overflowY: 'auto', borderRadius: '5px 5px 5px 5px', marginTop: 8 }} className="canva-table">
                                <Row className="canva-row head">
                                    <Col flex={'50px'} className="canva-col-head">
                                        <Typography className="canva-data">{t("ID")}</Typography>
                                    </Col>
                                    <Col flex={'auto'} className="canva-col-head">
                                        <Typography className="canva-data">{t("Name")}</Typography>
                                    </Col>
                                    <Col flex={'30px'} className="canva-col-head">
                                        <Typography className="canva-data"><MdAttachMoney size={16} /></Typography>
                                    </Col>
                                    <Col flex={'30px'} className="canva-col-head">
                                        <Typography className="canva-data"><MdTimelapse size={16} /></Typography>
                                    </Col>
                                </Row>
                                {blocks?.initiatives?.map((v, i) => (
                                    <Row className={`canva-row ${(i) % 2 !== 0 ? 'alt' : ''}`} key={i} style={{ cursor: 'pointer' }} onClick={() => navigate(v.initiativeId)}>
                                        <Col flex={'50px'} className="canva-col-head">
                                            <Typography className="canva-data">{v.initiativeId}</Typography>
                                        </Col>
                                        <Col flex={'auto'} className="canva-col-head">
                                            <Typography className="canva-data left">{v.initiativeName}</Typography>
                                        </Col>
                                        <Col flex={'30px'} className="canva-col-head">
                                            <Typography className="canva-data">{STATUS[v.statusTime]}</Typography>
                                        </Col>
                                        <Col flex={'30px'} className="canva-col-head">
                                            <Typography className="canva-data">{STATUS[v.statusCoast]}</Typography>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </GraphComponent>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default MainInitiative;
import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetBusinessAreaListPayload {
  User: string;
}

export type GetBusinessAreaListResponse = Array<{
  businessAreaId:         number;
  name:           string;
  user:        string;
}>

export function getBusinessAreaList(params: GetBusinessAreaListPayload): Promise<AxiosResponse<GetBusinessAreaListResponse>> {
  if (!params || !params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });

  return btoApi
   .get('/api/BusinessArea/GetList', { params });
}
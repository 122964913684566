import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetBusinessDriverGroupPayload {
  User?:              string | null;
}
export type GetBusinessDriverGroupResponse = Array<{
  businessDriverId: number;
  businessDriver:   string;
}>;

export function getBusinessDriverGroup(params: GetBusinessDriverGroupPayload): Promise<AxiosResponse<GetBusinessDriverGroupResponse>> {
  if(!params || !params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });
  
  return btoApi
    .get('/api/BusinessDriver/GetBusinessDriverGroup', {
      params 
    });
}

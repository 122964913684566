import PDF, { StyleSheet, Font } from '@react-pdf/renderer';
import { GetAllInitiativesByKanbanResponse } from 'bto_now/api';

import LogoSrc from 'shared/assets/img/BTONow_logo_logo.png';
import LogoText from 'shared/assets/img/BTONow_logo_texto.png';

import { useCallback } from 'react';

import { lightTheme } from 'themes/lightTheme';
import { currency, date, dateWithHours } from 'utils';

import PoppinsRegular from 'shared/assets/fonts/Poppins-Regular.ttf';
import PoppinsBold from 'shared/assets/fonts/Poppins-Bold.ttf';
import PoppinsLight from 'shared/assets/fonts/Poppins-Light.ttf';
import { useTranslation } from 'react-i18next';
import { IKanbanColumns } from '../layout';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: PoppinsRegular, fontStyle: 'normal', fontWeight: 'normal' },
    { src: PoppinsBold, fontStyle: 'normal', fontWeight: 'bold' },
    { src: PoppinsLight, fontStyle: 'normal', fontWeight: 'light' },
  ]
})

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bold: {
    fontWeight: lightTheme.font.weight.bold,
  },
  page: {
    padding: '24px 12px',
    fontFamily: 'Poppins',
  },
  logo: {
    width: 24,
  },
  logoText: {
    height: 16,
    marginLeft: 8,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    fontSize: lightTheme.font.size.xs,
    color: lightTheme.colors.neutral.white.white,
    fontWeight: lightTheme.font.weight.light,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 16px',
    borderBottom: '1px solid #CCCCCC',
    backgroundColor: lightTheme.colors.primary[900],
    marginBottom: 24,
  },

  // Resume Styles
  resumeContainer: {
    width: '100%',
    padding: '10px 16px',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backlogBG: { backgroundColor: 'rgb(236,236,236)', },
  planningBG: { backgroundColor: 'rgb(207, 216, 220)', },
  executionBG: { backgroundColor: 'rgb(158, 193, 207)', },
  finishedBG: { backgroundColor: 'rgb(108, 142, 191)', },
  title: {
    fontSize: lightTheme.font.size.sm,
    color: lightTheme.colors.text,
    fontWeight: lightTheme.font.weight.bold,
  },
  qtyCircle: {
    width: 26,
    height: 26,
    borderRadius: 13,
    marginLeft: 8,
    backgroundColor: 'white',
    fontSize: lightTheme.font.size.sm,
    textAlign: 'center',
    paddingTop: 4
  },
  totalText: {
    padding: '3px 6px',
    backgroundColor: 'white',
    borderRadius: 8,
    color: lightTheme.colors.text,
    fontSize: 9,
    marginLeft: 6,
  },
  contentStyle: {
    marginTop: 12,
    padding: 6,
    border: `1px solid ${lightTheme.colors.neutral.gray[700]}`,
    borderRadius: 8,
  },
  contentTitleContainer: {
    padding: '2px 8px',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    width: 165,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentTitle: {
    fontSize: 8,
    fontWeight: 'bold',
    color: lightTheme.colors.text,
  },
  statusContainer: {
    padding: '2px 8px',
    borderRadius: '50%',
    marginLeft: 4,
  },
  statusText: {
    fontSize: 6,
    fontWeight: 'bold',
    color: lightTheme.colors.neutral.white.white,
  },
  statusColorGreen: { backgroundColor: lightTheme.colors.utility.darkGreen[700] },
  statusColorYellow: { backgroundColor: lightTheme.colors.utility.yellow[700] },
  statusColorRed: { backgroundColor: lightTheme.colors.utility.red[700] },
  columnText: {
    fontSize: 6,
    fontColor: lightTheme.colors.neutral.gray.gray,
    textAlign: 'center',
    padding: '2px 1px',
    flex: 1,
  }
})

interface PDFProps {
  data: GetAllInitiativesByKanbanResponse['kanbans'];
  columns: IKanbanColumns,
}

const MyPdf = ({
  data,
  columns,
}: PDFProps) => {
  const { t } = useTranslation();

  const resolveResumeBG = useCallback(function (title: string) {
    switch (title) {
      case 'BACKLOG': return styles.backlogBG;
      case 'PLANNING': return styles.planningBG;
      case 'EXECUTION': return styles.executionBG;
      case 'FINISHED': return styles.finishedBG;
      default: return styles.backlogBG; // Cor padrão caso não seja nenhum dos valores esperados
    }
  }, [data]);

  const resolveStatusBG = useCallback(function (title: string) {
    switch (title) {
      case 'ON TRACK': return styles.statusColorGreen;
      case 'NEED ATTENTION': return styles.statusColorYellow;
      case 'AT RISK': return styles.statusColorRed;
      default: return { backgroundColor: '#000000' }; // Cor padrão caso não seja nenhum dos valores esperados
    }
  }, [data]);

  const elipsesText = useCallback(function (title: string, size: number) {
    if (title.length > size)  return title.substring(0, size - 3) + '...';
    else return title;
  }, [data]);

  const timeTranslate = useCallback(function (time: number) {
    switch (time) {
      case 1: return 'ON TIME';
      case 2: return 'AT RISK';
      case 3: return 'DELAYED';
      default: return 'ON TIME'; // Cor padrão caso não seja nenhum dos valores esperados
    }
  }, [data]);

  const costTranslate = useCallback(function (cost: number) {
    switch (cost) {
      case 1: return  'UNDER BUDGET';
      case 2: return  'AT RISK';
      case 3: return  'OVER BUDGET';
      default: return 'UNDER BUDGET'; // Cor padrão caso não seja nenhum dos valores esperados
    }
    }, [data]);

  return (
    <PDF.Document>
      {
        data.map((item, index) => (
          <PDF.Page size="A4" wrap style={styles.page} key={index}>

            {/** BTO NOW Header */}
            <PDF.View style={styles.header}>
              <PDF.View style={styles.logoContainer}>
                <PDF.Image src={LogoSrc} style={styles.logo} />
                <PDF.Image src={LogoText} style={styles.logoText} />
              </PDF.View>
              <PDF.Text style={styles.date}>{dateWithHours(new Date().toISOString())}</PDF.Text>
            </PDF.View>

            {/** KANBAN Resume */}
            <PDF.View fixed style={[styles.resumeContainer, resolveResumeBG(item.kanban)]}>
              <PDF.View style={styles.row}>
                <PDF.Text style={styles.title}>{t('item.kanban') || 'N/A'}</PDF.Text>
                <PDF.Text style={styles.qtyCircle}>{item.qty}</PDF.Text>
              </PDF.View>
              <PDF.View style={styles.row}>
                <PDF.View style={[styles.row, styles.totalText]}>
                  <PDF.Text style={[styles.bold]}>{t('Budget Plan')}:{' '}</PDF.Text>
                  <PDF.Text>{currency(item.budgetPlan)}</PDF.Text>
                </PDF.View>
                <PDF.View style={[styles.row, styles.totalText]}>
                  <PDF.Text style={[styles.bold]}>{t('Forecast')}:{' '}</PDF.Text>
                  <PDF.Text>{currency(item.totalForecast)}</PDF.Text>
                </PDF.View>
                <PDF.View style={[styles.row, styles.totalText]}>
                  <PDF.Text style={[styles.bold]}>{t('Budget Real')}:{' '}</PDF.Text>
                  <PDF.Text>{currency(item.budgetReal)}</PDF.Text>
                </PDF.View>
              </PDF.View>
            </PDF.View>

            { /** Programs */
              item.businessDrivers.map((bs, index) => (
                <PDF.View style={styles.contentStyle} wrap={false} key={index}>
                  <PDF.View style={[styles.row, { justifyContent: 'space-between' }]}>

                    <PDF.View style={styles.row}>
                      <PDF.View style={[styles.contentTitleContainer, resolveResumeBG(item.kanban)]}>
                        <PDF.Text style={styles.contentTitle}>{elipsesText(bs.businessDriver, 27)}</PDF.Text>
                        <PDF.Text style={[styles.qtyCircle, { transform: 'scale(.8)' }]}>{bs.qty}</PDF.Text>
                      </PDF.View>
                      {/* <PDF.View style={[styles.statusContainer, resolveStatusBG(bs.projectHealth)]}>
                        <PDF.Text style={styles.statusText}>{t(bs.projectHealth)}</PDF.Text>
                      </PDF.View> */}
                    </PDF.View>
                  </PDF.View>

                  <PDF.View style={[styles.row, { width: '100%', justifyContent: 'flex-end', marginTop: 4 }]}>
                    <PDF.View style={styles.row}>
                      <PDF.View style={[styles.row, styles.totalText, { fontSize: 8, backgroundColor: 'rgb(235, 235, 235)' }]}>
                        <PDF.Text style={[styles.bold]}>{t('Budget Plan')}:{' '}</PDF.Text>
                        <PDF.Text>{currency(item.budgetPlan)}</PDF.Text>
                      </PDF.View>
                      <PDF.View style={[styles.row, styles.totalText, { fontSize: 8, backgroundColor: 'rgb(235, 235, 235)' }]}>
                        <PDF.Text style={[styles.bold]}>{t('Forecast')}:{' '}</PDF.Text>
                        <PDF.Text>{currency(item.totalForecast)}</PDF.Text>
                      </PDF.View>
                      <PDF.View style={[styles.row, styles.totalText, { fontSize: 8, backgroundColor: 'rgb(235, 235, 235)' }]}>
                        <PDF.Text style={[styles.bold]}>{t('Budget Real')}:{' '}</PDF.Text>
                        <PDF.Text>{currency(item.budgetReal)}</PDF.Text>
                      </PDF.View>
                    </PDF.View>
                  </PDF.View>
                
                  {/** Projects */}
                  <PDF.View style={[styles.row, { marginTop: 12, borderBottom: `1px solid ${lightTheme.colors.neutral.gray[700]}` }]}>
                    <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t('PROJECT')}</PDF.Text>
                    <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t('PROGRAM')}</PDF.Text>
                    { columns.ActualPhase && <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t('ACTUAL PHASE')}</PDF.Text>}
                    { columns.GoliveDate && <PDF.Text style={[styles.columnText, { minWidth: 45 }]}>{t('GO-LIVE DATE')}</PDF.Text>}
                    { columns.BudgetPlan && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{t('BUDGET PLAN')}</PDF.Text>}
                    { columns.Forecast && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>FORECAST</PDF.Text>}
                    { columns.BudgetReal && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{t('BUDGET REAL')}</PDF.Text>}
                    { columns.Time && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>TIMELINE</PDF.Text>}
                    { columns.Financial && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>{t('FINANCIAL')}</PDF.Text>}
                    {/* { columns.Geral && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>{t('GERAL')}</PDF.Text>} */}
                    { columns.Time && <PDF.Text style={[styles.columnText, { minWidth: 30 }]}>{t('TIME')}</PDF.Text>}
                    { columns.Cost && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{t('COST')}</PDF.Text>}
                  </PDF.View>
                  {bs.initiatives.map((p, index) => (
                    <PDF.View style={[styles.row, { backgroundColor: index % 2 === 0 ? 'white' : '#ebebeb' }]}>
                      <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t(p.initiativeName)}</PDF.Text>
                      <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t(p.programme)}</PDF.Text>
                      {columns.ActualPhase && <PDF.Text style={[styles.columnText, { minWidth: 60 }]}>{t(p.actualPhaseDescription)}</PDF.Text>}
                      {columns.GoliveDate && <PDF.Text style={[styles.columnText, { minWidth: 45 }]}>{date(p.goLiveDate)}</PDF.Text>}
                      {columns.BudgetPlan && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{currency(p.totalRequested)}</PDF.Text>}
                      {columns.Forecast && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{currency(p.forecast)}</PDF.Text>}
                      {columns.BudgetReal && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{currency(p.totalInvestiment)}</PDF.Text>}
                      {columns.Time && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>{p.operationalProgress}%</PDF.Text>}
                      {columns.Financial && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>{p.financialProgress}%</PDF.Text>}
                      {/* {columns.Geral && <PDF.Text style={[styles.columnText, { minWidth: 35 }]}>GERAL</PDF.Text>} */}
                      {columns.Time && <PDF.Text style={[styles.columnText, { minWidth: 30 }]}>{timeTranslate(p.statusTime)}</PDF.Text>}
                      {columns.Cost && <PDF.Text style={[styles.columnText, { minWidth: 50 }]}>{costTranslate(p.statusCoast)}</PDF.Text>}
                    </PDF.View>
                  ))}
                </PDF.View>
              ))
            }
          </PDF.Page>
        ))
      }
    </PDF.Document>
  )
}

export default MyPdf;
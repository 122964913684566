import { btoApi } from 'bto_now/api/client';
import {create} from 'zustand';

export interface GetScopeDataPayload {
  user?: string | null;
  portfolioId: number;
  programId: number;
  projectId: number;
}

export interface GetScopeProps {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeType: string | null;
  scopeStatusId: number;
  scopeStatus: string | null;
  name: string;
  description: string;
  ownerId: number;
  owner: string | null;
}

interface GetScopeData {
  getScopes: (queries: GetScopeDataPayload) => Promise<GetScopeProps[]>;
}

export const useListScopes = create<GetScopeData>((set) => ({
  getScopes: async (params: GetScopeDataPayload): Promise<GetScopeProps[]> => {
    try {
      const response = await btoApi.get<GetScopeProps[]>(`api/ProjectScope/GetList?User=${params.user}&PortfolioId=${params.portfolioId}&ProgramId=${params.programId}&ProjectId=${params.projectId}`);

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}));
import { Button } from 'antd';
import styled from 'styled-components';

export const StyledIconButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: auto;
    height: auto;
    border: none;
    background: transparent;
    color: ${p => p.theme.colors.primary[900]};
    font-size: 24px;

    &:hover {
        background: transparent;
        color: ${p => p.theme.colors.primary[900]};
    }

    &:focus {
        background: transparent;
    }

    &:active {
        background: transparent;
    }

    .anticon {
        font-size: 24px;
    }
`;


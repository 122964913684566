import React from 'react';
import * as S from './styles';

interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ label, onClick, disabled }) => {
  return <S.StyledButton onClick={onClick} disabled={disabled}>{label}</S.StyledButton>;
};

export default Button;

import styled from "styled-components";
import { Row as defaultRow } from 'shared/components';

export const ModalContainer = styled.div`
  width: 100%;
  padding: 15px;
`;

export const Row = styled(defaultRow)`
  flex-direction: column;
  align-items: baseline;
`;

import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, PageHeader } from "shared/components";
import { IoGrid } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import PageContainer from "shared/components/PageContainer";
import "shared/theme/css/theme.css";
import Scope from "./components/Scope";
import Risks from "./components/Risks";
import Tasks from "./components/Tasks";
import StatusReportDescription from "./components/StatusReportDescription";
import Curve from "./components/Curve";
import Benefits from "./components/Benefits";
import Costs from "./components/Costs";
import Alerts from "./components/Alerts";
import ProjectHeader from "shared/layouts/ProjectHeader/index";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import HeaderActionsDashboard from "bto_now/pages/Admin/HomeBTO/PMO/DigitalStatusReport/components/HeaderActionsDashboard";
// import CurveS from "./components/CurveS";
import Issues from "./components/Issues";
// import CurveS from "components/CurveS";
import { StatusReportResponse, useStatusReportStore } from "shared/store-zustand/get-status-report/getStatusReport";
import { useTypeStatusReportStore } from "shared/store-zustand/get-status-report/getTypeStatusReport";
import CurveS from "shared/components/CurveS/index";

function DigitalStatusReport() {
  const { t } = useTranslation();
  const { id } = useParams();
  // const { pathname } = useLocation();
  const [dataStatusReport, setDataStatusReport] = useState<StatusReportResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const { getStatusReport } = useStatusReportStore();
  const { typeStatusReport } = useTypeStatusReportStore();

  // const id = pathname.split("/").filter(Boolean).pop();

  useEffect(() => {
    getStatusReport(Number(id), typeStatusReport)
      .then((data: StatusReportResponse | void) => {
        if (data) {
          setDataStatusReport(data);
        }
        setLoading(false);
      })
      .catch((error: string) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [id, typeStatusReport, getStatusReport]);


  const contentDocument = useRef<HTMLDivElement>(null);

  const handleDownload = useReactToPrint({
    content: () => contentDocument.current,
  });

  return (
    <>
      <Breadcrumb
        separator=">"
        items={[
          { title: <IoGrid size={20} /> },
          { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
          { title: t("Digital Status Report") },
        ]}
      />
      <PageContainer>
        <PageHeader
          title={t("Digital Status Report")}
          menu={[{ label: "Home", selected: true }]}
        />

        <HeaderActionsDashboard handleDownload={handleDownload} />

        <div className="row g-3" ref={contentDocument}>
          <div className="col-12">
            <ProjectHeader style={{ marginTop: "1rem" }} projectId={id} />
          </div>
          <div className="col-md-4">
            <div className="row g-3">
              <div className="col-12" style={{ height: "350px" }}>
                <Scope />
              </div>
              <div className="col-12" style={{ height: "50px" }}>
                <StatusReportDescription />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row g-3">
              <div className="col-12">
                <Risks />
              </div>
              <div className="col-12">
                <Tasks />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row g-2">
              <div className="col-6 mt-1">
                <Alerts />
              </div>
              <div className="col-6 mt-1">
                <Costs />
              </div>
              <div className="col-12 mt-0">
                <Benefits />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="col g-2">
            {/* <Curve /> */}
            <CurveS dataCurveS={dataStatusReport?.detalhesDoProjeto.otherDetailsInfo.newCurveS} loading={loading} />
            </div>
          </div>
          <div className="col-md-4">
            <div className="col-12">
              <Issues />
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
}

export default DigitalStatusReport;

import { Button } from "antd";
import IconAlert from "shared/theme/icon/IconAlert";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const getAlertVariant = (value) => {
  if (value === 3) return 'success';
  if (value === 2) return 'warning';
  if (value === 1) return 'danger';
  if (value === 0) return 'success';
  return 'info';
};

const IconAlertDemo = ({ title, value }) => {
  const [show, setShow] = useState(true);

  const { t } = useTranslation();

  const variant = getAlertVariant(value);

  if (show) {
    return (
      <IconAlert
        variant={variant}
        dismissible
        onClose={() => setShow(false)}
        style={{ backgroundColor: '#f0f0f0', borderColor: '#f0f0f0' }}
      >
        <p className="mb-0">{t(title)}</p>
      </IconAlert>
    );
  }

  return (
    <Button className="mb-3 me-3" onClick={() => setShow(true)}>
      {t('Show Alert')}
    </Button>
  );
};

export const Semaphore = ({ data }) => {
  const { t } = useTranslation();
  
  const alerts = [
    { title: t('Scope'), value: data.scope },
    { title: t('Schedule'), value: data.schedule },
    { title: t('Cost'), value: data.coast },
  ];

  return (
    <div className="d-flex flex-column m-0">
      {alerts.map(({ title, value }) => (
        <IconAlertDemo key={title} title={title} value={value} />
      ))}
    </div>
  );
};

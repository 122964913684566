import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface UpdateIssuePayload {
  issueId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  status:               string;
  urgency:              string;
  impact:               string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}

export interface UpdateIssueResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function updateIssue(params: UpdateIssuePayload): Promise<AxiosResponse<UpdateIssueResponse>> {
  return btoApi
    .post('/api/ProjectIssue/UpdateIssue', params);
};
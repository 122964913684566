import React, { useEffect, useState } from "react";
import * as S from './style';
import { Typography } from 'shared/components';
import { useTheme } from "styled-components";

export interface CheckBoxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  checkStyle?: React.CSSProperties;
  labelSize?: React.ComponentProps<typeof Typography>['size'];
  labelWeight?: React.ComponentProps<typeof Typography>['weight'];
  /**
   * **Tamanhos para ajustar o tamanho do checkbox na tela
   * - small = 12px
   * - medium = 18px
   * - marge = 24px
   */
  size?: 'small' | 'medium' | 'large'
}

function Input({
  label,
  checkStyle,
  style,
  labelSize,
  labelWeight,
  checked,
  onChange,
  size,
  ...props
}: CheckBoxProps) {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if(checked !== isChecked) setIsChecked(checked || false);
  }, [checked]);

  useEffect(() => {
    if(isChecked !== checked && onChange) {
      onChange({target: { checked: isChecked }} as React.ChangeEvent<HTMLInputElement>)
    }
  }, [isChecked]);

  return (
    <S.Container style={style} onClick={() => setIsChecked($ => !$)}>
      <S.CheckBorder {...props} style={checkStyle} checked={checked} size={size}>
        {isChecked && <S.CheckIco size={size}/>}
      </S.CheckBorder>
      {label && <Typography style={{ marginLeft: theme.spacing.xs }} size={labelSize || "small"} weight={labelWeight}>{label}</Typography>}
    </S.Container>
  );
}

export default Input;


export const oneDayInMs = 86400000;

export function date(date?: string| number | null): string {
  if(!date) return '';
  return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
}

export function dateWithHours(date?: string | null): string {
  if(!date) return '';
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    
  }
  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
}

/**
 * 
 * @param date String de data no formato brasileiro Ex: dd/mm/yyyy
 * @returns True ou False
 */
export function isValidDate(date: string | null): boolean {
  if(!date) return false;
  const match = date.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
  if(Array.isArray(match)) {
    const [day, month, year] = date.split('/');
    if(Number(day) > 0 && Number(month) > 0 && Number(month) <= 12 && Number(year) >= 1970)
      return true;
  }
  return false;
}

export function dateBrToISO(date: string | null | undefined): string {
  if(!date || !isValidDate(date)) {
    console.error('Invalid date format', date)
    return ''
  };
  const [day, month, year] = date.split('/');
  return new Date(Number(year), Number(month) - 1, Number(day)).toJSON();
}

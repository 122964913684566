import { Col, Row, Table, Typography, Input } from 'shared/components';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { useOutletContext } from 'react-router';
import { GetProjectResponse } from 'bto_now/api';
import { date } from 'utils';
import { currency, currencyMask, currencyToFloat } from 'utils/currency';
import { useTranslation } from 'react-i18next';

const FinancialCreate = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    project,
    setProject
  } = useOutletContext<{
    project: GetProjectResponse,
    setProject: React.Dispatch<React.SetStateAction<GetProjectResponse>>
  }>();

  function caclTotalInvestments(currentIndex: number): string {
    const financial = { ...project.financial };
    const data = [...financial.projectFinancial];
    if (data && Array.isArray(data)) {
      const newArray = [...data];
      const totalCapex = data[currentIndex].totalCapex;
      const totalOpex = data[currentIndex].totalOpex;
      const totalInvestments = Number(totalCapex || 0) + Number(totalOpex || 0);
      newArray[currentIndex].totalInvestments = totalInvestments;

      if (totalInvestments !== data[currentIndex].totalInvestments) {
        setProject({ ...project, financial: { ...financial, projectFinancial: newArray } });
      }
      return currency(totalInvestments);
    }
    return currency('0');
  }

  function calcAccumulatedInvestments(currentIndex: number) {
    const slice = project.financial.projectFinancial.slice(0, currentIndex + 1);
    return slice?.map(v => Number(v.totalInvestments)).reduce((a, c) => a + c, 0);
  }

  function calcConsumedBudget(currentIndex: number) {
    const line = project?.financial.projectFinancial[currentIndex];
    const totalInvestment = line.totalCapex + line.totalOpex;
    const totalForecast = line?.totalForecast || 0;
    if (totalForecast === 0) {
      return totalInvestment > 0 ? -(totalInvestment * 100) : 0; // Allow negative percentages beyond -100% if no forecast
    }
    return (Number(totalInvestment) / totalForecast) * 100;
  }

  function changeTotalForecast(value: string, index: number) {
    const financial = { ...project.financial };
    const newArray = [...financial.projectFinancial];
    newArray[index].totalForecast = currencyToFloat(currencyMask(value));
    setProject({ ...project, financial: { ...financial, projectFinancial: newArray } });
  }
  function changeTotalCapex(value: string, index: number) {
    const financial = { ...project.financial };
    const newArray = [...financial.projectFinancial];
    newArray[index].totalCapex = currencyToFloat(currencyMask(value));
    setProject({ ...project, financial: { ...financial, projectFinancial: newArray } });
  }
  function changeTotalOpex(value: string, index: number) {
    const financial = { ...project.financial };
    const newArray = [...financial.projectFinancial];
    newArray[index].totalOpex = currencyToFloat(currencyMask(value));
    setProject({ ...project, financial: { ...financial, projectFinancial: newArray } });
  }

  function totalForecastBudget(): number {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project?.financial.projectFinancial;
      return fn.map(v => v.totalForecast).reduce((c, a) => c + a, 0);
    }
    return 0;
  }

  function totalOpex(): number {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project.financial.projectFinancial;
      return fn.map(v => v.totalOpex).reduce((c, a) => c + a, 0);
    }
    return 0;
  }

  function totalCapex(): number {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project.financial.projectFinancial;
      return fn.map(v => v.totalCapex).reduce((c, a) => c + a, 0);
    }
    return 0;
  }

  function totalInvestments(): number {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project.financial.projectFinancial;
      return fn.map(v => v.totalInvestments).reduce((c, a) => c + a, 0);
    }
    return 0;
  }

  function calcTotalConsumedBudget() {
    const totalBudget = totalForecastBudget();
    return totalBudget === 0 ? -(totalInvestments() * 100) : (totalInvestments() / totalBudget) * 100;
  }

  function olderDate() {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project.financial.projectFinancial;
      return fn.map(v => v.startDate).reduce((c, a) => c < a ? c : a);
    }
    return '';
  }

  function recentlyDate() {
    if (Array.isArray(project?.financial?.projectFinancial)) {
      const fn = project.financial.projectFinancial;
      return fn.map(v => v.endDate).reduce((c, a) => c > a ? c : a);
    }
    return '';
  }

  return (
    <>
      <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
        <Typography weight='bold' size='medium'>{t('Financial Planning')}</Typography>
      </Row>

      <S.ScrollX>
        <Table style={{ marginTop: theme.spacing.sm, width: '100%' }}>
          <Table.Tr header>
            <Table.Td style={{ minWidth: '200px' }}>{t('Phase')}</Table.Td>
            <Table.Td>{t('Start Date Real')}</Table.Td>
            <Table.Td>{t('End Date Real')}</Table.Td>
            <Table.Td>{t('Forecast Budget')}</Table.Td>
            <Table.Td>{t('Total Capex Plan')}</Table.Td>
            <Table.Td>{t('Total Opex Plan')}</Table.Td>
            <Table.Td>{t('Total Investments Plan')}</Table.Td>
            <Table.Td>{t('Accumulated Investments')}</Table.Td>
            <Table.Td>{t('Consumed Budget (%)')}</Table.Td>
          </Table.Tr>
          {
            project?.financial?.projectFinancial && project.financial.projectFinancial.map((m, index) => {
              const consumedBudget = calcConsumedBudget(index);
              return (
                <Table.Tr key={index}>
                  <Table.Td>{m.phaseName}</Table.Td>
                  <Table.Td>{date(m.startDate)}</Table.Td>
                  <Table.Td>{date(m.endDate)}</Table.Td>
                  <Table.Td><Input label={'Insert a value'} value={currencyMask(m.totalForecast)} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => changeTotalForecast(ev.target.value, index)} /></Table.Td>
                  <Table.Td><Input label={'Insert a value'} value={currencyMask(m.totalCapex)} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => changeTotalCapex(ev.target.value, index)} /></Table.Td>
                  <Table.Td><Input label={'Insert a value'} value={currencyMask(m.totalOpex)} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => changeTotalOpex(ev.target.value, index)} /></Table.Td>
                  <Table.Td>{caclTotalInvestments(index)}</Table.Td>
                  <Table.Td>{currencyMask(calcAccumulatedInvestments(index))}</Table.Td>
                  <Table.Td>
                    <Table.ProgressBar
                      disabled
                      value={consumedBudget.toFixed(2)}
                      progress={consumedBudget}
                      style={{ color: consumedBudget < 0 ? 'red' : 'initial' }} // Apply red color for negative percentages
                    />
                  </Table.Td>
                </Table.Tr>
              );
            })
          }
          <Table.Tr style={{ border: 'none', background: 'white' }}>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            <Table.Td style={{ border: 'none' }}></Table.Td>
            {/* <Table.Td style={{ border: 'none' }}><Typography weight='bold'>{currencyMask(calcTotalConsumedBudget())}</Typography></Table.Td> */}
          </Table.Tr>
        </Table>
      </S.ScrollX>
    </>
  )
}

export default FinancialCreate;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetProcessMngListPayload {
    processId?: number;
    description?: string;
    status?: string;
    valueStreamMap?: string;
    mainProcess?: string;
    user: string;
}

export type GetProcessMngListResponse = Array<{
    companyId: number;
    processId: number;
    description: string;
    status: string;
    companies: string;
    valueStreamMap: string;
    mainProcess: string;
    businessStructure: string;
    businessArea: string;
    processType: string;
    regulatoryMap: string;
    mainDeliverables: string;
    input: string;
    output: string;
    businessBenefits: string;
    riskType: string;
    plataformsMng: string;
    sapModules: string;
    impact: string;
    businessDriver: string;
    trxVolumen: string;
    kpis: string;
    complexity: string;
}>

export function getProcessMngList(body: GetProcessMngListPayload): Promise<AxiosResponse<GetProcessMngListResponse>> {
    return btoApi
        .post('/api/ProcessManagement/GetList', body);
}
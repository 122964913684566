import { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as S from "./style";
import { Col, Typography } from 'shared/components';
import { currency } from "utils";
import { useTranslation } from "react-i18next";
import { RoundedText } from "../../styles";


interface HeaderProps {
  onToggle?(value: 'collapsed' | 'expanded'): void;
  minimized: boolean;
  title: string;
  count: number;
  budgetPlan: number;
  budgetReal: number;
  totalForecast: number;
}

export const Header: React.FC<HeaderProps> = ({ onToggle, minimized, title, count, budgetPlan, budgetReal, totalForecast }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState<'collapsed' | 'expanded'>('expanded');
  const [titleWidht, setTitleWidth] = useState(0);
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    const title = titleRef.current as unknown as HTMLParagraphElement;
    
    if(title) {
      setTitleWidth(title.clientWidth);
    }
  }, [])

  useEffect(() => {
    if(onToggle) {
      onToggle(toggle);
    }
  }, [toggle, onToggle]);

  return (
    <Col>
      <S.HeaderDiv titleWidth={titleWidht} minimized={minimized} title={title}>
        <S.IcoContainer onClick={() => setToggle(v => v === 'collapsed' ? 'expanded' : 'collapsed')}>
          {toggle === 'collapsed' 
            ? <S.IcoCollapse />
            : <S.IcoExpand/>
          }
        </S.IcoContainer>

        <S.Title ref={titleRef} minimized={minimized}>{title}</S.Title>
        <RoundedText>{count}</RoundedText>

        <S.BudgetContainer minimized={minimized}>
          <Typography weight="bold" size="small" style={{ whiteSpace: 'nowrap', marginLeft: 12, padding: '4px 8px', backgroundColor: 'white', borderRadius: 8, minWidth: 210, justifyContent: 'space-between' }}>{t("Budget Plan")}: <Typography size="small" style={{ marginLeft: 12 }}>{currency(budgetPlan)}</Typography></Typography>
          <Typography weight="bold" size="small" style={{ whiteSpace: 'nowrap', marginLeft: 12, padding: '4px 8px', backgroundColor: 'white', borderRadius: 8, minWidth: 210, justifyContent: 'space-between' }}>{t("Forecast")}: <Typography size="small" style={{ marginLeft: 12 }}>{currency(totalForecast)}</Typography></Typography>
          <Typography weight="bold" size="small" style={{ whiteSpace: 'nowrap', marginLeft: 12, padding: '4px 8px', backgroundColor: 'white', borderRadius: 8, minWidth: 210, justifyContent: 'space-between' }}>{t("Budget Real")}: <Typography size="small" style={{ marginLeft: 12 }}>{currency(budgetReal)}</Typography></Typography>
        </S.BudgetContainer>
      </S.HeaderDiv>
      
    </Col>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { FaInfoCircle, FaPlus, FaTrash } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { message } from 'antd'; 

import { Table, ComponentHeader, Typography, Row, Button, Select, ModalSuccess, Input, ButtonCalendarInput, StatusText } from "shared/components";

import { useNavigate } from "react-router-dom";
import { currencyMask, currencyToFloat } from "utils/currency";

import { CreateFinancialProps, QueryFinancialPayload, useCreateFinancial } from "shared/store-zustand/create-project/createFinancial";
import { useListTimeline } from "shared/store-zustand/create-project/getListTimeline";
import { Owner, useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { getUser } from "services";
import { formatDateBeforeSendToBack } from "utils/dates/formatDateBeforeSendToBack";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import * as S from './styles';
import ButtonIcon from "shared/components/ButtonIcon";
import { AiFillEdit } from "react-icons/ai";
import ModalEditFinancial from "./components/ModalEditFinancial";

interface FormData {
  financial: {
    budgetOwner: string;
    budgetDate: string;
    budgetCapex: number;
    budgetOpex: number;
    totalBudget?: number;
    description: string;
  }[];
}

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  financial: yup.array().of(
    yup.object({
      budgetOwner: yup.string()
        .required(t('This field is required.')),
      budgetDate: yup.string().required(t('This field is required.')),
      budgetCapex: yup.number()
        .typeError(t('Should be a number.'))
        .test(
          'is-required-when-capex-zero',
          t('Capex or Opex is required'),
          function(value) {
            const { budgetOpex } = this.parent;
            return budgetOpex !== 0 || (budgetOpex === 0 && (value ?? 0) > 0);
          }
        ),
      budgetOpex: yup.number()
        .typeError(t('Should be a number.'))
        .test(
          'is-required-when-capex-zero',
          t('Opex or Capex is required'),
          function(value) {
            const { budgetCapex } = this.parent;
            return budgetCapex !== 0 || (budgetCapex === 0 && (value ?? 0) > 0);
          }
        ),
      description: yup.string()
        .min(5, t('Field must have at least 5 characters.'))
        .required(t('This field is required.')),
    })
  ).required()
}).required();

const FinancialRitual = () => {
  const { createFinancial } = useCreateFinancial();
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();
  
  const navigate = useNavigate();

  const [qntyFinancial, setQntyFinancial] = useState([{ financialId: 0 }]);
  const [startDate, setStartDate] = useState<string[]>(Array(qntyFinancial.length).fill(''));

  const [modalOpen, setModalOpen] = useState(false);
  const [editFinancialModal, setEditFinancialModal] = useState(false);

  const { register, handleSubmit, control, setValue, formState: { errors, isValid, isDirty, submitCount }, trigger, watch, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      financial: qntyFinancial.map(() => ({
        budgetOwner: '',
        budgetDate: '',
        totalBudget: 0,
        budgetCapex: 0,
        budgetOpex: 0,
        description: ''
      }))
    },
    mode: 'onBlur'
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'financial'
  });

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (name?.includes('budgetCapex') || name?.includes('budgetOpex')) {
        const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);
        const capex = value.financial?.[index]?.budgetCapex || 0;
        const opex = value.financial?.[index]?.budgetOpex || 0;
        setValue(`financial.${index}.totalBudget`, capex + opex);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);
  

  const CreateFinancialDatabase = async (data: FormData) => {
    try {
      const user = getUser();

      const query: QueryFinancialPayload = { user: user ?? null };

      const newItems: CreateFinancialProps[] = data.financial.map((financial) => ({
        portfolioId: 1,
        programId: dataProject.programId!,
        projectId: dataProject.projectId!,
        financialTypeId: 0,
        financialDate: formatDateBeforeSendToBack(financial.budgetDate),
        financialId: 0,
        description: financial.description,
        capex: financial.budgetCapex,
        opex: financial.budgetOpex,
        ownerId: Number(financial.budgetOwner),
        monitorId: 0
      }));

      const response = await createFinancial(query, newItems);

      if (response.status === 200) setModalOpen(true);

    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    CreateFinancialDatabase(data)
    navigate('/dashboard/mng')
  };

  const handleNewTableFinancial = async () => {
    const isValidForm = await trigger();
    if (isDirty && isValidForm) {
      setQntyFinancial(prev => {
        const newFinancial = [...prev, { financialId: prev.length + 1 }];
        append({
          budgetOwner: '',
          budgetDate: '',
          totalBudget: 0,
          budgetCapex: 0,
          budgetOpex: 0,
          description: ''
        });
        clearErrors(`financial`);

        return newFinancial;
      });
    } else {
      // Show warning message instead of errors
      message.warning(t('Please fill out all required fields before adding a new financial entry.'));
    }
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    setValue(`financial.${index}.${field}`, value);
    clearErrors(`financial.${index}.${field}`);
  };

  const handleInputStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);

    setStartDate(prevState => {
      const newStartDate = [...prevState];
      newStartDate[index] = value;
      return newStartDate;
    });

    handleInputChange(index, 'budgetDate', value);
  };

  const handleDeleteFinancial = (index: number) => {
    if (index > 0) return remove(index);

    message.warning(t('The first form cannot be deleted.'));
  }

  const handleModalOk = () => {
    setModalOpen(false)
  }

  const statusColor = useCallback((color: string) => {
    switch (color) {
      case 'Paid': return 'green';
      case 'Late Payment': return 'red';
    }
  }, []);

  return (
    <>
      <ComponentHeader title={t('PMO: Ritual Project Financial')} menu={[{ label: t('Financial'), selected: true }]} />

      <S.ContainerList>
        <Table>
        <Table.Thead>
        <Table.Tr header>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Id</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Budget Date</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Total Budget</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Consumed</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Forecast</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Payment Status</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Paid Status</Table.Td>
          <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>Actions</Table.Td>
        </Table.Tr>
        </Table.Thead>
          <Table.Tbody>
            {fields.map((item, index) => (
              <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={item.id}>
                <Table.Td style={{ padding: '4px 12px' }}>001</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`financial.${index}.budgetDate`)}
                      placeholder={t('Budget Date') + ' *'}
                      minDate={index > 0 ? startDate[index - 1] : '01.01.1900'}
                      onChange={handleInputStartDateChange}
                      value="01.08.2024"
                      readOnly
                      noMinWidth
                      error={submitCount > 0 && !!errors?.financial?.[index]?.budgetDate}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetDate && <S.ErrorMessage>{errors.financial[index].budgetDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.budgetCapex`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Total Budget') + ' *'}
                          value={currencyMask(field.value)}
                          onChange={(e) => {
                            field.onChange(currencyToFloat(e.target.value))
                          }}
                          maxWidth
                          readOnly
                          error={submitCount > 0 && !!errors?.financial?.[index]?.budgetCapex}
                        />
                      )}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetCapex && <S.ErrorMessage>{errors.financial[index].budgetCapex.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.budgetOpex`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Consumed') + ' *'}
                          value={currencyMask(field.value)}
                          onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                          maxWidth

                          error={submitCount > 0 && !!errors?.financial?.[index]?.budgetOpex}
                        />
                      )}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetOpex && <S.ErrorMessage>{errors.financial[index].budgetOpex.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.totalBudget`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Forecast') + ' *'}
                          value={currencyMask(field.value)}
                          maxWidth
                          readOnly
                        />
                      )}
                    />
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <StatusText 
                    color={statusColor('Paid')}
                    size='extraSmall' weight='bold'>
                      Paid
                  </StatusText>
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>
                  <StatusText 
                      color={statusColor('Late Payment')}
                      size='extraSmall' weight='bold'>
                        Late Payment
                    </StatusText>
                  </Table.Td>
                <Table.Td style={{ padding: '4px 12px', textAlign: 'left' }} >
                  <S.IconContainer onClick={() => setEditFinancialModal(true)}>
                    <ButtonIcon>
                      <AiFillEdit style={{ width: '18px', height: '18px' }} />
                    </ButtonIcon>
                    <ButtonIcon>
                      <FaInfoCircle style={{ width: '18px', height: '18px' }} />
                    </ButtonIcon>
                  </S.IconContainer>
                </Table.Td>
              </Table.Tr>
            ))}

            {/* <Table.Tr onClick={handleNewTableFinancial} style={{ background: 'white', cursor: 'pointer' }}>
              <Table.Td><FaPlus size={18} /></Table.Td>
              <Table.Td colSpan={10}>{t("New Financial")}</Table.Td>
            </Table.Tr> */}
          </Table.Tbody>
        </Table>
      </S.ContainerList>

      {/* <S.ButtonContainer>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainer> */}

      <ModalEditFinancial 
        // projectId={id}
        // financialId={selectedScopeId}
        // scopeListData={scopeListData}
        editFinancialModal={editFinancialModal}
        setEditFinancialModal={setEditFinancialModal}
        // updateTrigger={updateTrigger}
        // setUpdateTrigger={setUpdateTrigger}
        // setSelectedScopes={setSelectedScopes}
        // setSelectedScopeId={setSelectedScopeId}
      />

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description="Project create with success"
        title="Success"
      />
    </>
  );
};

export default FinancialRitual ;
import { Modal } from "antd";
import { ModalContainer, Row } from "./styles";
import { CheckboxItem } from "./components/CheckboxItem";
import { Button } from 'shared/components';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IKanbanColumns } from "../..";

interface ModalKanbanProps {
  visible: boolean;
  onClose: () => void;
  onOk(arg: IKanbanColumns): void;
  columnsToShow: IKanbanColumns;
}

  export const ModalKanban = ({ visible, onClose, onOk, columnsToShow }: ModalKanbanProps) => {
    const [checkboxStates, setCheckboxStates] = useState<IKanbanColumns>(columnsToShow); 
  
    useEffect(() => {
      if (visible) {
        setCheckboxStates(columnsToShow); 
      }
    }, [visible, columnsToShow]);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    setCheckboxStates((prevStates) => ({ ...prevStates, [value]: checked }));
  };
    const { t } = useTranslation();

  return (
    <Modal
      title="Campos Kanban"
      visible={visible}
      footer={
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button type='secondary' onClick={onClose}>Cancelar</Button>
          <Button style={{ marginLeft: 12 }} onClick={() => onOk(checkboxStates)}>Aplicar</Button>
        </div>
      }
    >
      <ModalContainer>
        <Row>
          <CheckboxItem
            label={t("Actual Phase")}
            value="Actual Phase"
            checked={checkboxStates.ActualPhase}
            onChange={(e) => handleCheckboxChange("ActualPhase", e.target.checked)}
          />
          <CheckboxItem
            label={t("Go-Live Date")}
            value="Actual Phase"
            checked={checkboxStates.GoliveDate}
            onChange={(e) => handleCheckboxChange("GoliveDate", e.target.checked)}
          />
          <CheckboxItem
            label={t("Budget Plan")}
            value="Actual Phase"
            checked={checkboxStates.BudgetPlan}
            onChange={(e) => handleCheckboxChange("BudgetPlan", e.target.checked)}
          />
          <CheckboxItem
            label={t("Forecast")}
            value="Forecast"
            checked={checkboxStates.Forecast}
            onChange={(e) => handleCheckboxChange("Forecast", e.target.checked)}
          />
          <CheckboxItem
            label={t("Budget Real")}
            value="Actual Phase"
            checked={checkboxStates.BudgetReal}
            onChange={(e) => handleCheckboxChange("BudgetReal", e.target.checked)}
          />
          <CheckboxItem
            label={t("Operational")}
            value="Operational"
            checked={checkboxStates.Operational}
            onChange={(e) => handleCheckboxChange("Operational", e.target.checked)}
          />
          <CheckboxItem
            label={t("Financial")}
            value="Financial"
            checked={checkboxStates.Financial}
            onChange={(e) => handleCheckboxChange("Financial", e.target.checked)}
          />
          <CheckboxItem
            label={t("Geral")}
            value="Geral"
            checked={checkboxStates.Geral}
            onChange={(e) => handleCheckboxChange("Geral", e.target.checked)}
          />
          <CheckboxItem
            label={t("Time")}
            value="Time"
            checked={checkboxStates.Time}
            onChange={(e) => handleCheckboxChange("Time", e.target.checked)}
          />
          <CheckboxItem
            label={t("Cost")}
            value="Cost"
            checked={checkboxStates.Cost}
            onChange={(e) => handleCheckboxChange("Cost", e.target.checked)}
          />
        </Row>
      </ModalContainer>
    </Modal>
  );
};

import React from 'react';
import styled from 'styled-components';


export const Container = styled.div`

`;

export const Option = styled.option`
  
`;

export const ScrollX = styled.div`
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
`;
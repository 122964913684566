import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { ButtonsActionsPage, Select, SpinLoading, Table, ModalSuccess, CheckBox, ComponentHeader, ComponentContainer, Breadcrumb, Input, CalendarInput, PageHeader, PageContainer, ButtonCalendarInput } from "shared/components";
import { ProjectHeader } from "shared/layouts";
import { IoGrid } from "react-icons/io5";
import ModalCreateTask from "./components/ModalCreateTask";
import ModalEditTask from "./components/ModalEditTask";

import { getUser } from "services";
import { getListTaskManagement, GetListTaskManagementResponse } from "bto_now/api/taskManagement/getListTask";
import { getListTaskStatus, GetListTaskStatusResponse } from "bto_now/api/taskStatus/getListTaskStatus";
import { deleteTaskManagement, DeleteTaskManagementRequest } from "bto_now/api/taskManagement/deleteTask";

import { debounce } from "utils";
import { convertStatusTaskIdToStatus } from "utils/convertStatusTask/convertStatusTaskIdToStatusTask";

import * as S from './styles';

export interface TaskData {
  name: string;
  description: string;
  owner: string;
  taskStatus: string;
  startDate: string;
  endDate: string;
}

export interface TaskStatus {
  taskStatusId: number;
  taskStatus: string;
}

const Task = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const projectId = id || '';

  const initialTaskData: TaskData = {
    name: '',
    description: '',
    owner: '',
    startDate: '',
    endDate: '',
    taskStatus: '',
  };

  const [filters, setFilters] = useState<TaskData>(initialTaskData);
  const [taskListData, setTaskListData] = useState<GetListTaskManagementResponse[]>([]);
  const [filteredTaskList, setFilteredTaskList] = useState<GetListTaskManagementResponse[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);

  const [selectedTasks, setSelectedTasks] = useState<Set<number>>(new Set());
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);

  const [taskStatusList, setTaskStatusList] = useState<GetListTaskStatusResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: taskList } = await getListTaskManagement({ User: getUser(), ProjectId: projectId });
        setTaskListData(taskList);

        const { data: taskStatus } = await getListTaskStatus({ User: getUser() });
        setTaskStatusList(taskStatus);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(fetchData, 500)();
  }, [updateTrigger]);

  useEffect(() => {
    const filterTasks = () => {
      const filtered = taskListData.filter(task => {
        const nameMatch = task.name.toLowerCase().includes(filters.name.toLowerCase());
        const ownerMatch = task.owner.toLowerCase().includes(filters.owner.toLowerCase());
        const startDateMatch = !filters.startDate || moment(task.startDate).isSame(moment(filters.startDate), 'day');
        const endDateMatch = !filters.endDate || moment(task.endDate).isSame(moment(filters.endDate), 'day');
        const statusMatch = convertStatusTaskIdToStatus(taskStatusList, task.taskStatusId) === filters.taskStatus || filters.taskStatus === '';

        return nameMatch && ownerMatch && startDateMatch && endDateMatch && statusMatch
      });

      setFilteredTaskList(filtered);
    };

    filterTasks()

  }, [filters, taskListData]);

  const handleCheck = (id: number) => {
    setSelectedTasks(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
        setSelectedTaskId(null);
      } else {
        newSelected.add(id);
        setSelectedTaskId(id);
      }
      return newSelected;
    });
  };

  const deleteTaskDatabase = async () => {
    try {
      const selectedIds = Array.from(selectedTasks);

      for (const idSelect of selectedIds) {
        const findTask = taskListData.find(task => task.taskId === idSelect);

        if (findTask) {
          const taskToDelete: DeleteTaskManagementRequest = {
            projectId: findTask.projectId,
            taskId: findTask.taskId,
            user: getUser()
          };

          const response = await deleteTaskManagement(taskToDelete);

          if (response.status === 200) {
            console.log('Task deleted successfully:', response.data);
            setUpdateTrigger(prev => !prev);
          } else {
            console.error('Error deleting task:', response.statusText);
          }
        } else {
          console.error('Task to delete not found.');
        }
      }

      setModalOpen(true);
      setModalDescription(`Deleted ${selectedIds.length} tasks successfully.`);
    } catch (error) {
      console.error('Error deleting tasks:', error);
    }
  };

  const handleDelete = () => {
    deleteTaskDatabase();

    setUpdateTrigger(prev => !prev);
    setSelectedTasks(new Set());
    setSelectedTaskId(null);
  };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = () => {
    if (selectedTasks.size === 1) {
      const selectedId = Array.from(selectedTasks)[0];

      setSelectedTaskId(selectedId);
      setEditTaskModal(true);
    }
  }

  const handleOpenModalCreate = () => {
    setNewTaskModal(true);
  };

  const isChecked = (id: number) => selectedTasks.has(id);

  const isDisabledButtonDelete = selectedTasks.size === 0;
  const isDisabledButtonEdit = selectedTasks.size !== 1;
  const isDisabledButtonNew = selectedTasks.size > 0;

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: <Link to="/dashboard/mng/pmo/project-tasks-mng">{t('Project Tasks Management')}</Link> },
        { title: t('Tasks') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t('⁠Project Tasks Management')}
          menu={[{ label: t('Project Info'), selected: true }]}
        />

        <ProjectHeader style={{ marginBottom: '16px' }} projectId={projectId} />

        <ComponentContainer>
          <ComponentHeader title={t('PMO: Search Project tasks')} menu={[{ label: t('Tasks'), selected: true }]} />
          <S.ContainerForms>
            <S.FormRow>
              <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
                <S.InputContainer>
                  <Input
                    label={t('Task Name')}
                    name='TaskName'
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    style={{ fontSize: '12px' }}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Input
                    label={t('Task Owner')}
                    name='owner'
                    value={filters.owner}
                    onChange={(e) => setFilters({ ...filters, owner: e.target.value })}
                    style={{ fontSize: '12px' }}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <ButtonCalendarInput
                    placeholder={t('Start Date')}
                    name='startDate'
                    value={filters.startDate}
                    onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <ButtonCalendarInput
                    placeholder={t('Deadline')}
                    name='endDate'
                    value={filters.endDate}
                    onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Select
                    options={taskStatusList.map(status => ({ label: status.taskStatus, value: status.taskStatus }))}
                    label={t('Task Status')}
                    name='taskStatus'
                    value={filters.taskStatus}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, taskStatus: e.target.value })}
                  />
                </S.InputContainer>
              </div>
              <S.ButtonContainerRow>
                <ButtonsActionsPage
                  isDisabledButtonDelete={isDisabledButtonDelete}
                  isDisabledButtonEdit={isDisabledButtonEdit}
                  isDisabledButtonNew={isDisabledButtonNew}
                  handlePlus={handleOpenModalCreate}
                  handleEdit={handleOpenModalEdit}
                  handleTrash={handleDelete}
                />
              </S.ButtonContainerRow>
            </S.FormRow>
          </S.ContainerForms>

          <S.ContainerList>
            {
              isLoading ? (
                <SpinLoading />
              ) : (
                <S.TableContainer>
                  <Table style={{ marginTop: 16 }}>
                    <Table.Thead>
                      <Table.Tr header>
                        <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Id')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Task Name')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Task Description')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Task Owner')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Start Date')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('End Date')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Task Status')}</Table.Td>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {
                        filteredTaskList.map((task) => (
                          <Table.Tr key={task.taskId} style={{ borderBottom: '1px solid #ccc' }}>
                            <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(task.taskId)} checked={isChecked(task.taskId)} size="medium" /></Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{task.taskId}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{task.name}</Table.Td>
                            <Table.Td 
                              style={{ 
                                padding: '8px 12px',
                                maxWidth: '270px', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis',
                              }}
                              title={task.description}
                            >
                              {task.description}
                            </Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{task.owner}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{moment(task.startDate).format('DD.MM.YYYY')}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{moment(task.endDate).format('DD.MM.YYYY')}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{convertStatusTaskIdToStatus(taskStatusList, task.taskStatusId)}</Table.Td>
                          </Table.Tr>
                        ))
                      }
                    </Table.Tbody>
                  </Table>
                </S.TableContainer>
              )
            }
          </S.ContainerList>

          <ModalSuccess
            open={modalOpen}
            onOk={handleModalOk}
            description={modalDescription}
            title="Success"
          />

          <ModalCreateTask
            projectId={projectId}
            taskStatusList={taskStatusList}
            newTaskModal={newTaskModal}
            setNewTaskModal={setNewTaskModal}
            setUpdateTrigger={setUpdateTrigger}
          />

          <ModalEditTask
            projectId={projectId}
            taskId={selectedTaskId}
            editTaskModal={editTaskModal}
            updateTrigger={updateTrigger}
            setEditTaskModal={setEditTaskModal}
            setUpdateTrigger={setUpdateTrigger}
            taskListData={taskListData}
            taskStatusList={taskStatusList}
            setSelectedTasks={setSelectedTasks}
            setSelectedTaskId={setSelectedTaskId}
          />
        </ComponentContainer>
      </PageContainer>
    </>
  );
};

export default Task;
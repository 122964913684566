import { Link } from "react-router-dom";
import ProfileCard from './components/ProfileCard'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoGrid } from 'react-icons/io5';
import * as S from './styles';
import { PageContainer } from 'shared/components';
import { Breadcrumb } from 'shared/components';

function Profile() {
    const { t } = useTranslation();

    const userName = localStorage.getItem('USER_NAME') || '';
    const userEmail = localStorage.getItem('USER') || '';

    return (
        <>
            <Breadcrumb separator=">" items={[
                { title: <IoGrid size={20} /> },
                { title: <Link to="/dashboard/mng">Home</Link> },
                { title: t('Profile') }
                // { title: 'Portfolio Mng' }
            ]} />
            <PageContainer>
                <S.Container>
                    <ProfileCard userName={userName} userEmail={userEmail} />
                </S.Container>
            </PageContainer>
        </>
    )
}

export default Profile;
import * as S from "./styles";
import { GetAllInitiativesByKanbanResponse } from 'bto_now/api';
import { date } from 'utils';
import { currency } from 'utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from "styled-components";
import Semaphore from "shared/components/SemaphoreVariant";
import { Col } from "antd";
import { IKanbanColumns } from "../..";

export const DetailTable: React.FC<{
  data: GetAllInitiativesByKanbanResponse['kanbans'][0]['businessDrivers'][0]['initiatives'];
  columnsToShow: IKanbanColumns;
}> = ({ data, columnsToShow }) => {
  
  const {
    ActualPhase,
    GoliveDate,
    BudgetReal,
    Forecast,
    BudgetPlan,
    Operational,
    Financial,
    Geral,
    Time,
    Cost,
  } = columnsToShow;

  const { t } = useTranslation();

  const semaphoreProgress = (value: number) => {
    if (value === 0) return 'gray';
    if (value === 1) return 'danger';
    if (value === 2) return 'warning';
    if (value === 3) return 'success';
    return 'gray';
  };
  

  console.log(data)
  return (
    <S.DetailTableContainer>
      <table>
        <thead>
          <tr>
            <th style={{ padding: '0 12px', minWidth: 215, whiteSpace: 'nowrap' }}>{t("Project")}</th>
            <th style={{ padding: '0 12px', minWidth: 130, whiteSpace: 'nowrap' }}>{t("Program")}</th>
            { ActualPhase && <th style={{ padding: '0 12px', minWidth: 140, whiteSpace: 'nowrap' }}>{t("Actual Phase")}</th>}
            { GoliveDate && <th style={{ padding: '0 12px', minWidth: 80, whiteSpace: 'nowrap' }}>{t("Go-Live Date")}</th>}
            { BudgetPlan && <th style={{ padding: '0 12px', minWidth: 100, whiteSpace: 'nowrap' }}>{t("Budget Plan")}</th>}
            { Forecast && <th style={{ padding: '0 12px', minWidth: 100, whiteSpace: 'nowrap' }}>{t("Forecast")}</th>}
            { BudgetReal && <th style={{ padding: '0 12px', minWidth: 100, whiteSpace: 'nowrap' }}>{t("Budget Real")}</th>}
            { Operational && <th style={{ padding: '0 12px', minWidth: 80, whiteSpace: 'nowrap' }}>{t("Timeline")}</th>}
            { Financial && <th style={{ padding: '0 12px', minWidth: 80, whiteSpace: 'nowrap' }}>{t("Financial")}</th>}
            { Geral && <th style={{ padding: '0 12px' }}>{t("Geral")}</th>}
            { Time && <th style={{ padding: '0 12px' }}>{t("Time")}</th>}
            { Cost && <th style={{ padding: '0 12px' }}>{t("Cost")}</th>}
          </tr>
        </thead>
        <tbody>
        {data.map((item, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#ebebeb' }}>
              <td>{item.initiativeName}</td>
              <td>{item.programme}</td>
              { ActualPhase && <td>{item.actualPhaseDescription}</td>}
              { GoliveDate && <td>{date(item.goLiveDate)}</td>}
              { BudgetPlan && <td>{currency(item.totalRequested)}</td>}
              { Forecast && <td>{currency(item.forecast)}</td>}
              { BudgetReal && <td>{currency(item.totalInvestiment)}</td>}
              {Operational && <td style={{ width: 80  }}>
                <S.ProgressBar progressBarSemaphore={semaphoreProgress(item.statusTime)} progress={item.operationalProgress} ><span>{item.operationalProgress}</span></S.ProgressBar>
              </td>}
              {Financial && <td style={{ width: 80 }}>
                <S.ProgressBar progressBarSemaphore={semaphoreProgress(item.statusCoast)} progress={item.financialProgress}><span>{item.financialProgress}</span></S.ProgressBar>
              </td>}
              {Geral && <td>
                <Col style={{alignItems: 'center',}} className="standard-col-subcol">
                  <Semaphore value={item.statusGeral} />
                </Col>
              </td>}
              {Time && <td>
                <Col style={{alignItems: 'center',}} className="standard-col-subcol">
                  <Semaphore value={item.statusTime} />
                </Col>
              </td>}
              {Cost && <td>
                <Col style={{alignItems: 'center',}} className="standard-col-subcol">
                  <Semaphore value={item.statusCoast} />  
                </Col>
              </td>}
            </tr>
          ))}
        </tbody>
      </table>
    </S.DetailTableContainer>
  );
};

import React, { useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, ButtonCalendarInput, Input } from "shared/components";
import { TaskData, TaskStatus } from "../../index";

import { createTaskManagement, CreateTaskManagementRequest } from "bto_now/api/taskManagement/createTask";
import { convertStatusTaskToStatusId } from "utils/convertStatusTask/convertStatusTaskToStatusTaskId";
import { getUser } from "services";

import { GetListTaskStatusResponse } from "bto_now/api/taskStatus/getListTaskStatus";

import * as S from './styles';

interface ModalCreateTaskProps {
  projectId: string;
  taskStatusList: GetListTaskStatusResponse[];
  newTaskModal: boolean;
  setNewTaskModal: (state: boolean) => void;
  setUpdateTrigger: Dispatch<SetStateAction<boolean>>;
}

const ModalCreateTask = (props: ModalCreateTaskProps) => {
  const { projectId, newTaskModal, setNewTaskModal, setUpdateTrigger, taskStatusList } = props;
  const { t } = useTranslation();

  const initialTaskData: TaskData = {
    name: '',
    description: '',
    owner: '',
    startDate: '',
    endDate: '',
    taskStatus: '',
  };

  const [dataTask, setDataTask] = useState<TaskData>(initialTaskData);
  const [errors, setErrors] = useState({
    name: false,
    description: false,
    owner: false,
    startDate: false,
    endDate: false,
    taskStatus: false,
  });

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataTask(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (name: keyof TaskData, value: string) => {
    setDataTask(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const createTaskDatabase = async () => {
    try {
      const newItem: CreateTaskManagementRequest = {
        projectId: Number(projectId),
        taskId: 0,
        name: dataTask.name,
        description: dataTask.description,
        owner: dataTask.owner,
        taskStatusId: convertStatusTaskToStatusId(taskStatusList, dataTask.taskStatus),
        startDate: dataTask.startDate,
        endDate: dataTask.endDate,
        user: getUser(),
        ritualNumber: 0,
      };

      console.log(newItem)

      const response = await createTaskManagement(newItem);

      if (response.status === 200) {
        console.log('Scope created successfully:', response.data);
        setUpdateTrigger(prev => !prev);
      } else {
        console.error('Error creating scope:', response.statusText);
      }
    } catch (error) {
      console.error('Request failed:', error);
    }
  };


  const handleSubmit = async () => {
    setCreatingLoading(true)

    const newErrors = {
      name: !dataTask.name,
      description: !dataTask.description,
      owner: !dataTask.owner,
      startDate: !dataTask.startDate,
      endDate: !dataTask.endDate,
      taskStatus: !dataTask.taskStatus,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {

      await createTaskDatabase()

      setDataTask({
        name: '',
        description: '',
        owner: '',
        startDate: '',
        endDate: '',
        taskStatus: '',
      });

      setResetSelect(true);
      setNewTaskModal(false);
    }

    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    setDataTask({
      name: '',
      description: '',
      owner: '',
      startDate: '',
      endDate: '',
      taskStatus: '',
    });

    setNewTaskModal(false)
  }

  return (
    <Modal
      title={t("New Task")}
      open={newTaskModal}
      onCancel={() => setNewTaskModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading}
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Input
        label={t('Task Name') + ' *'}
        name='name'
        value={dataTask.name}
        onChange={handleInputChange}
        error={errors.name}
      />
      {errors.name && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Task Description') + ' *'}
        name='description'
        value={dataTask.description}
        onChange={handleInputChange}
        error={errors.description}
      />
      {errors.description && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Task Owner') + ' *'}
        name='owner'
        value={dataTask.owner}
        onChange={handleInputChange}
        error={errors.owner}
      />
      {errors.owner && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <ButtonCalendarInput
        placeholder={t('Start Date') + ' *'}
        name='startDate'
        value={dataTask.startDate}
        onChange={handleInputChange}
        error={errors.startDate}
      />
      {errors.startDate && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <ButtonCalendarInput
        placeholder={t('End Date') + ' *'}
        name='endDate'
        value={dataTask.endDate}
        onChange={handleInputChange}
        error={errors.endDate}
      />
      {errors.endDate && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={taskStatusList.map(status => ({ label: status.taskStatus, value: status.taskStatus}))}
        label={t('Task Status') + ' *'}
        name='taskStatus'
        value={dataTask.taskStatus}
        onChange={(e) => handleSelectChange('taskStatus', e.target.value)}
        error={errors.taskStatus}
        reset={resetSelect}
      />
      {errors.taskStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateTask;


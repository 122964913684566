import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { IoGrid } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Select, SpinLoading, Table, ModalSuccess, CheckBox, Breadcrumb, ComponentHeader, ComponentContainer, ButtonsActionsPage, Input, PageHeader, StatusText } from "shared/components";
import ModalCreateIssue from "./components/ModalCreateIssue/index";
import ModalEditIssue from "./components/ModalEditIssue/index";

import { debounce } from "utils";

import * as S from './styles';
import { IssueData, issueList } from "./data";
import { useGeneralDataSupport } from "shared/store-zustand/issues-project/getGeneralDataSupport";
import { TFunction } from "i18next";
import * as yup from "yup";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { getUser } from "services";

export interface FormSchemaIssues {
  issueName: string;
  issueDescription: string;
  type: string;
  issueStatus: string;
  urgency: string;
  impact: string;
  historical: string;
  openDate: string;
  forecastDate: string;
  owner: string;
}

export const schema = (t: TFunction<"translation", undefined>) => 
  yup.object().shape({
    issueName: yup.string()
      .trim()
      .required(t('This field is required.')),
    issueDescription: yup.string()
      .trim()
      .required(t('This field is required.')),
    type: yup.string()
      .required(t('This field is required.')),
    issueStatus: yup.string()
      .required(t('This field is required.')),
    urgency: yup.string()
      .required(t('This field is required.')),
    impact: yup.string()
      .required(t('This field is required.')),
    historical: yup.string()
      .trim()
      .required(t('This field is required.')),
    openDate: yup.string()
      .required(t('This field is required.')),
    forecastDate: yup.string()
      .required(t('This field is required.')),
    owner: yup.string()
      .required(t('This field is required.')),
  }).required();

const IssuesRitual = () => {
  const { generalDataSupport, getGeneralDataSupport } = useGeneralDataSupport();
  const { getFieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();
  const { id } = useParams();

  const initialIssueData: IssueData = {
    issueId: 0, 
    issueName: '',
    issueDescription: '',
    type: '', 
    issueStatus: '', 
    urgency: '', 
    impact: '', 
    historical: '',
    status: '', 
    lastUpdate: '',
    openDate: '',
    forecastDate: '',
    owner: ''
  };

  const [filters, setFilters] = useState<IssueData>(initialIssueData);
  const [issueListData, setIssueListData] = useState<[]>([]);
  const [filteredIssueList, setFilteredIssueList] = useState<IssueData[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [newIssueModal, setNewIssueModal] = useState(false);
  const [editIssueModal, setEditIssueModal] = useState(false);

  const [selectedIssues, setSelectedIssues] = useState<Set<number>>(new Set());
  const [selectedIssueId, setSelectedIssueId] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        getFieldListStatus({
          User: getUser(),
          portfolioId: 1
        });
        // const { data: IssueData } = await getListIssueManagement({ User: getUser(), ProjectId: id });
        // setIssueListData(IssueData);

        // const { data: IssueTypeData } = await getListIssueType({ User: getUser() });
        // setIssueTypeList(IssueTypeData);

        // const { data: IssueStatusData } = await getListIssueStatus({ User: getUser() });
        // setIssueStatusList(IssueStatusData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(fetchData, 500)();
  }, [updateTrigger]);

  useEffect( () => {
    try {
      getGeneralDataSupport();
      setFilteredIssueList(issueList)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // useEffect(() => {
  //   const filterTasks = () => {
  //     const filtered = IssueListData.filter(Issue => {
  //       const typeMatch = convertTypeIdInType(IssueTypeList, Issue.IssueTypeId) === filters.IssueType || filters.IssueType === '';
  //       const statusMatch = convertStatusIdInStatus(IssueStatusList, Issue.IssueStatusId) === filters.IssueStatus || filters.IssueStatus === '';
  //       const nameMatch = Issue.name.toLowerCase().includes(filters.name.toLowerCase());
  //       const ownerMatch = Issue.owner.toLowerCase().includes(filters.owner.toLowerCase());
        
  //       return typeMatch && statusMatch && nameMatch && ownerMatch;
  //     });

  //     setFilteredIssueList(filtered);
  //   };

  //   filterTasks();
  // }, [filters, IssueListData]);

  const handleCheck = (id: number) => {
    setSelectedIssues(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
        setSelectedIssueId(null);
      } else {
        newSelected.add(id);
        setSelectedIssueId(id);
      }
      return newSelected;
    });
  };

  // const deleteIssueDatabase = async () => {
  //   try {
  //     const selectedIds = Array.from(selectedIssues);
  //     for (const idSelect of selectedIds) {
  //       const params: DeleteIssueManagementRequest = {
  //         projectId: Number(id),
  //         IssueId: idSelect,
  //         user: getUser(),
  //         name: '',
  //         description: '',
  //         owner: ''
  //       };

  //       const response = await deleteIssueManagement(params);

  //       if (response.status === 200) {
  //         console.log('Issue deleted successfully:', response.data);
  //         setUpdateTrigger(prev => !prev);
  //       } else {
  //         console.error('Error deleting Issue:', response.statusText);
  //       }
  //     }

  //     setModalOpen(true);
  //     setModalDescription(`Deleted ${selectedIds.length} Issues successfully.`);

  //   } catch (error) {
  //     console.error('Error deleting Issues:', error);
  //   }
  // }

  // const handleDelete = () => {
  //   deleteIssueDatabase();

  //   setUpdateTrigger(prev => !prev);
  //   setSelectedIssues(new Set());
  //   setSelectedIssueId(null);
  // };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = () => {
    if (selectedIssues.size === 1) {
      const selectedId = Array.from(selectedIssues)[0];
      setSelectedIssueId(selectedId);
      setEditIssueModal(true);
    }
  };

  const handleOpenModalCreate = () => {
    setNewIssueModal(true);
  };

  const statusColorScale = useCallback((option: string) => {
    switch (option) {
      case 'LOW': return 'green';
      case 'HIGH': return 'red';
      case 'MODERATE': return 'orange';
      case 'VERY HIGH': return 'black';
      case 'CRITICAL': return 'black';
    }
  }, []);

  const isChecked = (id: number) => selectedIssues.has(id);
  
  const isDisabledButtonDelete = selectedIssues.size === 0;
  const isDisabledButtonEdit = selectedIssues.size !== 1;
  const isDisabledButtonNew = selectedIssues.size > 0;

  return (
    <>
      <ComponentHeader title={t('PMO: Ritual Project Issues')} menu={[{ label: t('Issues'), selected: true }]} />
      
      <S.ContainerForms>
        <S.FormRow>
          <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
            <S.InputContainer>
              <Input
                label={t('Issue Name') + ' *'}
                name='issueName'
                value={filters.issueName}
                onChange={(e) => setFilters({ ...filters, issueName: e.target.value })}
                style={{ fontSize: '12px' }}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
                label={t('Issue Type') + ' *'}
                name='type'
                value={filters.type}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, type: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.status.map((status: string) => ({ label: status, value: status }))}
                label={t('Issue Status') + ' *'}
                name='status'
                value={filters.status}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, status: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.urgency.map((scale: string) => ({ label: scale, value: scale }))}
                label={t('Urgency') + ' *'}
                name='urgency'
                value={filters.urgency}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, urgency: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.impact.map((impact: string) => ({ label: impact, value: impact }))}
                label={t('Impact') + ' *'}
                name='impact'
                value={filters.impact}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, impact: e.target.value })}
              />
            </S.InputContainer>
          </div>
          <S.ButtonContainerRow>
            <ButtonsActionsPage 
              isDisabledButtonDelete={isDisabledButtonDelete}
              isDisabledButtonEdit={isDisabledButtonEdit}
              isDisabledButtonNew={isDisabledButtonNew}
              handlePlus={handleOpenModalCreate}
              handleEdit={handleOpenModalEdit}
              handleTrash={() => console.log('delete')}
            />
          </S.ButtonContainerRow>
        </S.FormRow>
      </S.ContainerForms>

      <S.ContainerList>
        {
          isLoading ? (
            <SpinLoading />
          ) : (
            <S.TableContainer>
              <Table style={{ marginTop: 16 }}>
                <Table.Thead>
                  <Table.Tr header>
                    <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Id')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Issue Name')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Issue Description')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Type')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Status')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Urgency')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Impact')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Historical')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Open Date')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Forecast Date')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Owner')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Status')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Last Update')}</Table.Td>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {
                    filteredIssueList.map((issue) => (
                      <Table.Tr key={issue.issueId} style={{ borderBottom: '1px solid #ccc' }}>
                        <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(issue.issueId)} checked={isChecked(issue.issueId)} size="medium" /></Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.issueId}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.issueName}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.issueDescription}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.type}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.issueStatus}</Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={statusColorScale(issue.urgency)} 
                            size='extraSmall' weight='bold'>
                              {issue.urgency}
                          </StatusText>
                        </Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={statusColorScale(issue.impact)} 
                            size='extraSmall' weight='bold'>
                              {issue.impact}
                          </StatusText>
                        </Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.historical}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.openDate}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.forecastDate}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.owner}</Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={(issue.status === 'Active') ? 'green' : 'red'} 
                            size='extraSmall' weight='bold'>
                              {issue.status}
                          </StatusText>
                        </Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{issue.lastUpdate}</Table.Td>
                      </Table.Tr>
                    ))
                  }
                </Table.Tbody>
              </Table>
            </S.TableContainer>
          )
        }
      </S.ContainerList>

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description={modalDescription}
        title="Success"
      />

      <ModalCreateIssue 
        projectId={id}
        newIssueModal={newIssueModal}
        setNewIssueModal={setNewIssueModal}
        setUpdateTrigger={setUpdateTrigger}
      />

      
      <ModalEditIssue 
        projectId={id}
        issueId={selectedIssueId}
        editIssueModal={editIssueModal}
        updateTrigger={updateTrigger}
        setEditIssueModal={setEditIssueModal}
        setUpdateTrigger={setUpdateTrigger}
        setSelectedIssue={setSelectedIssues}
        setSelectedIssueId={setSelectedIssueId}
      />
    </>
  );
};

export default IssuesRitual;
import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetRitualOperationalDraftsPayload {
  User:              string | null;
  ProjectId:         string | null;
  RitualNumber:      number | null;
}

export interface GetRitualOperationalDraftsResponse {
  timelineStatus:        TimelineStatus;
  progressStatus:        ProgressStatus[];
  timelinePlanning:      Timeline[];
  timelineRealized:      Timeline[];
  curveS:                Curve[];
  phaseTimelineUpdate:   PhaseTimelineUpdate[];
  projectTimelineUpdate: ProjectTimelineUpdate[];
}

interface Curve {
  situation:  string;
  startDate:  string;
  endDate:    string;
  valorStart: number;
  valorEnd:   number;
}

interface PhaseTimelineUpdate {
  phaseExecutionId:  number;
  phase:             string;
  startDate:         string;
  endDate:           string;
  startDatePlan:     string;
  endDatePlan:       string;
  startDateReal:     string;
  endDateReal:       string;
  statusPhase:       string;
  percentProgressPhasePlan: number;
  percentProgressPhaseReal: number;
  percentAtraso:     number;
}

interface ProgressStatus {
  phaseExecutionId: number;
  phase:            string;
  progress:         number;
}

interface ProjectTimelineUpdate {
  project:           string;
  startDate:         string;
  endDate:           string;
  startDatePlan:     string;
  endDatePlan:       string;
  startDateReal:     string;
  endDateReal:       string;
  statusPhase:       string;
  percentAtraso:     number;
  percentProgressPhasePlan: number;
  percentProgressPhaseReal: number;
}

interface Timeline {
  phaseExecutionId: number;
  phase:            string;
  startDate:        string;
  endDate:          string;
}

interface TimelineStatus {
  timelineStatus:      number;
  spi:                 number;
  currentPhase:        string;
  daysWorked:          number;
  daysLeftToNextPhase: number;
  daysLeftToGoLive:    number;
}


export function getRitualOperational(params: GetRitualOperationalDraftsPayload): Promise<AxiosResponse<GetRitualOperationalDraftsResponse>> {
  if(!params || !params.User)
    return Promise.reject({error: 'Parametros não foram fornecidos'});
  
  return btoApi
    .get('/api/Ritual/GetRitualOperacional', { 
      params 
    });
}

import React from 'react';
import * as S from './styles';

import Update from './Update';

function Issues() {

  return (
    <S.Container>
      <Update />
    </S.Container>
  )
}

export default Issues;
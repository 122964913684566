import React from 'react';
import { Typography } from 'shared/components';
import { useTheme } from 'styled-components';

function Label(props: React.PropsWithChildren) {
  const theme = useTheme();
  return ( 
    <Typography
      size='small'
      weight='bold'
      style={{ 
        color: theme.colors.neutral.white[900],
        minHeight: 18,
        whiteSpace: 'nowrap',
      }}
    >{props.children}</Typography>
  )
}

export default Label;
import styled from 'styled-components';

interface ContainerProps {
  noshow?: boolean;
}

export const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'noshow',
})<ContainerProps>`
  display: ${props => props.noshow ? 'none' : 'flex'};
  flex-direction: column;
  width: 100%;
  padding: ${p => p.theme.spacing.sm};
  background: ${({ noshow }) => noshow ? '#E6E8F0' : 'white'};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  border-radius: ${p => p.theme.border.radius.sm};
`;


import React, { useEffect, useState } from 'react';
import { Col, Row } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import Label from './components/Label';
import Value from './components/Value';

import { SpinLoading } from "shared/components";

import {
  getAllInitiatives,
  GetAllInitiativesResponse,
} from 'bto_now/api';
 
import { getUser } from 'services';

import * as S from './styles';
import { useCreateProject } from 'shared/store-zustand/create-project/createProject';
import { useFieldListStatus } from 'shared/store-zustand/create-project/getFieldList';
import { projectTypeIdToName } from 'utils/convertIdToName/projectTypeIdToName';
import { projectManagerIdToName } from 'utils/convertIdToName/projectManagerIdToName';
import { projectOwnerIdToName } from 'utils/convertIdToName/projectOwnerIdToName';

interface Props {
  style?: React.CSSProperties;
  newProjectModal?: boolean;
}

function NewProjectHeader({ style, newProjectModal = false }: Props) {
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [loading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState<GetAllInitiativesResponse>();

  const projectType = projectTypeIdToName(fieldListStatus.projectTypeList, dataProject.projectTypeId);
  const projectManager = projectManagerIdToName(fieldListStatus.managerList, dataProject.managerId);
  const projectOwner = projectOwnerIdToName(fieldListStatus.ownerList, dataProject.ownerId)
  
  return (
    <S.Container style={style}>
      <S.GrayContainer>
        <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {newProjectModal ? (
            <SpinLoading isProjectHeader />
          ) :
            (
              <React.Fragment>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project Name")}: </Label><Value>{dataProject.name}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project ID")}: </Label><Value>{dataProject.projectId}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project Type")}: </Label><Value>{projectType}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Product Manager")}: </Label><Value>{projectManager}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Procject Owner")}: </Label><Value>{projectOwner}</Value></Col>
                <Col style={{ margin: '5px 10px'}}><Label>{t("Project Summary")}: </Label><Value>{dataProject.summary}</Value></Col>
              </React.Fragment>
          )
        }
        </Row>
      </S.GrayContainer>
    </S.Container>
  );
}

export default NewProjectHeader;
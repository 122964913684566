import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface CreateIssuePayload {
  issueId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  status:               string;
  urgency:              string;
  impact:               string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}

export interface CreateIssueResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function createIssue(params: CreateIssuePayload): Promise<AxiosResponse<CreateIssueResponse>> {
  return btoApi
    .post('/api/ProjectIssue/CreateIssue', params);
};

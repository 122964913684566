import { Avatar, Divider, Image } from "antd"

import { UserOutlined } from '@ant-design/icons';
import { FiUser, FiLogOut, FiHelpCircle  } from "react-icons/fi";
import { MdLanguage } from "react-icons/md";
import { IoNotificationsOutline } from 'react-icons/io5';
import logo from 'shared/assets/img/BTONow_logo-sem_assinatura_Terciaria-Negativo.png';

import { AnimatePresence, motion } from "framer-motion"; // Remover do projeto

import { Link, Outlet, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserName, logoutService } from "services";

import { Row } from 'shared/components';
import * as S from './styles';

const TopHeader = () => {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const navigate = useNavigate()
    const { t, i18n} = useTranslation();


    useEffect(() => {
        // if (!getToken()) navigate('/')
    }, [navigate])

    const toggleNavVisibility = () => {
        setIsNavVisible(!isNavVisible); // Toggle nav visibility on click
    };
    const closeNav = () => {
        setIsNavVisible(false); // Close nav
    };

    const logout = () => {
        closeNav()
        logoutService()
    }

    const settingsNavVariants = {
        open: {
            // opacity: 1,
            y: 0,
            transition: { duration: 0.5 },
        },
        closed: {
            // opacity: 0,
            y: -210,
            transition: { duration: 0.5 },
        },
    };

    const toggleLanguage = () => {
        const newLanguage = i18n.language === 'en-us' ? 'pt-BR' : 'en-us';
        i18n.changeLanguage(newLanguage).then(() => {
            localStorage.setItem('language', newLanguage);
        });
    };

    return (
        <>
            <S.Container>
                <S.HeaderContainer>
                    <Row>
                        <Link to="/dashboard/mng">
                            <Image src={logo} preview={false} className="dashboard-logo"/>
                        </Link>
                        <Divider type="vertical" style={{ background: '#FFF', height: '30px' }} />
                    </Row>
                    <Row>
                        <IoNotificationsOutline className="dashboard-option" />
                        <Divider type="vertical" style={{ background: '#FFF', height: '30px' }} />
                        <div onClick={toggleLanguage} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#FFF' }}>
                            <MdLanguage className="dashboard-option" style={{ fontSize: '24px' }} />
                            <span style={{fontSize: '16px', color: '#FFF' }}>{i18n.language === 'en-us' ? 'EN' : 'PT'}</span> {/* Texto de idioma */}
                        </div>
                        <Divider type="vertical" style={{ background: '#FFF', height: '30px' }} />
                        <div className="dashboard-settings" onClick={toggleNavVisibility}>
                            <Avatar style={{ backgroundColor: '#E10088' }} icon={<UserOutlined />} className="dashboard-avatar" />
                        </div>
                    </Row>
                </S.HeaderContainer>

                <Outlet />
            </S.Container>

            <AnimatePresence>
                {isNavVisible && (
                    <motion.div
                        className="settings-nav"
                        variants={settingsNavVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                    >
                        <div className="settings-nav__name">
                            <h3>{getUserName()}</h3>
                        </div>
                        <div className="settings-nav__options">
                            <Link to="/dashboard/profile" onClick={closeNav}>
                                <FiUser className="icon" />
                        {t("Ver perfil")}
                            </Link>
                        </div>
                        <div className="settings-nav__options">
                            <Link to="/dashboard/help" onClick={closeNav}>
                                <FiHelpCircle className="icon" />
                                {t("Ajuda")}
                            </Link>
                        </div>
                        <div className="settings-nav__out">
                            <a href="/" onClick={logout}>
                                <FiLogOut className="icon" />
                                {t("Sair")}
                            </a>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>

    )

}

export default TopHeader;
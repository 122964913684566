import React, { useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import { Form } from 'react-bootstrap';
import FalconComponentCard from 'shared/theme/components/FalconComponentCard';
import { useDescriptionAndBenefitsFormStore } from 'shared/store-zustand/get-status-report/setDescriptionAndBenefits.ts';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport.ts';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport.ts';
import { useTranslation } from "react-i18next";

const StatusReportDescription = () => {
  const { description, setDescription } = useDescriptionAndBenefitsFormStore();
  const {typeStatusReport} = useTypeStatusReportStore()
  const [readOnly, setReadOnly] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const { getStatusReport} = useStatusReportStore();

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if(typeStatusReport === 1){
    getStatusReport(Number(id), typeStatusReport)
    .then((data) => {
      if (data) {
        setDescription(data.description)
        setReadOnly(true)
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
}, [typeStatusReport]);


  return (
    <FalconComponentCard style={{ height: '350px' }}>
      <FalconComponentCard.Header title="Textareas" light={false}>
        <h3 className="mt-2 mb-0 fs-9">{t('Status Report Description')}</h3>
      </FalconComponentCard.Header>
      <FalconComponentCard.Body>
        <Form.Control
          as="textarea"
          placeholder={t("Description")}
          style={{ height: '100%', minHeight: '250px', maxHeight: '250px', fontSize: '13px' }}
          value={description}
          onChange={handleChange}
          readOnly={readOnly}
          maxLength={560}
        />
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default StatusReportDescription;

import { Typography } from 'shared/components';
import styled from "styled-components";

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const CategoryDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-radius: 0px 32px 32px 0px;
  width: 234px;
  height: 32px;
  padding: 4px 4px 4px 8px;
 
  background-color:
    ${p => p.title === 'BACKLOG' ? 'rgb(236,236,236)'
    : p.title === 'PLANNING' ? 'rgb(207, 216, 220)'
      : p.title === 'EXECUTION' ? 'rgb(158, 193, 207)'
        : 'rgb(108, 142, 191)'};
`;

export const Status = styled.p<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  white-space: nowrap;

  border-radius: 16px;
  padding: ${p => p.theme.spacing.nano} ${p => p.theme.spacing.sm};
  margin: 0px;
  text-transform: uppercase;
  color: ${p => p.theme.colors.neutral.white[0]};
  font-size: ${p => p.theme.font.size.xs};
  font-weight: ${p => p.theme.font.weight.bold};
  margin-left: ${p => p.theme.spacing.xs};

  background-color: ${p =>
    p.status === 'ON TRACK'
      ? p.theme.colors.utility.darkGreen[700]
      : p.status === 'NEED ATTENTION'
        ? p.theme.colors.utility.yellow[700]
        : p.status === 'AT RISK'
          ? p.theme.colors.utility.red[700]
          : p.theme.colors.neutral.gray[100]
  };
`;

export const Label = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  display: block;
`;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface UpdateTaskManagementRequest {
  projectId: number;
  taskId: number;
  ritualNumber: number;
  name: string;
  description: string;
  owner: string;
  taskStatusId: number;
  startDate: Date;
  endDate: Date;
  user: string | null;
}

export function updateTaskManagement (params: UpdateTaskManagementRequest): Promise<AxiosResponse<UpdateTaskManagementRequest>> {
  return btoApi.post('/api/TaskManagement/Update', params);
}
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, ComponentContainer, ComponentHeader, PageContainer, PageHeader } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Gantt, Task ,ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import { getUser } from 'services';
import { getGanttByProgram } from 'bto_now/api';
import { getStartEndDateForProject } from './helpers/getStartEndDateForProject';

type Project = {
  projectId: number;
  name: string;
  startDate: string;
  endDate: string;
};

type Program = {
  programId: number;
  program: string;
  projects: Project[];
};

type ApiResponse = Program[];

type CustomTask = Omit<Task, 'type'> & {
  type: "project" | "task";
};

const formatTasks = (data: ApiResponse): CustomTask[] => {
  const tasks: CustomTask[] = [];

  data.forEach(program => {
    // Crear una tarea de programa
    const programTask: CustomTask = {
      start: new Date(), 
      end: new Date(),
      name: program.program,
      id: `${program.programId}`,
      progress: 0,
      type: "project",
      hideChildren: false,
    };

    const projectTasks: CustomTask[] = program.projects.map(project => {
      return {
        start: project.startDate ? new Date(project.startDate) : new Date(),
        end: project.endDate ? new Date(project.endDate) : new Date(),
        name: project.name,
        id: `project-${project.projectId}`,
        progress: 0,
        type: "task",
        project: programTask.id, 
      };
    });

    // Actualizar las fechas del programa en base a los proyectos
    const [start, end] = getStartEndDateForProject(projectTasks, programTask.id);
    programTask.start = start;
    programTask.end = end;

    // Añadir el programa y sus proyectos al array de tareas
    tasks.push(programTask, ...projectTasks);
  });

  return tasks;
};


const ProgramsByGantt = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [view, setView] = useState(ViewMode.Day);
  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    getGanttByProgram({ user: getUser(), portfolioId: 1 })
      .then(({ data }) => {
        console.log(data)
        if (data && data.length > 0) {
          setTasks(formatTasks(data));
        }
      })
      .catch(err => console.log(err));
  }, []);

  const handleTaskChange = (task: Task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };


  const handleExpanderClick = (taskId: string) => {
    setTasks(prevTasks =>
      prevTasks.map(task => {
        if (task.id === taskId && task.type === 'project') {
          // Toggle hideChildren for the clicked project
          return {
            ...task,
            hideChildren: !task.hideChildren,
          };
        }
        return task;
      })
    );
  };
  
  const navigate = useNavigate()

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: 'Portfolio Mng' },
      ]} />
      <PageContainer>
        {/* <PageHeader
          title='Portfolio Mng'
          navList={[
            { label: 'GANTT', value: '.' },
            { label: 'LIST', value: '/dashboard/mng/portfolio/list' }
          ]}
          containerStyle={{ position: 'sticky', left: 0, marginBottom: 10 }}
        /> */}
        <PageHeader
          title='Portfolio Mng'
          menu={[
            { label: 'GANTT', selected: true, onClick: () => navigate('./') },
            { label: 'LIST', selected: false, onClick: () => navigate('/dashboard/mng/portfolio/list') }
          ]}
        />
        <ComponentContainer>
          <ComponentHeader
            title='Visão do Cronograma'
          />
          {tasks.length > 0 && (
            <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onSelect={handleSelect}
            onExpanderClick={(task) => handleExpanderClick(task.id)}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}

          />
          )}
        </ComponentContainer>
      </PageContainer>
    </>
  );
};

export default ProgramsByGantt;

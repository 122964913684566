import styled from "styled-components";
import { Container as defaultContainer } from "shared/components/ComponentContainer/styles";

export const ComponentContainer = styled(defaultContainer)`
  padding: 0px ${p => p.theme.spacing.sm};
`

export const ComponentHeader = styled.div`
  border-bottom: 1px solid #A6B1C5;

  > p {
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #151C23;
    display: flex;
    align-items: center;
  }
`



import styled from "styled-components";

export const TitleCard = styled.div`
  padding-bottom: 8px;
  size: 24px;
`;

export const Card = styled.div`
  padding: ${(p) => p.theme.spacing.sm};
  background: white;
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 200px;
  min-width: 240px;
  min-height: 330px;
  margin: 20px 20px 0px 0px;
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  overflow-x: auto;
`;

export const CardTracking = styled.div`
  padding-top: ${(p) => p.theme.spacing.sm};
  padding-right: ${(p) => p.theme.spacing.sm};
  padding-left: ${(p) => p.theme.spacing.sm};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  background: white;
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 200px;
  min-width: 240px;
  min-height: 330px;
  margin: 20px 20px 0px 0px;
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
`;

export const CardCurveS = styled.div`
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 200px;
  min-width: 520px;
  margin: 20px 20px 0px 0px;
  width: 100%;
  min-height: 330px;
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  overflow-x: auto;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

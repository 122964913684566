import styled from "styled-components";

export const Card = styled.div`
  padding: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 200px;
  min-width: 250px;
  margin-top: 20px;
  width: 100%;
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

import styled from "styled-components";
import { Col as defaultCol, ColProps as DefaultColProps } from "antd";

type CircleColor = "gray" | "success" | "warning" | "danger";

interface ColProps extends DefaultColProps {
  colorCircle?: CircleColor;
}

export const Container = styled.div`
  padding-top: 10px;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const Col = styled(defaultCol)<ColProps>`
  width: 13px;
  height: 13px;
  background-color: blue;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ colorCircle }) => {
    switch (colorCircle) {
      case "gray":
        return "gray";
      case "success":
        return "green";
      case "warning":
        return "yellow";
      case "danger":
        return "red";
      default:
        return "gray";
    }
  }};
`;

export const Line = styled.div`
  width: 2px;
  height: 90%;
  background-color: #c7c7cc;
  position: absolute;
  transform: translateX(-50%);
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

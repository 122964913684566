import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';

export interface DeleteScopeProps {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  name: string;
  description: string;
  user: string;
}

interface DeleteScopeStore {
  deleteScope: (params: DeleteScopeProps) => void;
}

export const useDeleteScope = create<DeleteScopeStore>((set) => ({
  deleteScope: async (params: DeleteScopeProps) => {
    try {
      const response = await btoApi.post('api/ProjectScope/Delete', params);

      if (response.status === 200) {
        console.log('Scope deleted successfully:', response);
      } else {
        console.error('Error deleting project:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to delete scope:', error); 
    }
  },
}));
import React from 'react';

import { Button, TabBar } from 'shared/components';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

interface MenuItem {
  label: string;
  selected: boolean;
  onClick?(value?: string): void;
  key: string;
}

interface Props {
  title: string;
  /**
   * *Menu list tem os seguintes atributos*
   * - *label* É o texto que será apresentado na tela
   * - *value* Pode ser qualquer valor desde que não seja repetido
   * - *onClick* Uma função de callback que será chamada quando clicado no item
   */
  menuList?: Array<{label: string, value: string, onClick(value?: string): void}>;
  topRight?: React.ReactNode;
  bottomRight?: React.ReactNode;
  buttons?: Array<{ label: string, value: string }>
}

function ComponentHeader({
  title,
  bottomRight,
  menuList,
  buttons,
  topRight
}: Props) {
  const navigate = useNavigate();

  function handleOnClick(value: string) {
    if(menuList) {
      const item = menuList.find(v => v.value === value);
      if(item?.onClick) item.onClick(value);
    }
  }

  return (
    <S.Container>
      <S.Row style={{ justifyContent: 'space-between' }}>
        <S.Row><S.Title>{title}</S.Title></S.Row>
        <S.Row>
          {
            topRight
              ? topRight
              : Array.isArray(buttons) && buttons.map((btn, index) => (
                <Button
                  key={index} 
                  size="small" 
                  style={{ marginLeft: 10 }} 
                  onClick={() => navigate(btn.value)}
                >
                  {btn.label}
                </Button>
              ))
          }
        </S.Row>
      </S.Row>
      <S.Row style={{ justifyContent: 'space-between', marginTop: 12 }}>
        {menuList && (
          <TabBar
            upperCase={true}
            list={menuList}
            onClick={handleOnClick}
          />
        )}
        <S.Row>{bottomRight}</S.Row>
      </S.Row>
    </S.Container>
  );
}

export default ComponentHeader;
import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetArchivedIssuesPayload {
  User?:              string | null;
  ProjectId?:         string | null;
}

export type GetArchivedIssuesResponse = Array<{
  issueId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  status:               string;
  urgency:              string;
  impact:               string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}>

export function getArchivedIssues(params: GetArchivedIssuesPayload): Promise<AxiosResponse<GetArchivedIssuesResponse>> {
  return btoApi
    .get('/api/ProjectIssue/GetArchivedIssues', { params });
};

import React, { useRef, useState, useEffect } from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';
import DropdownIndicator from './components/DropDownIndicator';

import { getCustomStyles } from './styles';
import styles from './SelectList.module.css';

export interface OptionType {
  label: string;
  value: string;
}

interface Props {
  label?: string;
  error?: boolean;
  name?: string;
  containerStyle?: React.CSSProperties;
  options?: Array<OptionType>;
  value: string[]; 
  index?: number;
  onChange?: (selectedOptions: string[]) => void; 
}

const NoClearIndicator = () => null;

function SelectList({
  label,
  options = [],
  name,
  error = false,
  containerStyle,
  onChange,
  value = [],
  index = 0,  
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const selectedOptions = options.filter(option =>
    value.includes(option.value)
  );

  useEffect(() => {
    if (containerRef.current) {
      const labelElement = containerRef.current.querySelector(`.${styles.label}`);
      if (labelElement) {
        if (menuIsOpen || selectedOptions.length > 0) {
          labelElement.classList.add(styles.active);
        } else {
          labelElement.classList.remove(styles.active);
        }
      }
    }
  }, [menuIsOpen, selectedOptions]);

  const handleChange = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const selected = Array.isArray(newValue) ? newValue : [];
    const selectedValues = selected.map(option => option.value); 
    if (onChange) {
      onChange(selectedValues); 
    }
  };

  return (
    <div
      ref={containerRef}
      style={{ ...containerStyle, minWidth: '150px' }}
    >
      <div className={styles.container}>
        {label && (
          <div className={`${styles.label} ${menuIsOpen || selectedOptions.length > 0 ? styles.active : ''} ${(index % 2 === 0) ? styles.labelIndexPair : styles.labelIndexOdd}`}>
            {label}
          </div>
        )}
        <Select
          isMulti
          name={name}
          options={options}
          menuPosition='absolute'
          placeholder={label}
          styles={getCustomStyles({ menuIsOpen, error })}
          components={{ DropdownIndicator, ClearIndicator: NoClearIndicator }}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          onChange={handleChange}
          value={selectedOptions} 
        />
      </div>
    </div>
  );
}

export default SelectList;



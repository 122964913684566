import styled from 'styled-components';

export const ErrorMessage = styled.span`
  color: red;
  margin-left: 0.2rem;
  font-size: 12px;
`;

export const InputRow = styled.div<InputContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem;
  min-height: ${({ hasError }) => hasError ? '95px' : '0px'};
`;

interface InputContainerProps {
  hasError?: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: ${({ hasError }) => hasError ? '87px' : '0px'};
`;
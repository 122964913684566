import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetProjectTypePayload {
  User: string;
}

export type GetProjectTypeResponse = Array<{
  typeId: number;
  type:   string;
}>

export function getProjectType(params: GetProjectTypePayload): Promise<AxiosResponse<GetProjectTypeResponse>> {
  if (!params ||!params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });

  return btoApi
   .get('/api/ProjectType/GetTypes', { params });
}
import { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

import { IoGrid } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import PDF from '@react-pdf/renderer';

import { Breadcrumb, Button, Row, SpinLoading, PageContainer } from 'shared/components';
import { PageHeader } from 'shared/layouts';
import KanbanPdf from './pdf';

import { getUser } from 'services';
import { getAllInitiativesByKanban, GetAllInitiativesByKanbanResponse } from 'bto_now/api';
import { dateWithHours } from 'utils';
import { useTheme } from 'styled-components';
import DetailsProject, { IKanbanColumns } from './layout';
import { ModalKanban } from './layout/components/modalKanban';

function KanbanPage() {
  const theme = useTheme();

  const [initiatives, setInitiatives] = useState<GetAllInitiativesByKanbanResponse>();
  const [kanbanColumnsToShow, setKanbanColumnsToShow] = useState<IKanbanColumns>({
    ActualPhase: true,
    GoliveDate: true,
    BudgetReal: true,
    BudgetPlan: true,
    Operational: true,
    Financial: true,
    Geral: true,
    Time: true,
    Cost: true,
    Forecast: true,
  });
  const { t } = useTranslation();

  const routes = [
    { title: <IoGrid size={20} style={{ margin: 0 }} /> },
    { title: <Link to="/dashboard/mng">{t("Home")}</Link> },
    { title: <Link to="/dashboard">{t("Portfolio Mng")}</Link> },
    { title: <Link to="/dashboard/business/0">{t("Portfolio Business Driver")}</Link> },
    { title: t('Kanban') }
  ];

  const [modalVisible, setModalVisible] = useState(false);

  const getKanban = useCallback(() => {
    getAllInitiativesByKanban({ User: getUser() })
      .then(({ data }) => setInitiatives(data));
  }, []);


  useEffect(() => {
    getKanban();
    const savedColumnsConfig = localStorage.getItem('kanbanColumnsConfig');
    if (savedColumnsConfig) {
      setKanbanColumnsToShow(JSON.parse(savedColumnsConfig));
    }

  }, [getKanban]);

  function openModal() {
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  function onOkModal(args: IKanbanColumns) {
    setKanbanColumnsToShow(args);
    localStorage.setItem('kanbanColumnsConfig', JSON.stringify(args));
    closeModal();
  }

  async function downloadPdf() {
    if (initiatives?.kanbans && initiatives.kanbans.length) {
      const date = dateWithHours(new Date().toISOString());
      const handledDate = date.replaceAll('/', '-').replaceAll(':', '-').replace(', ', '_');
      const blob = await PDF.pdf(<KanbanPdf columns={kanbanColumnsToShow} data={initiatives.kanbans} />).toBlob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Kanban_${handledDate}.pdf`;
      a.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }));
      window.URL.revokeObjectURL(url);
    }
  }

  // Caso quiser visualizar o PDF em tela, descomentar esse código

  // useEffect(() => {
  //   if(initiatives?.kanbans && initiatives.kanbans.length) {
  //     const element = document.getElementById('pdf-viewer');
  //     ReactDOM.render(
  //       <PDFViewer style={{ border: 'none', width: '100%', height: '100vh' }}>
  //         <KanbanPdf columns={kanbanColumnsToShow} data={initiatives.kanbans}/>
  //       </PDFViewer>
  //     , element);
  //   }
  // }, [initiatives?.kanbans]);

  return (
    <S.ScrollY>
      <Breadcrumb separator=">" items={routes} />
      <PageContainer>
        <PageHeader
          title={t("Business Driver Kanban")}
          containerStyle={{ position: 'sticky', left: 0 }}
          topRight={
            <Row>
              <Button style={{ marginLeft: 12 }} size='small' onClick={openModal}>{t("Show Fields")}</Button>
              <Button style={{ marginLeft: 12 }} size='small' onClick={downloadPdf}>{t("Download PDF")}</Button>
            </Row>
          }
        />

        <Row style={{ alignItems: 'flex-start' }}>
          {initiatives && Array.isArray(initiatives?.kanbans) ? (
            initiatives.kanbans.map(kanban => (
              <DetailsProject data={kanban} columnsToShow={kanbanColumnsToShow} containerStyle={{ marginRight: 16 }} />
            ))
          ) : (
            <SpinLoading />
          )}

        </Row>

      </PageContainer>

      <ModalKanban visible={modalVisible} onClose={closeModal} onOk={onOkModal} columnsToShow={kanbanColumnsToShow} />
    </S.ScrollY>
  );
}

export default KanbanPage;
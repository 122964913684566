import { Modal } from "antd";
import { FaCircle } from "react-icons/fa";

var CONF_URL = "";
if (window.location.host === 'localhost:3000' || window.location.host === 'localhost:3001') {
    CONF_URL = "http://localhost"; // MANTENHA LOCALHOST QUANDO FOR ENVIAR !!!
} else {
    CONF_URL = window.location.protocol + "//" + window.location.host;
}

export const getPhase = (value) => {
    switch (value) {
        case 1: return 'Project Project Plan & Kick-off';
        case 2: return 'Project Discovery & Explorer';
        case 3: return 'Project Build & Development';
        case 4: return 'Project UAT';
        case 5: return 'Project Cutover';
        case 6: return 'Project Go-live & Hypercare';
        default: return 'Backlog';
    }
}

export const space = [12,12];

export const PALETE = { 
    'C01': '#a280ff', 
    'C02': '#8669FF', 
    'C03': '#322790', 
    'C04': '#393556', 
    'C05': '#02002f',
    'C06': '#6c55ff' 
  };

export const STATUS = [ <FaCircle color="#eeeeee" />, <FaCircle color="#007d46" />, <FaCircle color="yellow" />, <FaCircle color="red" /> ];
export const STATUS_XS = [ <FaCircle color="#eeeeee" size={16} />, <FaCircle color="#007d46" size={16} />, <FaCircle color="yellow" size={16} />, <FaCircle color="red" size={16} /> ];
export const STATUS_MD = [ <FaCircle color="#eeeeee" size={24} />, <FaCircle color="#007d46" size={24} />, <FaCircle color="yellow" size={24} />, <FaCircle color="red" size={24} /> ];
export const STATUS_LG = [ <FaCircle color="#eeeeee" size={30} />, <FaCircle color="#007d46" size={30} />, <FaCircle color="yellow" size={30} />, <FaCircle color="red" size={30} /> ];

export const URL_API = CONF_URL+'/services/php';

export const setUserName = (value) => {
    localStorage.setItem('USER_NAME', value);
}
export const getUserName = (value) => {
    return localStorage.getItem('USER_NAME', value);
}
export const setUser = (value) => {
    localStorage.setItem('USER', value);
}

export const logoutService = () => {
    localStorage.removeItem('USER');
    localStorage.removeItem('TOKEN');
    window.location.href = '/';
}

export const getUser = () => {
    return localStorage.getItem('USER') || '';
}

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}

export const POST_CATCH = () => {
    Modal.error({ title: 'Erro crítico', content: 'Não foi possível se conectar com o servidor.' })
}
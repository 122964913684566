import { Typography } from 'shared/components';
import styled, { css } from "styled-components";

interface DivContainerProps {
  minimized: boolean;
}

export const DivContainer = styled.div<DivContainerProps>`
  width: fit-content;
  height: fit-content;
  background-color: white;
  padding: 20px 10px 0px 10px;
  border-radius: 4px;
  margin-top: 12px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  ${p => p.minimized && css`
    padding: 0px;
    border-radius: 15px;
    padding: 20px 0px;
    /* height: 100%; */
  `}

`;

export const Main = styled.div<{ minimized: boolean }>`
  width: 100%;
  /* height: 100%; */
  overflow: auto;
  /* max-height: calc(100vh - 320px); */
  /* overflow-x: auto; */
  background-color: ${p => p.theme.colors.neutral.white};
  /* transition: 100ms; */
  padding-right: 10px;
  

  ${p => p.minimized && css`
    height: 0px;
    width: 0px;
    opacity: 0;
  `}
`;

export const StatusProject = styled.div`
  width: 100%;
  min-height: 231px;
  margin: 20px 0px;
  border: 1px solid #0000004d;
  border-radius: 8px;
  padding: 8px;
`;

export const RoundedText = styled(Typography).attrs({
  size: "medium",
})`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 26px;
  height: 26px;
  color: ${p => p.theme.colors.neutral.white[900]};
  border-radius: 50%;
`;

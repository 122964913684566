import styled, { css } from "styled-components";
import { PiCalendarThin } from "react-icons/pi";

export const Container = styled.div`
  height: 50px;
`;

interface ButtonProps {
  hasValue: boolean;
  error: boolean | undefined;
}

export const Button = styled.button.attrs<ButtonProps>(({ hasValue, error }) => ({
  hasValue: hasValue || undefined,
  error: error || undefined,
}))<ButtonProps>`
  background-color: transparent;
  cursor: pointer;
  min-height: 25px;
  width: 100%;
  background: transparent;
  z-index: 2;
  padding: 0px 10px;
  font-size: 12px;
  color: ${({ hasValue }) => (hasValue ? '#4d5c73' : "#151C23")};
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${({ error }) => (error ? 'red' : '#C6C6C7')};
  border-radius: 8px;
  margin-top: 10px;
  height: 40px;
  min-width: 202px !important;
  transition: all 150ms ease-in-out;

  &:focus {
    border-color: #4d5c73;
  }
`;

export const Icon = styled(PiCalendarThin)`
  margin-left: 8px;
  font-size: 23px;
  color: #151C23;
  position: absolute;
  right: 10px;
`;

interface PlaceholderProps {
  hasValue: boolean;
  index: number;
}

export const Placeholder = styled.span.attrs<PlaceholderProps>(({ hasValue, index }) => ({
  hasValue: hasValue || undefined,
}))<PlaceholderProps>`
  color: #151C23;
  position: absolute;
  top: ${({ hasValue }) => (hasValue ? '-8px' : 'calc(50% - 9px)')};
  left: ${({ hasValue }) => (hasValue ? '5px' : '10px')};
  cursor: text;
  font-size: ${({ hasValue }) => (hasValue ? '10px' : '12px')};
  background-color: ${({ index }) => (index % 2 === 0) ? 'white' : '#F9FAFE'};
  padding: ${({ hasValue }) => hasValue && '1px 3px'};
  font-weight: 450;
  white-space: nowrap;
  transition: all 150ms ease-in-out;
`;



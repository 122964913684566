import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import { IoGrid } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Select, SpinLoading, Table, ModalSuccess, CheckBox, Breadcrumb, ComponentHeader, ComponentContainer, ButtonsActionsPage, Input, PageHeader, StatusText } from "shared/components";
import { ProjectHeader } from 'shared/layouts';
import ModalCreateRisk from "./components/ModalCreateRisk/index";
import ModalEditRisk from "./components/ModalEditRisk/index";

import { debounce } from "utils";
import { convertTypeIdInType } from "utils/convertType/convertTypeIdInType";
import { convertStatusIdInStatus } from "utils/convertStatus/convertStatusIdInStatus";

import * as S from './styles';
import { RiskData, riskList } from "./data";
import { useGeneralDataSupport } from "shared/store-zustand/risks-project/getGeneralDataSupport";
import { TFunction } from "i18next";
import * as yup from "yup";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { getUser } from "services";

export interface FormSchemaRisks {
  riskName: string;
  riskDescription: string;
  type: string;
  statusMitigation: string;
  probabilityScale: string;
  impactScale: string;
  historical: string;
  openDate: string;
  forecastDate: string;
  owner: string;
}

export const schema = (t: TFunction<"translation", undefined>) => 
  yup.object().shape({
    riskName: yup.string()
      .trim()
      .required(t('This field is required.')),
    riskDescription: yup.string()
      .trim()
      .required(t('This field is required.')),
    type: yup.string()
      .required(t('This field is required.')),
    statusMitigation: yup.string()
      .required(t('This field is required.')),
    probabilityScale: yup.string()
      .required(t('This field is required.')),
    impactScale: yup.string()
      .required(t('This field is required.')),
    historical: yup.string()
      .trim()
      .required(t('This field is required.')),
    openDate: yup.string()
      .required(t('This field is required.')),
    forecastDate: yup.string()
      .required(t('This field is required.')),
    owner: yup.string()
      .required(t('This field is required.')),
  }).required();

const RisksRitual = () => {
  const { generalDataSupport, getGeneralDataSupport } = useGeneralDataSupport();
  const { getFieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();
  const { id } = useParams();

  const initialRiskData: RiskData = {
    riskId: 0, 
    riskName: '',
    riskDescription: '',
    type: '', 
    statusMitigation: '', 
    probabilityScale: '', 
    impactScale: '', 
    historical: '',
    status: '', 
    lastUpdate: '',
    openDate: '',
    forecastDate: '',
    owner: ''
  };

  const [filters, setFilters] = useState<RiskData>(initialRiskData);
  const [RiskListData, setRiskListData] = useState<[]>([]);
  const [filteredRiskList, setFilteredRiskList] = useState<RiskData[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [newRiskModal, setNewRiskModal] = useState(false);
  const [editRiskModal, setEditRiskModal] = useState(false);

  const [selectedRisks, setSelectedRisks] = useState<Set<number>>(new Set());
  const [selectedRiskId, setSelectedRiskId] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        getFieldListStatus({
          User: getUser(),
          portfolioId: 1
        });
        // const { data: RiskData } = await getListRiskManagement({ User: getUser(), ProjectId: id });
        // setRiskListData(RiskData);

        // const { data: RiskTypeData } = await getListRiskType({ User: getUser() });
        // setRiskTypeList(RiskTypeData);

        // const { data: RiskStatusData } = await getListRiskStatus({ User: getUser() });
        // setRiskStatusList(RiskStatusData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(fetchData, 500)();
  }, [updateTrigger]);

  useEffect( () => {
    try {
      getGeneralDataSupport();
      setFilteredRiskList(riskList)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // useEffect(() => {
  //   const filterTasks = () => {
  //     const filtered = RiskListData.filter(Risk => {
  //       const typeMatch = convertTypeIdInType(RiskTypeList, Risk.RiskTypeId) === filters.RiskType || filters.RiskType === '';
  //       const statusMatch = convertStatusIdInStatus(RiskStatusList, Risk.RiskStatusId) === filters.RiskStatus || filters.RiskStatus === '';
  //       const nameMatch = Risk.name.toLowerCase().includes(filters.name.toLowerCase());
  //       const ownerMatch = Risk.owner.toLowerCase().includes(filters.owner.toLowerCase());
        
  //       return typeMatch && statusMatch && nameMatch && ownerMatch;
  //     });

  //     setFilteredRiskList(filtered);
  //   };

  //   filterTasks();
  // }, [filters, RiskListData]);

  const handleCheck = (id: number) => {
    setSelectedRisks(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
        setSelectedRiskId(null);
      } else {
        newSelected.add(id);
        setSelectedRiskId(id);
      }
      return newSelected;
    });
  };

  // const deleteRiskDatabase = async () => {
  //   try {
  //     const selectedIds = Array.from(selectedRisks);
  //     for (const idSelect of selectedIds) {
  //       const params: DeleteRiskManagementRequest = {
  //         projectId: Number(id),
  //         RiskId: idSelect,
  //         user: getUser(),
  //         name: '',
  //         description: '',
  //         owner: ''
  //       };

  //       const response = await deleteRiskManagement(params);

  //       if (response.status === 200) {
  //         console.log('Risk deleted successfully:', response.data);
  //         setUpdateTrigger(prev => !prev);
  //       } else {
  //         console.error('Error deleting Risk:', response.statusText);
  //       }
  //     }

  //     setModalOpen(true);
  //     setModalDescription(`Deleted ${selectedIds.length} Risks successfully.`);

  //   } catch (error) {
  //     console.error('Error deleting Risks:', error);
  //   }
  // }

  // const handleDelete = () => {
  //   deleteRiskDatabase();

  //   setUpdateTrigger(prev => !prev);
  //   setSelectedRisks(new Set());
  //   setSelectedRiskId(null);
  // };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = () => {
    if (selectedRisks.size === 1) {
      const selectedId = Array.from(selectedRisks)[0];
      setSelectedRiskId(selectedId);
      setEditRiskModal(true);
    }
  };

  const handleOpenModalCreate = () => {
    setNewRiskModal(true);
  };

  const statusColorScale = useCallback((option: string) => {
    switch (option) {
      case 'LOW': return 'green';
      case 'HIGH': return 'red';
      case 'MODERATE': return 'orange';
      case 'VERY HIGH': return 'black';
      case 'CRITICAL': return 'black';
    }
  }, []);

  const isChecked = (id: number) => selectedRisks.has(id);
  
  const isDisabledButtonDelete = selectedRisks.size === 0;
  const isDisabledButtonEdit = selectedRisks.size !== 1;
  const isDisabledButtonNew = selectedRisks.size > 0;

  return (
    <>
      <ComponentHeader title={t('PMO: Ritual Project Risks')} menu={[{ label: t('Risks'), selected: true }]} />
      
      <S.ContainerForms>
        <S.FormRow>
          <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
            <S.InputContainer>
              <Input
                label={t('Risk Name') + ' *'}
                name='RiskName'
                value={filters.riskName}
                onChange={(e) => setFilters({ ...filters, riskName: e.target.value })}
                style={{ fontSize: '12px' }}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
                label={t('Risk Type') + ' *'}
                name='type'
                value={filters.type}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, type: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.statusMitigation.map((status: string) => ({ label: status, value: status }))}
                label={t('Status Mitigation') + ' *'}
                name='statusMitigation'
                value={filters.statusMitigation}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, statusMitigation: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.probabilityScale.map((scale: string) => ({ label: scale, value: scale }))}
                label={t('Probability Scale') + ' *'}
                name='probabilityScale'
                value={filters.probabilityScale}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, probabilityScale: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={generalDataSupport.impactScale.map((impact: string) => ({ label: impact, value: impact }))}
                label={t('Impact Scale') + ' *'}
                name='impactScale'
                value={filters.impactScale}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, impactScale: e.target.value })}
              />
            </S.InputContainer>
          </div>
          <S.ButtonContainerRow>
            <ButtonsActionsPage 
              isDisabledButtonDelete={isDisabledButtonDelete}
              isDisabledButtonEdit={isDisabledButtonEdit}
              isDisabledButtonNew={isDisabledButtonNew}
              handlePlus={handleOpenModalCreate}
              handleEdit={handleOpenModalEdit}
              handleTrash={() => console.log('delete')}
            />
          </S.ButtonContainerRow>
        </S.FormRow>
      </S.ContainerForms>

      <S.ContainerList>
        {
          isLoading ? (
            <SpinLoading />
          ) : (
            <S.TableContainer>
              <Table style={{ marginTop: 16 }}>
                <Table.Thead>
                  <Table.Tr header>
                    <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Id')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Risk Name')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Risk Description')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Type')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Status Mitigation')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Probability Scale').toUpperCase()}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Impact Scale')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Historical')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Open Date')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Forecast Date')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Owner')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Status')}</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>{t('Last Update')}</Table.Td>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {
                    filteredRiskList.map((risk) => (
                      <Table.Tr key={risk.riskId} style={{ borderBottom: '1px solid #ccc' }}>
                        <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(risk.riskId)} checked={isChecked(risk.riskId)} size="medium" /></Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.riskId}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.riskName}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.riskDescription}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.type}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.statusMitigation}</Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={statusColorScale(risk.probabilityScale)} 
                            size='extraSmall' weight='bold'>
                              {risk.probabilityScale}
                          </StatusText>
                        </Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={statusColorScale(risk.impactScale)} 
                            size='extraSmall' weight='bold'>
                              {risk.impactScale}
                          </StatusText>
                        </Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.historical}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.openDate}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.forecastDate}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.owner}</Table.Td>
                        <Table.Td>
                          <StatusText 
                            color={(risk.status === 'Active') ? 'green' : 'red'} 
                            size='extraSmall' weight='bold'>
                              {risk.status}
                          </StatusText>
                        </Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{risk.lastUpdate}</Table.Td>
                      </Table.Tr>
                    ))
                  }
                </Table.Tbody>
              </Table>
            </S.TableContainer>
          )
        }
      </S.ContainerList>

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description={modalDescription}
        title="Success"
      />

      <ModalCreateRisk 
        projectId={id}
        newRiskModal={newRiskModal}
        setNewRiskModal={setNewRiskModal}
        setUpdateTrigger={setUpdateTrigger}
      />

      
      <ModalEditRisk 
        projectId={id}
        riskId={selectedRiskId}
        editRiskModal={editRiskModal}
        updateTrigger={updateTrigger}
        setEditRiskModal={setEditRiskModal}
        setUpdateTrigger={setUpdateTrigger}
        setSelectedRisks={setSelectedRisks}
        setSelectedRiskId={setSelectedRiskId}
      />
    </>
  );
};

export default RisksRitual;
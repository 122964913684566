import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  background-color: #393556;
  border: none;
  color: white;
  padding: 0 12px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 100px;
  height: 25px;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #4c4672;
  }

  &:disabled {
    background-color: #d9d9d9;
    cursor: not-allowed;
  }
`;
import { btoApi } from "bto_now/api/client";
import { create } from 'zustand';
import { getUser } from "services";

interface StatusReportState {
  statusReport: StatusReportResponse | null;
  getStatusReport: (ProjectId: number, status: number) => Promise<StatusReportResponse | void>;
  validateStatusReport: (ProjectId: number, description: string, benefits: string) => Promise<StatusReportResponse | void>;
}

export const useStatusReportStore = create<StatusReportState>((set) => ({
  statusReport: null, 
  getStatusReport: async (ProjectId: number, status: number): Promise<StatusReportResponse | void> => {
    try {
      if(status === 1){
        const response = await btoApi.get<StatusReportResponse>(`/api/DashBoard/GetStatusReport?User=${getUser()}&ProjectId=${ProjectId}&Status=${status}`);
        set({ statusReport: response.data });
        return response.data;
      }
      
      if(status === 0) {
        const response = await btoApi.post<StatusReportResponse>(`/api/ProjectStatusReport/NewStatusReport`,{ User: getUser(), ProjectId: ProjectId, description: '' , benefits: ''});
        set({ statusReport: response.data });
        return response.data;
      }

    } catch (error) {
      console.error('Failed to fetch status report:', error)
      return; 
    }
  },
  validateStatusReport: async (ProjectId: number,description: string, benefits: string): Promise<StatusReportResponse | void> => {
    try {
      const response = await btoApi.post<StatusReportResponse>(`/api/ProjectStatusReport/ValidateStatusReport`,{ User: getUser(), ProjectId: ProjectId,description, benefits});
      set({ statusReport: response.data });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch status report:', error)
      return; 
    }
  },
}));


interface FinancialDetail {
  type: string;
  total: number;
  totalCapex: number;
  totalOpex: number;
  runninCosts: number;
  committedBudget: number;
}

interface CurveS {
  situation: string;
  startDate: string;
  endDate: string;
  valorStart: number;
  valorEnd: number;
}

interface NewCurveS {
  phase: string;
  phaseExecutionId: number;
  situation: string;
  valor: number;
}

interface OtherDetailsInfo {
  detFinancial: FinancialDetail[];
  risks: any[]; // Ajusta según la estructura real
  issues: any[]; // Ajusta según la estructura real
  impacts: any[]; // Ajusta según la estructura real
  curveS: CurveS[];
  newCurveS: NewCurveS[];
}

export interface ProjectDetails {
  companyId: number;
  projectGroup: string;
  initiativeType: string;
  mainInitiative: string;
  initiativeName: string;
  summary: string;
  initiativeId: string;
  sponsor: string;
  businessLeader: string;
  itPartnerLeader: string;
  itBusinessPatner: string;
  keyUser: string;
  businessArea: string;
  businessAreaId: number;
  businessDriver: string;
  businessBenefits: string;
  techonologyBenefits: string;
  plataformEnvolved: string;
  businessProcessEnvolved: string;
  programme: string;
  programmeId: number;
  businessDriverId: number;
  projectId: string;
  name: string;
  typeId: number;
  type: string;
  startDate: string;
  startPhaseBuild: string | null;
  percentCompleteBuild: number;
  startPhaseUat: string | null;
  percentCompleteUat: number;
  startPhaseCutover: string | null;
  percentCompleteCutover: number;
  goLiveDate: string;
  endDate: string;
  actualPhase: number;
  actualPhaseDescription: string;
  progressPlaned: number;
  progressRealized: number;
  issuesQuantity: number;
  statusTime: number;
  statusCoast: number;
  kanbanId: number;
  kanban: string;
  statusRisk: number;
  statusIssue: number;
  statusScope: number;
  providers: string;
  totalRequested: number;
  totalInvestiment: number;
  totalInvestimentCapex: number;
  totalInvestimentOpex: number;
  commitedBudget: number;
  percentCommitedPlanned: number;
  totalRunningCoast: number;
  lastRitualDate: string;
  cpi: number | null;
  spi: number | null;
  extraBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  financialProgress: number;
  operacionalProgress: number;
  operationalProgress: number;
  toWin: boolean;
  projectManager: string;
  actualStatus: number;
  actualStatusDescription: string;
  user: string | null;
  otherDetailsInfo: OtherDetailsInfo;
  timeline: any[]; // Ajusta según la estructura real
  financial: {
    projectFinancial: any[]; // Ajusta según la estructura real
    projectFinancialGroup: any[]; // Ajusta según la estructura real
  };
}

interface Permissions {
  newStatusReport: boolean;
  saveStatusReport: boolean;
  validateStatusReport: boolean;
}

export interface StatusReportResponse {
  detalhesDoProjeto: ProjectDetails;
  scopeManagementList: any[]; // Ajusta según la estructura real
  projectRisks: any[]; // Ajusta según la estructura real
  taskManagementList: any[]; // Ajusta según la estructura real
  projectInformation: {
    scope: number;
    schedule: number;
    coast: number;
  };
  description: string;
  benefits: string;
  permissions: Permissions;
  publishDate: string;
}

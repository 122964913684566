import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { Button, CheckBox, ModalError, ModalSuccess, Row, Select, SpinLoading, StatusText, Table, Typography, Input } from 'shared/components';
import { useTheme } from 'styled-components';
import { getUser } from 'services';
import { createIssue, CreateIssuePayload, deleteIssue, DeleteIssuePayload, getArchivedIssues, getIssues, GetIssuesResponse, updateIssue, UpdateIssuePayload } from 'bto_now/api';
import { useParams } from 'react-router-dom';
import { dateWithHours, getUrlSearchParams, RitualManagementStatusRitual } from 'utils';
import { Modal } from 'antd';
import { FaPlus } from "react-icons/fa";
import { getGeneralDataSupportIssues } from 'bto_now/api/generalSupport/getGeneralDataSupportIssues';

function RitualIssueUpdate() {
  const theme = useTheme();
  const { projectId } = useParams();

  const statusRitual = getUrlSearchParams('statusRitual') as RitualManagementStatusRitual;
  const isNotDraft = statusRitual === 'Active' || statusRitual === 'History';

  const [data, setData] = useState<GetIssuesResponse>();
  const [archived, setArchived] = useState(false);
  const [savingLoading, setSavingLoading] = useState(false);

  const [checkedL, setCheckedL] = React.useState<Array<string>>([]);
  const [deletingLoading, setDeletingLoading] = React.useState(false);

  const [typeOptions, setTypeOptions] = useState<Array<string>>([]);
  const [impactOptions, setImpactOptions] = useState<Array<string>>([]);
  const [urgencyOptions, setUrgencyOptions] = useState<Array<string>>([]);
  const [statusOptions, setStatusOptions] = useState<Array<string>>([]);

  const [newIssueModal, setNewIssueModal] = useState(false);
  const [issueName, setIssueName] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [issueType, setissueType] = useState('');
  const [issueStatus, setIssueStatus] = useState('');
  const [issueUrgency, setUrgency] = useState('');
  const [issueImpact, setIssueImpact] = useState('');
  const [issueHistorical, setIssueObs] = useState('');
  const [creatingLoading, setCreatingLoading] = useState(false);

  const [modalSuccess, setModalSucess] = useState('');
  const [modalError, setModalError] = useState('');

  useEffect(() => {
    getGeneralDataSupportIssues()
      .then(({ data }) => {
        console.log('issues', data)
        if (data) {
          const type = data.tipos.find((v: any) => v.descricao === "TYPE");
          const urgency = data.tipos.find((v: any) => v.descricao === "URGENCY");
          const impact = data.tipos.find((v: any) => v.descricao === "IMPACT");
          const status = data.tipos.find((v: any) => v.descricao === "STATUS");
          if (type) setTypeOptions(type.opcoes);
          if (impact) setImpactOptions(impact.opcoes);
          if (urgency) setUrgencyOptions(urgency.opcoes);
          if (status) setStatusOptions(status.opcoes);
        }
      })
      .catch(() => null);
  }, []);

  useEffect(fetchAPI, [projectId, archived]);

  function fetchAPI() {
    setData(undefined);
    (archived ? getArchivedIssues : getIssues)({ User: getUser(), ProjectId: projectId as string })
      .then(({ data }) => {
        setData(data);
      })
      .catch(err => console.log(err));
  }

  function handleIfIsChecked(i: string) {
    const index = checkedL.find(v => v === i);
    if (!index) return false;
    else return true;
  }

  function handleCheck(i: string) {
    const index = checkedL.findIndex(v => v === i);

    if (Number(index) > -1) {
      checkedL.splice(Number(index), 1);
      setCheckedL([...checkedL]);
    }
    else setCheckedL(checkedL.concat(i));
  }

  function handleScaleColor(status: string) {
    switch (status) {
      case 'HIGH':
        return theme.colors.utility.red[500];
      case 'MODERATE':
        return theme.colors.utility.yellow[500];
      case 'LOW':
        return theme.colors.utility.darkGreen[500];
      case 'VERY HIGH':
        return theme.colors.neutral.white[900];
      case 'CRITICAL':
        return theme.colors.neutral.white[900];
    }
  }

  const statusColor = useCallback((color: string) => {
    switch (color) {
      case 'Archived': return 'yellow';
      case 'Active': return 'green';
    }
  }, []);

  function updateField(value: string, index: number, field: string) {
    if (data && data[index]) {
      const newData = [...data];
      newData[index][field] = value;
      setData([...newData]);
    }
  }

  const updateHistoricalMonitoring = (value: string, index: number) => updateField(value, index, 'historicalMonitoring');
  const updateType = (value: string, index: number) => updateField(value, index, 'type');
  const updateStatus = (value: string, index: number) => updateField(value, index, 'status');
  const updateUrgency = (value: string, index: number) => updateField(value, index, 'urgency');
  const updateImpact = (value: string, index: number) => updateField(value, index, 'impact');
  const updateName = (value: string, index: number) => updateField(value, index, 'name')
  const updateDescription = (value: string, index: number) => updateField(value, index, 'description');

  function deleteSelectedIssues(checkedL: Array<string>) {
    setDeletingLoading(true);
    if (data) {
      const buffer: GetIssuesResponse = new Array(0);
      for (let ind in checkedL) {
        const index = data.findIndex((v, i) => String(i) === checkedL[ind]);
        if (index > -1) {
          buffer.push(data[index]);
        }
      }
      const promisses = buffer.map((v) => {
        const deletePayload: DeleteIssuePayload = {
          ...v,
          user: getUser() as string,
          projectId: projectId as string,
        }
        deleteIssue(deletePayload);
      })

      Promise.allSettled(promisses)
        .then((responses: Array<PromiseSettledResult<void>>) => {
          setTimeout(fetchAPI, 1000);
          const rejected = responses.find((response) => response.status === 'rejected');
          if(rejected) setModalError('Unable to delete issue')
          else setModalSucess('Issue deleted successfully')
        })
        .finally(() => {
          setDeletingLoading(false);
          setCheckedL([]);
        });
    }
  }

  function newIssue() {

    if (
      !issueName 
      || !issueDescription 
      || !issueType 
      || !issueStatus 
      || !issueUrgency 
      || !issueImpact 
      // || !issueHistorical
    ) {
      setModalError('Please fill in all fields');
      return;
    }

    const payload: CreateIssuePayload = {
      issueId: 0,
      name: issueName,
      description: issueDescription,
      type: issueType,
      status: issueStatus,
      urgency: issueUrgency,
      impact: issueImpact,
      historicalMonitoring: issueHistorical,
      lastUpdate: new Date().toJSON(),
      user: getUser() as string,
      companyId: 0,
      owner: '',
      projectId: projectId as string,
    };

    setCreatingLoading(true);
    createIssue(payload)
    .then(({ data }) => {
      setNewIssueModal(false);
      if(data.sucesso) {
        setTimeout(fetchAPI, 1000);
        setModalSucess('Issue created successfully');
      }
      else {
        setModalError('Unable to create issue');
      }
    })
    .catch(() => setModalError('Unable to create issue'))
    .finally(() => { setCreatingLoading(false); });
  }

  function saveIssues() {
    setSavingLoading(true);
    if(data) {
      const promisses = data.map((v) => {
        const payload: UpdateIssuePayload = {
         ...v,
          user: getUser() as string,
          projectId: projectId as string,
        }
        updateIssue(payload);
      })

      Promise.allSettled(promisses)
        .then((responses: Array<PromiseSettledResult<void>>) => {
          setTimeout(fetchAPI, 1000);
          const rejected = responses.find((response) => response.status ==='rejected');
          if(rejected) setModalError('Unable to update issue')
          else setModalSucess('Issue updated successfully')
        })
        .finally(() => {
          setSavingLoading(false);
        });
    }
  }

  return (
    <S.Container>
    <Row style={{ justifyContent: 'space-between', marginBottom: 22 }}>
      <Typography weight='bold' size='medium'>Issues Management</Typography>
      <Row>
        <Button type='plain' style={{ marginRight: 12 }} onClick={() => setArchived(!archived)}>{archived ? 'Show active' : 'Show Archived'}</Button>
        { !archived && <Button style={{ marginRight: 6 }} disabled={isNotDraft} onClick={() => deleteSelectedIssues(checkedL)} loading={deletingLoading}>Delete</Button>}
        { !archived && <Button style={{ marginLeft: 6 }} disabled={isNotDraft} onClick={saveIssues} loading={savingLoading}>Save</Button>}
      </Row>
    </Row>

    {
      !data ? (
        <SpinLoading />
      ) : (
        <S.ScrollX>
          <Table style={{ marginTop: theme.spacing.sm, width: '100%' }}>
            <Table.Tr header>
              {!archived && <Table.Td></Table.Td>}
              {/* <Table.Td>Id</Table.Td> */}
              <Table.Td>Issue Name</Table.Td>
              <Table.Td>Issue Description</Table.Td>
              <Table.Td style={{ minWidth: 150 }}>Type</Table.Td>
              <Table.Td style={{ minWidth: 150 }}>Status</Table.Td>
              <Table.Td style={{ minWidth: 130 }}>Urgency</Table.Td>
              <Table.Td style={{ minWidth: 130 }}>Impact</Table.Td>
              <Table.Td>Obs</Table.Td>
              {/* <Table.Td>Status</Table.Td> */}
              <Table.Td>Last Update</Table.Td>
            </Table.Tr>
            {data.map((m, i) => (
              <Table.Tr key={i + '-IssueUpdateTable'}>
                {!archived && <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(String(i))} checked={handleIfIsChecked(String(i))} size="medium" /></Table.Td>}
                {/* <Table.Td>{m.issueId}</Table.Td> */}
                <Table.Td title={m.name} style={{ maxWidth: 220 }}>
                    <Table.Input disabled={isNotDraft} value={m.name} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateName(ev.target.value, i)} />  
                  </Table.Td>
                  <Table.Td title={m.description} style={{ maxWidth: 220 }}>
                    <Table.TextArea maxLength={1000} disabled={isNotDraft} value={m.description} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => updateDescription(ev.target.value, i)} />    
                  </Table.Td>
                <Table.Td>
                  <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateType(ev.currentTarget.value, i)}>
                    {typeOptions.length > 0 &&
                      typeOptions.map((type, index) => (
                        <Table.Option key={index} value={type} selected={type === m.type}>{type}</Table.Option>
                      ))
                    }
                  </Table.SelectBox>
                </Table.Td>
                <Table.Td>
                  <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateStatus(ev.currentTarget.value, i)}>
                    {statusOptions.length > 0 &&
                      statusOptions.map((status, index) => (
                        <Table.Option key={index} value={status} selected={status === m.status}>{status}</Table.Option>
                      ))
                    }
                  </Table.SelectBox>
                </Table.Td>
                <Table.Td>
                  <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateUrgency(ev.currentTarget.value, i)} style={{ backgroundColor: handleScaleColor(m.urgency), color: 'white' }}>
                    {urgencyOptions.length > 0 &&
                      urgencyOptions.map((probability, index) => (
                        <Table.Option key={index} value={probability} selected={probability === m.urgency}>{probability}</Table.Option>
                      ))
                    }
                  </Table.SelectBox>
                </Table.Td>
                <Table.Td>
                  <Table.SelectBox disabled={isNotDraft} onChange={(ev: React.FormEvent<HTMLSelectElement>) => updateImpact(ev.currentTarget.value, i)} style={{ backgroundColor: handleScaleColor(m.impact), color: 'white' }}>
                    {impactOptions.length > 0 &&
                      impactOptions.map((impact, index) => (
                        <Table.Option key={index} value={impact} selected={impact === m.impact}>{impact}</Table.Option>
                      ))
                    }
                  </Table.SelectBox>
                </Table.Td>
                <Table.Td>
                  <Table.TextArea maxLength={300} disabled={isNotDraft} value={m.historicalMonitoring} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => updateHistoricalMonitoring(ev.target.value, i)} />
                </Table.Td>
                {/* <Table.Td><StatusText color={statusColor(archived ? 'Archived' : 'Active')} size='extraSmall' weight='bold'>{archived ? 'Archived' : 'Active'}</StatusText></Table.Td> */}
                <Table.Td>{dateWithHours(m.lastUpdate)}</Table.Td>
              </Table.Tr>
            ))}
            {!archived && !isNotDraft &&
              (<Table.Tr onClick={() => setNewIssueModal(true)} style={{ background: 'white', cursor: 'pointer' }}>
                <Table.Td><FaPlus size={18} /></Table.Td>
                <Table.Td colSpan={10}>New Issue</Table.Td>
              </Table.Tr>)
            }
          </Table>
        </S.ScrollX>
      )
    }

    <Modal
      title="New Issue"
      open={newIssueModal}
      onCancel={() => setNewIssueModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setNewIssueModal(false)}>Cancel</Button>
          <Button style={{ marginLeft: 12 }} onClick={newIssue} loading={creatingLoading}>Create</Button>
        </div>
      }
    >
      <Input containerStyle={{ marginBottom: 12 }} label="Issue Name" onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setIssueName(ev.target.value)} />
      <Input containerStyle={{ marginBottom: 12 }} maxLength={1000} label="Issue Description" onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setIssueDescription(ev.target.value)} />
      {/** Carol seguir esse exemplo de select */}
      <Select 
        containerStyle={{ marginBottom: 12 }} 
        label="Type" 
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setissueType(ev.target.value)}
        options={typeOptions.map(op => ({ label: op, value: op }))}
      />
      
      <Select 
        containerStyle={{ marginBottom: 12 }} 
        label="Status" 
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setIssueStatus(ev.target.value)}
        options={statusOptions.map(op => ({ label: op, value: op }))}
      />

      <Select 
        containerStyle={{ marginBottom: 12 }} 
        label="Urgency" 
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setUrgency(ev.target.value)}
        options={urgencyOptions.map(op => ({ label: op, value: op }))}
      />

      <Select containerStyle={{ marginBottom: 12 }} 
        label="Impact" 
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setIssueImpact(ev.target.value)}
        options={impactOptions.map(op => ({ label: op, value: op }))}
      />

      <Input containerStyle={{ marginBottom: 24 }} maxLength={300} label="Obs" onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setIssueObs(ev.target.value)} />
    </Modal>

    <ModalSuccess autoClose={3000} open={modalSuccess.length > 0} title='Success!' description={modalSuccess} onOk={() => setModalSucess('')} />
    <ModalError autoClose={3000} open={modalError.length > 0} title='Error!' description={modalError} onOk={() => setModalError('')} />
  </S.Container>
  )
}

export default RitualIssueUpdate;

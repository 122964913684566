export const projectPhases = [
  {
    startDatePlan: "01.07.2024",
    endDatePlan: "10.07.2024",
    progressBarPlan: 100,
    phasePlanDays: 9,
    progressBarReal: 100,
    owner: "Jane Smith",
    status: "Completed"
  },
  {
    startDatePlan: "11.07.2024",
    endDatePlan: "20.07.2024",
    progressBarPlan: 100,
    phasePlanDays: 9,
    progressBarReal: 100,
    owner: "Emily Davis",
    status: "Completed"
  },
  {
    startDatePlan: "21.07.2024",
    endDatePlan: "30.07.2024",
    progressBarPlan: 100,
    phasePlanDays: 9,
    progressBarReal: 100,
    phaseRealDays: 10,
    owner: "Olivia Martinez",
    status: "Completed"
  },
  {
    startDatePlan: "31.07.2024",
    endDatePlan: "09.08.2024",
    progressBarPlan: 75,
    phasePlanDays: 9,
    progressBarReal: 70,
    phaseRealDays: 10,
    owner: "John Doe",
    status: "On Going"
  },
  {
    startDatePlan: "10.08.2024",
    endDatePlan: "19.08.2024",
    progressBarPlan: 50,
    phasePlanDays: 9,
    progressBarReal: 45,
    owner: "Alice Johnson",
    status: "On Going"
  },
  {
    startDatePlan: "20.08.2024",
    endDatePlan: "29.08.2024",
    progressBarPlan: 60,
    phasePlanDays: 9,
    progressBarReal: 65,
    phaseRealDays: 8,
    owner: "James Wilson",
    status: "On Going"
  },
  {
    startDatePlan: "30.08.2024",
    endDatePlan: "08.09.2024",
    progressBarPlan: 40,
    phasePlanDays: 9,
    progressBarReal: 50,
    owner: "Sophia Anderson",
    status: "On Going"
  },
  {
    startDatePlan: "09.09.2024",
    endDatePlan: "18.09.2024",
    progressBarPlan: 30,
    phasePlanDays: 9,
    progressBarReal: 25,
    owner: "Liam Thomas",
    status: "Waiting"
  },
  {
    startDatePlan: "19.09.2024",
    endDatePlan: "28.09.2024",
    progressBarPlan: 0,
    phasePlanDays: 9,
    progressBarReal: 0,
    phaseRealDays: 0,
    owner: "Michael Brown",
    status: "Waiting"
  },
  {
    startDatePlan: "29.09.2024",
    endDatePlan: "08.10.2024",
    progressBarPlan: 20,
    phasePlanDays: 9,
    progressBarReal: 15,
    owner: "Isabella Moore",
    status: "Waiting"
  }
];

import React from "react";
import * as S from './styles';

function Row(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
   <S.Row {...props} />
  );
}

export default Row;

import { btoApi } from "../client"
import { AxiosResponse } from "axios";

export interface GetGeneralDataSupportRisksResponse {
  entidade: string;
  tipos: Array<{
    descricao: string;
    opcoes: string[];
  }>;
}

export function getGeneralDataSupportRisks(): Promise<AxiosResponse<GetGeneralDataSupportRisksResponse>> {
  return btoApi
    .get('/api/GeneralDataSupport/GetGeneralDataSupportRisks');
}
import React from "react";
import * as S from './styles';

function Col(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
   <S.Col {...props} />
  );
}

export default Col;

import React from 'react';
import * as S from './styles';

interface Props extends React.PropsWithChildren {
  style?: React.CSSProperties;
}

function PageContainer({ children, style }: Props) {
  return (
    <S.Container style={style}>
      {children}
    </S.Container>
  )
}

export default PageContainer;

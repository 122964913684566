import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as S from './styles';

export interface CalendarInputProps {
  date: string;
  onChange: (newDate: string) => void;
}

function Table(props: React.TableHTMLAttributes<HTMLTableElement>) {
  return <S.Table {...props}>{props.children}</S.Table>
}

function Thead(props: React.ComponentProps<typeof S.Thead>) {
  return <S.Thead {...props}>{props.children}</S.Thead>;
}

function Tbody(props: React.ComponentProps<typeof S.Tbody>) {
  return <S.Tbody {...props}>{props.children}</S.Tbody>;
}
interface TrProps extends React.ComponentProps<typeof S.Tr> {
  header?: boolean;
}

function Tr({ header, ...props }: TrProps) {
  return <S.Tr {...props}>{props.children}</S.Tr>;
}

function Td(props: React.ComponentProps<typeof S.Td>) {
  return <S.Td {...props}>{props.children}</S.Td>
}

function Input(props: React.ComponentProps<typeof S.Input>) {
  return <S.Input {...props}/>
}

function TextArea(props: React.ComponentProps<typeof S.TextArea>) {
  return <S.TextArea {...props}/>
}

interface ProgressBarProps extends React.ComponentProps<typeof S.InputProgressBar>{
  progress: number
}
function ProgressBar({progress, ...props}: ProgressBarProps) {
  return <S.ProgressDiv progress={progress} disabled={props.disabled}><S.InputProgressBar {...props} /></S.ProgressDiv>;
}

function SelectBox(props: React.ComponentProps<typeof S.SelectBox>){
  return <S.SelectBox {...props}>{props.children}</S.SelectBox>
}

interface OptionProps extends React.OptionHTMLAttributes<HTMLOptionElement> {
  value?: string;
}

function Option(props: OptionProps) {
  return <S.Option {...props}>{props.children}</S.Option>
}

function Calendar(props: React.ComponentProps<typeof S.Input>) {
  const { value } = props;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const formattedDate = selectedDate?.toLocaleDateString();
  const valueDate = selectedDate === null ? value : formattedDate

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      value={valueDate}
      dateFormat="dd/MM/yyyy"
      onClickOutside={() => setSelectedDate(null)}
      customInput={<S.Input {...props} />}
    />
  );
};

Table.Thead = Thead;
Table.Tbody = Tbody;
Table.Tr = Tr;
Table.Td = Td;
Table.Input = Input;
Table.TextArea = TextArea;
Table.ProgressBar = ProgressBar;
Table.SelectBox = SelectBox;
Table.Option = Option;
Table.CalendarInput = Calendar; 

export default Table;
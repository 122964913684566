import styled, { css } from 'styled-components';
import { FaCheck } from "react-icons/fa6";
import { CheckBoxProps } from '.';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
`;

export const CheckBorder = styled.div<{ checked?: boolean, size?: CheckBoxProps['size'] }>`
  
  border-radius: 50%;
  border-style: solid;
  border-width: ${p => p.theme.border.width.hairLine};
  border-color: ${p => p.theme.colors.neutral.white[800]};
  display: flex;
  justify-content: center;
  align-items: center;

  ${p => p.checked && css`
    border-color: ${p => p.theme.colors.neutral.white[900]};
  `}
  
  ${p => p.size === 'small'
    ? css`
      width: ${p.theme.font.size.sm};
      height: ${p.theme.font.size.sm};
    `
    : p.size === 'medium'
      ? css`
      width: ${p.theme.font.size.default};
      height: ${p.theme.font.size.default};
    `
      : p.size === 'large'
        ? css`
        width: ${p.theme.font.size.lg};
        height: ${p.theme.font.size.lg};
      `
        : css`
        width: ${p.theme.font.size.default};
        height: ${p.theme.font.size.default};
      `
  }
`;

export const CheckIco = styled(FaCheck).attrs(p => ({
  size: p.size === 'small'
  ? 8
  : p.size === 'medium'
    ? 12
    : p.size === 'large'
      ? 16
      : 12,
  color: p.theme.colors.neutral.white[900],
}))``;
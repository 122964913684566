import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 12px;

  &::-webkit-scrollbar { 
    height: 8px;
  }
`;

export const ScrollY = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

import { Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { IoGrid } from 'react-icons/io5';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProjectHeader } from 'shared/layouts';
import { Link } from "react-router-dom";
import { POST_API, getToken, getUser, space } from "services";
// import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { Breadcrumb, PageHeader } from 'shared/components';

const Initiative = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const theme = useTheme();

  const { MAIN, INITIATIVE } = useParams()

  const [blocks, setBlocks] = useState([]);
  // const [key, setKey] = useState('0');

  const onload = () => {
    POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: INITIATIVE }).then(rs => rs.json()).then(res => {
      if (res.return) {
        setBlocks(res.data)
      } else { Modal.warning({ title: t('Atenção'), content: res.msg }) }
    }).catch(() => null)
  }

  useEffect(() => {

    // switch (window.location.href.split('/')[7]) {
    //   case 'scope': setKey('2'); break;
    //   case 'operational': setKey('3'); break;
    //   case 'financial': setKey('4'); break;
    //   case 'resourses': setKey('5'); break;
    //   case 'rii': setKey('6'); break;
    //   default: setKey('1'); break;
    // }

    onload()

  }, [])

  const routes = [
    { title: <IoGrid size={20} style={{ margin: 0 }} /> },
    { title: <Link to="/dashboard/mng">{t("Home")}</Link> },
    { title: <Link to="/dashboard">{t("Portfolio Mng")}</Link> },
    { title: <Link to={`/dashboard/initiative/${MAIN}`}>{t("Program Mng")}</Link> },
    { title: t('Project Details') }
  ]

  const isHomepage = pathname.startsWith('/dashboard/initiative/') && pathname.endsWith(`${MAIN}/${INITIATIVE}`);
  const isRitualMng = pathname.startsWith('/dashboard/initiative/') && pathname.endsWith('/ritual-mng');


  return (
    <Row>
      <Breadcrumb separator=">" items={routes} />
      <Col span={24} className="route-page">
        <>
          <PageHeader
            title='Project Details'
            menu={[
              { label: t('Homepage'), selected: isHomepage, onClick: () => navigate(`/dashboard/initiative/${MAIN}/${INITIATIVE}`)},
              { label: t('Rituals mng'), selected: isRitualMng, onClick: () => navigate(`/dashboard/initiative/${MAIN}/${INITIATIVE}/ritual-mng`) }
            ]}
          />  
          <ProjectHeader
            style={{ marginTop: 12 }}
            projectName={blocks.initiativeName}
            projectId={blocks.initiativeId}
            startDate={blocks.startDate_format}
            goLiveDate={blocks.goLiveDate_format}
            actualStatus={blocks.actualStatusDescription}
            sponsor={blocks.sponsor}
            itBusinessPartner={blocks.itBusinessPatner}
            projectManager={blocks.projectManager}
            providerSolution={blocks.providers}
          />
          <div style={{ marginTop: space[0] }}>
            <Outlet />
          </div>
        </>
      </Col>
    </Row>
  )

}

export default Initiative;
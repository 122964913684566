import { GetRitualFinancialResponse, GetRitualOperationalDraftsResponse } from 'bto_now/api';
import { create } from 'zustand';

interface GetOperationalData {
  operationalData: GetRitualOperationalDraftsResponse;
  financialData: GetRitualFinancialResponse;
  getOperationalData: (data: GetRitualOperationalDraftsResponse) => void;
  getFinancialData: (data: GetRitualFinancialResponse) => void;
}

const initialOperationalData: GetRitualOperationalDraftsResponse = {
  timelineStatus: {
    timelineStatus: 0,
    spi: 0,
    currentPhase: "",
    daysWorked: 0,
    daysLeftToNextPhase: 0,
    daysLeftToGoLive: 0,
  },
  progressStatus: [],
  timelinePlanning: [],
  timelineRealized: [],
  curveS: [],
  phaseTimelineUpdate: [],
  projectTimelineUpdate: [],
};

const initialFinancialData: GetRitualFinancialResponse = {
  phaseFinancialUpdate: [
    {
      phaseExecutionId: 0,
      phase: "",
      startDate: "",
      endDate: "",
      forecast: 0,
      totalCapex: 0,
      totalOpex: 0,
      totalInvestments: 0,
      project: "",
    }
  ],
  projectFinancialUpdate: [
    {
      phaseExecutionId: 0,
      phase: "",
      startDate: "",
      endDate: "",
      forecast: 0,
      totalCapex: 0,
      totalOpex: 0,
      totalInvestments: 0,
      project: "",
    }
  ]
};

export const useFineshedTimeline = create<GetOperationalData>((set) => ({
  operationalData: initialOperationalData,
  financialData: initialFinancialData,
  getOperationalData: (data: GetRitualOperationalDraftsResponse) => {
    set({ operationalData: data });
  },
  getFinancialData: (data: GetRitualFinancialResponse) => {
    set({ financialData: data });
  }
}));
import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export type GetActualStatusResponse = Array<{
  actualStatus: number;
  actualStatusDescription: string;
}>

export function getActualStatus(): Promise<AxiosResponse<GetActualStatusResponse>> {
  return btoApi
    .get('/api/Initiative/GetActualStatus');
}
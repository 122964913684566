import styled, { css } from 'styled-components';

export const Container = styled.div<{ label?: string, inputFocus: boolean, inputHasValue: boolean; error?: boolean }>`
  border: 1px solid ${p => p.theme.colors.neutral.gray[200]};
  border-radius: ${p => p.theme.border.radius.sm};
  margin-top: 10px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  height: 40px;

  ${p => p.label?.length ? css`
    &::before {
      content: "${p.label}";
      display: block;
      color: ${p => p.theme.colors.text};
      position: absolute;
      top: 18px;
      left: 8px;
      cursor: text;
      font-size: 12px;
      font-weight: 450;
      transform: translateY(-50%);
      transition: 150ms ease-in-out;
      background: white;
      padding: 1px 3px;
      white-space: nowrap;
    }
  ` : ''}

  ${p => (p.inputFocus || p.inputHasValue) && css`
    &::before {
      top: -1px;
      left: 5px;
      font-size: 10px;
      background: white;
      padding: 1px 3px;
    } 
  `}

  ${p => p.error && css`
    border-color: ${p.theme.colors.utility.red[500]};  
  `}
`;

export const Textarea = styled.textarea`
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  padding: 10px;
  font-size: 12px;
  color: ${({ theme, readOnly }) => readOnly ? '#4d5c73' : theme.colors.inputText};
  font-weight: ${({ theme, readOnly }) => !readOnly && theme.font.weight.medium};

  &:focus {
    outline: none;
  }
`;

import React from 'react';
import { Modal } from 'antd';
import { Button, Typography } from 'shared/components';
import { IoCloseCircle } from "react-icons/io5";
import { useTheme } from 'styled-components';
import * as S from './styles';

interface Props {
  title: string;
  description: string;
  onOk(): void;
  // onCancel(): void;
  open?: boolean;
  /**
   * ** Auto close significa que o modal irá fechar depois de um tempo**
   * - autoClose={2000} Significa que o modal irá fechar depois de 2 segundos
   */
  autoClose?: number;
}

function ModalError({
  title,
  onOk,
  // onCancel,
  open,
  description,
  autoClose,
}: Props) {
  const theme = useTheme();

  return (
    <Modal
      // title={title}
      centered
      destroyOnClose
      open={open}
      onCancel={onOk}
      footer={
        <S.ButtonContainer>
          {/* {autoClose && <S.AutoCloseProgressBar duration={autoClose} />} */}
          <Button autoClick={autoClose} style={{ marginLeft: 12 }} onClick={onOk}>Ok</Button>
        </S.ButtonContainer>
      }
    >
      <S.ContentContainer>
        <Typography weight='bold' size='extraLarge' style={{ marginTop: 42, marginBottom: 60, textAlign: 'center', widows: '100%' }}>{title}</Typography>
        <IoCloseCircle size={120} color={theme.colors.utility.red['red']} />
        <Typography style={{ marginTop: 42, marginBottom: 60, textAlign: 'center', widows: '100%' }}>{description}</Typography>
      </S.ContentContainer>
    </Modal>
  )
}

export default ModalError;

export const currency = (value: number | undefined | null | string, options?: Intl.NumberFormatOptions): string => {
  if(typeof value !== 'string' && !value) return currency('0');

  return new Intl.NumberFormat('pt-BR', {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: 0,
    currencyDisplay: "symbol",
    ...options
  }).format(Number(value));
};

export const currencyToFloat = (value: string | undefined): number => {
  if(!value) return 0;
  return Number(value.replace(/[R\$\.\s]/g, '').replace(',', '.'))
}

export const currencyMask = (value: number | string | undefined) => {
  if(!value) return currency('0');
  
  if(typeof value === 'number') value = String(value.toFixed(2)).replace('.', ',');
  
  const cents = String(value).replace(/[R\$\.\s]/g, '').split(',')[1];

  if(cents?.length >= 3) {
    return currency(currencyToFloat(value) * 10);
  }
  else if(cents?.length <=1) {
    return currency(currencyToFloat(value) / 10);
  }
  else {
    return currency(currencyToFloat(value));
  }
}

import styled from 'styled-components';
import { Typography } from 'shared/components';

export const Container = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.neutral.white[0]};
  padding: ${p => p.theme.spacing.sm};
  border-radius: ${p => p.theme.border.radius.sm};
`;

export const Title = styled(Typography).attrs({
  size: 'medium',
  weight: 'bold',
})``;


export const LinkContainer = styled.div`
  width: fit-content;
  padding: 5px;
  background-color: ${p => p.theme.colors.primary.blue};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin: auto;
`;

export const ErrorMessage = styled.span`
  color: red;
  margin-left: 0.2rem;
  font-size: 12px;
`;
import styled, { css } from 'styled-components';

interface ContainerProps {
  label?: string;
  error?: boolean;
  disabled?: boolean;  
}

export const Input = styled.input`
  cursor: pointer;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 2;
  padding: 0px 10px;
  font-size: 12px;
  color: ${({ theme, readOnly }) => readOnly ? '#4d5c73' : theme.colors.inputText};
  font-weight: ${({ theme, readOnly }) => !readOnly && theme.font.weight.medium};
  ${p => p.disabled && css`
      cursor: not-allowed;
    `
  }
`;


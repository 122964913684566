import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 10vh;
    height: 100vh;
    background: white;
    border-radius: 8px;
    box-shadow: ${p => p.theme.colors.blackBoxShadow}
`;
import styled from "styled-components";

export const DetailTableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;


  width: 100%;
  padding: 16px 0px 6px 0px;

  text-transform: uppercase;

  > table {
    width: 100%;
    border-collapse: collapse;
  }

  > table th {
    text-align: center;
    font-size: ${p => p.theme.font.size.xs};
    padding: 4px 0px 6px 0px;
    font-weight: ${p => p.theme.font.weight.regular};


    border-bottom: 1px solid black;
  }

  > table td {
    text-align: center;
    font-size: ${p => p.theme.font.size.xs};
    padding: 8px 0px 8px 0px;
    font-weight: ${p => p.theme.font.weight.regular};
    height: 30px;
  }
`;

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: auto;
  background-color: ${(props) => props.color || "transparent"};
`;

type SemaphoreColor = 'gray' | 'success' | 'warning' | 'danger';
interface ProgressBarProps {
  progressBarSemaphore: string
  progress: number,
}

export const ProgressBar = styled.div<ProgressBarProps>`
  height:  16px;
  background-color: rgb(200, 200, 200);
  border-radius: ${p => p.theme.border.radius.xs};
  text-align: center;
  position: relative;
  overflow: hidden; 
  color: black;
  font-weight: ${p => p.theme.font.weight.regular};

  margin: 0px 4px;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${p => p.progress}%;
    background-color: ${({ progressBarSemaphore }) => {
    switch (progressBarSemaphore) {
      case 'gray':
        return 'gray';
      case 'success':
        return 'green';
      case 'warning':
        return 'yellow';
      case 'danger':
        return 'red';
      default:
        return 'gray';
    }
  }};
    border-radius: ${p => p.theme.border.radius.xs};
    /* transition: 500ms; */
  }

  > span {
    position: absolute;
    text-align: center;
    
    top: 1px;
    left: 0;
    right: 0;
    z-index: 1; 
  }
`;

export const ProgressText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
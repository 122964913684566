import { Col, Row } from "antd"
import { useEffect, useState } from "react";
import { IoGrid } from 'react-icons/io5';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Link } from "react-router-dom";
import { getUser, space } from "services";
import { PageHeader, ProjectHeader } from 'shared/layouts';
import { buildNavigateString, getUrlSearchParams, dateBrToISO } from "utils";
import { Breadcrumb, Button, ModalError, ModalSuccess, ModalWarning } from 'shared/components';
import { finalizeRitual } from 'bto_now/api';
import { useTranslation } from "react-i18next";
import { useFineshedTimeline } from 'shared/store-zustand/old-timeline/finishedTimeline';
import { isDateFormatValid } from 'utils/dates/isDateFormatValid';
import { updateDraftTimeline } from 'bto_now/api';
import { updateDraftFinancial, UpdateDraftFinancialPayload } from 'bto_now/api';

const Initiative = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { programmeId, projectId, ritualNumber } = useParams();
  const { operationalData, financialData } = useFineshedTimeline()
  const statusRitual = getUrlSearchParams('statusRitual');

  const isNotDraft = statusRitual === 'Active' || statusRitual === 'History';

  const [ritualName, setRitualName] = useState('');

  const [confirmModal, setConfirmModal] = useState(false);
  const [finalizingLoading, setFinalizingLoading] = useState(false);
  const [successModal, setSuccessModal] = useState('');
  const [errorModal, setErrorModal] = useState('');

  useEffect(() => {
    setRitualName(getUrlSearchParams('ritualName'));
  }, []);

  const routes = [
    { title: <IoGrid size={20} style={{ margin: 0 }} /> },
    { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
    { title: <Link to="/dashboard">{t('Portfolio Mng')}</Link> },
    { title: <Link to={`/dashboard/initiative/${programmeId}`}>{t("Program Mng")}</Link> },
    { title: <Link to={`/dashboard/initiative/${programmeId}/${projectId}`}>{t("Project Details")}</Link> },
    { title: t('Ritual Management') }
  ]

  function mountRitualsUrls(page) {
    const baseUrl = `/dashboard/initiative/${programmeId}/${projectId}/ritual/${ritualNumber}`;
    const statusRitual = getUrlSearchParams('statusRitual');
    const url = buildNavigateString({ path: `${baseUrl}${page}`, params: { ritualName, statusRitual } });
    return url;
  }

  const saveOperational = async () => {
    if (operationalData.phaseTimelineUpdate) {
      const payload = operationalData.phaseTimelineUpdate
        .map(v => ({
          user: getUser(),
          projectId: projectId,
          phaseExecutionId: v.phaseExecutionId,
          startDate: isDateFormatValid(v.startDateReal) ? dateBrToISO(v.startDateReal) : v.startDateReal,
          endDate: isDateFormatValid(v.endDateReal) ? dateBrToISO(v.endDateReal) : v.endDateReal,
          progressPhaseReal: v.percentProgressPhaseReal,
          dateType: 'R',
        }))

      const params = {
        user: getUser(),
        projectId: projectId,
        ritualNumber: Number(ritualNumber)
      }

      await updateDraftTimeline(payload, params)
        .then(({ data }) => {
          if (data) {
            console.log(data)
          }         })
        .catch((error) => {
          console.log(error)
        })
    }
  };

  function preparePhasesData() {
    return financialData?.phaseFinancialUpdate.map(phase => ({
      phaseExecutionId: phase.phaseExecutionId,
      totalCapex: phase.totalCapex,
      totalOpex: phase.totalOpex,
      totalInvestments: phase.totalInvestments,
      forecast: phase.forecast,
    }));
  }

  const saveFinancial = async () => {  
    const phasesData = preparePhasesData();
  
    const payload = phasesData?.map(phase => ({
      user: getUser() || '',
      projectId: projectId,
      financialType: 'R',
      phaseExecutionId: phase.phaseExecutionId,
      totalCapex: phase.totalCapex,
      totalOpex: phase.totalOpex,
    })) ?? [];
  
    await updateDraftFinancial(payload)
      .then(({ data }) => {
        if (data.sucesso) setSuccessModal(true);
        else setErrorModal(data.mensagemErro);
      })
      .catch(() => { })
  }

  const handleFinish = async () => {
    setFinalizingLoading(true);
    await saveOperational();
    await saveFinancial();

    await finalizeRitual({
      projectId: projectId,
      ritualNumber,
      user: getUser(),
    }).then(({data}) => {
      if(data.sucesso) setSuccessModal('Ritual saved with success');
      else setErrorModal(data.mensagemErro);
    })
    .finally(() => {
      setConfirmModal(false);
      setFinalizingLoading(false);
    });
  }

  function okSuccessModalOk() {
    setSuccessModal('');
    const baseUrl = `/dashboard/initiative/${programmeId}/${projectId}/ritual-mng`;
    const url = buildNavigateString({ path: baseUrl });
    navigate(url);
  }

  return (
    <Row>
      <Breadcrumb separator=">" items={routes} />
      <Col span={24} className="route-page">
        <PageHeader
          title={`Ritual Management - ${ritualName}`}
          navList={[
            { label: 'Operational', value: mountRitualsUrls('/operational') },
            { label: 'Financial', value: mountRitualsUrls('/financial') },
            { label: 'Risks', value: mountRitualsUrls('/risks') },
            { label: 'Issues', value: mountRitualsUrls('/issues') },
            // { label: 'tasks', value: mountRitualsUrls('/taskmng') },
          ]}
          topRight={
            <Button disabled={isNotDraft} onClick={() => setConfirmModal(true)}>Finish</Button>
          }
        />
        <ProjectHeader
          projectId={projectId}
          style={{ marginTop: '12px' }}
        />
        <div style={{ marginTop: space[0] }}>
          <Outlet />
        </div>
      </Col>
      <ModalWarning
        title="Are you sure you want to finish?"
        description="If all tabs have not been filled in, the ritual will remain as a draft"
        onOk={handleFinish}
        onCancel={() => setConfirmModal(false)}
        open={confirmModal}
        loading={finalizingLoading}
      />
      <ModalSuccess
        title="Success!"
        description={successModal}
        onOk={okSuccessModalOk}
        open={successModal?.length > 0}
      />
      <ModalError
        title="Error!"
        description={errorModal}
        onOk={() => setErrorModal('')}
        open={errorModal?.length > 0}
      />
    </Row>
  );
}

export default Initiative;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface CreateScopeManagementRequest {
  projectId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  owner: string;
  user: string;
  description: string;
}

export function createScopeManagement (params: CreateScopeManagementRequest): Promise<AxiosResponse<CreateScopeManagementRequest>> {
  return btoApi.post('/api/ScopeManagement/Create', params);
}
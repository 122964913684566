import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface DeleteTaskManagementRequest {
  projectId: number;
  taskId: number;
  user: string | null;
}

export function deleteTaskManagement (params: DeleteTaskManagementRequest): Promise<AxiosResponse<DeleteTaskManagementRequest>> {
  return btoApi.post('/api/TaskManagement/Delete', params);
}
import { MdAttachMoney, MdAccountTree, MdPercent, MdTimelapse, MdList, MdWarning, MdMessage, MdBackupTable, MdStar  } from "react-icons/md";

import './styles.css';
import { LuTarget } from "react-icons/lu";
import { RiTeamFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";


const KPIComponent = ( { title, value, type, size = 'sm', children = null } ) => {
    const { t } = useTranslation();

    return (
        <div className={`kpi-card ${size}`}>
            <p className='kpi-title'>{t(title)}</p>
            <div className="kpi-row">
                <p className='kpi-value'>{value}</p>
                { type === 'money' ?
                    <MdAttachMoney className="kpi-icon" />
                : type === 'project' ?
                    <MdAccountTree className="kpi-icon" />
                : type === 'percent' ?
                    <MdPercent className="kpi-icon" />
                : type === 'time' ?
                    <MdTimelapse className="kpi-icon" />
                : type === 'target' ?
                    <LuTarget className="kpi-icon" />
                : type === 'list' ?
                    <MdList className="kpi-icon" />
                : type === 'warn' ?
                    <MdWarning className="kpi-icon" />
                : type === 'chat' ?
                    <MdMessage className="kpi-icon" />
                : type === 'disk' ?
                    <MdBackupTable className="kpi-icon" />
                : type === 'group' ?
                    <RiTeamFill className="kpi-icon" />
                : type === 'star' ?
                    <MdStar className="kpi-icon" />
                : null }
            </div>
            { children !== null ? (
                <div className="kpi-divider">{ children }</div>
            ) : null }
        </div>
    )

}

export default KPIComponent;
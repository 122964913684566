import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Select, SpinLoading, Table, StatusText, ModalSuccess, CheckBox } from "shared/components";
import { Input, ButtonCalendarInput } from 'shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import * as S from './styles';

interface ScopeData {
  id: number;
  scopeDescript: string;
  scopeList: string;
  dateBegin: string;
  dateEnd: string;
  scopeOwner: string;
  scopeStatus: string;
}

const TimelineMng = () => {
  const { t } = useTranslation();

  const getNextId = () => {
    const storedScopes = JSON.parse(localStorage.getItem('scopes') || '[]');
    const maxId = storedScopes.reduce((max: number, scope: ScopeData) => Math.max(scope.id, max), 0);
    return maxId + 1;
  };

  const initialScopeData: ScopeData = {
    id: getNextId(),
    scopeDescript: '',
    scopeList: '',
    dateBegin: '',
    dateEnd: '',
    scopeOwner: '',
    scopeStatus: ''
  };

  const [dataScope, setDataScope] = useState<ScopeData>(initialScopeData);
  const [scopeListStorage, setScopeListStorage] = useState<ScopeData[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [errors, setErrors] = useState({
    scopeDescript: false,
    scopeList: false,
    dateBegin: false,
    dateEnd: false,
    scopeOwner: false,
    scopeStatus: false,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  const [selectedScopes, setSelectedScopes] = useState<Set<number>>(new Set());

  const [resetSelect, setResetSelect] = useState(false);

  const EscopeType = [
    { label: 'First Type' },
    { label: 'Second Type' },
    { label: 'Third Type' },
    { label: 'Four Type' }
  ];

  const EscopeStatus = [
    { label: 'Active' },
    { label: 'Inactive' },
  ];

  useEffect(() => {
    const storedScopes = JSON.parse(localStorage.getItem('scopes') || '[]');
    setScopeListStorage(storedScopes);
  }, [updateTrigger]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (name: keyof ScopeData, value: string) => {
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSubmit = () => {
    const newErrors = {
      scopeDescript: !dataScope.scopeDescript,
      scopeList: !dataScope.scopeList,
      dateBegin: !dataScope.dateBegin,
      dateEnd: !dataScope.dateEnd,
      scopeOwner: !dataScope.scopeOwner,
      scopeStatus: !dataScope.scopeStatus,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      const newItem: ScopeData = { ...dataScope };

      handleSaveStorage(newItem)
      setDataScope(prevState => ({
        ...prevState,
        id: prevState.id + 1,
        scopeDescript: '',
        scopeList: '',
        dateBegin: '',
        dateEnd: '',
        scopeOwner: '',
        scopeStatus: ''
      }));

      setResetSelect(true);
    }
  };

  const handleSaveStorage = (scope: ScopeData) => {
    const storedScopes = JSON.parse(localStorage.getItem('scopes') || '[]');
    const newScopes = [...storedScopes, scope];
    localStorage.setItem('scopes', JSON.stringify(newScopes));
    setUpdateTrigger(prev => !prev);
  };

  const handleCheck = (id: number) => {
    setSelectedScopes(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleDelete = () => {
    const storedScopes = JSON.parse(localStorage.getItem('scopes') || '[]');
    const remainingScopes = storedScopes.filter((scope: ScopeData) => !selectedScopes.has(scope.id));

    localStorage.setItem('scopes', JSON.stringify(remainingScopes));

    setUpdateTrigger(prev => !prev);
    setSelectedScopes(new Set());
    setModalDescription(`Delete ${selectedScopes.size} scopes on the table with successfully.`);
    setModalOpen(true);
  };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const isChecked = (id: number) => selectedScopes.has(id);
  const isDisabledButtonDelete = selectedScopes.size === 0;

  return (
    <>
      <S.ContainerForms>
        <S.FormRow>
          <div style={{ display: 'flex', gap: '0.75rem', flex: 1 }}>
            <S.InputContainer>
              <Input
                label={t('Scope Descript') + ' *'}
                name='scopeDescript'
                value={dataScope.scopeDescript}
                onChange={handleInputChange}
                error={errors.scopeDescript}
              />
              {errors.scopeDescript && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={EscopeType.map(op => ({ label: op.label, value: op.label }))}
                label={t('Escope List') + ' *'}
                name='scopeList'
                value={dataScope.scopeList}
                onChange={(e) => handleSelectChange('scopeList', e.target.value)}
                error={errors.scopeList}
                reset={resetSelect}
              />
              {errors.scopeList && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Escope Owner') + ' *'}
                name='scopeOwner'
                value={dataScope.scopeOwner}
                onChange={handleInputChange}
                error={errors.scopeOwner}
              />
              {errors.scopeOwner && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <ButtonCalendarInput
                placeholder={t('Date Begin') + ' *'}
                name='dateBegin'
                value={dataScope.dateBegin}
                onChange={handleInputChange}
                error={errors.dateBegin}
              />
              {errors.dateBegin && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <ButtonCalendarInput
                placeholder={t('Date End') + ' *'}
                name='dateEnd'
                value={dataScope.dateEnd}
                onChange={handleInputChange}
                error={errors.dateEnd}
              />
              {errors.dateEnd && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={EscopeStatus.map(op => ({ label: op.label, value: op.label }))}
                label={t('Scope Status') + ' *'}
                name='scopeStatus'
                value={dataScope.scopeStatus || ''}
                onChange={(e) => handleSelectChange('scopeStatus', e.target.value)}
                error={errors.scopeStatus}
                reset={resetSelect}
              />
              {errors.scopeStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
            </S.InputContainer>
          </div>
          <S.ButtonContainerRow>
            <Button
              size="small"
              onClick={handleSubmit}
              style={{ marginRight: '8px' }}
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
            <Button
              size="small"
              onClick={handleDelete}
              disabled={isDisabledButtonDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </S.ButtonContainerRow>
        </S.FormRow>
      </S.ContainerForms>

      <S.ContainerList>
        {
          !scopeListStorage ? (
            <SpinLoading />
          ) : (
            <Table style={{ marginTop: 16 }}>
              <Table.Tr header>
                <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Id</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Scope Description</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Scope List</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Scope Owner</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Date Begin</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Date End</Table.Td>
                <Table.Td style={{ padding: '4px 12px' }}>Scope Status</Table.Td>
              </Table.Tr>

              {
                scopeListStorage && scopeListStorage.map((scope) => (
                  <Table.Tr key={scope.id} style={{ borderBottom: '1px solid #ccc' }}>
                    <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(scope.id)} checked={isChecked(scope.id)} size="medium" /></Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.id}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.scopeDescript}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.scopeList}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.scopeOwner}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.dateBegin}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{scope.dateEnd}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>
                      <StatusText size='small' style={{ width: 100 }} color={scope.scopeStatus === 'Active' ? 'green' : 'red'}>{scope.scopeStatus}
                      </StatusText>
                    </Table.Td>
                  </Table.Tr>
                ))
              }
            </Table>
          )
        }
      </S.ContainerList>

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description={modalDescription}
        title="Success"
      />
    </>
  );
};

export default TimelineMng;

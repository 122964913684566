import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Button, ModalError, ModalSuccess, Row, SpinLoading, Table, Input } from 'shared/components';

import { useNavigate, useParams } from 'react-router-dom';
import { IoIosLink } from "react-icons/io";
import { Modal } from 'antd';

import { createRitual, getAllRituals, GetAllRitualsResponse } from 'bto_now/api';
import { getUser } from 'services';
import { date } from 'utils';
import { buildNavigateString } from 'utils';
import { useTranslation } from 'react-i18next';
import { IoPencil } from 'react-icons/io5';

import * as S from './styles';

function RitualsMng() {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const { MAIN, INITIATIVE } = useParams();

  const [data, setData] = useState<GetAllRitualsResponse>();
  const [showModal, setShowModal] = useState(false);
  const [ritualName, setRitualName] = useState('');
  const [error, setError] = useState(false);
  const [createRitualNameLoading, setCreateRitualNameLoading] = useState(false);
  const [errorModal, setErrorModal] = useState('');
  const [successModal, setSuccessModal] = useState('');

  const fetch = useCallback(() => {
    getAllRituals({ User: getUser(), ProjectId: INITIATIVE as string })
      .then(({ data }) => setData(data));

  }, []);

  useEffect(fetch, []);

  function newRitual() {
    setCreateRitualNameLoading(true);

    if (ritualName.length === 0) {
      setError(true);
      setCreateRitualNameLoading(false);
      return;
    }

    createRitual({
      user: getUser() || '',
      projectId: INITIATIVE as string,
      name: ritualName,
    })
      .then(({ data }) => {
        if (data.sucesso) {
          // setSuccessModal('Ritual created with success');
          // setTimeout(fetch, 10000);

          const navString = buildNavigateString({
            path: `/dashboard/initiative/${MAIN}/${INITIATIVE}/ritual/${data.ritualNumber}/operational`,
            params: { ritualName, statusRitual: 'Draft' }
          });

          navigate(navString);
        } else setErrorModal(data.mensagemErro);
      })
      .catch(() => {
        setErrorModal('Failed to create');
      })
      .finally(() => {
        setCreateRitualNameLoading(false);
        setShowModal(false);
      });
  }

  function navToEditRitual(ritualName: string, ritualNumber: number, statusRitual: string) {
    const url = buildNavigateString({
      path: `/dashboard/initiative/${MAIN}/${INITIATIVE}/ritual/${ritualNumber}/operational`,
      params: { ritualName, statusRitual }
    });

    navigate(url);
  }

  function handleRitualName(e: React.ChangeEvent<HTMLInputElement>) {
    setError(false);
    setRitualName(e.target.value.trim());
  }

  function handleCancel() {
    setShowModal(false);
    setError(false)
    setRitualName('');
  }

  return !Array.isArray(data) ? (
    <SpinLoading />
  ) : (
    <S.Container>
      <Row style={{ width: '100%', justifyContent: 'space-between' }}>
        <S.Title>{t("Rituals Mng")}</S.Title>
        <Button size='small' onClick={() => setShowModal(true)}>{t("New Ritual Mng")}</Button>
      </Row>

      <Row style={{ marginTop: theme.spacing.sm, alignItems: 'center' }}>
        <Input containerStyle={{ marginRight: theme.spacing.sm, width: 250 }} label={t("Ritual Name")} />
        <Input containerStyle={{ marginRight: theme.spacing.sm, width: 250 }} label={t("Created By")} />
        <Input containerStyle={{ marginRight: theme.spacing.sm, width: 250 }} label={t("Created In")} />
        <Button size='small'>{t("Search")}</Button>
      </Row>

      <Table style={{ marginTop: theme.spacing.lg, width: '100%', tableLayout: 'fixed' }}>
        <Table.Tr header>
          <Table.Td style={{ width: '5%' }}></Table.Td>
          <Table.Td>{t("Ritual Name")}</Table.Td>
          <Table.Td>{t("Operational Changes")}</Table.Td>
          <Table.Td>{t("Financial Changes")}</Table.Td>
          <Table.Td>{t("Risk Changes")}</Table.Td>
          <Table.Td style={{ width: '17%' }}>{t("Issues Changes")}</Table.Td>
          <Table.Td>{t("Created By")}</Table.Td>
          <Table.Td>{t("Created In")}</Table.Td>
          <Table.Td>{t("Status")}</Table.Td>
        </Table.Tr>
        {
          data.map(d => (
            <Table.Tr >
              <Table.Td>
                <S.LinkContainer onClick={() => navToEditRitual(d.ritualName, d.ritualNumber, d.statusRitual)}>
                  {d.statusRitual === 'Draft' ? (
                    <IoPencil size={18} color='#fff' />
                  ) : (
                    <IoIosLink size={18} color='#fff' />
                  )}
                </S.LinkContainer>
              </Table.Td>

              <Table.Td>{d.ritualName}</Table.Td>
              <Table.Td>{d.operacionalChanges}</Table.Td>
              <Table.Td>{d.financialChanges}</Table.Td>
              <Table.Td>{d.riskChanges}</Table.Td>
              <Table.Td>{d.issueChanges}</Table.Td>
              <Table.Td>{d.createBy}</Table.Td>
              <Table.Td>{date(d.createDate)}</Table.Td>
              <Table.Td>{d.statusRitual}</Table.Td>
            </Table.Tr>
          ))
        }
      </Table>

      <Modal
        centered
        destroyOnClose
        title={t("New Ritual Mng")}
        cancelText='Cancel'
        okText='Continue'
        onCancel={handleCancel}
        onClose={handleCancel}
        open={showModal}
        footer={
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button type='secondary' onClick={handleCancel}>Cancelar</Button>
            <Button style={{ marginLeft: 12 }} onClick={newRitual} loading={createRitualNameLoading}>Aplicar</Button>
          </div>
        }
      >
        <Input 
          autoFocus 
          style={{ padding: '24px 0' }} 
          label={t("Please write a name for this ritual")}
          value={ritualName} 
          onChange={handleRitualName} 
          error={error} 
        />
        {error && <S.ErrorMessage>Ritual name is required</S.ErrorMessage>}
      </Modal>
      <ModalError title='Error!' description={errorModal} onOk={() => setErrorModal('')} open={String(errorModal).length > 0} />
      <ModalSuccess title='Success!' description={errorModal} onOk={() => setSuccessModal('')} open={String(successModal).length > 0} />
    </S.Container >
  )
}

export default RitualsMng;

import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface UpdateRiskPayload {
  riskId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  statusMitigation:     string;
  probabilityScale:     string;
  impactScale:          string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}

export interface UpdateRiskResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function updateRisk(params: UpdateRiskPayload): Promise<AxiosResponse<UpdateRiskResponse>> {
  return btoApi
    .post('/api/ProjectRisk/UpdateRisk', params);
};
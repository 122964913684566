import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface DeleteIssuePayload {
  issueId: number;
  companyId: number;
  projectId: string;
  name: string;
  description: string;
  type: string;
  owner: string;
  status: string;
  urgency: string;
  impact: string;
  historicalMonitoring: string;
  lastUpdate: string;
  user: string;
}

export interface DeleteIssueResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function deleteIssue(body: DeleteIssuePayload): Promise<AxiosResponse<DeleteIssueResponse>> {
  return btoApi
   .post('/api/ProjectIssue/DeleteIssue', body);
}
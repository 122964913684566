import styled, { css } from 'styled-components';

import { Props } from './index';

export const Typography = styled.p<{ size?: Props['size'], weight?: Props['weight'] }>`
  ${p =>  p.size === 'extraSmall' ? css`
          font-size: ${p => p.theme.font.size.xs};
        ` : p.size === 'small' ? css`
          font-size: ${p => p.theme.font.size.sm};
        ` : p.size === 'medium' ? css`
          font-size: ${p => p.theme.font.size.md};
        ` : p.size === 'large' ? css`
          font-size: ${p => p.theme.font.size.lg};
        ` : p.size === 'extraLarge' ? css`
          font-size: ${p => p.theme.font.size.xl};
        ` : p.size === 'extraExtraLarge' ? css`
          font-size: ${p => p.theme.font.size.xxl};
        ` : css`
          font-size: ${p => p.theme.font.size.default};
        `
  };
  margin: 0px;
  font-weight: ${p => p.theme.font.weight[p.weight || 'regular']};
  color: ${p => p.theme.colors.text};
  display: flex;
  align-items: center;

`;

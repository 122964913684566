import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetProgrammeListPayload {
  User: string;
}

export type GetProgrammeListResponse = Array<{
  programmeId:         number;
  programme:           string;
  businessArea:        null;
  businessDriver:      null;
  businessBenefits:    null;
  techonologyBenefits: null;
  sponsor:             null;
  itPartnerLeader:     null;
  resourceEnvolved:    null;
  providerEnvolved:    null;
}>

export function getProgrammeList(params: GetProgrammeListPayload): Promise<AxiosResponse<GetProgrammeListResponse>> {
  if (!params ||!params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });

  return btoApi
   .get('/GetProgrammeList', { params });
}
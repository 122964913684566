import { Breadcrumb, ComponentContainer } from "shared/components";
import { IoGrid } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PageHeader from "shared/components/PageHeader";
import { PageContainer } from "shared/components";
import NewProjectHeader from "shared/layouts/NewProjectHeader";
import { useStatusModalStore } from "shared/store-zustand/create-project/getStatusModal";
import { useEffect } from "react";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

const PMO_NewCreateProject = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { statusModal, setStatusModal } = useStatusModalStore();
  const { dataProject, clearDataProject } = useCreateProject();

  const navigate = useNavigate();

  const menus = {
    general: {
      label: t('General'),
      url: `/dashboard/mng/pmo/new-create-project/general`,
    },
    operacional: {
      label: t('Timeline'),
      url: `/dashboard/mng/pmo/new-create-project/operational`,
    },
    scope: {
      label: t('Scope'),
      url: `/dashboard/mng/pmo/new-create-project/scope`,
    },
    financial: {
      label: t('Financial'),
      url: `/dashboard/mng/pmo/new-create-project/financial`,
    },
  }

  useEffect(() => {
    return () => {
      setStatusModal(true);
      clearDataProject();
    };
  }, []);

  const showComponentContainer = dataProject.name.length === 0 ? true : false;

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: t('New Create Project Management') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t("Create Project")}
          menu={[
            { label: menus.general.label, selected: pathname === menus.general.url, onClick: () => navigate(menus.general.url) },
            { label: menus.operacional.label, selected: pathname === menus.operacional.url, onClick: () => navigate(menus.operacional.url) },
            { label: menus.scope.label, selected: pathname === menus.scope.url, onClick: () => navigate(menus.scope.url) },
            { label: menus.financial.label, selected: pathname === menus.financial.url, onClick: () => navigate(menus.financial.url) },
          ]}
        />

     
        {!showComponentContainer && (
          <NewProjectHeader 
            style={{ marginBottom: '1rem'}} 
          />
        )}

        <ComponentContainer noShowComponent={showComponentContainer}>
          <Outlet />
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default PMO_NewCreateProject;
export function dateMask(value: string): string {
  if (!value) return '';

  // Extraindo apenas a parte da data da string
  const datePart = value.split('T')[0]; 

  // Verificando se a data está no formato ISO (YYYY-MM-DD)
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (isoDateRegex.test(datePart)) {
    return datePart;
  }

  const v = value.replace(/[^0-9]/g, '');
  switch (v.length) {
    case 1:
    case 2: { return `${v}`; }
    case 3:
    case 4: { return `${v.slice(0, 2)}/${v.slice(2, 4)}`; }
    case 5:
    case 6:
    case 7:
    default: { return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4, 8)}`; }
  }
}

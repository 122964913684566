import styled, { css } from 'styled-components';

import { Props } from './index';

export const Status = styled.p<{ size?: Props['size'], weight?: Props['weight'], color?: Props['color'] }>`
  margin: 0px;
  ${p => p.size === 'extraSmall' ? css`
          font-size: ${p => p.theme.font.size.xs};
        ` : p.size === 'small' ? css`
          font-size: ${p => p.theme.font.size.sm};
        ` : p.size === 'medium' ? css`
          font-size: ${p => p.theme.font.size.md};
        ` : p.size === 'large' ? css`
          font-size: ${p => p.theme.font.size.lg};
        ` : p.size === 'extraLarge' ? css`
          font-size: ${p => p.theme.font.size.xl};
        ` : p.size === 'extraExtraLarge' ? css`
          font-size: ${p => p.theme.font.size.xxl};
        ` : css`
          font-size: ${p => p.theme.font.size.default};
        `
  };
  font-weight: ${p => p.theme.font.weight[p.weight || 'regular']};

  ${
    p => 
      p.color === 'green'
      ? css`
        border-color: ${p => p.theme.colors.utility.darkGreen[700]};
        background-color: ${p => p.theme.colors.utility.darkGreen[100]};
        color: ${p => p.theme.colors.utility.darkGreen[700]};
      `
      : p.color === 'yellow'
      ? css`
        border-color: ${p => p.theme.colors.utility.yellow[700]};
        background-color: ${p => p.theme.colors.utility.yellow[100]};
        color: ${p => p.theme.colors.utility.yellow[700]};
      `
      : p.color === 'red'
      ? css`
        border-color: ${p => p.theme.colors.utility.red[700]};
        background-color: ${p => p.theme.colors.utility.red[100]};
        color: ${p => p.theme.colors.utility.red[700]};
      `
      : p.color === 'blue'
      ? css`
        border-color: #0E7CC3;
        background-color: #A5CFEA;
        color: #0E7CC3;
      `

      : p.color === 'white'
      ? css`
        border-color: black;
        background-color: white;
        color: black;
      `

      : p.color === 'orange'
      ? css`
        border-color: #F57C00;
        background-color: #FFCC80;
        color: #F57C00;
      `

      : p.color === 'black'
      ? css`
        border-color: black;
        background-color: #595959;
        color: black;
      `
      : null
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-style: solid;
  border-width: ${p => p.theme.border.width.hairLine};
  border-radius: 16px;
  padding: ${p => p.theme.spacing.nano} ${p => p.theme.spacing.sm};
`;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetBusinessDriverPayload {
  User?: string | null;
  BusinessDriverId?: string | null;
}

export interface GetBusinessDriverResponse {
  totalPrograms: number;
  totalProjects: number;
  toStart: number;
  inProgress: number;
  finished: number;
  statusTime: number;
  statusCost: number;
  statusScope: number;
  statusRisk: number;
  statusIssues: number;
  consumed: number;
  totalBudgetPlan: number;
  totalForecast: number;
  totalBudgetReal: number;
  percentConsumed: number;
  initiatives: Initiative[];
  businessDriverOperational: BusinessDriverOperational[];
  businessDriverFinancial: BusinessDriverFinancial[];
}

interface BusinessDriverFinancial {
  businessDriver: string;
  budgetPlan: number;
  budgetReal: number;
}

interface BusinessDriverOperational {
  businessDriver: string;
  toStart: number;
  inProgress: number;
  finished: number;
}

interface Initiative {
  companyId: number;
  projectGroup: string;
  initiativeType: string;
  mainInitiative: string;
  initiativeName: string;
  summary: string;
  initiativeId: string;
  sponsor: string;
  businessLeader: string;
  itPartnerLeader: string;
  itBusinessPatner: string;
  keyUser: string;
  businessArea: string;
  businessDriver: string;
  businessBenefits: string;
  techonologyBenefits: string;
  plataformEnvolved: string;
  businessProcessEnvolved: string;
  programme: string;
  programmeId: number;
  businessDriverId: number;
  projectId: string;
  name: string;
  type: string;
  startDate: string;
  startPhaseBuild: null;
  percentCompleteBuild: number;
  startPhaseUat: null;
  percentCompleteUat: number;
  startPhaseCutover: null;
  percentCompleteCutover: number;
  goLiveDate: string;
  endDate: string;
  actualPhase: string;
  actualPhaseDescription: string;
  progressPlaned: number;
  progressRealized: number;
  issuesQuantity: number;
  statusTime: number;
  statusCoast: number;
  statusRisk: number;
  statusIssue: number;
  statusScope: number;
  providers: string;
  totalRequested: number;
  totalInvestiment: number;
  totalInvestimentCapex: number;
  totalInvestimentOpex: number;
  commitedBudget: number;
  percentCommitedPlanned: number;
  totalRunningCoast: number;
  lastRitualDate: string;
  cpi: null;
  spi: null;
  extraBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  financialProgress: number;
  operacionalProgress: number;
  operationalProgress: number;
  toWin: boolean;
  otherDetailsInfo: OtherDetailsInfo;
}

interface OtherDetailsInfo {
  detFinancial: null;
  risks: null;
  issues: null;
  impacts: null;
  curveS: null;
}

export function getBusinessDriver(params: GetBusinessDriverPayload): Promise<AxiosResponse<GetBusinessDriverResponse>> {
  if (!params || !params.BusinessDriverId || !params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });

  return btoApi
    .get('/api/BusinessDriver/GetBusinessDriver', {
      params
    });
}

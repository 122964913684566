import { Row, SpinLoading, Typography, Table, Input, ButtonCalendarInput } from 'shared/components';

import { useTheme } from 'styled-components';
import React from 'react';
import { GetProjectResponse } from 'bto_now/api';
import { useOutletContext } from 'react-router-dom';
import { dateMask } from 'utils';
import { useTranslation } from 'react-i18next';

const OperationalProject = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    project,
    setProject
  } = useOutletContext<{
    project: GetProjectResponse,
    setProject: React.Dispatch<React.SetStateAction<GetProjectResponse>>
  }>();

  function handleChangeDate(value: string, index: number, field: string) {
    if (project?.timeline) {
      const newDate = [...project?.timeline];
      newDate[index][field] = dateMask(value);
      setProject({ ...project, timeline: newDate });
    }
  }

  return (
    <>
      <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
        <Typography weight='bold' size='medium'>Timeline Planning</Typography>
      </Row>

      {!project ? (
        <SpinLoading />
      ) : (
        <Table style={{ width: '100%', marginTop: theme.spacing.sm, }}>
          <Table.Tr header>
            <Table.Td>Phase</Table.Td>
            <Table.Td>Start Date Plan</Table.Td>
            <Table.Td>End Date Plan</Table.Td>
            <Table.Td>Phase Responsable</Table.Td>
            <Table.Td>Previous Risks</Table.Td>
            <Table.Td>Project Dependences</Table.Td>
          </Table.Tr>
          {project?.timeline.map((m, index) => (
            <Table.Tr key={index}>
              <Table.Td>{m.phaseName}</Table.Td>
              <Table.Td >
                <ButtonCalendarInput
                  placeholder={t('Start') + ' *'}
                  name='Start'
                  value={m.startDate}
                  maxDate={m.endDate}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleChangeDate(ev.target.value, index, 'startDate')}                  
                />
              </Table.Td>
              <Table.Td >
                <ButtonCalendarInput
                  placeholder={t('End') + ' *'}
                  name='End'
                  value={m.endDate}
                  minDate={m.startDate}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => handleChangeDate(ev.target.value, index, 'endDate')}                  
                />
              </Table.Td>
              <Table.Td><Input disabled placeholder={t('Phase Responsable')} >{m.responsible}</Input></Table.Td>
              <Table.Td><Input disabled placeholder={t('Previous Risks')} >{m.risks}</Input></Table.Td>
              <Table.Td><Input disabled placeholder={t('Project Dependences')} >{m.dependencies}</Input></Table.Td>
            </Table.Tr>
          ))}
        </Table>
      )}
    </>
  )
}

export default OperationalProject;

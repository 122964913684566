import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface DeleteRiskPayload {
  riskId: number;
  companyId: number;
  projectId: string;
  name: string;
  description: string;
  type: string;
  owner: string;
  statusMitigation: string;
  probabilityScale: string;
  impactScale: string;
  historicalMonitoring: string;
  lastUpdate: string;
  user: string;
}

export interface DeleteRiskResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function deleteRisk(body: DeleteRiskPayload): Promise<AxiosResponse<DeleteRiskResponse>> {
  return btoApi
   .post('/api/ProjectRisk/DeleteRisk', body);
}
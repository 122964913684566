import styled from 'styled-components';

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 200px;
`;

export const ErrorMessage = styled.span`
  color: red;
  margin-left: 0.2rem;
  font-size: 12px;
`;

export const ButtonContainerRow = styled.div`
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  width: auto; 
`;

export const ButtonContainerInsert = styled.div`
  margin: 0.6rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;
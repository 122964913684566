import React, { useEffect, useState } from 'react';
import { ComponentContainer, StatusText, Table, ComponentHeader, Select, SpinLoading, Input, PageHeader } from 'shared/components';
import { getProcessMngList, GetProcessMngListResponse } from 'bto_now/api';
import { getUser } from 'services';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import PageContainer from 'shared/components/PageContainer';
import { debounce } from 'utils';
import * as S from './styles';

interface BusinessManagementData {
  processId: string;
  description: string;
  companies: string;
  status: string;
  valueStreamMap: string;
  mainProcess: string;
}

function BTO_BMO_PlatFormMng_List() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialBusinessManagementData: BusinessManagementData = {
    processId: '',
    description: '',
    companies: '',
    status: '',
    valueStreamMap: '',
    mainProcess: '',
  }

  const [processManagement, setProcessManagement] = useState<BusinessManagementData>(initialBusinessManagementData);
  const [dataProcessManagement, setDataProcessManagement] = useState<GetProcessMngListResponse | undefined>(undefined);
  const [filteredData, setFilteredData] = useState<GetProcessMngListResponse | undefined>(undefined);

  const businessManagementCompanies = [
    { label: 'Company one' },
    { label: 'Company Two' },
    { label: 'Company Three' },
    { label: 'Company Four' }
  ];

  const businessManagementStatus = [
    { label: 'Active' },
    { label: 'Inactive' },
  ];

  const businessManagementValueStreamMap = [
    { label: 'Stream map one' },
    { label: 'Stream map Two' },
    { label: 'Stream map Three' },
  ];
  
  const businessManagementMainProcess = [
    { label: 'Process one' },
    { label: 'Process Two' },
    { label: 'Process Three' },
    { label: 'Process Four' }
  ];

  useEffect(() => {
    debounce(() => {
      getProcessMngList({ user: getUser(), processId: undefined })
        .then(({ data }) => {
          setDataProcessManagement(data);
          setFilteredData(data);
        })
        .catch(err => console.log(err));
    }, 500)();
  }, []);

  useEffect(() => {
    if (dataProcessManagement) {
      const newDataProcessManagement = dataProcessManagement.filter(data =>  
        (!processManagement.processId || data.processId.toString().includes(processManagement.processId.toString())) &&
        (!processManagement.description || data.description.toLowerCase().includes(processManagement.description.toLowerCase())) &&
        (!processManagement.companies || data.companies === processManagement.companies) &&
        (!processManagement.status || data.status === processManagement.status) &&
        (!processManagement.valueStreamMap || data.valueStreamMap === processManagement.valueStreamMap) &&
        (!processManagement.mainProcess || data.mainProcess === processManagement.mainProcess)
      );
      setFilteredData(newDataProcessManagement);
    }
  }, [processManagement, dataProcessManagement]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProcessManagement(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (name: keyof BusinessManagementData, value: string) => {
    setProcessManagement(prevState => ({ ...prevState, [name]: value }));
  };
  
  const navToDetail = (id: number) => {
    navigate('detail/' + id);
  }

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: 'Process Management' }
      ]} />
      <PageContainer>
        <PageHeader
          title='Process Mng List'
          menu={[{ label: 'Home', selected: true }]}
        />
        <ComponentContainer>
          <ComponentHeader title='BMO: Business Management Office' menu={[{ label: 'General Info', selected: true }]} />

          <S.FormContainer>
            <Input
              label={t('Id Process Mng')}
              name='processId'
              value={processManagement.processId}
              onChange={handleInputChange}
            />
            <Input
              label={t('Process Mng Name')}
              name='description'
              value={processManagement.description}
              onChange={handleInputChange}
            />
            <Select
              options={businessManagementCompanies.map(op => ({ label: op.label, value: op.label }))}
              label={t('Main Capabilities')}
              name='companies'
              value={processManagement.companies}
              onChange={(e) => handleSelectChange('companies', e.target.value)}
              disabled={true}
            />
            <Select
              options={businessManagementStatus.map(op => ({ label: op.label, value: op.label }))}
              label={t('Process Mng Status')}
              name='status'
              value={processManagement.status}
              onChange={(e) => handleSelectChange('status', e.target.value)}
              disabled={true}
            />
            <Select
              options={businessManagementValueStreamMap.map(op => ({ label: op.label, value: op.label }))}
              label={t('Value Stream Map')}
              name='valueStreamMap'
              value={processManagement.valueStreamMap}
              onChange={(e) => handleSelectChange('valueStreamMap', e.target.value)}
              disabled={true}
            />
            <Select
              options={businessManagementMainProcess.map(op => ({ label: op.label, value: op.label }))}
              label={t('Main Process')}
              name='mainProcess'
              value={processManagement.mainProcess}
              onChange={(e) => handleSelectChange('mainProcess', e.target.value)}
              disabled={true}
            />
          </S.FormContainer>

          <S.Container>
            {
              !filteredData ? (
                <SpinLoading />
              ) : (
                <Table style={{ marginTop: 12 }}>
                  <Table.Thead>
                    <Table.Tr header>
                      <Table.Td className="table-cell">Id Process Mng</Table.Td>
                      <Table.Td className="table-cell">Process Mng Name</Table.Td>
                      <Table.Td className="table-cell">Process Mng Status</Table.Td>
                      <Table.Td className="table-cell">Companies</Table.Td>
                      <Table.Td className="table-cell">Value Stream Map</Table.Td>
                      <Table.Td className="table-cell">Main Process</Table.Td>
                      <Table.Td className="table-cell">Business Structure</Table.Td>
                      <Table.Td className="table-cell">Business Areas</Table.Td>
                      <Table.Td className="table-cell">Process Type</Table.Td>
                      <Table.Td className="table-cell">Regulatory Map</Table.Td>
                      <Table.Td className="table-cell">Main Deliverables</Table.Td>
                      <Table.Td className="table-cell">Process Mng Input</Table.Td>
                      <Table.Td className="table-cell">Process Mng Output</Table.Td>
                      <Table.Td className="table-cell">Business Benefits</Table.Td>
                      <Table.Td className="table-cell">Risks Type</Table.Td>
                      <Table.Td className="table-cell">Platforms Mng</Table.Td>
                      <Table.Td className="table-cell">Sap Modules</Table.Td>
                      <Table.Td className="table-cell">P&L Impact</Table.Td>
                      <Table.Td className="table-cell">Business Driver</Table.Td>
                      <Table.Td className="table-cell">Trx Volumen</Table.Td>
                      <Table.Td className="table-cell">Process Mng Kpis</Table.Td>
                      <Table.Td className="table-cell">Process Mng Complexity</Table.Td>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {
                      filteredData.map((data) => (
                        <Table.Tr style={{ cursor: 'pointer' }} key={data.processId}>
                          <Table.Td className="table-cell">{data.processId}</Table.Td>
                          <Table.Td className="table-cell">{data.description}</Table.Td>
                          <Table.Td className="table-cell">{data.status}</Table.Td> 
                          <Table.Td className="table-cell">{data.companies}</Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.valueStreamMap}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.mainProcess}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.businessStructure}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.businessArea}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.processType}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.regulatoryMap}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.mainDeliverables}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.input}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.output}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.businessBenefits}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.riskType}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.plataformsMng}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.sapModules}</StatusText></Table.Td>
                          <Table.Td className="table-cell">{data.impact}</Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.businessDriver}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.trxVolumen}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.kpis}</StatusText></Table.Td>
                          <Table.Td className="table-cell"><StatusText size='small' color="blue" className="status-text">{data.complexity}</StatusText></Table.Td>
                        </Table.Tr>
                      ))
                    }
                  </Table.Tbody>
                </Table>
              )
            }
          </S.Container>
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default BTO_BMO_PlatFormMng_List;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
  padding-bottom: 5px;
  -webkit-box-shadow: ${p => p.theme.colors.blackBoxShadow};
  -moz-box-shadow: ${p => p.theme.colors.blackBoxShadow};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
  a:link, :visited, :hover, :active {
    text-decoration: none;
  }

  svg {
    path {
      fill: #8C8C8C;
    }
  }
`;

// src/shared/components/graphPizza/GraphPizza.tsx

import React, { useEffect, useCallback } from 'react';
import * as echarts from 'echarts';
import { PALETE } from 'services';

interface GraphPizzaProps {
  data: { name: string; value: number }[] | undefined;
  height?: number;
}

const GraphPizza: React.FC<GraphPizzaProps> = ({ data, height = 500 }) => {

  const loadGraphPizza = useCallback(() => {
    var chartDom = document.getElementById('graphPizza01');
    var pizzaChart = echarts.init(chartDom!, null, { renderer: 'svg' });

    const option = {
      textStyle: { fontFamily: 'Poppins' },
      color: [PALETE.C01, PALETE.C02, PALETE.C03, PALETE.C04, PALETE.C05, PALETE.C06],
      legend: { 
        show: true,
        orient: 'vertical',
        itemWidth: 20,
        itemHeight: 20,
        left: 0,
        top: '15%'
      },
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
      series: [
        {
          name: 'Percentual',
          type: 'pie',
          radius: ['55%', '95%'],
          left: 220,
          label: { show: false, position: 'center' },
          emphasis: { label: { show: true, fontSize: 10, fontWeight: 'bold', formatter: (v: { value: any }) => `${v.value}%` } },
          itemStyle: { borderRadius: 8, borderColor: '#fff', borderWidth: 2 },
          data
        },
      ]
    };

    option && pizzaChart.setOption(option);
    pizzaChart.resize();
  }, [data]);

  useEffect(() => {
    loadGraphPizza();
  }, [data, loadGraphPizza]);

  return <div id="graphPizza01" style={{ height }} />;
};

export default GraphPizza;

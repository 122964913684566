import React from 'react';
import * as S from './styles';

interface ComponentContainerProps {
  children: React.ReactNode;
  noShowComponent?: boolean;
}

function ComponentContainer({ children, noShowComponent }: ComponentContainerProps) {
  return (
    <S.Container noshow={noShowComponent ? true : false}>
      {children}
    </S.Container>
  );
}

export default ComponentContainer;
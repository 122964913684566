import Row from '../Row';
import Typography from '../Typography';
import React from 'react';
import * as S from './styles';

interface Props {
  separator: string;
  items: Array<{ title: React.ReactNode }>
}

function Breadcrumb({
  separator,
  items
}: Props) {

  return (
    <S.Container>
      <Row>
        {
          items.map((item, index) => (
            <React.Fragment key={index}>
              <Typography style={{ marginLeft: 10 }} size='medium'>{item.title}</Typography>
              {index < items.length - 1 && <Typography style={{ marginLeft: 10 }} size='medium'>{separator}</Typography>}
            </React.Fragment>
          ))
        }
      </Row>
    </S.Container>
  )
}

export default Breadcrumb;
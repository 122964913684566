import { btoApi } from 'bto_now/api/client';
import {create} from 'zustand';

export interface GetTimelinePhaseDataPayload {
  user?: string | null;
  portfolioId: number;
  programId: number;
  projectId: number;
}

interface TimelinePhaseData {
  portfolioId: number;
  programId: number;
  projectId: number;
  phaseId: number;
  phase: string;
  startDate: string | null;
  endDate: string | null;
  ownerId: number;
  owner: string;
}

interface GetTimelinePhaseData {
  timelinePhases: TimelinePhaseData[];
  getTimelinePhases: (queries: GetTimelinePhaseDataPayload) => void;
}

export const useListTimeline = create<GetTimelinePhaseData>((set) => ({
    timelinePhases: [],
    getTimelinePhases: async (params: GetTimelinePhaseDataPayload): Promise<void> => {
      try {
        const response = await btoApi.get<TimelinePhaseData[]>(`api/ProjectTimeline/GetList?User=${params.user}&PortfolioId=${params.portfolioId}&ProgramId=${params.programId}&ProjectId=${params.projectId}`);

        set(() => ({
          timelinePhases: response.data
        }));
      } catch (error) {
        console.error('Failed to fetch field list timeline:', error)
        throw error
      }
    }
}));
import styled, { css } from 'styled-components';
import DefaultInput from 'shared/components/Input';
import DefaultTextArea from '../TextArea';

export const Table = styled.table`
  width: 100%;
  font-size: 13px;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  background: #edf2f8; 
  position: sticky;
  top: 0;
  td {
    padding: 4px 4px 4px 4px;
    font-weight: 700;
    color: #344050; 
  }
`;

export const Tbody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #ffffff; 
  }

  tr:nth-child(even) {
    background-color: #f9fafe; 
  }
`;

export const Tr = styled.tr<{ header?: boolean }>`
  ${p => p.header ? css`
    background: #edf2f8; 
    td {
      padding: 4px 4px 4px 10px;
      font-weight: 600;
      color: #344050; 
    }
  ` : css`
    &:nth-child(odd) {
      background-color: #f9fafe; 
    }

    &:nth-child(even) {
      background-color: #ffffff; 
    }
  `}
`;

export const Td = styled.td`
  white-space: nowrap;
  padding: 6px 4px 6px 4px;
  text-align: left;
  border-bottom: 1px solid #f0f3f8;
  color: #5E6E82;
`;

export const Input = styled(DefaultInput)<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  font-size: ${p => p.theme.font.size.xs};
  background: transparent;

  ${p => p.error && css`
    background: ${p.theme.colors.utility.red[50]};
    border: 1px solid ${p.theme.colors.utility.red[700]};
    &:hover, &:active, &:focus {
      border-color: ${p.theme.colors.utility.red[700]};
    }
  `}
`;

export const TextArea = styled(DefaultTextArea)<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  padding-top: 4.5px;
  resize: none;
  border: none;
  text-align: center;
  font-size: ${p => p.theme.font.size.xs};
  
  &::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    min-height: 200px;
    &::-webkit-scrollbar {
      display: block;
    }
  }

  ${p => p.error && css`
    background: ${p.theme.colors.utility.red[50]};
    border: 1px solid ${p.theme.colors.utility.red[700]};
    &:hover, &:active, &:focus {
      border-color: ${p.theme.colors.utility.red[700]};
    }
  `}
`;

export const ProgressDiv = styled.div<{progress: number, disabled?: boolean}>`
  background: transparent;
  position: relative;
  border-radius: ${p  => p.theme.border.radius.sm};
  
  ${p => p.disabled && css`
    color: ${p => p.theme.colors.neutral.gray[400]};
      background: transparent;
  `}

  &::before {
    content: "";
    display: flex;
    height: 100%;
    width: ${p => p.progress}%;
    max-width: 100%;
    background: ${p => p.progress <= 100 ? p.theme.colors.utility.darkGreen[100] : p.theme.colors.utility.red[300]};
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: ${p  => p.theme.border.radius.sm};
  }
`;

export const InputProgressBar = styled(Input)`
  background: transparent;
  ${p => p.disabled && css`
    color: ${p => p.theme.colors.neutral.gray[700]};
  `}
`;

export const SelectBox = styled.select`
  width: 100%;
  padding: 0px 12px;
  border: none;
  text-align: center;
  background-color: ${p => p.theme.colors.neutral.gray[100]};
  border-radius: ${p => p.theme.border.radius.sm};
  cursor: pointer;
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  ${p => p.disabled && css`
    border-color: ${p => p.theme.colors.neutral.gray[100]};
    color: ${p => p.theme.colors.neutral.gray[300]};
    cursor: not-allowed;
    &:hover, &:focus {
      border-color: ${p => p.theme.colors.neutral.gray[100]};
    }
  `}
`;

export const Option = styled.option`
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
`;


import React, { ReactNode } from 'react';
import { ButtonProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as S from './styles';

interface ButtonIconProps extends ButtonProps {
  children?: ReactNode;  
}

const ButtonIcon: React.FC<ButtonIconProps> = ({ children, ...props }) => (
  <S.StyledIconButton {...props}>
    {children}
  </S.StyledIconButton>
);

export default ButtonIcon;

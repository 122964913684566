import React, { useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input } from "shared/components";
import { ScopeData } from "../../index";

import { GetListScopeTypeResponse } from "bto_now/api/scopeType/getListScopeType";
import { GetListScopeStatusResponse } from "bto_now/api/scopeStatus/getListScopeStatus";
import { createScopeManagement, CreateScopeManagementRequest } from "bto_now/api/scopeManagement/createScope";
import { convertStatusInStatusId } from "utils/convertStatus/convertStatusInStatusId";
import { getUser } from "services";
import { convertTypeInTypeId } from "utils/convertType/convertTypeInTypeId";

import * as S from './styles';

interface ModalCreateScopeProps {
  scopeTypeList: GetListScopeTypeResponse[];
  scopeStatusList: GetListScopeStatusResponse[];
  projectId: string | undefined;
  newScopeModal: boolean;
  setNewScopeModal: (state: boolean) => void;
  setUpdateTrigger: Dispatch<SetStateAction<boolean>>;
}

const ModalCreateScope = (props: ModalCreateScopeProps) => {
  const { projectId, newScopeModal, setNewScopeModal, setUpdateTrigger, scopeStatusList, scopeTypeList } = props;
  const { t } = useTranslation();

  const initialScopeData: ScopeData = {
    name: '',
    scopeType: '',
    owner: '',
    scopeStatus: '',
    description: ''
  };

  const [dataScope, setDataScope] = useState<ScopeData>(initialScopeData);
  const [errors, setErrors] = useState({
    name: false,
    scopeType: false,
    owner: false,
    scopeStatus: false,
    description: false,
  });

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (name: keyof ScopeData, value: string) => {
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const createScopeDatabase = async () => {
    try {
      const newItem: CreateScopeManagementRequest = {
        projectId: Number(projectId),
        name: dataScope.name,
        scopeTypeId: convertTypeInTypeId(scopeTypeList, dataScope.scopeType),
        owner: dataScope.owner,
        scopeStatusId: convertStatusInStatusId(scopeStatusList, dataScope.scopeStatus),
        user: getUser(),
        description: dataScope.description,
      };
  
      console.log(newItem);

      const response = await createScopeManagement(newItem);

      if (response.status === 200) {
        console.log('Scope created successfully:', response.data);
        setUpdateTrigger(prev => !prev);
      } else {
        console.error('Error creating scope:', response.statusText);
      }
    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const handleSubmit = async () => {
    setCreatingLoading(true);
  
    const newErrors = {
      name: !dataScope.name,
      scopeType: !dataScope.scopeType,
      owner: !dataScope.owner,
      scopeStatus: !dataScope.scopeStatus,
      description: !dataScope.description,
    };
  
    setErrors(newErrors);
  
    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      await createScopeDatabase();
  
      setDataScope(initialScopeData); 

      setDataScope({
        name: '',
        scopeType: '',
        owner: '',
        scopeStatus: '',
        description: ''
      });
  
      setResetSelect(true);
      setNewScopeModal(false);
    }
  
    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    setDataScope({
      name: '',
      scopeType: '',
      owner: '',
      scopeStatus: '',
      description: ''
    });

    setNewScopeModal(false)
  }

  return (
    <Modal
      title={t("New Scope Mng")}
      open={newScopeModal}
      onCancel={() => setNewScopeModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading}
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Input
        label={t('Scope Name') + ' *'}
        name='name'
        value={dataScope.name}
        onChange={handleInputChange}
        error={errors.name}
      />
      {errors.name && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={scopeTypeList.map(scope => ({ label: scope.scopeType, value: scope.scopeType }))}
        label={t('Scope Type') + ' *'}
        name='scopeType'
        value={dataScope.scopeType}
        onChange={(e) => handleSelectChange('scopeType', e.target.value)}
        error={errors.scopeType}
        reset={resetSelect}
      />
      {errors.scopeType && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Scope Owner') + ' *'}
        name='owner'
        value={dataScope.owner}
        onChange={handleInputChange}
        error={errors.owner}
      />
      {errors.owner && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Description') + ' *'}
        name='description'
        value={dataScope.description}
        onChange={handleInputChange}
        error={errors.description}
      />
      {errors.description && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={scopeStatusList.map(scope => ({ label: scope.scopeStatus, value: scope.scopeStatus }))}
        label={t('Scope Status') + ' *'}
        name='scopestatus'
        value={dataScope.scopeStatus}
        onChange={(e) => handleSelectChange('scopeStatus', e.target.value)}
        error={errors.scopeStatus}
        reset={resetSelect}
      />
      {errors.scopeStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateScope;
import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface SaveProjectPayload {
    companyId?:               number;
    projectGroup?:            string;
    initiativeType?:          string;
    mainInitiative?:          string;
    initiativeName?:          string;
    summary?:                 string;
    initiativeId?:            string;
    sponsor?:                 string;
    businessLeader?:          string;
    itPartnerLeader?:         string;
    itBusinessPatner?:        string;
    keyUser?:                 string;
    businessArea?:            string;
    businessDriver?:          string;
    businessBenefits?:        string;
    techonologyBenefits?:     string;
    plataformEnvolved?:       string;
    businessProcessEnvolved?: string;
    programme?:               string;
    programmeId?:             number;
    projectManager?:          string;
    businessDriverId?:        number;
    projectId?:               string;
    name?:                    string;
    typeId?:                  number;
    type?:                    string;
    startDate?:               string;
    startPhaseBuild?:         string;
    percentCompleteBuild?:    number;
    startPhaseUat?:           string;
    percentCompleteUat?:      number;
    startPhaseCutover?:       string;
    percentCompleteCutover?:  number;
    goLiveDate?:              string;
    endDate?:                 string;
    actualPhase?:             number | string;
    actualPhaseDescription?:  string;
    actualStatus?:            number;
    progressPlaned?:          number;
    progressRealized?:        number;
    issuesQuantity?:          number;
    statusTime?:              number;
    statusCoast?:             number;
    kanbanId?:                number;
    kanban?:                  string;
    statusRisk?:              number;
    statusIssue?:             number;
    statusScope?:             number;
    providers?:               string;
    totalRequested?:          number;
    totalInvestiment?:        number;
    totalInvestimentCapex?:   number;
    totalInvestimentOpex?:    number;
    commitedBudget?:          number;
    percentCommitedPlanned?:  number;
    totalRunningCoast?:       number;
    lastRitualDate?:          string;
    cpi?:                     number;
    spi?:                     number;
    extraBudget?:             number;
    forecast?:                number;
    consumed?:                number;
    available?:               number;
    financialProgress?:       number;
    operacionalProgress?:     number;
    operationalProgress?:     number;
    toWin?:                   boolean;
    user?:                    string;
    timeline?:                Timeline[];
    financial:                Financial;
    digitalTransformation:    number;
}

export interface Financial {
    projectFinancial:      ProjectFinancial[];
    projectFinancialGroup: ProjectFinancialGroup[];
}

export interface ProjectFinancial {
    user:             string;
    projectId:        string;
    phaseName:        string;
    phaseExecutionId: number;
    ritualNumber:     number;
    startDate:        string;
    endDate:          string;
    finacialType:     string;
    totalOpex:        number;
    totalCapex:       number;
    totalForecast:    number;
    totalInvestments: number;
}

export interface ProjectFinancialGroup {
    project:          string;
    startDate:        string;
    endDate:          string;
    forecast:         number;
    totalCapex:       number;
    totalOpex:        number;
    totalInvestments: number;
}

export interface Timeline {
    user:              string;
    projectId:         string;
    phaseName:         string;
    phaseExecutionId:  number;
    ritualNumber:      number;
    dateType:          string;
    startDate:         string;
    endDate:           string;
    progressPhaseReal: number;
}


export interface SaveProjectResponse {
    sucesso:      boolean;
    mensagemErro: string;
    project:      Project;
}

export interface Project {
    companyId:               number;
    projectGroup:            string;
    initiativeType:          string;
    mainInitiative:          string;
    initiativeName:          string;
    summary:                 string;
    initiativeId:            string;
    sponsor:                 string;
    businessLeader:          string;
    itPartnerLeader:         string;
    itBusinessPatner:        string;
    keyUser:                 string;
    businessArea:            string;
    businessDriver:          string;
    businessBenefits:        string;
    techonologyBenefits:     string;
    plataformEnvolved:       string;
    businessProcessEnvolved: string;
    programme:               string;
    programmeId:             number;
    businessDriverId:        number;
    projectId:               string;
    name:                    string;
    typeId:                  number;
    type:                    string;
    startDate:               Date;
    startPhaseBuild:         Date;
    percentCompleteBuild:    number;
    startPhaseUat:           Date;
    percentCompleteUat:      number;
    startPhaseCutover:       Date;
    percentCompleteCutover:  number;
    goLiveDate:              Date;
    endDate:                 Date;
    actualPhase:             string;
    actualPhaseDescription:  string;
    progressPlaned:          number;
    progressRealized:        number;
    issuesQuantity:          number;
    statusTime:              number;
    statusCoast:             number;
    kanbanId:                number;
    kanban:                  string;
    statusRisk:              number;
    statusIssue:             number;
    statusScope:             number;
    providers:               string;
    totalRequested:          number;
    totalInvestiment:        number;
    totalInvestimentCapex:   number;
    totalInvestimentOpex:    number;
    commitedBudget:          number;
    percentCommitedPlanned:  number;
    totalRunningCoast:       number;
    lastRitualDate:          Date;
    cpi:                     number;
    spi:                     number;
    extraBudget:             number;
    forecast:                number;
    consumed:                number;
    available:               number;
    financialProgress:       number;
    operacionalProgress:     number;
    operationalProgress:     number;
    toWin:                   boolean;
    user:                    string;
    otherDetailsInfo:        OtherDetailsInfo;
    timeline:                Timeline[];
    financial:               Financial;
}

export interface Financial {
    projectFinancial:      ProjectFinancial[];
    projectFinancialGroup: ProjectFinancialGroup[];
}

export interface ProjectFinancial {
    user:             string;
    projectId:        string;
    phaseName:        string;
    phaseExecutionId: number;
    ritualNumber:     number;
    startDate:        string;
    endDate:          string;
    finacialType:     string;
    totalOpex:        number;
    totalCapex:       number;
    totalForecast:    number;
    totalInvestments: number;
}

export interface ProjectFinancialGroup {
    project:          string;
    startDate:        string;
    endDate:          string;
    forecast:         number;
    totalCapex:       number;
    totalOpex:        number;
    totalInvestments: number;
}

export interface OtherDetailsInfo {
    detFinancial: DetFinancial[];
    risks:        Impact[];
    issues:       Impact[];
    impacts:      Impact[];
    curveS:       Curve[];
}

export interface Curve {
    situation:  string;
    startDate:  Date;
    endDate:    Date;
    valorStart: number;
    valorEnd:   number;
}

export interface DetFinancial {
    type:            string;
    total:           number;
    totalCapex:      number;
    totalOpex:       number;
    runninCosts:     number;
    committedBudget: number;
}

export interface Impact {
    description:      string;
    type:             string;
    statusMitigation: string;
    lastUpdate:       Date;
}

export interface Timeline {
    user:              string;
    projectId:         string;
    phaseName:         string;
    phaseExecutionId:  number;
    ritualNumber:      number;
    dateType:          string;
    startDate:         string;
    endDate:           string;
    progressPhaseReal: number;
}


export function saveProject(data: SaveProjectPayload): Promise<AxiosResponse<SaveProjectResponse>> {
    return btoApi
        .post('/api/ProjectInformation/SaveProject', data)
}

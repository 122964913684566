import React, { useEffect, useState } from 'react';
import styles from './textArea.module.css';
import classNames from 'classnames';
import * as S from './styles'; 

export interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textareaStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  value?: string;
  name?: string;
  error?: boolean;
}

function Textarea({
  label,
  textareaStyle,
  containerStyle,
  value,
  name,
  error: _error,
  readOnly = false,
  ...props
}: Props) {

  const [inputFocus, setInputFocus] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(_error);

  useEffect(() => {
    if (error !== _error) setError(_error);
  }, [_error]);

  useEffect(() => {
    if (inputValue !== value) setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputValue !== value && props.onChange) props.onChange({
      target: {
        name: name,
        value: inputValue,
      }
    } as React.ChangeEvent<HTMLTextAreaElement>);
  }, [inputValue]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerError]: error,
        [styles.containerDisabled]: props.disabled,
      })}
      style={{ ...containerStyle, minWidth: ((label?.length || 0) * 7) + 35 }}
    >
      {label && (
        <label
          className={classNames(styles.containerLabel, {
            [styles.containerLabelFloat]: readOnly || inputFocus || (inputValue && inputValue.length > 0),
          })}
        >
          {label}
        </label>
      )}
      <S.Textarea
        {...props}
        style={{ ...textareaStyle, pointerEvents: readOnly ? 'none' : 'auto' }}
        value={inputValue}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => setInputValue(value)}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
      />
    </div>
  );
}

export default Textarea;

import React from 'react';
import * as S from './styles';
import { IconBaseProps } from 'react-icons';
import { stringCut } from 'utils/stringCut';
import Typography from 'shared/components/Typography';
import Col from 'shared/components/Col';

interface Props {
  title: string;
  desc?: string;
  large?: boolean;
  ico: (props: IconBaseProps) => JSX.Element;
  onClick?(): void;
}

function AppButton({
  title,
  large,
  ico: Ico,
  desc,
  onClick
}: Props) {
  return (
    <S.Container large={large} onClick={() => onClick && onClick()}>
      <Col>
        <Typography weight='bold' size='medium'>{title}</Typography>
        <Typography style={{ marginTop: 12 }} weight='regular' size="extraSmall">{stringCut(desc, 50)}</Typography>
      </Col>
      <S.IcoContainer>
        <Ico size={32} color={'#393556'}/>
      </S.IcoContainer>
    </S.Container>
  )
}

export default AppButton;

import { Checkbox } from "antd";
import { CheckboxItemContainer, Label } from "./styles";


export const CheckboxItem = ({ label, value, checked, onChange }: { label: string; value: string; checked: boolean; onChange: (e: any) => void }) => {
  return (
    <CheckboxItemContainer className="checkbox-item" >
      <Checkbox checked={checked} onChange={onChange} value={value}>
        <Label>{label}</Label>
      </Checkbox>
    </CheckboxItemContainer>
  )
}
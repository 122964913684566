import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.neutral.white[0]};
  border-radius: ${p => p.theme.border.radius.sm};
  padding: 10px;
  min-height: 85px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.span`
  font-size: ${p => p.theme.font.size.md};
  font-weight: ${p => p.theme.font.weight.bold};
`;
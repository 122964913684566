import React, { useMemo } from "react";
import * as S from "./styles";

import { useNavigate, useLocation } from "react-router-dom";

import { AppButton } from "shared/layouts";
import {
  Breadcrumb,
  ComponentContainer,
  PageHeader,
  PageContainer,
  ComponentHeader,
} from "shared/components";

import {
  FaCalendar,
  FaRegGem,
  FaRegChartBar,
  FaClipboardList,
  FaTasks,
  FaClipboardCheck,
  FaChartLine,
  FaRoute,
  FaMap,
  FaEdit,
} from "react-icons/fa";

import { IoAlbums, IoGrid, IoLayers } from "react-icons/io5";
import { FaBarsStaggered } from "react-icons/fa6";
import { useTranslation } from "react-i18next";


const menus = [["Home", "/dashboard/mng"]];

function HomeBTO() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const menuList = useMemo(
    () =>
      menus.map((menu) => ({
        label: t(menu[0]),
        selected: location.pathname.includes(t(menu[1])),
        onClick: () => navigate(t(menu[1])),
      })),
    [location.pathname]
  );

  function nav(url: string) {
    return () => navigate(url);
  }

  return (
    <>
      <Breadcrumb separator=">" items={[{ title: <IoGrid size={20} /> }, { title: t("Home") }]}/>
      <PageContainer>
        <PageHeader title={t("BTO.NOW: Management")} menu={menuList} />
        <ComponentContainer>
          <ComponentHeader title={t("Integrated Offices")} menu={[{ label: t("BTO.NOW APPs"), selected: true }]} />

          <S.ButtonsSection>
            <S.AppButtonsTitle>
              {t("Strategy & Investments Portfolio")}
            </S.AppButtonsTitle>
            <S.AppButtonsContainer>
              <AppButton title={t("Portfolio Mng: Strategic Business Driver")} ico={FaRegGem} desc={t("Strategy Management modeled on the Enterprise Project Portfolio")} onClick={nav("/dashboard/business/0")} />
              {/* <AppButton title={t('Portfolio Mng: Investments Mng')} ico={FaChartLine} desc={t('Investment Management of the Enterprise Project Portfolio')} onClick={nav('')} /> */}
              {/* <AppButton title={t('Portfolio Mng: Roadmap Strategy')} ico={FaRoute} desc={t('Roadmap Strategy for the Enterprise Project Portfolio')} onClick={nav('')} />  */}
              {/* <AppButton title={t('Portfolio Mng: Business Model Heatmap')} ico={FaMap} desc={t('Business Model Management and Portfolio Maturity and Performance')} onClick={nav('')} />  */}
            </S.AppButtonsContainer>
          </S.ButtonsSection>

          <S.ButtonsSection>
            <S.AppButtonsTitle>
              {t("PMO: Portfolio Management Office")}
            </S.AppButtonsTitle>
            <S.AppButtonsContainer>
              <AppButton title={t("PMO: Portfolio Execution")} ico={FaBarsStaggered} desc={t("Portfolio Execution Management & Business Drivers Strategy structure information detail")}onClick={nav("/dashboard")} />
              <AppButton title={t("PMO: Create Project Management")} ico={FaCalendar} desc={t("Create Project Management and Integrated Portfolio Execution Management with Business Driver Connect")} onClick={nav("/dashboard/mng-project/new/general")}/>
              {/* <AppButton title={t('PMO: New Create Project Management')} ico={FaClipboardCheck} desc={t('Measurement of Projects and Status Report with Structuring of information in the Execution phase')} onClick={nav('/dashboard/mng/pmo/new-create-project/general')} /> */}
              {/* <AppButton title={t('PMO: Project Ritual Management')} ico={FaClipboardCheck} desc={t('Measurement of Projects and Status Report with Structuring of information in the Execution phase')} onClick={nav('/dashboard/mng/pmo/project-ritual-mng')} /> */}
              {/* <AppButton title={t('PMO: Modify & Visualization Project')} ico={FaEdit} desc={t('Project analysis structure for modifications to management asset views')} onClick={nav('')} /> */}
              {/* <AppButton title={t('PMO: Portfolio MNG')} ico={FaBarsStaggered} desc={t('Portfolio Execution Management & Business Drivers Strategy structure information detail')} onClick={nav('/dashboard/mng/portfolio/gantt')} /> */}
            </S.AppButtonsContainer>
          </S.ButtonsSection>

          <S.ButtonsSection>
            <S.AppButtonsTitle>
              {t("PMO: Administration Project Management")}
            </S.AppButtonsTitle>
            <S.AppButtonsContainer>
              <AppButton title={t("PMO: Digital Status Report")} ico={FaRegChartBar} desc={t("Digital Status Report for Management")}onClick={nav("/dashboard/mng/pmo/digital-status-report")}/>
              <AppButton title={t("PMO: Project Scope Management")}ico={FaClipboardList} desc={t("Detailed project scope management for analysis and integration of expected deliverables")} onClick={nav("/dashboard/mng/pmo/project-scope-mng")}/>
              <AppButton title={t("PMO: Project Tasks Management")}ico={FaTasks} desc={t("Project Task Management of Portfolio and integrated into Rituals Mng")} onClick={nav("/dashboard/mng/pmo/project-tasks-mng")}/>
            </S.AppButtonsContainer>
          </S.ButtonsSection>

          <S.ButtonsSection>
            <S.AppButtonsTitle>
              {t("BMO: Business Management Office")}
            </S.AppButtonsTitle>
            <S.AppButtonsContainer>
              <AppButton title={t("BMO: Value Stream Map")} ico={IoLayers} desc={t("VSM by Industries vision to execution Business Model and Process Mng detail to Transformation Journey")} onClick={nav("/dashboard/mng/bmo/landscape-integration")}/>
              <AppButton title={t("BMO: Process Management")}ico={IoAlbums} desc={t("Process Management detail with Operational and Financial information and connect to Business Engineering")} onClick={nav("/dashboard/mng/bmo/platform-mng/list")}/>
            </S.AppButtonsContainer>
          </S.ButtonsSection>

          <S.ButtonsSection>
            <S.AppButtonsTitle>
              {t("TMO: Technology Management Office")}
            </S.AppButtonsTitle>
            <S.AppButtonsContainer>
              <AppButton title={t("TMO: Landscape Integration")} ico={IoLayers} desc={t("Platform Management and Performance Systems. Features, Capabilities and Integration , connect to Business Model")} onClick={nav("/dashboard/mng/tmo/landscape-integration")}/>
              <AppButton title={t("TMO: Platform Management")} ico={IoAlbums} desc={t("Design landscape and performance and integration systems monitoring.")}onClick={nav("/dashboard/mng/tmo/platform-mng/list")}/>
            </S.AppButtonsContainer>
          </S.ButtonsSection>
        </ComponentContainer>
      </PageContainer>
    </>
  );
}

export default HomeBTO;

import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetArchivedRisksPayload {
  User?:              string | null;
  ProjectId?:         string | null;
}

export type GetArchivedRisksResponse = Array<{
  riskId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  statusMitigation:     string;
  probabilityScale:     string;
  impactScale:          string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}>

export function getArchivedRisks(params: GetArchivedRisksPayload): Promise<AxiosResponse<GetArchivedRisksResponse>> {
  return btoApi
    .get('/api/ProjectRisk/GetArchivedRisks', { params });
};

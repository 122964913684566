import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetInitiativesFilterPayload {
    projectId?: number;
    businessAreaId?: number;
    programmeId?: number;
    businessDriverId?: number;
    actualPhase?: number;
    name?: string;
    user: string;
  }

  export type GetInitiativesFilterResponse = Array<{
    companyId: number;
    projectGroup: string;
    initiativeType: string;
    mainInitiative: string;
    initiativeName: string;
    summary: string;
    initiativeId: string;
    sponsor: string;
    businessLeader: string;
    itPartnerLeader: string;
    itBusinessPatner: string;
    keyUser: string;
    businessArea: string;
    businessDriver: string;
    businessBenefits: string;
    techonologyBenefits: string;
    plataformEnvolved: string;
    businessProcessEnvolved: string;
    programme: string;
    programmeId: number;
    businessDriverId: number;
    projectId: string;
    name: string;
    typeId: number;
    type: string;
    startDate: string;
    startPhaseBuild: string | null;
    percentCompleteBuild: number;
    startPhaseUat: string | null;
    percentCompleteUat: number;
    startPhaseCutover: string | null;
    percentCompleteCutover: number;
    goLiveDate: string;
    endDate: string;
    actualPhase: number;
    actualPhaseDescription: string;
    progressPlaned: number;
    progressRealized: number;
    issuesQuantity: number;
    statusTime: number;
    statusCoast: number;
    kanbanId: number;
    kanban: string;
    statusRisk: number;
    statusIssue: number;
    statusScope: number;
    providers: string;
    totalRequested: number;
    totalInvestiment: number;
    totalInvestimentCapex: number;
    totalInvestimentOpex: number;
    commitedBudget: number;
    percentCommitedPlanned: number;
    totalRunningCoast: number;
    lastRitualDate: string | null;
    cpi: number | null;
    spi: number | null;
    extraBudget: number;
    forecast: number;
    consumed: number;
    available: number;
    financialProgress: number;
    operacionalProgress: number;
    operationalProgress: number;
    toWin: boolean;
    projectManager: string;
    actualStatus: number;
    actualStatusDescription: string;
    user: string | null;
    otherDetailsInfo: {
      detFinancial: any[];
      risks: any[];
      issues: any[];
      impacts: any[];
      curveS: any[];
    };
    timeline: any[];
    financial: {
      projectFinancial: any[];
      projectFinancialGroup: any[];
    };
  }>;

  ///api/Initiative/GetInitiativesFilter

    export function getInitiativesFilter(payload: GetInitiativesFilterPayload): Promise<AxiosResponse<GetInitiativesFilterResponse>> {
        return btoApi
        .post('/api/Initiative/GetInitiativesFilter', payload);
    }

import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AutoCloseProgressBar = styled.div<{ duration?: number }>`
  width: 80%;
  height: 6px;
  align-self: center;
  justify-self: center;
  background-color: ${p => p.theme.colors.primary[500]};
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
  
  &::after {
    content: '';
    display: block;
    background-color: ${p => p.theme.colors.primary[900]};
    border-radius: 3px;
    height: 100%;
    width: 100%;
    
    animation-name: autoCloseProgress;
    animation-duration: ${p => p.duration};
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;

    @keyframes autoCloseProgress {
      0% {
        width: 100%;
      }
      100% {
        width: 0%;
      }
    }
  }
`;
import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListTaskManagementPayload {
  User?: string | null;
  ProjectId: string;
}

export interface GetListTaskManagementResponse {
  projectId: number;
  taskId: number;
  ritualNumber: number;
  name: string;
  description: string;
  owner: string;
  taskStatusId: number;
  startDate: Date;
  endDate: Date;
  user: string | null;
}

export function getListTaskManagement (params: GetListTaskManagementPayload): Promise<AxiosResponse<GetListTaskManagementResponse[]>> {
  return btoApi.get('/api/TaskManagement/GetList', { params });
}
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { message } from "antd";
import { CircularProgressbar } from 'react-circular-progressbar';
import { AiFillEdit } from "react-icons/ai";
import 'react-circular-progressbar/dist/styles.css';
import * as yup from "yup";

import { Table, ComponentHeader, Button, ButtonCalendarInput, Input, StatusText, SpinLoading } from "shared/components";
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';

import { GetTimelinePhaseDataPayload, useListTimeline } from "shared/store-zustand/create-project/getListTimeline";
import { getUser } from "services";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { CreateTimelineProps, FormDataTimeline, QueryTimelinePayload, useCreateTimeline } from "shared/store-zustand/create-project/createTimeline";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import { formatDateBeforeSendToBack } from "utils/dates/formatDateBeforeSendToBack";
import { areDatesSequential } from "utils/dates/areDatesSequential";

import { projectPhases } from "./data";

import * as S from './styles';
import ButtonIcon from "shared/components/ButtonIcon";

interface FormData {
  phases: {
    realStartDate: string;
    realEndDate: string;
  }[];
}

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  phases: yup.array().of(
    yup.object({
      realStartDate: yup.string()
        .required(t('This field is required.')),
      realEndDate: yup.string()
        .required(t('This field is required.')),
    })
  ).required()
}).required();

const TimelineRitual = () => {
  const { dataProject } = useCreateProject();
  const { timelinePhases, getTimelinePhases } = useListTimeline();
  const { createTimeline, getTimelineData, timelineData} = useCreateTimeline();
  const { fieldListStatus } = useFieldListStatus();
  const { id } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string[]>(projectPhases?.map(item => item.startDatePlan));
  const [endDate, setEndDate] = useState<string[]>(projectPhases?.map(item => item.endDatePlan));
  const [phaseRealDays, setPhaseRealDays] = useState<number[]>(projectPhases?.map(item => item.phasePlanDays));

  const [isReadonly, setIsreadonly] = useState<boolean[]>(Array(projectPhases.length).fill(true));

  const { register, handleSubmit, control, setValue, formState: { errors }, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      phases: timelinePhases.map((phase: any, index: number) => ({
        realStartDate: startDate[index],
        realEndDate: endDate[index],
        phasePlanDays: 0,
      }))
    }
  });

  const { fields } = useFieldArray({
    control,
    name: 'phases',
  });

  useEffect(() => {
    const queriesTimeline: GetTimelinePhaseDataPayload = {
      user: getUser(),
      portfolioId: 1,
      programId: 1,
      projectId: Number(id)
    };

    const fetchData = async () => {
      try {
        await getTimelinePhases(queriesTimeline);
      } catch (error) {
        console.error('Error fetching timeline phases:', error);
      }
    };

    fetchData();
  }, [getTimelinePhases])

  useEffect(() => {
    phaseRealDays.map((item, index) => {
      calculatePhaseDays(index)
    })
  }, [startDate, endDate])

  const CreateTimelineDatabase = async (data: FormData) => {
    try {
      const user = getUser();

      const query: QueryTimelinePayload = { user: user ?? null };

      const newItems: CreateTimelineProps[] = data.phases.map((timeline) => ({
        portfolioId: 1,
        programId: dataProject.programId!,  
        projectId: dataProject.projectId!,  
        startDate: formatDateBeforeSendToBack(timeline.realStartDate),
        endDate: formatDateBeforeSendToBack(timeline.realEndDate),
        owner: dataProject.ownerId,
      }));

      const datesAreSequential = areDatesSequential(data.phases, 'realStartDate', 'realEndDate')
      
      if (!datesAreSequential) return message.warning(t('Dates must to be sequential'));
  
      const response = await createTimeline(query, newItems);

      // if (response.status === 200) {
      //   getTimelineData(data.phases)
      // }
  
    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data)
    // navigate('/dashboard/mng/pmo/new-create-project/scope')
  };
  
  const calculatePhaseDays = (index: number) => {
    const dateStart = startDate[index];
    const dateEnd =  endDate[index];
    const days = calculateDateDiffInDays(dateStart, dateEnd);
    setPhaseRealDays(prevState => {
      const newPhasePlanDays = [...prevState];
      newPhasePlanDays[index] = days;
      return newPhasePlanDays;
    });
  }

  const handleInputStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);

    setStartDate(prevState => {
      const newStartDate = [...prevState];
      newStartDate[index] = value;
      return newStartDate;
    });

    setValue(`phases.${index}.realStartDate`, value);

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.realStartDate`);
    }
  };

  const handleInputEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);

    setEndDate(prevState => {
      const newEndDate = [...prevState];
      newEndDate[index] = value;
      return newEndDate;
    });
    
    setValue(`phases.${index}.realEndDate`, value);

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.realEndDate`);
    }
  };

  const statusColor = useCallback((option: string) => {
    switch (option) {
      case 'Completed': return 'green';
      case 'On Going': return 'blue';
      case 'Waiting': return 'white';
    }
  }, []);

  const handleEditRealDates = () => {
    setIsreadonly(prev => 
      prev.map((readonly, index) => 
        projectPhases[index].status !== 'Completed' ? false : readonly
      )
    );

    message.info(t('Real date fields that is not completed is open to edit.'))
  }
  return (
    <>
      <ComponentHeader title={t('PMO: Ritual Project Timeline')} menu={[{ label: t('Timeline'), selected: true }]} />

      <S.IconButtonContainer>
        <ButtonIcon onClick={handleEditRealDates}>
          <AiFillEdit style={{ width: '22px', height: '22px' }} />
        </ButtonIcon>
      </S.IconButtonContainer>
      
      <S.ContainerList>
        {fields.length === 0 ? (
          <SpinLoading />
        ) : (
          <S.TableContainer>
            <Table style={{ marginTop: 16 }}>
              <Table.Thead>
                <Table.Tr header>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Id')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Phase')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Start Date Plan')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('End Date Plan')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{t('Progress Bar Plan')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{t('Phase Plan Days')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Start Date Real')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('End Date Real')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{t('Progress Bar Real')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{t('Phase Real Days')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Owner')}</Table.Td>
                  <Table.Td style={{ padding: '4px 12px' }}>{t('Status')}</Table.Td>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {fields.map((item, index) => (
                  <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={item.id}>
                    <Table.Td style={{ padding: '8px 12px' }}>00{timelinePhases[index].phaseId + 1}</Table.Td>
                    <Table.Td style={{ padding: '8px 16px', textAlign: 'left' }}>{timelinePhases[index].phase}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{projectPhases[index].startDatePlan}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{projectPhases[index].endDatePlan}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px', textAlign: 'center', verticalAlign: 'middle' }}>
                      <S.ContainerProgressBar>
                        <CircularProgressbar value={projectPhases[index].progressBarPlan} text={`${projectPhases[index].progressBarPlan}%`} />
                      </S.ContainerProgressBar>
                    </Table.Td>
                    <Table.Td style={{ padding: '8px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{projectPhases[index].phasePlanDays}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>
                      <S.InputContainer>
                        <ButtonCalendarInput
                          {...register(`phases.${index}.realStartDate`)}
                          placeholder={t('Real Start Date') + ' *'}
                          minDate={endDate[index - 1] || '01.01.1900'}
                          maxDate={endDate[index]}
                          value={projectPhases[index]?.startDatePlan}
                          onChange={handleInputStartDateChange}
                          error={!!errors?.phases?.[index]?.realStartDate}
                          readOnly={isReadonly[index]}
                          noMinWidth
                        />
                        {errors?.phases?.[index]?.realStartDate && <S.ErrorMessage>{errors.phases[index].realStartDate.message}</S.ErrorMessage>}
                      </S.InputContainer>
                    </Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>
                      <S.InputContainer>
                        <ButtonCalendarInput
                          {...register(`phases.${index}.realEndDate`)}
                          placeholder={t('Real End Date') + ' *'}
                          minDate={startDate[index]}
                          maxDate={startDate[index + 1] || '31.12.2100'}
                          value={projectPhases[index]?.endDatePlan}
                          onChange={handleInputEndDateChange}
                          error={!!errors?.phases?.[index]?.realEndDate}
                          readOnly={isReadonly[index]}
                          noMinWidth
                        />
                        {errors?.phases?.[index]?.realEndDate && <S.ErrorMessage>{errors.phases[index].realEndDate.message}</S.ErrorMessage>}
                      </S.InputContainer>
                    </Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>
                      <S.ContainerProgressBar>
                        <CircularProgressbar value={projectPhases[index].progressBarReal} text={`${projectPhases[index].progressBarReal}%`} />
                      </S.ContainerProgressBar>
                    </Table.Td>
                    <Table.Td style={{ padding: '8px 12px', textAlign: 'center', verticalAlign: 'middle' }}>{phaseRealDays[index]}</Table.Td>
                    <Table.Td style={{ padding: '8px 12px' }}>{projectPhases[index].owner}</Table.Td>
                    <Table.Td>
                      <StatusText 
                        color={statusColor(projectPhases[index].status)} 
                        size='extraSmall' weight='bold'>
                          {projectPhases[index].status}
                      </StatusText>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </S.TableContainer>
        )}
      </S.ContainerList>
      <S.ButtonContainer>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainer>
    </>
  );
};

export default TimelineRitual;




import { btoApi } from 'bto_now/api/client';
import {create} from 'zustand';

export interface Program {
  companyId: number;
  portfolioId: number;
  programId: number;
  name: string;
}

export interface ProjectType {
  projectTypeId: number;
  projectType: string;
}

export interface Manager {
  companyId: number;
  managerId: number;
  name: string;
}

export interface Owner {
  companyId: number;
  ownerId: number;
  name: string;
}

interface ResponseData {
  programList: Program[];
  projectTypeList: ProjectType[];
  managerList: Manager[];
  ownerList: Owner[];
}

interface GetFieldListStatusPayload {
  User?: string | null;
  portfolioId: number
}

interface GetFieldListStatus {
  fieldListStatus: ResponseData;
  getFieldListStatus: (params: GetFieldListStatusPayload) => void;
}

const initialResponseData: ResponseData = {
  programList: [],
  projectTypeList: [],
  managerList: [],
  ownerList: []
};

export const useFieldListStatus = create<GetFieldListStatus>((set) => ({
    fieldListStatus: initialResponseData,
    getFieldListStatus: async (params: GetFieldListStatusPayload): Promise<void> => {
      try {
        const response = await btoApi.get<ResponseData>('/api/Project/GetFieldList', { params });
        set({ fieldListStatus: response.data })
      } catch (error) {
        console.error('Failed to fetch field list status:', error)
      }
    }
}));
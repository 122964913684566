import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export type UpdateDraftFinancialPayload = Array<{
  user:             string;
  projectId:        string;
  phaseExecutionId?: number;
  financialType:    string;
  totalCapex:       number;
  totalOpex:        number;
}>


export interface UpdateDraftFinancialResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function updateDraftFinancial(body: UpdateDraftFinancialPayload): Promise<AxiosResponse<UpdateDraftFinancialResponse>> {
  return btoApi
   .post('/api/Ritual/UpdateDraftFinancial', body);
}
import styled from 'styled-components';

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  flex: 1;
  margin-bottom: 10px;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -20px;
  left: 3px;
  color: red;
  font-size: 12px;
`;

export const ButtonContainer = styled.div`
  margin-top: 1.3rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const IconButtonContainer = styled.div`
  margin-top: -2rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const TableContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  overflow-x: auto;
`;

export const ContainerProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 48px;
  height: 48px;
  margin: 0 auto;
`;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetAllRitualsPayload {
  User?:              string | null;
  ProjectId:          string | null;
}

export type GetAllRitualsResponse = Array<{
  projectId:          number;
  ritualNumber:       number;
  ritualName:         string;
  operacionalChanges: string;
  financialChanges:   string;
  riskChanges:        string;
  issueChanges:       string;
  createBy:           string;
  createDate:         string;
  statusRitual:       string;
}>;


export function getAllRituals(params: GetAllRitualsPayload): Promise<AxiosResponse<GetAllRitualsResponse>> {
  if(!params || !params.User || !params.ProjectId)
    return Promise.reject({error: 'Parametros não foram fornecidos'});
  
  return btoApi
    .get('/api/Ritual/GetAllRituals', { 
      params 
    });
}

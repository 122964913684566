import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';

export interface UpdateScopeProps {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  description: string;
  ownerId: number;
  user: string;
}

interface UpdateScopeStore {
  updateScope: (params: UpdateScopeProps) => Promise<AxiosResponse | undefined>;
}

export const useUpdateScope = create<UpdateScopeStore>((set) => ({
  updateScope: async (params: UpdateScopeProps): Promise<AxiosResponse | undefined> => {
    try {
      const response = await btoApi.post('api/ProjectScope/Update', params);

      if (response.status === 200) {
        console.log('Scope updated successfully:', response);
        return response;
      } else {
        console.error('Error updating scope:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to update scope:', error);
    }
  },
}));
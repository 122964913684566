import React from 'react';

import Typography from 'shared/components/Typography';
import Row from 'shared/components/Row';
import { useTheme } from 'styled-components';

import * as S from './styles';
import { ButtonCustomized } from 'shared/components';

interface Props {
  title: string;
  children?: React.ReactNode;
  menu?: Array<{
    label: string;
    selected: boolean;
    onClick?(label?: string): void;
  }>
  buttons?: Array<{
    label: string;
    onClick: () => void;
  }>;
}

function ComponentHeader({
  title,
  menu,
  children,
  buttons,
}: Props) {
  const theme = useTheme();

  return (
    <S.Container>
      <S.ContainerChildren>
        <Row>
          <Typography size='medium' weight='bold'>{title}</Typography>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
          {buttons && buttons.map((button, index) => (
            <ButtonCustomized key={index} label={button.label} onClick={button.onClick} />
          ))}
        </div>
        </Row>
        <Row style={{ marginTop: 14 }}>
          {Array.isArray(menu) && menu.length > 0 && menu.map((item, index) => (
            <React.Fragment key={index}>
              <S.MenuItem onClick={() => item.onClick && item.onClick(item.label)} selected={item.selected} key={index}>{item.label}</S.MenuItem>
              {index < menu.length - 1 && <Typography style={{ color: theme.colors.neutral.gray[300] }} weight='light'>|</Typography>}
            </React.Fragment>
          ))}
        </Row>
      </S.ContainerChildren>
      {children}
    </S.Container>
  );
}

export default ComponentHeader;
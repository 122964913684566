import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetGanttByProgramPayload {
  user: string;
  portfolioId: number;
}

export type GetGanttByProgramResponse = Array<{
  programId: number;
  program: string;
  projects: any[];
}>;

export function getGanttByProgram(payload: GetGanttByProgramPayload): Promise<AxiosResponse<GetGanttByProgramResponse>> {
  return btoApi
    .get("/api/Project/GetGanttByProgram", { params: payload });
}

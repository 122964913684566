import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface CreateRitualPayload { 
  projectId: string;
  name: string;
  user: string;
}

export interface CreateRitualResponse {
  sucesso: boolean;
  mensagemErro: string;
  ritualNumber: number;
}

export function createRitual(data: CreateRitualPayload): Promise<AxiosResponse<CreateRitualResponse>> {
  return btoApi.post('/api/Ritual/CreateRitual', data);
}

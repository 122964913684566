import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export type UpdateDraftTimelinePayload = Array<{
  user:              string;
  projectId:         string;
  phaseExecutionId:  number;
  dateType:          string;
  startDate:         string;
  endDate:           string;
  progressPhaseReal: number;
}>

export interface UpdateDraftTimelineResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export interface Params {
  user:         string;
  projectId:    string | undefined;
  ritualNumber: number;
}


export function updateDraftTimeline(data: UpdateDraftTimelinePayload, params: Params): Promise<AxiosResponse<UpdateDraftTimelineResponse>> {
  if(!data)
    return Promise.reject({error: 'Parametros não foram fornecidos'});
  
  return btoApi
    .post(`api/Ritual/UpdateDraftTimelineWithGet?User=${params.user}&ProjectId=${params.projectId}&RitualNumber=${params.ritualNumber}`, data)
}

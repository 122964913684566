import { Breadcrumb, ComponentContainer, ComponentHeader, PageContainer } from 'shared/components';
import React from 'react';
import { IoGrid } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import ImgLandscape from 'shared/assets/img/imagem_bto.jpeg';

function TMO_LandscapeIntegration() {

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: 'Landscape Integration' }
      ]} />
      <PageContainer>
        <ComponentContainer>
          <ComponentHeader title="Landscape Integration" menu={[{ label: 'Home', selected: true }]} />
          <img
            style={{ marginTop: 12 }}
            src={ImgLandscape}
            alt='landscape'
          />
        </ComponentContainer>
      </PageContainer>
    </>
  );
}

export default TMO_LandscapeIntegration;
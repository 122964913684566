import { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalInactivityProps {
  logout: () => void;
}

const ModalInactivity: React.FC<ModalInactivityProps> = ({ logout }) => {
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(10);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const downtime = 600000;

  const startCountdown = () => {
    setShow(true);
    setCounter(10);
    intervalRef.current = setInterval(() => {
      setCounter(prevCounter => {
        if (prevCounter <= 1) {
          clearInterval(intervalRef.current!);
          logout();
        }
        return prevCounter - 1;
      });
    }, 1000);
  };

  const handleCancel = () => {
    setShow(false);
    resetTimer();
  };

  const resetTimer = useCallback(() => {
    setCounter(10);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(startCountdown, downtime);
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keypress', resetTimer);
    document.addEventListener('click', resetTimer);

    resetTimer();

    return () => {
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keypress', resetTimer);
      document.addEventListener('click', resetTimer);
    };
  }, [resetTimer]);

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Inatividade detectada</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você será deslogado em {counter} {counter !== 1 ? "segundos" : "segundo"} devido à inatividade.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInactivity;

export const lightTheme = {
  colors: {
    text: '#151C23',
    inputText: '#4d5c73',
    background: '#F1F3FC',
    headerBackground: '#02002F',
    blackBoxShadow: '5px 5px 10px rgb(0 0 0 / 0.2)',
    primary: {
      'blue' : '#5342EC',
      900: '#322690',
      800: '#4033BC',
      700: '#5442E2',
      600: '#6C54FF',
      500: '#8669FF',
      400: '#A280FF',
      300: '#BC9AFF',
      200: '#D3B5FF',
      100: '#E7D2FF',
      50: '#F8F0FF',
    },
    secondary: {
      'pink' : '#E10088',
      900: '#7A0045',
      800: '#9E005A',
      700: '#C00070',
      600: '#DD0085',
      500: '#F43299',
      400: '#FF5AAD',
      300: '#FF7FC0',
      200: '#FFA4D3',
      100: '#FFC9E4',
      50: '#FFEDF6',
    },
    terciary: {
      'gray': '#4D5C73',
      900: '#303A48',
      800: '#404C5F',
      700: '#515F75',
      600: '#64738B',
      500: '#78879F',
      400: '#8E9CB3',
      300: '#A6B1C5',
      200: '#BEC7D7',
      100: '#D8DDE7',
      50: '#F2F4F7',
    },
    neutral: {
      white: {
        'white': '#F2F2F4',
        900: '#39393A',
        800: '#4B4B4D',
        700: '#5E5E60',
        600: '#727274',
        500: '#868688',
        400: '#9B9B9D',
        300: '#B0B0B2',
        200: '#C6C6C7',
        100: '#DDDDDD',
        50: '#F3F3F4',
        0: '#FFFFFF',
      },
      gray: {
        'gray': '#878F93',
        900: '#353A3C',
        800: '#464C4F',
        700: '#585F63',
        600: '#6B7377',
        500: '#80888B',
        400: '#959CA0',
        300: '#ABB2B5',
        200: '#C2C7CA',
        100: '#DADDDF',
        50: '#F3F4F4',
      }
    },
    utility: {
      darkGreen: {
        'green' : '#007D46',
        900: '#004321',
        800: '#00582D',
        700: '#006D3B',
        600: '#10834B',
        500: '#33985F',
        400: '#54AC76',
        300: '#77C091',
        200: '#9CD3AE',
        100: '#C3E5CD',
        50: '#EBF7EE',
      },
      yellow: {
        'yellow': '#FFA62B',
        900: '#5B2E00',
        800: '#763D00',
        700: '#914D00',
        600: '#AA6000',
        500: '#C27400',
        400: '#D78A1C',
        300: '#E9A24A',
        200: '#F7BB79',
        100: '#FFD5AC',
        50: '#FFF1E2',
      },
      red: {
        'red': '#EF3C5C',
        900: '#780023',
        800: '#9C002E',
        700: '#BD033C',
        600: '#DA244D',
        500: '#F24460',
        400: '#FF6477',
        300: '#FF8691',
        200: '#FFA8AD',
        100: '#FFCBCD',
        50: '#FFEEEE',
      }
    }
  },
  font: {
    size: {
      default: "16px",
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '20px',
      xl: '24px',
      xxl: '32px',
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600,
    },
    family: 'Poppins',
  },
  border: {
    radius: {
      default: '8px',
      xs: '4px',
      sm: '8px',
      md: '12px',
      lg: '100px',
      xl: '24px',
      pill: '100px',
      circular: '100%',
    },
    width: {
      node: '0px',
      hairLine: '1px',
      thin: '2px',
      thick: '4px',
      medium: '6px',
      heavy: '8px',
    }
  },
  spacing: {
    nano: '4px',
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    huge: '48px',
    giant: '64px',
  }
}

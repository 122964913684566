import React from "react";
import * as S from './styles';

export interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  /** 
   * **Os valores declarados aqui são conforme configurado no arquivo de Tema**
   * - **default** = 16px (when not declared);
   * - **extraSmall** = 10px;
   * - **small** = 12px;
   * - **medium** = 14px;
   * - **large** = 20px;
   * - **extraLarge** = 24px;
   * - **extraExtraLarge** = 32px;
   */
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge' | 'extraExtraLarge';
  /**
   * **Os valores declarados aqui são conforme configurado no arquivo de Tema**
   * - **light** = 300
   * - **regular** = 400
   * - **medium** = 500
   * - **bold** = 600
   */
  weight?: 'light' | 'regular' | 'medium' | 'bold';
}

function Typography(props: Props) {
  return (
   <S.Typography {...props} />
  );
}

export default Typography;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { IoGrid } from 'react-icons/io5';
import { Dropdown } from 'react-bootstrap';
import { ThreeDots, Link as LinkIcon } from 'react-bootstrap-icons'; 
import {useStatusReportStore} from 'shared/store-zustand/get-status-report/getStatusReport';
import { useTranslation } from "react-i18next";

import {
  Select,
  ComponentContainer,
  SpinLoading,
  Table,
  Breadcrumb,
  ComponentHeader,
  PageHeader,
  Input,
} from 'shared/components';

import { PageContainer } from 'shared/components';

import {
  getBusinessDriverGroup,
  getProgrammeList,
  getBusinessAreaList,
  getAllPhases,
  getInitiativesFilter,
  GetInitiativesFilterResponse
} from 'bto_now/api';

import { getUser } from 'services';

import { debounce } from 'utils';

import moment from 'moment';

import * as S from './styles';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport';
import { IoIosLink } from 'react-icons/io';

function PMO_ProjectScopeMng() {
  const {getStatusReport} = useStatusReportStore()
  const {setTypeStatusReport} = useTypeStatusReportStore()

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const [list, setList] = useState<GetInitiativesFilterResponse>();
  const [isLoading, setIsLoading] = useState(false);


  const [projectId, setProjectId] = useState('');
  const [projectName, setProjectName] = useState('');
  const [programName, setProgramName] = useState('');
  const [businessArea, setBusinessArea] = useState('');
  const [actualPhase, setActualPhase] = useState('');
  const [businessDriver, setBusinessDriver] = useState('');

  const [businessDriverOptions, setBusinessDriverOptions] = useState<Array<any>>([]);
  const [programNameOptions, setProgramNameOptions] = useState<Array<any>>([]);
  const [businessAreaOptions, setBusinessAreaOptions] = useState<Array<any>>([]);
  const [actualPhaseOptions, setActualPhaseOptions] = useState<Array<number>>([]);

  const [loadingSelects, setLoadingSelects] = useState(true);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      getInitiativesFilter({ user: getUser(), projectId: +projectId, name: projectName, actualPhase: +actualPhase, businessAreaId: +businessArea, programmeId: +programName, businessDriverId: +businessDriver })
        .then(({ data }) => {
          console.log(data)
          setList(data)
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false));
    }, 500)();
  }, [
    projectId,
    projectName,
    programName,
    businessArea,
    actualPhase,
    businessDriver,
  ]);


  useEffect(() => {
    setLoadingSelects(true);
    Promise.all([
      getBusinessDriverGroup({ User: getUser() }),
      getProgrammeList({ User: getUser() }),
      getBusinessAreaList({ User: getUser() }),
      getAllPhases(),
    ])
      .then(([businessDriverData, programeNameData, businessAreaData, phasesData]) => {
        setBusinessDriverOptions(businessDriverData.data);
        setProgramNameOptions(programeNameData.data);
        setBusinessAreaOptions(businessAreaData.data);
        setActualPhaseOptions(phasesData.data
          .filter(v => v.phaseExecutionId >= 1 && v.phaseExecutionId <= 7)
          .map(v => v.phaseExecutionId));
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingSelects(false));
  }, []);


  function navToDetail(id: number, status: number): void {
    getStatusReport(id, status)
      .then((response: any) => {
        if (!response) { 
          return;
        }
          setTypeStatusReport(status);
          const path = location.pathname;
          if (path.includes('digital-status-report')) {
            navigate(`dashboard/${id}`);
          } else if (path.includes('project-scope-mng')) {
            navigate(`scope/${id}`);
          } else if (path.includes('project-tasks-mng')) {
            navigate(`task/${id}`);
          } else if (path.includes('project-ritual-mng')) {
            navigate(`ritual/${id}/scope`);
          }
      })
      .catch((error: any) => {
        console.error('Failed to fetch status report:', error);
      });

  }

  const [showInputs, setShowInputs] = useState(false);

  useEffect(() => {
    if (!isLoading && list) {
      setShowInputs(true);
    }
  }, [isLoading, list]);
 
  const isDigitalStatusReport = location.pathname.includes('digital-status-report');
  const isTaskProject = location.pathname.includes('project-tasks-mng');
  const isRitualProject = location.pathname.includes('ritual');

  const getLastTitleBreadcrumb = () => {
    switch (true) {
      case isDigitalStatusReport:
        return t('Digital Status Report');
      case isTaskProject:
        return t('Task Management');
      default:
        return t('Project Scope Management');
    }
  }

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: getLastTitleBreadcrumb() }
      ]} />
      <PageContainer>
        <PageHeader
          title={t('⁠Project Mng Information')}
          menu={[{ label: t('Project Info'), selected: true }]}
        />
        <ComponentContainer>
          <ComponentHeader title={t('PMO: Search Project Mng')} menu={[{ label: t('Project Mng List'), selected: true }]} />
          {
            showInputs && (
              <div style={{ display: 'flex', paddingTop: 7, flexWrap: 'wrap' }}>
                <Input containerStyle={{ marginRight: 12 }} label={t('Project Id')} value={projectId} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setProjectId(ev.target.value)} />
                
                <Input containerStyle={{ marginRight: 12 }} label={t('Project Name')} value={projectName} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setProjectName(ev.target.value)} />

                {actualPhaseOptions && <Select containerStyle={{ marginRight: 12 }} label={t('Actual Phase')} value={actualPhase} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setActualPhase(ev.target.value)} options={actualPhaseOptions.map(v => ({ label: v.toString(), value: v.toString() }))} />}

                {programNameOptions && <Select containerStyle={{ marginRight: 12 }} label={t('Program Name')} value={programName} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setProgramName(ev.target.value)} options={programNameOptions.map(v => ({ label: v.programme, value: v.programmeId }))} />}


                {businessAreaOptions && <Select containerStyle={{ marginRight: 12 }} label={t('Business Area')} value={businessArea} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setBusinessArea(ev.target.value)} options={businessAreaOptions.map(v => ({ label: v.name, value: v.businessAreaId }))} />}

                {businessDriverOptions && <Select containerStyle={{ marginRight: 12 }} label={t('Bussines Driver')} value={businessDriver} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setBusinessDriver(ev.target.value)} options={businessDriverOptions.map(v => ({ label: v.businessDriver, value: v.businessDriverId }))}/>}
              </div>
            )
          }

          <S.Container>
            {
              isLoading ? <SpinLoading /> : !list ? (
                <SpinLoading />
              ) : (
                <Table style={{ marginTop: 12 }}>
                  <Table.Thead>
                    <Table.Tr header>
                      <Table.Td>{t('Initiative Id')}</Table.Td>
                      <Table.Td>{t('Initiative Name')}</Table.Td>
                      <Table.Td>{t('Start Date')}</Table.Td>
                      <Table.Td>{t('End Date')}</Table.Td>
                      <Table.Td>{t('Business Area')}</Table.Td>
                      <Table.Td>{t('Actual Phase')}</Table.Td>
                      <Table.Td>{t('Business Driver')}</Table.Td>
                      <Table.Td>{t('Sponsor')}</Table.Td>
                      <Table.Td></Table.Td>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {
                      list && list.map((it, ind) => (
                        <Table.Tr style={{ cursor: 'pointer' }} key={ind}>
                          <Table.Td>{it.initiativeId}</Table.Td>
                          <Table.Td>{it.initiativeName}</Table.Td>
                          <Table.Td>{moment(it.startDate).format('YYYY-MM-DD')}</Table.Td>
                          <Table.Td>{moment(it.endDate).format('YYYY-MM-DD')}</Table.Td>
                          <Table.Td>{it.businessArea}</Table.Td>
                          <Table.Td>{it.actualPhase}</Table.Td>
                          <Table.Td>{it.businessDriver}</Table.Td>
                          <Table.Td>{it.sponsor}</Table.Td>
                          <Table.Td>
                          {isDigitalStatusReport ? (
                              <Dropdown>
                              <Dropdown.Toggle style={{ padding: '5px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'none', border: 'none' }} variant="secondary" id="dropdown-basic">
                                  <ThreeDots style={{ color: '#4A4A4A', fontSize: '20px' }} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => navToDetail(Number(it.initiativeId), 1 )}>{t('View Status Report')}</Dropdown.Item>
                                  <Dropdown.Item onClick={() => navToDetail(Number(it.initiativeId), 0 )}>{t('Create Status Report')}</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> 
                            ) : isRitualProject ? (
                              <Dropdown>
                                <Dropdown.Toggle style={{ padding: '5px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'none', border: 'none' }} variant="secondary" id="dropdown-basic">
                                  <ThreeDots style={{ color: '#4A4A4A', fontSize: '20px' }} />
                                </Dropdown.Toggle>
  
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => navToDetail(Number(it.initiativeId), 1 )}>{t('View Ritual Project')}</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ): (
                              <IoIosLink
                                className="standard-data-action"
                                size={16}
                                onClick={() => navToDetail(Number(it.initiativeId), 1)}
                              />                       
                            )}                          
                          </Table.Td>
                        </Table.Tr>
                      ))
                    }
                  </Table.Tbody>
                </Table>
              )
            }
          </S.Container>
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default PMO_ProjectScopeMng;

import styled from 'styled-components';

export const ContainerForms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 200px;
  margin-bottom: 10px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  margin: 10px;
`;

export const ErrorMessage = styled.span`
  position: absolute;
  bottom: -20px;
  left: 3px;
  color: red;
  font-size: 12px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

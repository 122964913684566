import { Breadcrumb, SpinLoading, Table, PageContainer } from "shared/components";
import { IoGrid, IoPencil } from 'react-icons/io5';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { PageHeader } from "shared/components";
import { Accordion } from "react-bootstrap";
import { IoIosLink } from "react-icons/io";
import { Typography } from "antd";
import { getListByProgram, GetListByProgramResponse } from "bto_now/api";
import { useEffect, useState } from "react";
import { getUser } from "services";
import { debounce } from 'utils';
import moment from 'moment';
import './styles.css';
import * as S from './styles';

export default function ProgramByList() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [list, setList] = useState<GetListByProgramResponse>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      getListByProgram({ user: getUser(), portfolioId: 1 })
        .then(({ data }) => {
          setList(data)
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false));
    }, 500)();
  }, []);

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: 'Portfolio Mng' },
      ]} />
      <PageContainer>
        <PageHeader
          title='Portfolio Mng'
          menu={[
            { label: t('GANTT'), selected: false, onClick: () => navigate('/dashboard/mng/portfolio/gantt') },
            { label: t('LIST'), selected: true, onClick: () => navigate('./') }
          ]}
        />
        <S.ComponentContainer>
          <S.ComponentHeader><p>{t('Visão da lista')}</p></S.ComponentHeader>
          
          {
            isLoading ? <SpinLoading /> : !list ? (
              <SpinLoading />
            ) : (
              <Accordion className="custom-accordion">
                {list?.map(program => (
                  <Accordion.Item key={program.programId} eventKey={String(program.programId)} className="custom-accordion-item">
                    <Accordion.Header className="custom-accordion-header">
                      <div className="custom-accordion-header-content">
                        <Link to={`/dashboard/initiative/${program.programId}`} onClick={(e) => e.stopPropagation()} style={{ marginRight: 15 }}>
                          <Typography style={{ width: '20px' }} className="standard-data-title item center">
                            <IoIosLink className="standard-data-title-action" size={22} />
                          </Typography>
                        </Link>
                        <span className="program-name">{program.program}</span>
                        <div className="program-details">
                          <div className="program-detail-item">
                            <strong>Budget Plan:</strong>
                            <span>{"R$ 760.210"}</span>
                          </div>
                          <div className="program-detail-item">
                            <strong>Total Forecast:</strong>
                            <span>{"R$ 680.070"}</span>
                          </div>
                          <div className="program-detail-item">
                            <strong>Real Budget:</strong>
                            <span>{"R$ 760.210"}</span>
                          </div>
                          <div className="program-detail-item">
                            <strong>Start Date:</strong>
                            <span>{moment("2024-01-02").format('YYYY-MM-DD')}</span>
                          </div>
                          <div className="program-detail-item">
                            <strong>End Date:</strong>
                            <span>{moment("2025-01-01").format('YYYY-MM-DD')}</span>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body className="custom-accordion-body">
                      <Table className="custom-table">
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Td></Table.Td>
                            <Table.Td></Table.Td>
                            <Table.Td>Nome do Projeto</Table.Td>
                            <Table.Td>ID do Projeto</Table.Td>
                            <Table.Td>Status Prazo</Table.Td>
                            <Table.Td>Status do Custo</Table.Td>
                            <Table.Td>Nome do Programa</Table.Td>
                            <Table.Td>Patrocinador</Table.Td>
                            <Table.Td>Parceiro de Negócio de TI</Table.Td>
                            <Table.Td>Área de Negócio</Table.Td>
                            <Table.Td>Data de Início</Table.Td>
                            <Table.Td>Data de Implementação</Table.Td>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          {program.projects.map(project => (
                            <Table.Tr key={project.projectId}>
                              <Table.Td>
                                <Link to={`#`}>
                                  <Typography style={{ width: '20px' }}><IoIosLink className="standard-data-action" /></Typography>
                                </Link>
                              </Table.Td>
                              <Table.Td>
                                <Link to={`#`}>
                                  <Typography style={{ width: '20px' }}><IoPencil className="standard-data-action" /></Typography>
                                </Link>
                              </Table.Td>
                              <Table.Td className="custom-td">{project.name}</Table.Td>
                              <Table.Td className="custom-td">{project.projectId}</Table.Td>
                              <Table.Td className="custom-td">statusPrazo</Table.Td>
                              <Table.Td className="custom-td">statusCusto</Table.Td>
                              <Table.Td className="custom-td">{project.program}</Table.Td>
                              <Table.Td className="custom-td">{project.owner}</Table.Td>
                              <Table.Td className="custom-td">businessPartner</Table.Td>
                              <Table.Td className="custom-td">{project.businessDriver}</Table.Td>
                              <Table.Td className="custom-td">{moment(project.startDate).format('YYYY-MM-DD')}</Table.Td>
                              <Table.Td className="custom-td">{moment(project.endDate).format('YYYY-MM-DD')}</Table.Td>
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )
          }
        </S.ComponentContainer>
      </PageContainer>
    </>
  )
}

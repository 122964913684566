import { Typography } from 'shared/components';
import styled from 'styled-components';

export const AppButtonsContainer = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
`

export const AppButtonsTitle = styled(Typography).attrs({
  size: 'medium',
  weight: 'bold',
})`
  padding-bottom: ${p => p.theme.spacing.xs};
  /* margin-bottom: 24px; */
  margin-top: 24px;
`;

export const ButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: ${p => p.theme.spacing.md}; */
  /* border-radius: ${p => p.theme.border.radius.default}px; */
  /* background: ${p => p.theme.colors.neutral.gray[200]}; */
  /* background: white; */
  /* box-shadow: ${p => p.theme.colors.blackBoxShadow}; */
  /* border-radius: ${p => p.theme.border.radius.sm}; */
  /* margin-bottom: ${p => p.theme.spacing.md}; */
`;

import { Breadcrumb, ComponentContainer } from "shared/components";
import { IoGrid } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PageHeader from "shared/components/PageHeader";
import { PageContainer } from "shared/components";
import NewProjectHeader from "shared/layouts/NewProjectHeader";
import { useStatusModalStore } from "shared/store-zustand/create-project/getStatusModal";
import { useEffect } from "react";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

const PMO_NewRitualProject = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { statusModal, setStatusModal } = useStatusModalStore();
  const { dataProject, clearDataProject } = useCreateProject();

  const navigate = useNavigate();

  const baseRitualUrl = `/dashboard/mng/pmo/project-ritual-mng/ritual/${id}`;

  const menus = {
    scope: {
      label: t('Scope'),
      url: `${baseRitualUrl}/scope`,
    },
    timeline: {
      label: t('Timeline'),
      url: `${baseRitualUrl}/timeline`,
    },
    risks: {
      label: t('Risks'),
      url: `${baseRitualUrl}/risks`,
    },
    issues: {
      label: t('Issues'),
      url: `${baseRitualUrl}/issues`,
    },
    financial: {
      label: t('Financial'),
      url: `${baseRitualUrl}/financial`,
    },
  }

  useEffect(() => {
    return () => {
      setStatusModal(true);
      clearDataProject();
    };
  }, []);

  const isScope = pathname.startsWith('/dashboard/mng/pmo/project-ritual-mng/ritual') && pathname.endsWith('/scope');
  const isTimeline = pathname.startsWith('/dashboard/mng/pmo/project-ritual-mng/ritual') && pathname.endsWith('/timeline');
  const isRisks = pathname.startsWith('/dashboard/mng/pmo/project-ritual-mng/ritual') && pathname.endsWith('/risks');
  const isIssues = pathname.startsWith('/dashboard/mng/pmo/project-ritual-mng/ritual') && pathname.endsWith('/issues');
  const isFinancial = pathname.startsWith('/dashboard/mng/pmo/project-ritual-mng/ritual') && pathname.endsWith('/financial');

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: t('Ritual Project Management') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t("Ritual Project")}
          menu={[
            { label: menus.scope.label, selected: isScope, onClick: () => navigate(menus.scope.url) },
            { label: menus.timeline.label, selected: isTimeline, onClick: () => navigate(menus.timeline.url) },
            { label: menus.risks.label, selected: isRisks, onClick: () => navigate(menus.risks.url) },
            { label: menus.issues.label, selected: isIssues, onClick: () => navigate(menus.issues.url) },
            { label: menus.financial.label, selected: isFinancial, onClick: () => navigate(menus.financial.url) },
          ]}
        />

     
        <NewProjectHeader 
          style={{ marginBottom: '1rem'}} 
        />

        <ComponentContainer>
          <Outlet />
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default PMO_NewRitualProject;
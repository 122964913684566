import { Modal } from "antd";
import React, { useEffect, useRef, useState } from 'react';
import { IoGrid } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ProjectHeader } from 'shared/layouts';
import { getToken, getUser, POST_API } from "services";
import { ModalError, ModalSuccess, Breadcrumb, ComponentContainer, PageContainer } from 'shared/components';
import { ButtonRef } from "shared/components/Button";
import { getProject, GetProjectResponse, newProject, saveProject, SaveProjectPayload } from 'bto_now/api';
import { buildNavigateString, date, dateBrToISO, isValidDate } from "utils";
import PageHeader from "shared/components/PageHeader";
import { ComponentHeader } from "shared/components";
import { useTranslation } from "react-i18next";


const CreateProject: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { projectId } = useParams();

  const saveButtonRef = useRef<ButtonRef>(null);
  const [modalError, setModalError] = useState<string>('');
  const [modalSuccess, setModalSuccess] = useState<string>('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [project, setProject] = useState<GetProjectResponse>();

  const [_projectId, set_ProjectId] = useState('');
  const [programmeId, setProgrammeId] = useState(0);

  const [blocks, setBlocks] = useState<any>([]);

  useEffect(fetchApi, []);

  function loadDetails() {
    POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: projectId }).then(rs => rs.json()).then(res => {
      if (res.return) {
        setBlocks(res.data)
      } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
    }).catch(() => null)
  }

  function fetchApi() {
    loadDetails();
    if (projectId !== 'new') {
      getProject({ User: getUser(), ProjectId: projectId as string }).then(({ data }) => {
        setProject({
          ...data,
          timeline: data.timeline.map(v => ({ ...v, startDate: date(v.startDate), endDate: date(v.endDate) })),
        });
      });
    }
    else if (projectId === 'new') {
      newProject({ User: getUser() }).then(({ data }) => {
        console.log(data)
        setProject({
          ...data,
        });
      });
    }
  }

  function areFinancialFieldsValid(): boolean | undefined {
    return project?.financial.projectFinancial.some(item =>
      item.totalForecast > 0 &&
      item.totalCapex > 0 &&
      item.totalOpex > 0
    );
  }

  function saveButtonRefCallback() {
    console.log("Project data before saving:", project);
    if (!project?.businessDriverId ||
      !project?.programmeId ||
      !project?.initiativeName ||
      !project?.typeId ||
      !project?.kanbanId ||
      !project?.actualStatus
    ){
      setModalError("Error while trying to create a new project.\n Check the mandatory fields!");
      return;
    }

    // Verifica se pelo menos um par de startDate e endDate está preenchido
    const hasValidDates = project.timeline.some(
      (tm) => isValidDate(tm.startDate) && isValidDate(tm.endDate)
    );

    const hasInvalidDateRange = project.timeline.some(
      (tm) => new Date(tm.startDate) > new Date(tm.endDate)
    );
    
    // if (hasInvalidDateRange) {
    //   setModalError("Start Date cannot be greater than End Date in any timeline entry.");
    //   return;
    // }

    // if (!hasValidDates) {
    //   setModalError("Please ensure at least one timeline entry has both start and end dates filled.");
    //   return;
    // }
  
    // Verifica se os campos financeiros são válidos
    // if (!areFinancialFieldsValid()) {
    //   setModalError("Please ensure all financial fields (Forecast Budget, Capex Plan, Opex Plan) are filled in.");
    //   return;
    // }

    setSaveLoading(true);

    const saveProjectPayload: SaveProjectPayload = {
      ...project,
      projectId: project.projectId ? project.projectId : "",
      projectManager: project.projectManager ? project.projectManager : "",
      initiativeId: project.initiativeId ? project.initiativeId : "",
      mainInitiative: project.mainInitiative ? project.mainInitiative : "",
      providers: project.providers ? project.providers : "",
      initiativeType: project.initiativeType ? project.initiativeType : "",
      name: project.initiativeName ? project.initiativeName : "",
      summary: project.summary ? project.summary : "",
      typeId: project.typeId ? project.typeId : 0,
      sponsor: project.sponsor ? project.sponsor : "",
      businessLeader: project.businessLeader ? project.businessLeader : "",
      itPartnerLeader: project.itPartnerLeader ? project.itPartnerLeader : "",
      itBusinessPatner: project.itBusinessPatner ? project.itBusinessPatner : "",
      keyUser: project.keyUser ? project.keyUser : "",
      businessArea: project.businessArea ? project.businessArea : "",
      businessBenefits: project.businessBenefits ? project.businessBenefits : "",
      techonologyBenefits: project.techonologyBenefits ? project.techonologyBenefits : "",
      plataformEnvolved: project.plataformEnvolved ? project.plataformEnvolved : "",
      businessProcessEnvolved: project.businessProcessEnvolved ? project.businessProcessEnvolved : "",
      lastRitualDate: project.lastRitualDate ? project.lastRitualDate : new Date().toJSON(),
      actualPhase: project.actualPhase ? project.actualPhase : 0,
      user: getUser(),
      timeline: project.timeline
        .filter(v => isValidDate(v.startDate) && isValidDate(v.endDate))
        .map(tm => ({
          ...tm,
          user: getUser(),
          dateType: 'P',
          startDate: dateBrToISO(tm.startDate),
          endDate: dateBrToISO(tm.endDate),
          progressPhaseReal: tm.progressPhaseReal || 0
        })),
      financial: {
        projectFinancial: project.financial?.projectFinancial.map(fn => ({
          ...fn,
          user: getUser(),
          finacialType: 'P',
          totalForecast: fn.totalForecast || 0,
        })),
        projectFinancialGroup: project.financial?.projectFinancialGroup.map(fn => ({
          ...fn,
          user: getUser(),
          finacialType: 'P',
        }))
      },
    }

    saveProject(saveProjectPayload).then(({ data }) => {
      if (data.sucesso) {
        set_ProjectId(data.project.projectId);
        setProgrammeId(data.project.programmeId);

        if (projectId === 'new') setModalSuccess('Project created successfully');
        else setModalSuccess('Project updated successfully');
        setTimeout(fetchApi, 1000);
      }
      else setModalError("Error while trying to create a new project. Please try again");
    })
      .finally(() => { setSaveLoading(false); });
  }

  function onOkModalSuccess() {
    const url = buildNavigateString({
      path: `/dashboard/initiative/${programmeId}/${_projectId}`,
    });

    navigate(url);
    // setModalSuccess('');
  }

  const allMenus: { [key: string]: { label: string; url: string } } = {
    g: {
      label: t('General'),
      url: `/dashboard/mng-project/${projectId}/general`,
    },
    o: {
      label: t('Timeline'),
      url: `/dashboard/mng-project/${projectId}/operational`,
    },
    f: {
      label: t('Financial'),
      url: `/dashboard/mng-project/${projectId}/financial`,
    },
  };

  const menuItemsNewProject = ['g', 'o', 'f'].map(key => ({
    label: allMenus[key].label,
    selected: pathname === allMenus[key].url,
    onClick: () => navigate(allMenus[key].url),
  }));

  const menuItemsModifyProject = ['g'].map(key => ({
    label: allMenus[key].label,
    selected: pathname === allMenus[key].url,
    onClick: () => navigate(allMenus[key].url),
  }));


  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: t('Project Mng') }
      ]} />
      <PageContainer>
        <PageHeader
          title={projectId === 'new' ? "Create Project" : "Modify Project"}
          menu={projectId === 'new' ? menuItemsNewProject : menuItemsModifyProject}
        />
        <ComponentContainer>
          <ComponentHeader
            title={t('Project Mng')}
            buttons={[
              { label: t('Finish'), onClick: saveButtonRefCallback },
            ]}
            menu={[
              { label: t('Info'), selected: true }
            ]}
          />
          <div style={{ display: 'flex', paddingTop: 7, flexWrap: 'wrap', margin: 0 }}>
          </div>
          {
            projectId !== 'new' && (
              <ProjectHeader
                style={{ marginTop: 12 }}
                projectName={blocks.initiativeName}
                projectId={blocks.initiativeId}
                startDate={blocks.startDate_format}
                goLiveDate={blocks.goLiveDate_format}
                actualStatus={blocks.actualStatus}
                sponsor={blocks.sponsor}
                itBusinessPartner={blocks.itBusinessPatner}
                projectManager={blocks.projectManager}
                providerSolution={blocks.providers}
              />
            )
          }
          <Outlet context={{
            saveButtonRef,
            project,
            setProject,
          }} />
        </ComponentContainer>
        <ModalError
          title="Error!"
          description={modalError}
          open={modalError.length > 0}
          onOk={() => setModalError('')}
        />
        <ModalSuccess
          title="Success!"
          description={modalSuccess}
          open={modalSuccess.length > 0}
          onOk={onOkModalSuccess}
        />
      </PageContainer>
    </>
  );
}

export default CreateProject;

import { Typography } from 'shared/components';
import * as S from './styles';

const variants = (value: number) => {
  if (value === 0) return 'gray';
  if (value === 1) return 'danger';
  if (value === 2) return 'warning';
  if (value === 3) return 'success';
  return 'gray';
};

const dadosMocados = [
  {
    id: 1,
    title: 'Start',
    data: '05 Apr 2024',
    value: 3,
  },
  {
    id: 2,
    title: 'Project Plan',
    data: '05 Apr 2024',
    value: 2,
  },
  {
    id: 3,
    title: 'Discovery',
    data: '05 Apr 2024',
  },
  {
    id: 4,
    title: 'Build',
    data: '05 Apr 2024',
  },
  {
    id: 5,
    title: 'Test',
    data: '05 Apr 2024',
  },
  {
    id: 6,
    title: 'Go-Live',
    data: '05 Apr 2024',
  },
]

const TrackingMonitor = () => {
  return (
    <>
      <S.Row>
        <S.Container>
          <S.Line></S.Line>
          {dadosMocados.map((item) => (
            <S.ItemContainer style={{ paddingTop: item.id === 1 ? 0 : 30 }} key={item.id}>
              <S.Col colorCircle={variants(item.value || 0)}></S.Col>
            </S.ItemContainer>
          ))}
        </S.Container>
        <S.Container>
          {dadosMocados.map((item) => (
            <>
              <Typography style={{ paddingLeft: '5px', fontSize: '13px', fontWeight: '500', paddingTop: item.id === 1 ? 0 : 6 }}>{item.title}</Typography>
              <Typography style={{ color: '#c7c7cc', paddingLeft: '5px', fontSize: '11px', fontWeight: '500' }}>{item.data}</Typography>
            </>
          ))}
        </S.Container>
      </S.Row>
    </>
  )
}

export default TrackingMonitor;
import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface CreateRiskPayload {
  riskId:               number;
  companyId:            number;
  projectId:            string;
  name:                 string;
  description:          string;
  type:                 string;
  owner:                string;
  statusMitigation:     string;
  probabilityScale:     string;
  impactScale:          string;
  historicalMonitoring: string;
  lastUpdate:           string;
  user:                 string;
}

export interface CreateRiskResponse {
  sucesso:      boolean;
  mensagemErro: string;
}

export function createRisk(params: CreateRiskPayload): Promise<AxiosResponse<CreateRiskResponse>> {
  return btoApi
    .post('/api/ProjectRisk/CreateRisk', params);
};

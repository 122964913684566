import { Col, Row } from "antd";
import GraphComponent from "shared/components/Graph";
import KPIComponent from "shared/components/KPI";
import { useTranslation } from 'react-i18next';
import MockData from '../Mock/MockData';
import GraphBar from "shared/components/GraphBar";
import { moneyMask } from "utils/moneyMask";
import * as S from './styles';


const TotalInvestmentsTest = () => {
    const { t } = useTranslation();
    const { TotalInvestmentsData, TotalInvestmentsMonthData } = MockData;

    return (
        <S.Row gutter={12}>
            <S.Col span={4}>
                <KPIComponent title={t("Total Budget")} value={'R$' + moneyMask(TotalInvestmentsData.totalBudget)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Forecast")} value={'R$' + moneyMask(TotalInvestmentsData.forecast)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Consumed")} value={'R$' + moneyMask(TotalInvestmentsData.consumed)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Available")} value={'R$' + moneyMask(TotalInvestmentsData.available)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Extra Budget")} value={'R$' + moneyMask(TotalInvestmentsData.extraBudget)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Total Investments")} value={'R$' +  moneyMask(TotalInvestmentsData.totalInvestiment)} type="money" />
            </S.Col>
            <S.Col span={24} style={{ height: 252, marginTop: 12}}>
                <GraphComponent title={t('Total Investments Realized')}>
                    <GraphBar graphType={'GRAPH01'} dataAxis={TotalInvestmentsData.dataAxis} dataValue={TotalInvestmentsData.dataValue} />
                </GraphComponent>
            </S.Col>
            <S.Col span={24}>
                <GraphComponent title={t('Total Realized By Phases')}>
                    <GraphBar graphType={'GRAPH01'} dataAxis={TotalInvestmentsMonthData.dataAxis} dataValue={TotalInvestmentsMonthData.dataValue} />
                </GraphComponent>
            </S.Col>
        </S.Row>
    )
}

export default TotalInvestmentsTest;

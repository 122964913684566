import Typography from 'shared/components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* min-height: 70px; */
  background: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #A6B1C5;
  padding-bottom: 12px;
`;

export const ContainerChildren = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const MenuItem = styled(Typography).attrs({
  size: 'small',
})<{ selected: boolean }>`
  border-bottom: 1px solid ${p => p.selected ? p.theme.colors.primary['blue'] : 'transparent'};
  padding: 4px 0px;
  margin: 0px 8px;
  width: fit-content;
  cursor: pointer;
  text-transform: uppercase;
  color: ${p => p.selected ? p.theme.colors.primary['blue'] : p.theme.colors.text};
`;

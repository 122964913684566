import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListScopeTypePayload {
  User?: string | null;
}

export interface GetListScopeTypeResponse {
  scopeTypeId: number;
  scopeType: string;
  user: string;
}

export function getListScopeType (params: GetListScopeTypePayload): Promise<AxiosResponse<GetListScopeTypeResponse[]>> {
  return btoApi.get('/api/ScopeType/GetList', { params });
}
import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Typography } from 'shared/components';
import { moneyMask } from 'utils/moneyMask';

type Custo = {
  title: string,
  value?: number,
  loading: boolean,
  suffix: string,
  prefix: string,
  valueClassName: string,
  badgeBg: string,
  badgeText: string,
};

type CustoComponentProps = {
  dadosCusto: Custo;
}

const CustoComponent = ({ dadosCusto }: CustoComponentProps) => {
  const { t } = useTranslation();

  return (
    <Card className={classNames(dadosCusto.valueClassName, 'overflow-hidden')} style={{ paddingRight: 0, maxHeight: 130, marginTop: 20, minWidth: 280 }} >
      <Card.Body className="position-relative" style={{ minHeight: '130px' }}>
        <Typography style={{ borderBottom: '1px solid', fontSize: '14px', fontWeight: 600, paddingBottom: 10 }}>{t(dadosCusto.title)}</Typography>
        <div
          className={classNames(
            'display-4 fs-3 fw-normal font-sans-serif'
          )}
          style={{ padding: '8px 0' }}
        >
          <span style={{ fontSize: '25px' }}>
            {dadosCusto.prefix}{moneyMask(dadosCusto?.value ?? 0)}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustoComponent;

import Typography from 'shared/components/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px;
  width: 100%;
  min-height: 100px;
  background: white;
  border-radius: ${p => p.theme.border.radius.default};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  padding: ${p => p.theme.spacing.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing.sm};
`;

export const MenuItem = styled(Typography).attrs({
  size: 'small',
})<{ selected: boolean }>`
  border-bottom: 1px solid ${p => p.selected ? p.theme.colors.primary['blue'] : 'transparent'};
  padding: 4px 0px;
  margin: 0px 8px;
  width: fit-content;
  cursor: pointer;
  text-transform: uppercase;
  color: ${p => p.selected ? p.theme.colors.primary['blue'] : p.theme.colors.text};
`;

import  { components } from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import styles from './DropdownIndicator.module.css'

const DropdownIndicator = (props: any) => {
  const { menuIsOpen } = props;
 
  return (
    <components.DropdownIndicator {...props}>
      <FaChevronDown
        className={`${styles.icon} ${menuIsOpen ? styles.open : ''}`}
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
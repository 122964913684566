import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './bto_now/routes';
import { PrimeReactProvider } from 'primereact/api';
import './shared/assets/css/antd.css';
import './shared/assets/css/form.css';
import './index.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import './shared/assets/css/dashboard.css';
import './shared/i18n'

import { ThemeProvider } from 'styled-components';
import { lightTheme } from './themes/lightTheme'
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PrimeReactProvider>
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter>

        <App /> {/** Codigo da fabrica */}


      </BrowserRouter>
    </ThemeProvider>
  </PrimeReactProvider>
);

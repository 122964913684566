import { PageHeader } from 'shared/layouts';
import { Breadcrumb, PageContainer, ComponentContainer, ComponentHeader } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as S from './styles';


const Help = () => {
    const { t } = useTranslation();

    const iframeStyle: React.CSSProperties = {
        border: '0',
        borderRadius: '8px',
    };

    return (
        <>
            <Breadcrumb separator=">" items={[
                { title: <IoGrid size={20} /> },
                { title: <Link to="/dashboard/mng">Home</Link> },
                { title: <Link to="/dashboard/help">{t('Help')}</Link> },
            ]} />
            <PageContainer>
                <PageHeader 
                    title='⁠Help'
                    navList={[
                        {label: 'Form', value: '.' },
                        {label: 'requests', value: 'request' }
                    ]}
                    containerStyle={{ position: 'sticky', left: 0, marginBottom: 10 }}
                />
                <ComponentContainer>
                    <ComponentHeader title='Help' menu={[{ label: 'Info', selected: true }]} />

                    <S.Container>
                       <iframe src="
                        https://forms.monday.com/forms/embed/14f60ec9dce53173753e35fd6de380d7?r=use1"
                        width="100%" height="1500" style={iframeStyle}>
                       </iframe>
                    </S.Container>
                </ComponentContainer>
            </PageContainer>
         </>
    );
}

export default Help;

import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetListByProgramPayload {
  user: string;
  portfolioId: number;
}

export type GetListByProgramResponse = Array<{
  programId: number,
  program: string,
  projects: [
    {
      companyId: number,
      portfolioId: number,
      programId: number,
      program: string,
      projectId: number,
      name: string,
      summary: string,
      projectTypeId: number,
      projectType: string,
      projectStatusId: number,
      projectStatus: string,
      managerId: number,
      manager: string,
      ownerId: number,
      owner: string,
      businessDriverId: number,
      businessDriver: string,
      startDate: string,
      endDate: string
    }
  ]
}>;

export function getListByProgram(payload: GetListByProgramPayload): Promise<AxiosResponse<GetListByProgramResponse>> {
  return btoApi
    .get(`/api/Project/GetListByProgram`, {params: payload});
}
import styled from 'styled-components';
import { FaSearch } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 40px;
  width: 120px
`;

export const Icon = styled(FaSearch).attrs(p => ({
  color: p.theme.colors.neutral.gray[700],
}))`
  position: absolute;
  right: 10px;
  top: 16px;
`;


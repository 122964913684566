import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { TFunction } from "i18next";
import { message } from "antd";
import * as yup from "yup";

import { Select, Table, ComponentHeader, Button, Input, ButtonCalendarInput } from "shared/components";
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';
import { Owner } from "shared/store-zustand/create-project/getFieldList";

import { useListTimeline } from "shared/store-zustand/create-project/getListTimeline";
import { getUser } from "services";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { CreateTimelineProps, FormDataTimeline, QueryTimelinePayload, useCreateTimeline } from "shared/store-zustand/create-project/createTimeline";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import { formatDateBeforeSendToBack } from "utils/dates/formatDateBeforeSendToBack";
import { areDatesSequential } from "utils/dates/areDatesSequential";

import * as S from './styles';

interface FormData {
  phases: {
    planStartDate: string;
    planEndDate: string;
    phaseOwner: string;
    phaseMilestone: string;
  }[];
}

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  phases: yup.array().of(
    yup.object({
      planStartDate: yup.string()
        .required(t('This field is required.')),
      planEndDate: yup.string()
        .required(t('This field is required.')),
      phaseOwner: yup.string()
        .required(t('This field is required.')),
      phaseMilestone: yup.string()
        .required(t('This field is required.')),  
    })
  ).required()
}).required();

const milestone = [
  { id: 1, label: 'Option 1' },
  { id: 2, label: 'Option 2' },
  { id: 3, label: 'Option 3' }
]

const TimelineProject = () => {
  const { dataProject } = useCreateProject();
  const { timelinePhases } = useListTimeline();
  const { createTimeline, getTimelineData, timelineData} = useCreateTimeline();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string[]>(Array(timelinePhases.length).fill(''));
  const [endDate, setEndDate] = useState<string[]>(Array(timelinePhases.length).fill(''));
  const [phasePlanDays, setPhasePlanDays] = useState<number[]>(Array(timelinePhases.length).fill(0));

  const { register, handleSubmit, control, setValue, formState: { errors },     clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      phases: timelinePhases.map((phase: any, index: number) => ({
        planStartDate: '',
        planEndDate: '',
        phasePlanDays: 0,
        phaseOwner: '',
        phaseMilestone: '',
      }))
    }
  });

  const { fields } = useFieldArray({
    control,
    name: 'phases',
  });

  useEffect(() => {
    const newPhasePlanDays = timelineData.map((timeline: FormDataTimeline) => {
      const startDate = timeline.planStartDate
      const dateEnd = timeline.planEndDate;
      return calculateDateDiffInDays(startDate, dateEnd);
    });

    setPhasePlanDays(newPhasePlanDays);
  }, [timelineData])

  const CreateTimelineDatabase = async (data: FormData) => {
    try {
      const user = getUser();

      const query: QueryTimelinePayload = { user: user ?? null };

      const newItems: CreateTimelineProps[] = data.phases.map((timeline) => ({
        portfolioId: 1,
        programId: dataProject.programId!,  
        projectId: dataProject.projectId!,  
        startDate: formatDateBeforeSendToBack(timeline.planStartDate),
        endDate: formatDateBeforeSendToBack(timeline.planEndDate),
        owner: Number(timeline.phaseOwner),
      }));

      const datesAreSequential = areDatesSequential(data.phases, 'planStartDate', 'planEndDate')
      
      if (!datesAreSequential) return message.warning(t('Dates must to be sequential'));
  
      const response = await createTimeline(query, newItems);

      if (response.status === 200) {
        getTimelineData(data.phases)
      }
  
    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    CreateTimelineDatabase(data)
    navigate('/dashboard/mng/pmo/new-create-project/scope')
  };

  const handleInputStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);

    setStartDate(prevState => {
      const newStartDate = [...prevState];
      newStartDate[index] = value;
      return newStartDate;
    });

    setValue(`phases.${index}.planStartDate`, value);

    calculatePhaseDays(value, endDate[index], index)

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.planStartDate`);
    }
  };

  const calculatePhaseDays = (startDate: string, endDate: string, index: number) => {
    const dateStart = startDate;
    const dateEnd =  endDate;
    const days = calculateDateDiffInDays(dateStart, dateEnd);
    setPhasePlanDays(prevState => {
      const newPhasePlanDays = [...prevState];
      newPhasePlanDays[index] = days;
      return newPhasePlanDays;
    });
  }

  const handleInputEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);

    setEndDate(prevState => {
      const newEndDate = [...prevState];
      newEndDate[index] = value;
      return newEndDate;
    });
    
    setValue(`phases.${index}.planEndDate`, value);

    calculatePhaseDays(startDate[index], value, index)

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.planEndDate`);
    }
  };

  return (
    <>
      <ComponentHeader title={t('PMO: Create Project Timeline')} menu={[{ label: t('Timeline'), selected: true }]} />

      <S.ContainerList>
        <Table>
          <Table.Tbody>
            {fields.map((item, index) => (
              <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={item.id}>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Input
                      label={t('Phase Name')}
                      name={`phases[${index}].phaseName`}
                      value={timelinePhases[index].phase}
                      index={index}
                      readOnly
                    />
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`phases.${index}.planStartDate`)}
                      placeholder={t('Plan Start Date') + ' *'}
                      maxDate={endDate[index]}
                      minDate={index > 0 && endDate[index - 1] ? endDate[index - 1] : '01.01.1900'}
                      value={timelineData[index]?.planStartDate}
                      onChange={handleInputStartDateChange}
                      index={index}
                      error={!!errors?.phases?.[index]?.planStartDate}
                    />
                    {errors?.phases?.[index]?.planStartDate && <S.ErrorMessage>{errors.phases[index].planStartDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`phases.${index}.planEndDate`)}
                      placeholder={t('Plan End Date') + ' *'}
                      minDate={startDate[index]}
                      maxDate={startDate[index + 1] || '31.12.2100'}
                      value={timelineData[index]?.planEndDate}
                      onChange={handleInputEndDateChange}
                      index={index}
                      error={!!errors?.phases?.[index]?.planEndDate}
                    />
                    {errors?.phases?.[index]?.planEndDate && <S.ErrorMessage>{errors.phases[index].planEndDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Input
                      label={t('Phase Plan Days') + '*'}
                      value={phasePlanDays[index] ? String(phasePlanDays[index]) : String(0)}
                      index={index}
                      maxWidth
                      readOnly
                    />
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Select
                      {...register(`phases.${index}.phaseOwner`)}
                      options={fieldListStatus.ownerList.map((option: Owner) => ({ label: t(option.name), value: option.ownerId.toString()}))}
                      label={t('Owner') + '*'}
                      value={timelineData[index]?.phaseOwner}
                      index={index}
                      error={!!errors?.phases?.[index]?.phaseOwner}
                    />
                    {errors?.phases?.[index]?.phaseOwner && <S.ErrorMessage>{errors.phases[index].phaseOwner.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Select
                      {...register(`phases.${index}.phaseMilestone`)}
                      options={milestone.map((milestone) => ({ label: t(milestone.label), value: milestone.id.toString()}))}
                      label={t('Milestone') + '*'}
                      // value={timelineData[index]?.phaseMilestone}
                      index={index}
                      error={!!errors?.phases?.[index]?.phaseMilestone}
                    />
                    {errors?.phases?.[index]?.phaseMilestone && <S.ErrorMessage>{errors.phases[index].phaseMilestone.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </S.ContainerList>

      <S.ButtonContainer>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainer>
    </>
  );
};

export default TimelineProject;




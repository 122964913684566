import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface CreateTaskManagementRequest {
  projectId: number;
  taskId: number;
  ritualNumber?: number;
  name: string;
  description: string;
  owner: string;
  taskStatusId: number;
  startDate: string| null;
  endDate: string | null;
  user: string | null;
}

export function createTaskManagement (params: CreateTaskManagementRequest): Promise<AxiosResponse<CreateTaskManagementRequest>> {
  return btoApi.post('/api/TaskManagement/Create', params);
}
import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetAllInitiativesPayload {
    projectId?: string;
    projectName?: string;
    programName?: string;
    businessArea?: string;
    actualPhase?: string;
    bussinesDriver?: string;
    user: string;
  }

  export type GetAllInitiativesResponse = Array<{
    companyId: number,
    projectGroup: string,
    initiativeType: string,
    mainInitiative: string,
    initiativeName: string,
    summary: string,
    initiativeId: string,
    sponsor: string,
    businessLeader: string,
    itPartnerLeader: string,
    itBusinessPatner: string,
    keyUser: string,
    businessArea: string,
    businessDriver: string,
    businessBenefits: string,
    techonologyBenefits: string,
    plataformEnvolved: string,
    businessProcessEnvolved: string,
    programme: string,
    programmeId: string,
    businessDriverId: number,
    projectId: string,
    name: string,
    typeId: number,
    type: string,
    startDate: string,
    startPhaseBuild: string | null,
    percentCompleteBuild: number,
    startPhaseUat: string | null,
    percentCompleteUat: number,
    startPhaseCutover: string | null,
    percentCompleteCutover: number,
    goLiveDate: string,
    endDate: string,
    actualPhase: string,
    actualPhaseDescription: string,
    progressPlaned: number,
    progressRealized: number,
    issuesQuantity: number,
    statusTime: number,
    statusCoast: number,
    kanbanId: number,
    kanban: string,
    statusRisk: number,
    statusIssue: number,
    statusScope: number,
    providers: string,
    totalRequested: number,
    totalInvestiment: number,
    totalInvestimentCapex: number,
    totalInvestimentOpex: number,
    commitedBudget: number,
    percentCommitedPlanned: number,
    totalRunningCoast: number,
    lastRitualDate: string,
    cpi: number | null,
    spi: number | null,
    extraBudget: number,
    forecast: number,
    consumed: number,
    available: number,
    financialProgress: number,
    operacionalProgress: number,
    operationalProgress: number,
    toWin: boolean,
    projectManager: string,
    actualStatus: number,
    actualStatusDescription: string,
    user: string | null,
    otherDetailsInfo: {
      detFinancial: Array<unknown>,
      risks: Array<unknown>,
      issues: Array<unknown>,
      impacts: Array<unknown>,
      curveS: Array<unknown>
    },
    timeline: Array<unknown>,
    financial: {
      projectFinancial: Array<unknown>,
      projectFinancialGroup: Array<unknown>
    }
  }>;
  
    // export function getAllInitiatives(body: GetAllInitiativesPayload): Promise<AxiosResponse<GetAllInitiativesResponse>> {
    //     return btoApi
    //     .get('/api/Initiative/GetAllInitiatives', body);
    // }
    export function getAllInitiatives(body: GetAllInitiativesPayload): Promise<AxiosResponse<GetAllInitiativesResponse>> {
        return btoApi
        .get(`/api/Initiative/GetAllInitiatives?user=${body.user}`, );
    }
import React, { useEffect, useState, forwardRef } from 'react';
import styles from './Input.module.css';
import classNames from 'classnames';
import * as S from './styles';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  type?: string;
  value?: string;
  name?: string;
  error?: boolean;
  maxWidth?: boolean;
  index?: number;
}

const Input = forwardRef<HTMLInputElement, Props>(({
  label,
  inputStyle,
  containerStyle,
  value = '',
  name,
  error: _error,
  readOnly = false,
  maxWidth = false,
  index = 0,
  ...props
}, ref) => {

  const [inputFocus, setInputFocus] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(_error);

  useEffect(() => {
    if (error !== _error) setError(_error);
  }, [_error]);

  useEffect(() => {
    if (inputValue !== value) setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputValue !== value && props.onChange) {
      props.onChange({
        target: {
          name: name,
          value: inputValue,
        }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [inputValue]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerMaxWidth]: maxWidth,
        [styles.containerError]: error,
        [styles.containerDisabled]: props.disabled,
      })}
      style={{ ...containerStyle }}
    >
      {label && (
        <label
          className={classNames(styles.containerLabel, {
            [styles.containerLabelFloat]: readOnly || inputFocus || (inputValue && inputValue.length > 0),
            [styles.containerLabelFloatIndexOdd]: index % 2 === 1,
            [styles.containerLabelFloatIndexPair]: index % 2 === 0
          })}
        >
          {label}
        </label>
      )}
      <S.Input
        {...props}
        ref={ref}
        style={inputStyle}
        value={inputValue}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setInputValue(value)}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        readOnly={readOnly}
      />
    </div>
  );
});

export default Input;
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';

export interface CreateProjectProps {
  portfolioId: number;
  programId: number;
  projectId?: number;  
  name: string;
  summary: string;
  projectTypeId: number;
  managerId: number;
  ownerId: number;
  user: string;
}

interface CreateProjectResponse {
  project: CreateProjectProps;
}

const initialProject: CreateProjectProps = {
  portfolioId: 0,
  programId: 0,
  projectId: 0,
  name: "",
  summary: "",
  projectTypeId: 1,
  managerId: 1,
  ownerId: 1,
  user: ""
};

interface CreateProjectStore {
  dataProject: CreateProjectProps;
  createProject: (params: CreateProjectProps) => void;
  clearDataProject: () => void;
}

export const useCreateProject = create<CreateProjectStore>((set) => ({
  dataProject: initialProject,
  createProject: async (params: CreateProjectProps): Promise<void> => {
    try {
      const response = await btoApi.post<CreateProjectResponse>('/api/Project/Create', params);

      set({ dataProject: response.data.project });

      if (response.status === 200) {
        console.log('Project created successfully:', response.data.project);
      } else {
        console.error('Error creating project:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to create project:', error);
    }
  },
  clearDataProject: () => set({ dataProject: initialProject })
}));

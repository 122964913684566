import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';
import * as yup from "yup";

import { Button, Input } from "shared/components";

import * as S from './styles';
import { TFunction } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { currencyMask, currencyToFloat } from "utils/currency";

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  totalBudget: yup.number(),
  budgetCapex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Capex or Opex is required'),
      function(value) {
        const { budgetOpex } = this.parent;
        return budgetOpex !== 0 || (budgetOpex === 0 && (value ?? 0) > 0);
      }
    ),
  budgetOpex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Opex or Capex is required'),
      function(value) {
        const { budgetCapex } = this.parent;
        return budgetCapex !== 0 || (budgetCapex === 0 && (value ?? 0) > 0);
      }
    ),
  totalConsumed: yup.number(),
  consumedCapex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Capex or Opex is required'),
      function(value) {
        const { consumedOpex } = this.parent;
        return consumedOpex !== 0 || (consumedOpex === 0 && (value ?? 0) > 0);
      }
    ),
  consumedOpex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Opex or Capex is required'),
      function(value) {
        const { consumedCapex } = this.parent;
        return consumedCapex !== 0 || (consumedCapex === 0 && (value ?? 0) > 0);
      }
    ),
  totalForecast: yup.number(),
  forecastCapex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Capex or Opex is required'),
      function(value) {
        const { forecastOpex } = this.parent;
        return forecastOpex !== 0 || (forecastOpex === 0 && (value ?? 0) > 0);
      }
    ),
  forecastOpex: yup.number()
    .typeError(t('Should be a number.'))
    .test(
      'is-required-when-capex-zero',
      t('Opex or Capex is required'),
      function(value) {
        const { forecastCapex } = this.parent;
        return forecastCapex !== 0 || (forecastCapex === 0 && (value ?? 0) > 0);
      }
    ),
})

interface ModalEditFinancialProps {
  editFinancialModal: boolean;
  setEditFinancialModal: (state: boolean) => void;
}

type FormSchema = yup.InferType<ReturnType<typeof schema>>

const ModalEditFinancial: React.FC<ModalEditFinancialProps> = (props) => {
  const { editFinancialModal, setEditFinancialModal } = props;
  const { t } = useTranslation();

  const { handleSubmit, watch, control, formState: { errors }, setValue } = useForm<FormSchema>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      totalBudget: 0,
      budgetCapex: 0,
      budgetOpex: 0,
      totalConsumed: 0,
      consumedCapex: 0,
      consumedOpex: 0,
      totalForecast: 0,
      forecastCapex: 0,
      forecastOpex: 0,
    },
    mode: 'onBlur'
  });

  const [creatingLoading, setCreatingLoading] = useState(false);

  const budgetCapex = watch('budgetCapex');
  const budgetOpex = watch('budgetOpex');
  const consumedCapex = watch('consumedCapex');
  const consumedOpex = watch('consumedOpex');
  const forecastCapex = watch('forecastCapex');
  const forecastOpex = watch('forecastOpex');

  useEffect(() => {
    setValue('totalBudget', (budgetCapex ?? 0) + (budgetOpex ?? 0));
  }, [budgetCapex, budgetOpex, setValue]);

  useEffect(() => {
    setValue('totalConsumed', (consumedCapex ?? 0) + (consumedOpex ?? 0));
  }, [consumedCapex, consumedOpex, setValue]);

  useEffect(() => {
    setValue('totalForecast', (forecastCapex ?? 0) + (forecastOpex ?? 0));
  }, [forecastCapex, forecastOpex, setValue]);

  const onSubmit = (data: FormSchema) => {
    console.log(data)
  }

  const hasErrorBudget = (!!errors.budgetCapex && !!errors.budgetOpex);
  const hasErrorConsumed = (!!errors.consumedCapex && !!errors.consumedOpex);
  const hasErrorForecast = (!!errors.forecastCapex && !!errors.forecastOpex);

  return (
    <Modal
      title={t(`Update Financial`)}
      open={editFinancialModal}
      onCancel={() => setEditFinancialModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setEditFinancialModal(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: 12 }}
            onClick={handleSubmit(onSubmit)}
            loading={creatingLoading}
          >
            {t("Save")}
          </Button>
        </div>
      }
    >
      <S.InputRow>
        <S.InputContainer>
          <Controller
            name='budgetCapex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Capex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.budgetCapex && !!errors?.budgetOpex}
                maxWidth
              />
            )}
          />
          {errors.budgetCapex && errors.budgetOpex && <S.ErrorMessage>{errors.budgetCapex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer>
          <Controller
            name='budgetOpex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Opex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.budgetOpex && !!errors?.budgetCapex}
                maxWidth
              />
            )}
          />
          {errors.budgetCapex && errors.budgetOpex && <S.ErrorMessage>{errors.budgetOpex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer hasError={hasErrorBudget}>
          <Controller
            name='totalBudget'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Total Budget') + ' *'}
                value={currencyMask(field.value)}
                error={!!errors?.totalBudget}
                readOnly
                maxWidth
              />
            )}
          />
        </S.InputContainer>
      </S.InputRow>
      <S.InputRow>
        <S.InputContainer>
          <Controller
            name='consumedCapex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Consumed Capex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.consumedCapex && !! errors?.consumedOpex}
                maxWidth
              />
            )}
          />
          {errors.consumedCapex && errors.consumedOpex && <S.ErrorMessage>{errors.consumedCapex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer>
          <Controller
            name='consumedOpex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Consumed Opex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.consumedOpex && !!errors?.consumedCapex}
                maxWidth
              />
            )}
          />
          {errors.consumedOpex && errors.consumedCapex && <S.ErrorMessage>{errors.consumedOpex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer hasError={hasErrorConsumed}>
          <Controller
            name='totalConsumed'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Total Consumed') + ' *'}
                value={currencyMask(field.value)}
                error={!!errors?.totalConsumed}
                readOnly
                maxWidth
              />
            )}
          />
        </S.InputContainer>
      </S.InputRow>
      <S.InputRow>
        <S.InputContainer>
          <Controller
            name='forecastCapex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Forecast Capex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.forecastCapex && !!errors?.forecastOpex}
                maxWidth
              />
            )}
          />
          {errors.forecastCapex && errors.forecastOpex && <S.ErrorMessage>{errors.forecastCapex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer>
          <Controller
            name='forecastOpex'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Forecast Opex') + ' *'}
                value={currencyMask(field.value)}
                onChange={(e) => field.onChange(currencyToFloat(e.target.value))}
                error={!!errors?.forecastOpex && !!errors?.forecastCapex}
                maxWidth
              />
            )}
          />
          {errors.forecastOpex && errors.forecastCapex && <S.ErrorMessage>{errors.forecastOpex.message}</S.ErrorMessage>}
        </S.InputContainer>
        <S.InputContainer hasError={hasErrorForecast}>
          <Controller
            name='totalForecast'
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('Total forecast') + ' *'}
                value={currencyMask(field.value)}
                error={!!errors?.totalForecast}
                readOnly
                maxWidth
              />
            )}
          />
        </S.InputContainer>
      </S.InputRow>
    </Modal>
  );
}

export default ModalEditFinancial;




import styled, { css } from 'styled-components';
import { Input as defaultInput } from 'shared/components';
import { InputHTMLAttributes } from 'react';

export const ContainerMain = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Input = styled(defaultInput)`
 
`;
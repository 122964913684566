import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';

export interface CreateScopeProps {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  description: string;
  ownerId: number;
  user: string;
}

interface CreateScopeStore {
  scopeStatus: boolean;
  createScope: (params: CreateScopeProps) => Promise<AxiosResponse | undefined>;
}

export const useCreateScope = create<CreateScopeStore>((set) => ({
  scopeStatus: false,
  createScope: async (params: CreateScopeProps): Promise<AxiosResponse | undefined> => {
    try {
      const response = await btoApi.post('api/ProjectScope/Create', params);

      if (response.status === 200) {
        console.log('Scope created successfully:', response);
        set({ scopeStatus: true });

        return response;
      } else {
        console.error('Error creating scope:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create scope:', error);
    }
  },
}));
import {create} from 'zustand';

interface FormState {
  description: string;
  benefits: string;
  setDescription: (description: string) => void;
  setBenefits: (benefits: string) => void;
}

export const useDescriptionAndBenefitsFormStore = create<FormState>((set) => ({
  description: '',
  benefits: '',
  setDescription: (description) => set({ description }),
  setBenefits: (benefits) => set({ benefits }),
}));


/**
 * Monta uma URL completa
 * @dev Pode ser adicionado parametros como entrada da função
 * @param {string} path path to navigate to
 * @param {Object} params url search parameters
 * @returns {Object} Built-in URL Object
 */
export function buildUrl({
  path,
  params
}: {
  path: string,
  params?: { [key: string]: string}
}) {
  const url = new URL(path, document.location.href);

  if(params) {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }

  return url;
}

/**
 * Uma função para montar uma string para ser parassa para o navigate do react-router-dom
 * @param {string} path path to navigate to
 * @param {Object} params url search parameters
 * @returns {string} Returns {path + query parameters} for pass to navigate of react-router-dom
 */
export function buildNavigateString({
  path,
  params
}: {
  path: string,
  params?: { [key: string]: string}
}) {
  const url = buildUrl({ path, params });
  return `${url.pathname}${url.search}`
}

/**
 * Pega um valor da url localizado nos searchParams, key é o nome da chave onde está o valor
 * @param {string} key what key to get on search parameters
 * @returns 
 */
export function getUrlSearchParams(key: string): string {
  const search = document.location.search;
  const searchParsed = new URLSearchParams(search);
  return searchParsed.get(key) || '';
}

import { Col, Row, Typography } from 'shared/components';
import * as S from "./styles";
import { useTranslation } from 'react-i18next';
import { RoundedText } from '../../styles';

interface DescriptionDivProps {
  program: string;
  status: string;
  budgetReal: string;
  budgetPlan: string;
  count: number;
  title: string;
  totalForecast: string;
}

const DescriptionDiv: React.FC<DescriptionDivProps> = ({ program, status, budgetReal, count, title, budgetPlan, totalForecast }) => {
  const { t } = useTranslation();
  return (
    <S.DescriptionContainer>
      <Col style={{ width: '100%' }}>
        <Row>
          <S.CategoryDiv title={title}>
            <S.Label size="small" weight="medium" title={program}>{program}</S.Label>
            <RoundedText>{count}</RoundedText>
          </S.CategoryDiv>
          {/* <S.Status status={status}>{t(`${status}`)}</S.Status> */}
        </Row>
        <Row style={{ justifyContent: 'flex-end', width: '100%', marginTop: 6 }}>
          <Typography size="small" weight="bold" style={{ whiteSpace: 'nowrap', minWidth: 210, justifyContent: 'space-between', marginTop: 12, background: '#ebebeb', padding: '4px 8px', borderRadius: 8 }}>{t("Budget Plan")}: <Typography size="small" weight="regular" style={{ marginLeft: 8 }}>{budgetPlan}</Typography></Typography>
          <Typography size="small" weight="bold" style={{ whiteSpace: 'nowrap', minWidth: 210, justifyContent: 'space-between', marginLeft: 12, marginTop: 12, background: '#ebebeb', padding: '4px 8px', borderRadius: 8 }}>{t("Forecast")}: <Typography size="small" weight="regular" style={{ marginLeft: 8 }}>{totalForecast}</Typography></Typography>
          <Typography size="small" weight="bold" style={{ whiteSpace: 'nowrap', minWidth: 210, justifyContent: 'space-between', marginLeft: 12, marginTop: 12, background: '#ebebeb', padding: '4px 8px', borderRadius: 8 }}>{t("Budget Real")}: <Typography size="small" weight="regular" style={{ marginLeft: 8 }}>{budgetReal}</Typography></Typography>
        </Row>
      </Col>
    </S.DescriptionContainer>
  );
};

export default DescriptionDiv

import styled from "styled-components";

interface TableHeaderCellProps {
  noBorderRight?: boolean;
}
interface backgroundColor {
  alt?: boolean;
}

export const tbody = styled.tbody`
  text-align: 'center'
`;

export const Section = styled.section`
 background-color: #ffffff;
 padding: 1rem;
 margin: 1rem 0;
 border-radius: ${p => p.theme.border.radius.sm};
 box-shadow: 0px 0px 5px ${p => p.theme.colors.neutral.gray[200]};
`;

export const TableContainer = styled.div`
  overflow-y: auto;
  border-radius: 5px;
  margin-top: 8px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead`
  background-color: #EAE7F1;
`;

export const TableRow = styled.tr<backgroundColor>`
  text-align: center;
  border-bottom: 1px solid #cbcaca;
  background-color: ${(props) => (props.alt ? '#F2F2F2' : 'transparent')};
`;

export const TableHeaderCell = styled.th<TableHeaderCellProps>`
  padding: 4px;
  border-bottom: 1px solid #cbcaca;
  border-right: ${(props) => (props.noBorderRight ? 'none' : '1px solid #cbcaca')};
`;

export const TableCell = styled.td<TableHeaderCellProps>`
  padding: 4px;
  border-bottom: 1px solid #cbcaca;
  border-right: ${(props) => (props.noBorderRight ? 'none' : '1px solid #cbcaca')};
  text-align: left;
`;
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as S from './styles';

import {
  Select,
  ComponentContainer,
  SpinLoading,
  Table,
  StatusText,
  Breadcrumb,
  ComponentHeader,
  Row,
  Button
} from 'shared/components';

import { Input } from 'shared/components';
import { PageContainer }  from 'shared/components';

import {
  getArchitectureInformation,
  GetArchitectureInformationResponse,
  getGeneralDataSupport
} from 'bto_now/api';
import { PageHeader } from 'shared/layouts';
import { getUser } from 'services';
import { IoGrid } from 'react-icons/io5';
import { date, debounce } from 'utils';
import * as XLSX from "xlsx";
import { useTranslation } from 'react-i18next';


function BTO_TMO_PlatFormMng_List() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [list, setList] = useState<GetArchitectureInformationResponse>();
  const [name, setName] = useState('');
  const [mainPlatformMng, setMainPlatformMng] = useState('');
  const [processMngScope, setProcessMngScope] = useState('');
  const [gartnerConcept, setGartnerConcept] = useState('');
  const [mainCapabilities, setMainCapabilities] = useState('');
  const [softwareProvider, setSoftwareProvider] = useState('');

  const [mainPlatOpc, setMainPlatOpc] = useState<Array<string>>();
  const [procMngScop, setProcMngScop] = useState<Array<string>>();
  const [gartnConcep, setGartnConcep] = useState<Array<string>>();
  const [mainCapabil, setMainCapabil] = useState<Array<string>>();
  const [softwProvid, setSoftwProvid] = useState<Array<string>>();
  const [loadingSelects, setLoadingSelects] = useState(true);

  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    debounce(() => {
      getArchitectureInformation({ user: getUser(), mainPlatformMng, processMngScope, gartnerConcept, mainCapabilities, name, softwareProvider })
      .then(({ data }) => setList(data))
      .catch(err => console.log(err));
    }, 500)();
  }, [
    name,
    mainPlatformMng,
    processMngScope,
    gartnerConcept,
    mainCapabilities,
    softwareProvider,
  ]);

  useEffect(() => {
    setLoadingSelects(true);
    getGeneralDataSupport({ Entidade: 'TMO_PLA', User: getUser() })
      .then(({ data }) => {
        setMainPlatOpc(data.tipos.find(v => v.descricao === 'Main Platform Mng')?.opcoes);
        setProcMngScop(data.tipos.find(v => v.descricao === 'Process Mng Scope')?.opcoes);
        setGartnConcep(data.tipos.find(v => v.descricao === 'Gartner Concept')?.opcoes);
        setMainCapabil(data.tipos.find(v => v.descricao === 'Main Capabilities')?.opcoes);
        setSoftwProvid(data.tipos.find(v => v.descricao === 'Software Provider')?.opcoes);
      })
      .catch(err => console.log(err))
      .finally(() => setLoadingSelects(false));
  }, []);

  function navToDetail(id: number) {
    navigate('detail/' + id);
  }

  const onDownload = () => {
  const headers = [
    t("Platform Mng Code"), t("Platform Name"), t("Description"), t("Main Capabilities"),
    t("Platform Mng Status"), t("Main Platform Mng"), t("Main Process Scope"), t("Process Mng Scope"),
    t("Business Structure"), t("Transactional Volumetry"), t("Software Provider"), t("Services Provider"),
    t("Gartner Concept"), t("Integration Type"), t("SAP Integration"), t("Performance Metrics"),
    t("Business Driver"), t("Budget"), t("Costs"), t("ROI"), t("Financial Impact"),
    t("Implementation Timeline"), t("Maintenance Schedule"), t("Decommissioning Date"),
  ];

  const data = list?.map(item => [
      item.plataformId,
      item.name,
      item.description,
      item.mainCapabilities,
      item.status,
      item.mainPlatformMng,
      item.mainProcessScope,
      item.processMngScope,
      item.businessStructure,
      item.transactionalVolumetry,
      item.softwareProvider,
      item.servicesProvider,
      item.gartnerConcept,
      item.integrationType,
      item.sapIntegration,
      item.performanceMetrics,
      item.businessDriver,
      item.budget,
      item.costs,
      item.roi,
      item.financialImpact,
      item.implementationTimeline,
      item.maintenanceSchedule,
      item.decomissioningDate,
  ]) || [];

    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    const colWidths = [
      { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 25 }, { wch: 20 }, { wch: 25 },
      { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 }, { wch: 25 },
      { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 },
      { wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 },
  ];

    ws['!cols'] = colWidths;
    
    let [ date, hours ] = new Date().toISOString().split(/T/)
    hours = hours.replace(/(.[0-9]{3}Z)/, '')
    hours = hours.replaceAll(":", "-")

    const fileName = `portfolio_mng_${date}_${hours}.xlsx`

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb,fileName);
  };

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: 'Platform Management' }
      ]} />
      <PageContainer>
        <PageHeader 
          title='⁠Platform Mng List'
          navList={[
            {label: 'Home', value: '.' }
          ]}
          containerStyle={{ position: 'sticky', left: 0, marginBottom: 10 }}
          topRight={
            <Row>
              <Button onClick={onDownload} style={{ marginLeft: 12 }} size='small'>Exportar</Button>
            </Row>
          }
        />
        <ComponentContainer>
          <ComponentHeader title='TMO: Technology Management Office' menu={[{ label: 'General Info', selected: true }]} />

          {
            loadingSelects ? <SpinLoading /> : (
              <div style={{ display: 'flex', paddingTop: 7, flexWrap: 'wrap' }}>
              <Input containerStyle={{ marginRight: 12 }} label='Platform Name' value={name} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setName(ev.target.value)}/>
              {mainCapabil && <Select containerStyle={{ marginRight: 12 }} label='Main Capabilities' value={mainCapabilities} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setMainCapabilities(ev.target.value)} options={mainCapabil.map(v => ({ label: v, value: v }))} />}
              {mainPlatOpc && <Select containerStyle={{ marginRight: 12 }} label='Main Platform Mng' value={mainPlatformMng} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setMainPlatformMng(ev.target.value)} options={mainPlatOpc.map(v => ({ label: v, value: v }))} />}
              {procMngScop && <Select containerStyle={{ marginRight: 12 }} label='Process Mng Scope' value={processMngScope} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setProcessMngScope(ev.target.value)} options={procMngScop.map(v => ({ label: v, value: v }))} />}
              {softwProvid && <Select containerStyle={{ marginRight: 12 }} label='Software Provider' value={softwareProvider} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setSoftwareProvider(ev.target.value)} options={softwProvid.map(v => ({ label: v, value: v }))} />}
              {gartnConcep && <Select containerStyle={{ marginRight: 12 }} label='Gartner Concept' value={gartnerConcept} onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => setGartnerConcept(ev.target.value)} options={gartnConcep.map(v => ({ label: v, value: v }))} />}
            </div>
            )
          }

          <S.Container>
            {
              !list ? (
                <SpinLoading />
              ) : (
                <Table style={{ marginTop: 12 }}>
                  <Table.Thead>
                    <Table.Tr header>
                      <Table.Td>Platform Mng Code</Table.Td>
                      <Table.Td>Platform Name</Table.Td>
                      <Table.Td>Description</Table.Td>
                      <Table.Td>Main Capabilities</Table.Td>
                      <Table.Td>Platform Mng Status</Table.Td>
                      <Table.Td>Main Platform Mng</Table.Td>
                      <Table.Td>Main Process Scope</Table.Td>
                      <Table.Td>Process Mng Scope</Table.Td>
                      <Table.Td>Business Structure</Table.Td>
                      <Table.Td>Transactional Volumetry</Table.Td>
                      <Table.Td>Software Provider</Table.Td>
                      <Table.Td>Services Provider</Table.Td>
                      <Table.Td>Gartner Concept</Table.Td>
                      <Table.Td>Integration Type</Table.Td>
                      <Table.Td>SAP Integration</Table.Td>
                      <Table.Td>Performance Metrics</Table.Td>
                      <Table.Td>Business Driver</Table.Td>
                      <Table.Td>Budget</Table.Td>
                      <Table.Td>Costs</Table.Td>
                      <Table.Td>ROI</Table.Td>
                      <Table.Td>Financial Impact</Table.Td>
                      <Table.Td>Implementation Timeline</Table.Td>
                      <Table.Td>Maintenance Schedule</Table.Td>
                      <Table.Td>Decomissioning Date</Table.Td>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {
                      list && list.map((it, ind) => (
                        <Table.Tr style={{ cursor: 'pointer' }} key={ind} onClick={() => navToDetail(it.plataformId)}>
                          <Table.Td>{it.plataformId}</Table.Td>
                          <Table.Td>{it.name}</Table.Td>
                          <Table.Td>{it.description}</Table.Td>
                          <Table.Td>{it.mainCapabilities}</Table.Td>
                          <Table.Td><StatusText size='small' style={{ width: 100 }} color='green'>{it.status}</StatusText></Table.Td>
                          <Table.Td>{it.mainPlatformMng}</Table.Td>
                          <Table.Td>{it.mainProcessScope}</Table.Td>
                          <Table.Td>{it.processMngScope}</Table.Td>
                          <Table.Td>{it.businessStructure}</Table.Td>
                          <Table.Td>{it.transactionalVolumetry}</Table.Td>
                          <Table.Td>{it.softwareProvider}</Table.Td>
                          <Table.Td><StatusText size='small' style={{ width: 100 }} color='blue'>{it.servicesProvider}</StatusText></Table.Td>
                          <Table.Td>{it.gartnerConcept}</Table.Td>
                          <Table.Td>{it.integrationType}</Table.Td>
                          <Table.Td>{it.sapIntegration}</Table.Td>
                          <Table.Td>{it.performanceMetrics}</Table.Td>
                          <Table.Td>{it.businessDriver}</Table.Td>
                          <Table.Td>{it.budget}</Table.Td>
                          <Table.Td>{it.costs}</Table.Td>
                          <Table.Td>{it.roi}</Table.Td>
                          <Table.Td>{it.financialImpact}</Table.Td>
                          <Table.Td>{date(it.implementationTimeline)}</Table.Td>
                          <Table.Td>{it.maintenanceSchedule}</Table.Td>
                          <Table.Td>{date(it.decomissioningDate)}</Table.Td>
                        </Table.Tr>
                      ))
                    }
                  </Table.Tbody>
                </Table>
              )
            }
          </S.Container>
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default BTO_TMO_PlatFormMng_List;

import { btoApi } from "../client"
import { AxiosResponse } from "axios";

export interface GetGeneralDataSupportPayload {
  Entidade: string;
  User: string;
}

export interface GetGeneralDataSupportReponse {
  entidade: string;
  tipos: Array<{
    descricao: string;
    opcoes: string[];
  }>;
}

export function getGeneralDataSupport(params: GetGeneralDataSupportPayload): Promise<AxiosResponse<GetGeneralDataSupportReponse>> {
  return btoApi
    .get('/api/GeneralDataSupport/GetGeneralDataSupport', { params });
}
import { Card, Col, Divider, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { POST_API, STATUS_LG, getToken, getUser } from "../../../../../services";
import { useTranslation } from 'react-i18next';


import * as echarts from 'echarts';

const Operational = () => {
    const { t } = useTranslation();

    const { INITIATIVE } = useParams()

    const [ blocks, setBlocks ] = useState([]);

    const onload = () => {
        POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: INITIATIVE, period: 'Quarter', size: 50 }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null)
    }

    useEffect(() => {

        var chartDom = document.getElementById('curvess');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            xAxis: { type: 'category', data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
            yAxis: { type: 'value' },
            grid: { right: '0px', top: '10px', bottom: '20px' },
            series: [ { data: [Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000], type: 'line', smooth: true } ]
        };
          
        myChart.setOption(option)

        onload()

    }, [])


    return (
        <Row gutter={[8,8]}>
            <Col span={24}>
                <Row gutter={[8,8]}>
                    <Col span={14}>
                        <Row gutter={[8,8]}>
                            <Col span={6}>
                                <Card size="small" className="operation-card" title={t("Status Timeline")}>
                                    <div className="operation-kpi">{STATUS_LG[blocks.statusTime]}</div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card size="small" className="operation-card" title={t("SPI")}>
                                    <div className="operation-kpi">
                                        <Typography className="operation-kpi-text">0,00</Typography>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card size="small" className="operation-card" title={t("Status Cost")}>
                                    <div className="operation-kpi">{STATUS_LG[blocks.statusCoast]}</div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card size="small" className="operation-card" title={t("CPI")}>
                                    <div className="operation-kpi">
                                        <Typography className="operation-kpi-text">0,00</Typography>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card size="small" className="operation-card" title={t("Devious Timeline")}>
                                    <div className="operation-kpi">
                                        <Typography className="operation-kpi-text">0,0%</Typography>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card size="small" className="operation-card" title={t("Custom KPI")}>
                                    <div className="operation-kpi">
                                        <Typography className="operation-kpi-text">0,00</Typography>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card size="small" className="operation-card" title={t("Custom KPI")}>
                                    <div className="operation-kpi">
                                        <Typography className="operation-kpi-text">0,00</Typography>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <Card title={t("Curve S - Operational")} size={'small'}>
                            <div id="curvess" style={{height: 170}}></div>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24}><Divider style={{marginTop: 10, marginBottom: 10}} /></Col>
            <Col span={24}>
                <Row gutter={[8,8]} style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
                    <Col flex={'200px'}>
                        <Typography className="title-operational">{t("PHASE")}</Typography>
                        <Typography className="text-operational">{t("Start Project & Setup PMO Governance")}</Typography>
                        <Typography className="text-operational alt">{t("Kick-off & Explorer")}</Typography>
                        <Typography className="text-operational">{t("Build & Development")}</Typography>
                        <Typography className="text-operational alt">{t("UAT")}</Typography>
                        <Typography className="text-operational">{t("Cutover")}</Typography>
                        <Typography className="text-operational alt last">{t("Go-live & Hypercare")}</Typography>
                    </Col>
                    <Col flex={'auto'} style={{ overflowY: 'auto' }}>
                        <Row style={{ flexWrap: 'nowrap' }}>
                            <Col flex={'642px'} style={{minWidth: '642px', width: '642px', maxWidth: '642px', marginRight: 8}}>
                                <Typography className="title01-operational">{t("Planning Progress")}</Typography>
                                <Row className="subtitle01-operational">
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Start Date")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("End Date")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'147px'}>{t("Progress Phase")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'147px'}>{t("Progress Project")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'148px'}>{t("Accumulated")} (%)</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                                <Row className="alt last">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'147px'}>0,0</Col>
                                    <Col className="col-operational" flex={'148px'}>0,0</Col>
                                </Row>
                            </Col>
                            <Col flex={'1196px'} style={{minWidth: '1196px', width: '1196px', maxWidth: '1196px'}}>
                                <Typography className="title01-operational">{t("Real Progress")}</Typography>
                                <Row className="subtitle01-operational">
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Start Date")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("End Date")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Float Days")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'147px'}>{t("Progress Phase")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'147px'}>{t("Progress Project")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'148px'}>{t("Accumulated")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'150px'}>{t("Deviation")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'296px'}>{t("Last Update")}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt last">
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>00/00/0000</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'296px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Operational;
interface DateRange {
  [key: string]: string;
}

export const areDatesSequential = <T extends DateRange>(
  dates: T[],
  startDateKey: keyof T,
  endDateKey: keyof T
): boolean => {
  const parseDate = (date: string): Date => {
    const [day, month, year] = date.split('.').map(Number);
    return new Date(year, month - 1, day);
  };

  for (let i = 1; i < dates.length; i++) {
    const prevEndDate = parseDate(dates[i - 1][endDateKey]);
    const currentStartDate = parseDate(dates[i][startDateKey]);
    const currentEndDate = parseDate(dates[i][endDateKey]);

    if (currentStartDate < prevEndDate) return false;
    if (currentStartDate > currentEndDate) return false;
  }

  return true;
};

export interface IssueData {
  issueId: number;
  issueName: string;
  issueDescription: string;
  type: string;
  issueStatus: string;
  urgency: string;
  impact: string;
  historical: string;
  status: string;
  lastUpdate: string;
  openDate: string;        
  forecastDate: string;    
  owner: string;
}

export const issueList: IssueData[] = [
  {
    issueId: 1,
    issueName: "Budget Overrun",
    issueDescription: "Budget over 20%.",
    type: "BUSINESS",
    issueStatus: "IN PROGRESS",
    urgency: "CRITICAL",
    impact: "HIGH",
    historical: "Adjusted last quarter.",
    status: "Active",
    lastUpdate: "10.08.2024",
    openDate: "01.01.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    issueId: 2,
    issueName: "Scope Creep",
    issueDescription: "Features added unapproved.",
    type: "SCOPE",
    issueStatus: "IN PROGRESS",
    urgency: "HIGH",
    impact: "MODERATE",
    historical: "Frequent scope changes.",
    status: "Active",
    lastUpdate: "09.08.2024",
    openDate: "05.02.2024",
    forecastDate: "30.11.2024",
    owner: "OWNER PADRÃO",
  },
  {
    issueId: 3,
    issueName: "Server Downtime",
    issueDescription: "Unplanned server downtime.",
    type: "TECHNICAL",
    issueStatus: "RESOLVED",
    urgency: "CRITICAL",
    impact: "CRITICAL",
    historical: "Similar issue last year.",
    status: "Inactive",
    lastUpdate: "08.08.2024",
    openDate: "10.01.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    issueId: 4,
    issueName: "Stakeholder Miscommunication",
    issueDescription: "Stakeholders uninformed.",
    type: "OTHERS",
    issueStatus: "CANCELED",
    urgency: "MODERATE",
    impact: "LOW",
    historical: "Previous comms issues.",
    status: "Inactive",
    lastUpdate: "07.08.2024",
    openDate: "01.03.2024",
    forecastDate: "30.09.2024",
    owner: "OWNER PADRÃO",
  },
  {
    issueId: 5,
    issueName: "Security Vulnerability",
    issueDescription: "Critical vulnerability.",
    type: "TECHNICAL",
    issueStatus: "IN PROGRESS",
    urgency: "CRITICAL",
    impact: "CRITICAL",
    historical: "No past vulnerabilities.",
    status: "Active",
    lastUpdate: "06.08.2024",
    openDate: "15.02.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    issueId: 6,
    issueName: "Feature Delay",
    issueDescription: "Feature behind schedule.",
    type: "BUSINESS",
    issueStatus: "IN PROGRESS",
    urgency: "MODERATE",
    impact: "HIGH",
    historical: "Previous delays noted.",
    status: "Active",
    lastUpdate: "05.08.2024",
    openDate: "01.01.2024",
    forecastDate: "31.03.2024",
    owner: "OWNER PADRÃO",
  }
];





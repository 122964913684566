import { Breadcrumb, PageContainer, PageHeader } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row } from "antd";
import KPIComponent from "shared/components/KPI";
import GraphBarOperational from 'shared/components/GraphBarOperational';
import GraphPizza from 'shared/components/GraphPizza';
import GraphComponent from 'shared/components/Graph';
import { useEffect, useState } from 'react';
import * as S from './styles';
import { getDashOperacional, GetDashOperacionalResponse } from 'bto_now/api/portfolio/getDashOperacional';
import { getUser } from 'services';

const PortfolioOperational = () => {
    const { t } = useTranslation();

    const [dashData, setDashData] = useState<GetDashOperacionalResponse | null>(null);

    const onLoad = async () => {
        try {
            const response = await getDashOperacional({ user: getUser() }); 
            if (response.data) {
                setDashData(response.data);
            }
        } catch (error) {
            Modal.warning({ title: 'Atenção', content: 'Erro ao carregar os dados do Dashboard Operacional.' });
        }
    };

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            <Breadcrumb separator=">" items={[
                { title: <IoGrid size={20} style={{ margin: 0 }} /> },
                { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
                { title: <Link to="/dashboard">{t('Portfolio Mng')}</Link> },
                { title: t('Portfolio Operational Mng') }
            ]} />
            <PageContainer>
                <PageHeader
                    title={t('Portfolio Operational Mng')}
                    menu={[{ label: t('Home'), selected: true }]}
                />

                <Row>
                    <Col span={24}>
                        <Row justify="space-between">
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Total Projects")} value={dashData?.totalPhases?.total} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Study")} value={dashData?.totalPhases?.estudo} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Planning")} value={dashData?.totalPhases?.planejamento} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Execution")} value={dashData?.totalPhases?.execucao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Homologation")} value={dashData?.totalPhases?.homologacao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Implementation")} value={dashData?.totalPhases?.implantacao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Finished")} value={dashData?.totalPhases?.encerramento} type='project' size='operational' />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{ marginTop: '15px' }}>
                        <Row gutter={[16, 16]} justify="space-around">
                            <Col md={16} sm={12}>
                                <GraphComponent title={t('On-Going & Finished Projects By Program')}>
                                    <GraphBarOperational
                                        eixoX={dashData?.programmeFinishedOngoing?.map(item => item.programme)}
                                        data01={dashData?.programmeFinishedOngoing?.map(item => item.onGoing)}
                                        data02={dashData?.programmeFinishedOngoing?.map(item => item.finished)}
                                        height={450}
                                    />
                                </GraphComponent>
                            </Col>
                            <Col md={8} sm={12}>
                                <GraphComponent title={t('Projects In Progress Percentage by Program')}>
                                    <GraphPizza data={dashData?.programmePercentual?.map(item => ({ name: item.programme, value: item.percentTotal }))} height={450} />
                                </GraphComponent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </PageContainer>
        </>
    );
};

export default PortfolioOperational;

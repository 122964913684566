import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';

export interface QueryFinancialPayload {
  user?: string | null;
}

interface FormDataFinancial {
  budgetOwner: string;
  budgetDate: string;
  totalBudget: number;
  budgetCapex: number;
  budgetOpex: number;
  description: string;
}

export interface CreateFinancialProps {
  portfolioId: number;
  programId: number;
  projectId: number;
  financialTypeId: number;
  financialDate: string; 
  financialId: number;
  description: string;
  capex: number;
  opex: number;
  ownerId: number;
  monitorId: number;
}

interface CreateFinancialStore {
  financialData: FormDataFinancial[];
  createFinancial: (query: QueryFinancialPayload, params: CreateFinancialProps[]) => Promise<AxiosResponse>;
}

export const useCreateFinancial = create<CreateFinancialStore>((set) => ({
  financialData: [],
  createFinancial: async (query: QueryFinancialPayload, params: CreateFinancialProps[]): Promise<AxiosResponse> => {
    try {
      const response = await btoApi.post(`/api/ProjectFinancial/SaveBudget?User=${query.user}`, params);

      if (response.status === 200) {
        console.log('Financial created successfully:', response);
        return response;
      } else {
        console.error('Error creating project:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create project:', error);
      throw error; 
    }
  },
}));

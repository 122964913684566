import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListScopeManagementPayload {
    User?: string | null;
    ProjectId: string | undefined;
}

export interface GetListScopeManagementResponse {
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  description: string;
  owner: string;
  user: string;
}

export function getListScopeManagement (params: GetListScopeManagementPayload): Promise<AxiosResponse<GetListScopeManagementResponse[]>> {
    return btoApi.get('/api/ScopeManagement/GetList', { params });
}
import ComponentContainer from 'shared/components/ComponentContainer';
import { ComponentHeader } from 'shared/components';
import * as S from './styles';

import React, { useEffect, useState } from 'react';
import { PageContainer } from 'shared/components';
import { Breadcrumb } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { getArchitectureInformationDetail, GetArchitectureInformationDetailResponse } from 'bto_now/api';
import { getUser } from 'services';
import { currency, date } from 'utils';
import PageHeader from 'shared/components/PageHeader';

function TMO_MP_List_Detail() {
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<GetArchitectureInformationDetailResponse>();

  useEffect(() => {
    getArchitectureInformationDetail({
      User: getUser(),
      PlataformId: id,
    }).then(({ data }) => {
      setDetail(data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: <Link to="/dashboard/mng/tmo/platform-mng/list">Platform Management</Link> },
        { title: 'Details' }
      ]} />
      <PageContainer>
        <PageHeader
          title={'⁠Platform Mng Detail: ' + id}
          menu={[{ label: 'Home', selected: true }]}
        />
        <ComponentContainer>
          <ComponentHeader title="Platform Mng Info" menu={[{ label: 'home', selected: true }]} />

          <S.ContainerMain>
            <S.Input label='Platform Mng Code' value={String(detail?.plataformId)} disabled />
            <S.Input label='Platform Name' value={detail?.name} disabled />
            <S.Input label='Description' value={detail?.description} disabled />
            <S.Input label='Main Capabilities' value={detail?.mainCapabilities} disabled />
            <S.Input label='Type' value={detail?.type} disabled />
            <S.Input label='Platform Mng Status' value={detail?.status} disabled />
            <S.Input label='Responsible Teams' value={detail?.responsibleTeams} disabled />
            <S.Input label='Main Platform Mng' value={detail?.mainPlatformMng} disabled />
            <S.Input label='Main Process Scope' value={detail?.mainProcessScope} disabled />
            <S.Input label='Process Mng Scope' value={detail?.processMngScope} disabled />
            <S.Input label='Business Structure' value={detail?.businessStructure} disabled />
            <S.Input label='Transactional Volumetry' value={detail?.transactionalVolumetry} disabled />
            <S.Input label='Software Provider' value={detail?.softwareProvider} disabled />
            <S.Input label='Services Provider' value={detail?.servicesProvider} disabled />
            <S.Input label='Gartner Concept' value={detail?.gartnerConcept} disabled />
            <S.Input label='Integration Type' value={detail?.integrationType} disabled />
            <S.Input label='Integration Points' value={detail?.integrationPoints} disabled />
            <S.Input label='Platform Mng Input' value={detail?.platformMngInput} disabled />
            <S.Input label='Platform Mng Output' value={detail?.platformMngOutput} disabled />
            <S.Input label='Platform Mng Bi-direction' value={detail?.platformMngBiDirection} disabled />
            <S.Input label='SAP Integration' value={detail?.sapIntegration} disabled />
            <S.Input label='Upgrade Plans' value={detail?.upgradePlans} disabled />
            <S.Input label='Geographical Scope' value={detail?.geographicalScope} disabled />
            <S.Input label='Compliance Requirements' value={detail?.complianceRequirements} disabled />
            <S.Input label='Risk Management' value={detail?.riskManagement} disabled />
            <S.Input label='Performance Metrics' value={detail?.performanceMetrics} disabled />
            <S.Input label='Stakeholders' value={detail?.stakeholders} disabled />
            <S.Input label='Business Area' value={detail?.businessArea} disabled />
            <S.Input label='Business Driver' value={detail?.businessDriver} disabled />
            <S.Input label='Budget' value={currency(detail?.budget)} disabled />
            <S.Input label='Costs' value={currency(detail?.costs)} disabled />
            <S.Input label='ROI' value={String(detail?.roi) + '%'} disabled />
            <S.Input label='Financial Impact' value={detail?.financialImpact} disabled />
            <S.Input label='Implementation Timeline' value={date(detail?.implementationTimeline)} disabled />
            <S.Input label='Maintenance Schedule' value={detail?.maintenanceSchedule} disabled />
            <S.Input label='Decomissioning Date' value={date(detail?.decomissioningDate)} disabled />
          </S.ContainerMain>
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default TMO_MP_List_Detail;

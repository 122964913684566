import styled from 'styled-components';

export const Container = styled.div<{ large?: boolean }>`
  width: ${p => p.large ? 408 : 200}px;
  height: 180px;
  background: ${p => p.theme.colors.neutral.gray[50]};
  border-radius: ${p => p.theme.border.radius.default};
  padding: ${p => p.theme.spacing.md} ${p => p.theme.spacing.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);;
    background: ${p => p.theme.colors.neutral.gray[100]};
  }
`;

export const IcoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { ThreeDots } from 'react-bootstrap-icons';

export const Container = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  padding-bottom: 85px;
`;

export const CustomDropdownToggle = styled(Dropdown.Toggle)`
  padding: 4px; /* Ajusta el padding según tus necesidades */
  background-color: transparent !important; /* Ajusta el fondo si es necesario */
  border: none !important; /* Quita el borde si es necesario */
`;

export const ThreeDotsIcon = styled(ThreeDots)`
  width: 20px; /* Ajusta el tamaño del ícono */
  height: 20px;
`;
import { Col, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { POST_API, getToken, getUser } from "../../../../../services";
import { useTranslation } from "react-i18next";


const Resourses = () => {
    const { t } = useTranslation();

    const { INITIATIVE } = useParams()

    const [ blocks, setBlocks ] = useState([]);

    const onload = () => {
        POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: INITIATIVE, period: 'Quarter', size: 50 }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null)
    }

    useEffect(() => {
        onload()
    }, [])

    return (

        <Row>
            <Col span={24} style={{ flexWrap: 'nowrap', overflowY: 'auto', borderRadius: '8px 8px 0px 0px' }}>
                <Row style={{ flexWrap: 'nowrap' }}>
                    <Col flex={'auto'} style={{minWidth: 300}} className="subtitle01-col-resourses">{t("Name")}</Col>
                    <Col flex={'100px'} className="subtitle01-col-resourses">{t("Role")}</Col>
                    <Col flex={'100px'} className="subtitle01-col-resourses">{t("Type")}</Col>
                    <Col flex={'120px'} className="subtitle01-col-resourses">{t("Time Allocation")}</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M1</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M2</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M3</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M4</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M5</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M6</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M7</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M8</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M9</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M10</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M11</Col>
                    <Col flex={'70px'} className="subtitle01-col-resourses">FTE/M12</Col>
                    <Col flex={'160px'} className="subtitle01-col-resourses">Total</Col>
                </Row>
                <Row style={{ flexWrap: 'nowrap' }}>
                    <Col flex={'auto'} style={{minWidth: 300}} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'120px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'160px'} className="col-resourses">-</Col>
                </Row>
                <Row className="alt" style={{ flexWrap: 'nowrap' }}>
                    <Col flex={'auto'} style={{minWidth: 300}} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'120px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'160px'} className="col-resourses">-</Col>
                </Row>
                <Row style={{ flexWrap: 'nowrap' }}>
                    <Col flex={'auto'} style={{minWidth: 300}} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'100px'} className="col-resourses">-</Col>
                    <Col flex={'120px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'70px'} className="col-resourses">-</Col>
                    <Col flex={'160px'} className="col-resourses">-</Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Resourses;
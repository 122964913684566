import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 10px  ${p => p.theme.spacing.sm};
  background: ${p => p.theme.colors.neutral.white[0]};
  border-radius: ${p => p.theme.border.radius.sm};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
`;

export const GrayContainer = styled.div`
  width: 100%;
  border-radius: ${p => p.theme.border.radius.xs};
  padding: 0 ${p => p.theme.spacing.sm};
  background: #f2f2f4;
  flex-wrap: wrap;
`;
import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface UpdateScopeManagementRequest {
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  owner: string;
  description: string;
  user: string;
}

export function updateScopeManagement (params: UpdateScopeManagementRequest): Promise<AxiosResponse<UpdateScopeManagementRequest>> {
  return btoApi.post('/api/ScopeManagement/Update', params);
}
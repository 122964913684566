import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetBusinessDriverCanvaPayload {
  User?:              string | null;
  BusinessDriverId?:  number | null;
}

export interface GetBusinessDriverCanvaResponse {
  businessLeader:       string;
  itLeader:             string;
  startDate:            string;
  endDate:              string;
  businessBenefits:     BusinessBenefit[] | null;
  projectList:          ProjectList[] | null;
  mainAreas:            BusinessBenefit[] | null;
  mainRisk:             MainRisk[] | null;
  stakeHolders:         BusinessBenefit[] | null;
  mainProviders:        BusinessBenefit[] | null;
  operationalStructure: null;
  financialStructure:   null;
}

interface MainRisk {
  name: string;
}

interface BusinessBenefit {
  name: string;
}

interface ProjectList {
  projectId:   string;
  projectName: string;
  statusTime:  number;
  statusCost:  number;
}


export function getBusinessDriverCanva(params: GetBusinessDriverCanvaPayload): Promise<AxiosResponse<GetBusinessDriverCanvaResponse>> {
  if(!params || !params.BusinessDriverId || !params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });
  
  return btoApi
    .get('/api/BusinessDriver/GetBusinessDriverCanva', { 
      params 
    });
}

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListTaskStatusPayload {
  User?: string | null;
}

export interface GetListTaskStatusResponse {
  taskStatusId: number;
  taskStatus: string;
  user: string;
}

export function getListTaskStatus (params: GetListTaskStatusPayload): Promise<AxiosResponse<GetListTaskStatusResponse[]>> {
  return btoApi.get('/api/TaskStatus/GetList', { params });
}
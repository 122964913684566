export function moneyMask(value: number) {
  // Sufixo baseado no valor do número
  let suffix = '';
  if (value >= 1000000) {
    value = value / 1000000;
    suffix = 'm';
  } else if (value >= 1000) {
    value = value / 1000;
    suffix = 'k';
  }

  // Formatando o número com separadores de milhar
  let formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(value);

  // Substituindo vírgulas por pontos
  formattedValue = formattedValue.replace(/,/g, '.');

  // Removendo zeros finais se todos forem zeros após o ponto decimal
  if (formattedValue.endsWith('.000')) {
    formattedValue = formattedValue.slice(0, -4);
  }

  return formattedValue + suffix;
}
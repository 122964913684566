import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  background: var(--primary);
  border-radius: 0.5rem;
  padding: 8px;
  border-radius: ${p => p.theme.border.radius.default};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
`
export const ContainerData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: transparent;
  border-radius: 0.5rem;
`

export const Img = styled.img`
  width: 50px;
  height: 50px;
`

export const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #FFF;
  margin: 0px;
  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box;
`
export const containerButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`
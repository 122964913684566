import styled, { css } from 'styled-components';

interface Container {
  minWidth: boolean
}

export const Container = styled.div<{ label?: string, inputFocus: boolean, inputHasValue: boolean; error?: boolean, disabled?: boolean }>`
  border: 1px solid ${p => p.theme.colors.neutral.gray[200]};
  border-radius: ${p => p.theme.border.radius.sm};
  margin: 4px 0px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  /* background: white; */
  /* overflow: hidden; */

  ${p => p.label?.length && css`
    &::before {
    content: "${p.label}";
    display: block;
    color: ${p => p.theme.colors.text};
    position: absolute;
    top: calc(50% - 9px);
    left: 10px;
    cursor: text;
    font-size: 12px;
    font-weight: 450;
    transition: 150ms ease-in-out;
    margin-right: 25px;
    white-space: nowrap;
  }  
  `}

  ${p => (p.inputFocus || p.inputHasValue) && css`
    &::before {
      top: -8px;
      left: 5px;
      font-size: 10px;
      background: white;
      padding: 1px 3px;
    } 
  `}

  ${p => p.error && css`
    border-color: ${p.theme.colors.utility.red[500]}  
  `}

  ${p => p.disabled && css`
    opacity: 0.4;
  `}

`;

export const Input = styled.input`
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 2;
  padding: 0px 10px;
  font-size: 12px;
  color: ${({ theme, readOnly }) => readOnly ? '#4d5c73' : theme.colors.inputText};
  font-weight: ${({ theme, readOnly }) => !readOnly && theme.font.weight.medium};
  
  ${p => p.disabled && css`
      cursor: not-allowed;
    `
  }
`;
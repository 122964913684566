
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AutoCloseProgressBar = styled.div<{ duration?: number }>`
  width: 80%;
  height: 6px;
  align-self: center;
  justify-self: center;
  background-color: ${p => p.theme.colors.primary[500]};
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;

  &::after {
    content: '';
    display: block;
    background-color: ${p => p.theme.colors.primary[900]};
    border-radius: 3px;
    height: 100%;
    width: 100%;
    
    animation-name: autoCloseProgress;
    animation-duration: ${p => p.duration}ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes autoCloseProgress {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }
  
`;

import React, { useState, useEffect, useRef, forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import { convertStringToDate } from "utils/dates/convertStringToDate";

import * as S from "./styled";

interface DatePickerProps {
  initialDate?: Date;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  buttonLabel?: string;
  inputStyle?: React.CSSProperties;
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  noMinWidth?: boolean;
  minDate?: string;
  maxDate?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  required?: boolean;
  index?: number;
}

const ButtonCalendarInput: React.FC<DatePickerProps> = ({
  onChange,
  disabled,
  readOnly,
  minDate = "01.01.2010",
  maxDate = "31.12.2100",
  placeholder,
  name,
  value,
  required,
  error,
  index = 0
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [hasValue, setHasValue] = useState(!!value);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      const parsedDate = convertStringToDate(value);

      if (parsedDate) {
        setSelectedDate(parsedDate);
        setHasValue(true);
      } else {
        console.error("Invalid date format:", value);
        setSelectedDate(null);
        setHasValue(false);
      }
    } else {
      setSelectedDate(null);
      setHasValue(false);
    }
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (!selectedDate) {
          setHasValue(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedDate]);

  const handleButtonClick = (onClick: () => void) => {
    setHasValue(true);
    onClick();
  };

  const handleDateChange = (date: Date | null) => {
    if (readOnly) return;
    const formattedDate = moment(date).format("DD.MM.YYYY");
    setSelectedDate(date);
    setHasValue(!!date);

    if (onChange) {
      const event = {
        target: {
          name: name || "",
          value: formattedDate,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
  };

  const CustomInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => {
      return (
        <S.Button
          onClick={() => handleButtonClick(onClick)}
          ref={ref} 
          disabled={readOnly}
          hasValue={hasValue || !!value}
          error={error}
        >
          <S.Icon />
          {value}
          <S.Placeholder hasValue={hasValue || !!value} index={index}>
            {placeholder}
          </S.Placeholder>
        </S.Button>
      );
    }
  );

  return (
    <S.Container ref={ref}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        customInput={
          <CustomInput 
            placeholder={placeholder} 
          />
        }
        dateFormat="dd.MM.yyyy"
        disabled={disabled}
        minDate={convertStringToDate(minDate)}
        maxDate={convertStringToDate(maxDate)}
        readOnly={readOnly}
        name={name}
        required={required}
      />
    </S.Container>
  );
};

export default ButtonCalendarInput;



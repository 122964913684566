import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface DeleteScopeManagementRequest {
  projectId: number;
  scopeId: number;
  name: string;
  user: string;
  owner: string;
  description: string;
}

export function deleteScopeManagement (params: DeleteScopeManagementRequest): Promise<AxiosResponse<DeleteScopeManagementRequest>> {
    return btoApi.post('/api/ScopeManagement/Delete',  params );
}
import * as S from './styles';

export const semaphoreVariant = (value: number) => {
  if (value === 0) return 'gray';
  if (value === 1) return 'danger';
  if (value === 2) return 'warning';
  if (value === 3) return 'success';
  return 'gray';
};

const sizeMap = {
  normal: '12px',
  xs: '16px',
  md: '24px',
  lg: '30px',
};

function Semaphore({ value, size = 'normal' }: { value: number; size?: 'normal' | 'xs' | 'md' | 'lg'}) {
  return (
    <S.Col semaphoreColor={semaphoreVariant(value)} size={sizeMap[size]}>
      <div />
    </S.Col>
  );
}

export default Semaphore;

import { Button, Col, Modal, Row, Segmented, Spin, Typography } from "antd";
import "./styles.css";
import { useEffect, useState } from "react";
import {
  IoChevronBackOutline,
  IoChevronDown,
  IoChevronForwardOutline,
  IoChevronUp,
} from "react-icons/io5";
import { IoIosLink } from "react-icons/io";
import { MdCenterFocusStrong } from "react-icons/md";

import { POST_API, getToken, getUser } from "services";

import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { ComponentHeader, InputSearch } from "shared/components";
import { debounce } from 'utils';
import { useTranslation } from "react-i18next";

const RoadmapComponent = () => {
  const theme = useTheme();

  const [period, setPeriod] = useState("Monthly");
  const [size, setSize] = useState(100);
  const [block, setBlock] = useState(true);
  const [select, setSelect] = useState(null);
  const [column, setColumn] = useState(3);
  const [line, setLine] = useState(0);
  const [height, setHeight] = useState(0);
  const [screen, setScreen] = useState(0);

  const [items, setItems] = useState([]);
  const [blocks, setBlocks] = useState([]);

  const [groupBy, setGroupBy] = useState("programme");
  const [search, setSearch] = useState("");

  useEffect(() => {
    onPeriod();
  }, [period, groupBy]);
  useEffect(() => {
    onload();
  }, [size, period, groupBy]);

  useEffect(() => {
    debounce(() => {
      onPeriod();
      onload();
    })();
  }, [search]);

  useEffect(() => onCenter, [screen]);

  const onload = async () => {
    await POST_API("/load.php", {
      token: getToken(),
      user: getUser(),
      period,
      size,
      groupBy,
      search,
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          setBlocks(res.data);
          setHeight(res.data.length * 28);
          setLine(res.line);
        } else {
          Modal.warning({ title: "Atenção!", content: res.msg });
        }
      })
      .catch(() => null);
  };

  const onPeriod = async () => {
    setLine(0);
    await POST_API("/header.php", {
      token: getToken(),
      user: getUser(),
      period,
      size,
      groupBy,
      search,
    })
      .then((rs) => rs.json())
      .then((res) => {
        if (res.return) {
          setItems(res.period);
          setColumn(res.column);
          setScreen(res.screen);
        } else {
          Modal.warning({ title: "Atenção", content: res.msg });
        }
      })
      .catch(() => null)
      .finally(() => onCenter());
  };

  const onSelect = (index) => {
    var items = select;
    var height = blocks.length;

    if (typeof select?.[index] !== "undefined") {
      items = { ...items, [index]: !items[index] };
    } else {
      items = { ...items, [index]: true };
    }

    Object.keys(items).forEach((v, i) => {
      if (items[v]) {
        height += blocks[v].items.length;
      }
    });

    setHeight(height * 28);
    setSelect(items);
  };

  const onSize = (type) => {
    if (type === "plus") {
      setSize(size + 50);
    } else {
      setSize(size - 50);
    }
  };

  const onCenter = () => {
    try {
      const el = document.getElementById("roadmap-body");
      if (el) el.scrollTo(size * screen, 0);
    } catch (error) {
      console.log(error);
    }
  };

  const { t } = useTranslation();
  return (
    <Row className="">
      <ComponentHeader
        title={t("Gantt Overview")}
        topRight={
          <div className="roadmap-header-right">
            <Segmented
              size="small"
              value={period}
              onChange={(v) => setPeriod(v)}
              options={[t("Monthly"), { label: t("Quarterly"), disabled: true }]}
            />
            <div className="roadmap-header-size">
              <div
                className={
                  size === 100
                    ? "roadmap-header-size-button disabled"
                    : "roadmap-header-size-button"
                }
                onClick={() => (size === 100 ? null : onSize("minus"))}
              >
                -
              </div>
              <div className={"roadmap-header-size-button"} onClick={onCenter}>
                <MdCenterFocusStrong />
              </div>
              <div
                className={
                  size === 300
                    ? "roadmap-header-size-button disabled"
                    : "roadmap-header-size-button"
                }
                onClick={() => (size === 300 ? null : onSize("plus"))}
              >
                +
              </div>
            </div>
            <Row>
            <InputSearch onChange={ev => setSearch(ev.target.value)} value={search} placeholder={t("Search")} />
            </Row>
          </div>
        }
        menuList={[
          {
            label: t("Program"),
            value: "p",
            onClick: () => {
              setGroupBy("programme");
            },
          },
          {
            label: t("Business Drivers"),
            value: "b",
            onClick: () => {
              setGroupBy("businessDrivers");
            },
          },
        ]}
      />
      {/* 
      <Col span={24} className="roadmap-header">
        <Typography style={{ fontWeight: 'bold' }}>{t("Gantt Overview")}</Typography>
        <div className="roadmap-header-right">
          <Segmented size="small" value={period} onChange={(v) => setPeriod(v)} options={['Monthly', { label: 'Quarterly', disabled: false }]} />
          <div className="roadmap-header-size">
            <div className={size === 100 ? "roadmap-header-size-button disabled" : "roadmap-header-size-button"} onClick={() => size === 100 ? null : onSize('minus')}>-</div>
            <div className={"roadmap-header-size-button"} onClick={onCenter}><MdCenterFocusStrong /></div>
            <div className={size === 300 ? "roadmap-header-size-button disabled" : "roadmap-header-size-button"} onClick={() => size === 300 ? null : onSize('plus')}>+</div>
          </div>
          <div>
            <InputSearch placeholder="Pesquisa" />
          </div>
        </div>
      </Col>
      <div className="tab-initiative" style={{ marginBottom: 10 }}>
        <Typography className={`initiative-tab ${key === '1' ? 'active' : ''}`} onClick={() => { setKey('1') }}>Programa</Typography>
        <Typography className={`initiative-tab ${key === '2' ? 'active' : ''}`} onClick={() => { setKey('2') }}>Business Drivers</Typography>
      </div> */}

      {false ? (
        <Col span={24} className="roadmap-load">
          <center>
            <Spin />
          </center>
        </Col>
      ) : (
        <Col span={24}>
          <Row style={{ flexWrap: "nowrap", borderTop: "1px solid #0000001a" }}>
            <Col flex={block ? "300px" : "50px"} className="roadmap-block">
              <Row
                className="roadmap-block-open"
                align={"middle"}
                justify={"end"}
              >
                <Col style={{ height: "30px" }}>
                  {block ? (
                    <IoChevronBackOutline
                      size={20}
                      className="roadmap-block-open-button"
                      onClick={() => {
                        setBlock(false);
                        setSelect(null);
                      }}
                    />
                  ) : (
                    <IoChevronForwardOutline
                      size={20}
                      className="roadmap-block-open-button"
                      onClick={() => setBlock(true)}
                    />
                  )}
                </Col>
              </Row>
              <Row className="roadmap-block-items">
                {blocks.map((v, i) => (
                  <>
                    <Col
                      span={24}
                      key={"a" + i}
                      className={
                        block
                          ? "roadmap-block-item"
                          : "roadmap-block-item center"
                      }
                      onClick={() => onSelect(i)}
                    >
                      {!block ? (
                        <div
                          style={{ backgroundColor: v.color }}
                          className="roadmap-block-item-circle"
                        ></div>
                      ) : (
                        <>
                          <div className="roadmap-block-item-title">
                            <div
                              style={{ backgroundColor: v.color }}
                              className="roadmap-block-item-circle"
                            ></div>
                            {groupBy === "programme" ? (
                              <Link to={`/dashboard/initiative/${v.id}`}>
                                <Typography
                                  className={"roadmap-block-item-text text"}
                                  style={{ color: v.color }}
                                >
                                  {v.id ? t(`${v.name}`) : t(`${v.name}`)}
                                </Typography>
                              </Link>
                            ) : (
                              <Link
                                to={`/dashboard/business/${v.businessDriverId}`}
                              >
                                <Typography
                                  className={"roadmap-block-item-text text"}
                                  style={{ color: v.color }}
                                >
                                  {v.id ? t(`${v.name}`) : t(`${v.name}`)}
                                </Typography>
                              </Link>
                            )}
                          </div>
                          <Typography
                            className={"roadmap-block-item-text"}
                            style={{ color: v.color }}
                          >
                            {" "}
                            {select?.[i] ? (
                              <IoChevronUp className="roadmap-block-item-select" />
                            ) : (
                              <IoChevronDown
                                className="roadmap-block-item-select"
                                onClick={() => onSelect(i)}
                              />
                            )}
                          </Typography>
                        </>
                      )}
                    </Col>
                    {select?.[i]
                      ? v?.items.map((v1, i1) => (
                          <Col
                            key={"b" + i1}
                            span={24}
                            className="roadmap-block-item"
                            style={{ justifyContent: "flex-start" }}
                          >
                            <Link
                              to={`/dashboard/initiative/${v.id}/${v1.initiativeId}`}
                            >
                              <Typography
                                style={{ marginRight: 10 }}
                                className="standard-data item center"
                              >
                                <IoIosLink
                                  style={{ marginLeft: theme.spacing.sm }}
                                  className="standard-data-action"
                                  size={16}
                                />
                              </Typography>
                            </Link>
                            <Typography className="roadmap-block-subitem">
                              {t(v1.initiativeName)}
                            </Typography>
                          </Col>
                        ))
                      : null}
                  </>
                ))}
              </Row>
            </Col>
            <Col flex={"auto"} className="roadmap-body" id="roadmap-body">
              <Row className="roadmap-body-row">
                {items.map((v, i) => (
                  <Col
                    key={"c" + i}
                    flex={
                      v.len > 0 ? `${size * v.len}px` : `${size * column}px`
                    }
                    className="roadmap-q"
                  >
                    <Typography>{t(v.title)}</Typography>
                  </Col>
                ))}
              </Row>
              <Row className="roadmap-body-row">
                {items.map((v, i) =>
                  v.month.map((v1, i1) => (
                    <Col
                      key={"d" + i1}
                      flex={`${size}px`}
                      className="roadmap-q"
                    >
                      <Typography
                        className={
                          v1.active
                            ? "roadmap-q-title active"
                            : "roadmap-q-title "
                        }
                      >
                        {t(v1.title)}
                      </Typography>
                    </Col>
                  ))
                )}
              </Row>
              {blocks.map((v, i) => (
                <>
                  <Row key={"e" + i} className="roadmap-body-row">
                    {items.map((v2, i2) =>
                      v2.month.map((v1, i1) => (
                        <Col
                          key={"f" + i1}
                          flex={`${size}px`}
                          className="roadmap-q"
                        ></Col>
                      ))
                    )}
                  </Row>
                  {select?.[i]
                    ? v?.items.map((v1, i1) => (
                        <Row key={"g" + i1} className="roadmap-body-row">
                          {items.map((v2, i2) =>
                            v2.month.map((v1, i1) => (
                              <Col
                                key={"h" + i1}
                                flex={`${size}px`}
                                className="roadmap-q"
                              ></Col>
                            ))
                          )}
                        </Row>
                      ))
                    : null}
                </>
              ))}
              <Row className="roadmap-body-timeline">
                {blocks.map((v, i) => (
                  <Col span={24} key={"i" + i}>
                    <Row className="roadmap-body-row item">
                      <Typography
                        className="roadmap-body-timeline-item-text"
                        style={{ marginLeft: v.position.start * size }}
                      >
                        {v.id ? t(`${v.name}`) : t(`${v.name}`)}{" "}
                        <div className="roadmap-body-timeline-item-text-circle"></div>{" "}
                        {t(v.label.startDate)} - {t(v.label.endDate)}{" "}
                        <div className="roadmap-body-timeline-item-text-circle"></div>{" "}
                        {v.label.dif} {t("Days")}{" "}
                      </Typography>
                      <div
                        className="roadmap-body-timeline-item"
                        style={{
                          backgroundColor: v.color,
                          width: v.position.length * size,
                          marginLeft: v.position.start * size,
                        }}
                      ></div>
                    </Row>
                    {select?.[i]
                      ? v?.items.map((v1, i1) => (
                          <Row key={"j" + i1} className="roadmap-body-row item">
                            <Typography
                              className="roadmap-body-timeline-subitem-text"
                              style={{ marginLeft: v1.position.start * size }}
                            >
                              {t(v1.initiativeName)}{" "}
                              <div className="roadmap-body-timeline-item-text-circle"></div>{" "}
                              {t(v1.label.startDate)} - {t(v1.label.endDate)}{" "}
                              <div className="roadmap-body-timeline-item-text-circle"></div>{" "}
                              {v1.label.dif} {t("Days")}{" "}
                            </Typography>
                            <div
                              className="roadmap-body-timeline-subitem"
                              style={{
                                width: v1.position.length * size,
                                marginLeft: v1.position.start * size,
                              }}
                            ></div>
                          </Row>
                        ))
                      : null}
                  </Col>
                ))}
              </Row>
              <Row
                className="roadmap-body-timeline"
                style={{ width: `${line}px`, overflow: "hidden" }}
              >
                {blocks.map((v, i) => (
                  <Col key={"k" + i} span={24}>
                    <Row className="roadmap-body-row item">
                      <div
                        className="roadmap-body-timeline-item notitle"
                        style={{
                          backgroundColor: v.color,
                          width: v.position.length * size,
                          marginLeft: v.position.start * size,
                        }}
                      ></div>
                    </Row>
                    {select?.[i]
                      ? v?.items.map((v1, i1) => (
                          <Row key={"l" + i1} className="roadmap-body-row item">
                            <div
                              className="roadmap-body-timeline-subitem notitle"
                              style={{
                                width: v1.position.length * size,
                                marginLeft: v1.position.start * size,
                              }}
                            ></div>
                          </Row>
                        ))
                      : null}
                  </Col>
                ))}
              </Row>
              <Row className="roadmap-body-line">
                <div
                  className="roadmap-body-line-item"
                  style={{ height: `${height}px`, marginLeft: `${line}px` }}
                ></div>
              </Row>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default RoadmapComponent;

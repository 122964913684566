import React, { useEffect, useRef, useState, forwardRef } from 'react';
import styles from './Select.module.css';
import { FaChevronDown } from 'react-icons/fa6';
import * as S from './styles';

interface Props {
  label?: string;
  error?: boolean;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  name?: string;
  value?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  disabled?: boolean;
  reset?: boolean;
  readOnly?: boolean;
  index?: number;
  onChange?: (value: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<Props> = forwardRef(({
  label,
  inputStyle,
  containerStyle,
  options,
  onChange,
  name,
  error: _error,
  value,
  disabled,
  reset,
  readOnly = false,
  index = 0,
}: Props, ref) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [inputDisplay, setInputDisplay] = useState('');
  const [listToDisplay, setListToDisplay] = useState(options);
  const [selectedValue, setSelectedValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(_error);
  const inputRef = useRef<HTMLInputElement>(null);

  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (_error !== error) setError(_error);
  }, [_error]);

  useEffect(() => {
    if (value && value !== selectedValue) {
      setSelectedValue(value);
      const selectedOption = options?.find((option) => option.value === value);
      setInputDisplay(selectedOption ? selectedOption.label : '');
    }
  }, [value, options]);

  useEffect(() => {
    if (reset) {
      setInputDisplay('');
      setSelectedValue('');
    }
  }, [reset]);

  useEffect(() => {
    setTimeout(() => setIsOpen(inputFocus), 150);
  }, [inputFocus]);

  useEffect(() => {
    if (!isOpen) {
      setListToDisplay(options);
    }
  }, [isOpen, options]);

  useEffect(() => {
    if (isOpen) calculateDropdownPosition();
  }, [isOpen]);

  const calculateDropdownPosition = () => {
    const containerRect = containerRef.current!.getBoundingClientRect();
    const dropdownRect = dropdownRef.current!.getBoundingClientRect();

    if (containerRect.bottom + dropdownRect.height > window.innerHeight) setDropdownPosition('top');
    else setDropdownPosition('bottom');
  };

  useEffect(() => {
    if (options?.length && inputDisplay) {
      const filteredList = options.filter((op) => {
        const reg = new RegExp(inputDisplay, 'gi');
        return reg.test(op.label);
      });

      if (filteredList.length) {
        setError(false);
        setListToDisplay(filteredList);
      } else {
        setError(true);
        setSelectedValue('');
        setListToDisplay([]);
      }
    } else {
      setListToDisplay(options);
    }
  }, [inputDisplay, options]);

  useEffect(() => {
    const customEv = {
      target: {
        name,
        value: selectedValue,
      },
    } as React.ChangeEvent<HTMLSelectElement>;

    if (onChange) onChange(customEv);
  }, [selectedValue]);

  const toggleInputFocus = () => {
    setInputFocus((prev) => !prev);
    setInputDisplay('')
  };

  const handleSelectOption = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!readOnly && !containerRef.current?.contains(e.relatedTarget as Node)) {
      setSelectedValue('');
      setInputDisplay('');
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${styles.container} ${error ? styles.error : ''} ${disabled ? styles.disabled : ''} ${inputFocus ? styles.focused : ''} ${inputDisplay ? styles.hasValue : ''} ${(index % 2 === 0) ? styles.hasValueIndexPair : styles.hasValueIndexOdd }`}
      style={{ ...containerStyle, minWidth: ((label?.length || 0) * 7) + 35 }}
      ref={containerRef}
      data-label={label}
    >
      <S.Input
        className={styles.input}
        ref={inputRef}
        style={{ ...inputStyle, pointerEvents: readOnly ? 'none' : 'auto' }}
        value={inputDisplay}
        onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setInputDisplay(value)}
        onFocus={() => !readOnly && setInputFocus(true)}
        onBlur={handleSelectOption}
        onClick={() => !readOnly && setInputDisplay('')}
        disabled={disabled}
        readOnly={readOnly}
      />
      <FaChevronDown
        className={`${styles.icon} ${isOpen ? styles.open : ''}`}
        onClick={!disabled && !readOnly ? toggleInputFocus : undefined}
      />
      {isOpen && !readOnly && (
        <div
          className={`${styles.optionsContainer} ${dropdownPosition === 'top' ? styles.top : styles.bottom}`}
          ref={dropdownRef}
        >
          {listToDisplay && listToDisplay.length > 0 ? (
            listToDisplay.map((op) => (
              <p
                className={`${styles.option} ${op.label === inputDisplay ? styles.selected : ''}`}
                key={op.value}
                onClick={() => {
                  setInputFocus(false);
                  setTimeout(() => {
                    if (!readOnly) {
                      setInputDisplay(op.label);
                      setSelectedValue(op.value);
                      setIsOpen(false); 
                    }
                  }, 100);
                }}
              >
                {op.label}
              </p>
            ))
          ) : (
            <p className={styles.option} onClick={() => setInputDisplay('')}>
              Sem resultado
            </p>
          )}
        </div>
      )}
    </div>
  );
});

export default Select;



import React, {useState, useEffect} from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Spinner } from 'react-bootstrap';
import CustoComponent from './CustoComponent';
import { useParams } from 'react-router-dom';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport.ts';
import { useTranslation } from "react-i18next";

const Costs = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [dataApi, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getStatusReport} = useStatusReportStore();
  const {typeStatusReport} = useTypeStatusReportStore()

  useEffect(() => {
    getStatusReport(Number(id), typeStatusReport)
    .then((data) => {
      if (data) {
        setData(data.detalhesDoProjeto);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
}, []);

  const statsData = [
    {
      title: t('Planned Project Cost'),
      value: dataApi.totalRequested,
      decimal: true,
      suffix: '',
      prefix: 'R$',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      link: '',
      image: ''
    },
    {
      title: t('Actual Project Cost'),
      value: dataApi.totalInvestiment,
      decimal: true,
      suffix: '',
      prefix: 'R$',
      valueClassName: 'text-info',
      badgeBg: 'info',
      badgeText: '0.0%',
      link: '',
      image:  ''
    },
  ];

  if (!dataApi & loading) {
    return (
      <Card style={{ height: '350px' }}>
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Row className="g-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {statsData.map((stat, index) => (
        <Col key={stat.title} xs={12} sm={6} md={4} lg={3} style={{ flex: '1 1 100%', maxWidth: '100%'}}>
          <CustoComponent stat={stat} style={{ width: '100%', height: '100%', fontSize: '13px',}} />
        </Col>
      ))}
    </Row>
  );
}

export default Costs;

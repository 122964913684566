import styled, { css } from 'styled-components';
import { FaChevronDown } from 'react-icons/fa6';

export const Container = styled.div<{ label?: string, inputFocus: boolean, inputHasValue: boolean; error?: boolean, disabled?: boolean }>`
  border: 1px solid ${p => p.theme.colors.neutral.gray[200]};
  border-radius: ${p => p.theme.border.radius.sm};
  margin: 4px 0px;
  margin-top: 10px;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  /* overflow: hidden; */

  &::before {
    content: "${p => p.label}";
    display: block;
    color: ${p => p.theme.colors.text};
    position: absolute;
    top: calc(50% - 9px);
    left: 10px;
    cursor: text;
    font-size: 12px;
    font-weight: 450;
    transition: 150ms ease-in-out;
    margin-right: 25px;
    white-space: nowrap;
  }

  ${p => (p.inputFocus || p.inputHasValue) && css`
    &::before {
      top: -8px;
      left: 5px;
      font-size: 10px;
  
      padding: 1px 3px;
    } 
  `}

  ${p => p.error && css`
    border-color: ${p.theme.colors.utility.red[500]}  
  `}

  ${p => p.disabled && css`
    opacity: 0.4;
    svg {
      cursor: not-allowed;
    }
  `}
`;

interface InputProps {
  readOnly: boolean
}

export const Input = styled.input<InputProps>`
  cursor: pointer;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  font-size: 12px;
  z-index: 2;
  padding: 0px 10px;
  color: ${({ theme, readOnly }) => readOnly ? '#4d5c73' : theme.colors.text};
  font-weight: ${({ theme, readOnly }) => !readOnly && theme.font.weight.medium};
  ${p => p.disabled && css`
    cursor: not-allowed;
  `}
`;

export const Icon = styled(FaChevronDown).attrs({
  size: '25px',
})<{ isOpen: boolean }>`
  padding: 3px;
  box-sizing: border-box;
  cursor: pointer;
  transform: ${p => p.isOpen ? 'rotateZ(180deg)' : 'rotateZ(360deg)'};
  transition: 150ms;
  border-radius: 20px;
  margin-right: 5px;
`;

export const OptionsContainer = styled.div<{ dropDownPosition: string }>`
  position: absolute;
  max-height: 180px;
  overflow-y: auto;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: ${p => p.theme.border.radius.default};
  box-shadow: ${p => p.theme.colors.blackBoxShadow};
  margin-top: 5px;

  ${p => p.dropDownPosition === 'top' ? css`
    bottom: 100%;
  ` : css`
    top: 100%;
  `}
`;

export const Option = styled.p<{ selected: boolean }>`
  padding: 4px 10px;
  color: ${p => p.theme.colors.text};
  background-color: ${p => p.selected ? p.theme.colors.neutral.gray[200] : 'white'};
  width: 100%;
  z-index: 3;
  cursor: pointer;
  margin: 0px 0px;
  font-size: ${p => p.theme.font.size.sm};

  &:hover {
    background-color: ${p => p.theme.colors.neutral.gray[50]};
  }
`;


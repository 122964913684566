import React, { useState, useEffect, ChangeEvent } from "react";
import { IoGrid } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Select, SpinLoading, Table, ModalSuccess, CheckBox, Breadcrumb, ComponentHeader, ComponentContainer, ButtonsActionsPage, Input, PageHeader, PageContainer } from "shared/components";
import { ProjectHeader } from 'shared/layouts';
import ModalCreateScope from "./components/ModalCreateScope/index";
import ModalEditScope from "./components/ModalEditScope/index";

import { getListScopeManagement, GetListScopeManagementResponse } from "bto_now/api/scopeManagement/getListScope";
import { getUser } from "services";
import { getListScopeType, GetListScopeTypeResponse } from "bto_now/api/scopeType/getListScopeType";
import { getListScopeStatus, GetListScopeStatusResponse } from "bto_now/api/scopeStatus/getListScopeStatus";
import { deleteScopeManagement, DeleteScopeManagementRequest } from "bto_now/api/scopeManagement/deleteScope";

import { debounce } from "utils";
import { convertTypeIdInType } from "utils/convertType/convertTypeIdInType";
import { convertStatusIdInStatus } from "utils/convertStatus/convertStatusIdInStatus";

import * as S from './styles';

export interface ScopeData {
  scopeType: string;
  scopeStatus: string;
  name: string;
  owner: string;
  description: string;
}

const Scope = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const initialScopeData: ScopeData = {
    scopeType: '',
    scopeStatus: '',
    name: '',
    owner: '',
    description: '',
  };

  const [filters, setFilters] = useState<ScopeData>(initialScopeData);
  const [scopeListData, setScopeListData] = useState<GetListScopeManagementResponse[]>([]);
  const [filteredScopeList, setFilteredScopeList] = useState<GetListScopeManagementResponse[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [newScopeModal, setNewScopeModal] = useState(false);
  const [editScopeModal, setEditScopeModal] = useState(false);

  const [selectedScopes, setSelectedScopes] = useState<Set<number>>(new Set());
  const [selectedScopeId, setSelectedScopeId] = useState<number | null>(null);

  const [scopeTypeList, setScopeTypeList] = useState<GetListScopeTypeResponse[]>([]);
  const [scopeStatusList, setScopeStatusList] = useState<GetListScopeStatusResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const { data: scopeData } = await getListScopeManagement({ User: getUser(), ProjectId: id });
        setScopeListData(scopeData);

        const { data: scopeTypeData } = await getListScopeType({ User: getUser() });
        setScopeTypeList(scopeTypeData);

        const { data: scopeStatusData } = await getListScopeStatus({ User: getUser() });
        setScopeStatusList(scopeStatusData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    debounce(fetchData, 500)();
  }, [updateTrigger]);

  useEffect(() => {
    const filterTasks = () => {
      const filtered = scopeListData.filter(scope => {
        const typeMatch = convertTypeIdInType(scopeTypeList, scope.scopeTypeId) === filters.scopeType || filters.scopeType === '';
        const statusMatch = convertStatusIdInStatus(scopeStatusList, scope.scopeStatusId) === filters.scopeStatus || filters.scopeStatus === '';
        const nameMatch = scope.name.toLowerCase().includes(filters.name.toLowerCase());
        const ownerMatch = scope.owner.toLowerCase().includes(filters.owner.toLowerCase());

        return typeMatch && statusMatch && nameMatch && ownerMatch;
      });

      setFilteredScopeList(filtered);
    };

    filterTasks();
  }, [filters, scopeListData]);

  const handleCheck = (id: number) => {
    setSelectedScopes(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
        setSelectedScopeId(null);
      } else {
        newSelected.add(id);
        setSelectedScopeId(id);
      }
      return newSelected;
    });
  };

  const deleteScopeDatabase = async () => {
    try {
      const selectedIds = Array.from(selectedScopes);
      for (const idSelect of selectedIds) {
        const params: DeleteScopeManagementRequest = {
          projectId: Number(id),
          scopeId: idSelect,
          user: getUser(),
          name: '',
          description: '',
          owner: ''
        };

        const response = await deleteScopeManagement(params);

        if (response.status === 200) {
          console.log('Scope deleted successfully:', response.data);
          setUpdateTrigger(prev => !prev);
        } else {
          console.error('Error deleting scope:', response.statusText);
        }
      }

      setModalOpen(true);
      setModalDescription(`Deleted ${selectedIds.length} scopes successfully.`);

    } catch (error) {
      console.error('Error deleting scopes:', error);
    }
  }

  const handleDelete = () => {
    deleteScopeDatabase();

    setUpdateTrigger(prev => !prev);
    setSelectedScopes(new Set());
    setSelectedScopeId(null);
  };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = () => {
    if (selectedScopes.size === 1) {
      const selectedId = Array.from(selectedScopes)[0];
      setSelectedScopeId(selectedId);
      setEditScopeModal(true);
    }
  };

  const handleOpenModalCreate = () => {
    setNewScopeModal(true);
  };

  const isChecked = (id: number) => selectedScopes.has(id);

  const isDisabledButtonDelete = selectedScopes.size === 0;
  const isDisabledButtonEdit = selectedScopes.size !== 1;
  const isDisabledButtonNew = selectedScopes.size > 0;

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: <Link to="/dashboard/mng/pmo/project-scope-mng">{t('Project Scope Management')}</Link> },
        { title: t('Scope') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t('⁠Project Scope Management')}
          menu={[{ label: t('Project Info'), selected: true }]}
        />

        <ProjectHeader style={{ marginBottom: '16px' }} projectId={id} />

        <ComponentContainer>
          <ComponentHeader title={t('PMO: Search Project Scope')} menu={[{ label: t('Scope'), selected: true }]} />
          <S.ContainerForms>
            <S.FormRow>
              <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
                <S.InputContainer>
                  <Input
                    label={t('Scope Name') + ' *'}
                    name='scopeName'
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    style={{ fontSize: '12px' }}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Select
                    options={scopeTypeList.map(type => ({ label: type.scopeType, value: type.scopeType }))}
                    label={t('Scope Type') + ' *'}
                    name='scopeType'
                    value={filters.scopeType}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, scopeType: e.target.value })}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Input
                    label={t('Owner') + ' *'}
                    name='owner'
                    value={filters.owner}
                    onChange={(e) => setFilters({ ...filters, owner: e.target.value })}
                    style={{ fontSize: '12px' }}
                  />
                </S.InputContainer>
                <S.InputContainer>
                  <Select
                    options={scopeStatusList.map(status => ({ label: status.scopeStatus, value: status.scopeStatus }))}
                    label={t('Scope Status') + ' *'}
                    name='scopeStatus'
                    value={filters.scopeStatus}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, scopeStatus: e.target.value })}
                  />
                </S.InputContainer>
              </div>
              <S.ButtonContainerRow>
                <ButtonsActionsPage
                  isDisabledButtonDelete={isDisabledButtonDelete}
                  isDisabledButtonEdit={isDisabledButtonEdit}
                  isDisabledButtonNew={isDisabledButtonNew}
                  handlePlus={handleOpenModalCreate}
                  handleEdit={handleOpenModalEdit}
                  handleTrash={handleDelete}
                />
              </S.ButtonContainerRow>
            </S.FormRow>
          </S.ContainerForms>

          <S.ContainerList>
            {
              isLoading ? (
                <SpinLoading />
              ) : (
                <S.TableContainer>
                  <Table style={{ marginTop: 16 }}>
                    <Table.Thead>
                      <Table.Tr header>
                        <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Id')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Scope Name')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Scope Type')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Scope Owner')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Description')}</Table.Td>
                        <Table.Td style={{ padding: '4px 12px' }}>{t('Scope Status')}</Table.Td>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {
                        filteredScopeList.map((scope) => (
                          <Table.Tr key={scope.scopeId} style={{ borderBottom: '1px solid #ccc' }}>
                            <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(scope.scopeId)} checked={isChecked(scope.scopeId)} size="medium" /></Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{scope.scopeId}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{scope.name}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{t(convertTypeIdInType(scopeTypeList, scope.scopeTypeId))}</Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{scope.owner}</Table.Td>
                            <Table.Td 
                              style={{ 
                                padding: '8px 12px',
                                maxWidth: '270px', 
                                whiteSpace: 'nowrap', 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis',
                              }}
                              title={scope.description}
                            >
                              {scope.description}
                            </Table.Td>
                            <Table.Td style={{ padding: '8px 12px' }}>{t(convertStatusIdInStatus(scopeStatusList, scope.scopeStatusId))}</Table.Td>
                          </Table.Tr>
                        ))
                      }
                    </Table.Tbody>
                  </Table>
                </S.TableContainer>
              )
            }
          </S.ContainerList>

          <ModalSuccess
            open={modalOpen}
            onOk={handleModalOk}
            description={modalDescription}
            title="Success"
          />
        </ComponentContainer>
      </PageContainer>

      <ModalCreateScope
        projectId={id}
        scopeStatusList={scopeStatusList}
        scopeTypeList={scopeTypeList}
        newScopeModal={newScopeModal}
        setNewScopeModal={setNewScopeModal}
        setUpdateTrigger={setUpdateTrigger}
      />

      <ModalEditScope
        projectId={id}
        scopeId={selectedScopeId}
        scopeListData={scopeListData}
        editScopeModal={editScopeModal}
        updateTrigger={updateTrigger}
        setEditScopeModal={setEditScopeModal}
        setUpdateTrigger={setUpdateTrigger}
        scopeTypeList={scopeTypeList}
        scopeStatusList={scopeStatusList}
        setSelectedScopes={setSelectedScopes}
        setSelectedScopeId={setSelectedScopeId}
      />
    </>
  );
};

export default Scope;






import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export type GetAllPhasesResponse = Array<{
  phaseExecutionId: number;
  name:             string;
}>

export function getAllPhases(): Promise<AxiosResponse<GetAllPhasesResponse>> {
  return btoApi
    .get('/api/Phase/GetAllPhases');
}

import React, { useState } from 'react';
import { Button, Row } from 'shared/components'
import { getToken, getUser, POST_API } from 'services';
import { Modal } from 'antd/es';
import { message } from 'antd';
import { AiOutlineUser } from "react-icons/ai";

import * as S from './styles';

interface ProfileCardProps {
    userName: string;
    userEmail: string;
}

const ProfileCard = ({ userName, userEmail }: ProfileCardProps) => {

    const [messageApi, contextHolder] = message.useMessage();

    const success = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const error = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const warning = (message: string) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };



    const [isEditing, setIsEditing] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleEdit = () => {
        setIsEditing(true);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    const handleSave = async (setBlocks: any) => {
        if (newPassword === confirmPassword) {
            POST_API('/changePassword.php', {
                token: getToken(),
                user: getUser(),
                oldPassword,
                newPassword,
                confirmPassword
            }).then(rs => rs.json()).then(res => {
                success('Senha atualizada com sucesso!')
                setIsEditing(false);
                if (res.return) {
                    setBlocks(res.data)
                } else {
                    error('A senha inserida está incorreta.')
                    Modal.warning({ title: 'Atenção', content: res.msg })
                }
            }).catch(() => null)
        } else {
            warning('As senhas não correspondem. Por favor, verifique-as.')
        }
    };

    return (
        <>
            {contextHolder}


            <AiOutlineUser style={{ padding: 20, background: '#DDD', borderRadius: '50%', marginBottom: 12 }} size={88} />

            <S.Input label='Nome' type="text" value={userName} disabled={true} />
            <S.Input label='E-mail' type="email" value={userEmail} disabled={true} />

            {isEditing && (
                <>
                    <S.Input
                        label='Senha atual'
                        type="password"
                        value={oldPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
                        disabled={!isEditing}
                    />
                    <S.Input
                        label='Nova senha'
                        type='password'
                        value={newPassword}
                        disabled={!isEditing}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                    />
                    <S.Input
                        label='Confirmar senha'
                        type='password'
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                    />
                </>
            )}


            <Row style={{ marginTop: 12 }}>
                {isEditing ? (
                    <>
                        <Button style={{ marginRight: 6 }} size='medium' onClick={handleSave}>Salvar</Button>
                        <Button style={{ marginLeft: 6 }} size='medium' onClick={() => setIsEditing(false)}>Cancelar</Button>
                    </>
                ) : (
                    <Button size='medium' onClick={handleEdit}>Editar</Button>
                )}
            </Row>
        </>
    );
};

export default ProfileCard;

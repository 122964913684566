export interface RiskData {
  riskId: number;
  riskName: string;
  riskDescription: string;
  type: string;
  statusMitigation: string;
  probabilityScale: string;
  impactScale: string;
  historical: string;
  status: string;
  lastUpdate: string;
  openDate: string;        
  forecastDate: string;    
  owner: string;           
}

export const riskList: RiskData[] = [
  {
    riskId: 1,
    riskName: "Market Fluctuation",
    riskDescription: "Unexpected market changes.",
    type: "FINANCIAL",
    statusMitigation: "CONTINUOUS MONITORING",
    probabilityScale: "HIGH",
    impactScale: "CRITICAL",
    historical: "Low impact in the past five years",
    status: "Active",
    lastUpdate: "01.07.2024",
    openDate: "01.01.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    riskId: 2,
    riskName: "Data Breach",
    riskDescription: "Exposure of sensitive data.",
    type: "TECHNOLOGY",
    statusMitigation: "IN PROGRESS",
    probabilityScale: "VERY HIGH",
    impactScale: "HIGH",
    historical: "Several minor breaches reported",
    status: "Active",
    lastUpdate: "10.08.2024",
    openDate: "05.02.2024",
    forecastDate: "30.11.2024",
    owner: "OWNER PADRÃO",
  },
  {
    riskId: 3,
    riskName: "Regulatory Changes",
    riskDescription: "Changes in laws/regulations.",
    type: "LEGAL & COMPLIANCE",
    statusMitigation: "OPEN",
    probabilityScale: "MODERATE",
    impactScale: "MODERATE",
    historical: "Frequent minor changes over the years",
    status: "Active",
    lastUpdate: "15.06.2024",
    openDate: "10.01.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    riskId: 4,
    riskName: "Employee Turnover",
    riskDescription: "Losing key employees.",
    type: "HUMAN RESOURCES",
    statusMitigation: "ACCEPTED",
    probabilityScale: "HIGH",
    impactScale: "MODERATE",
    historical: "Stable turnover with no significant losses",
    status: "Inactive",
    lastUpdate: "20.04.2024",
    openDate: "01.03.2024",
    forecastDate: "30.09.2024",
    owner: "OWNER PADRÃO",
  },
  {
    riskId: 5,
    riskName: "Project Scope Creep",
    riskDescription: "Uncontrolled scope expansion.",
    type: "SCOPE",
    statusMitigation: "IN PROGRESS",
    probabilityScale: "MODERATE",
    impactScale: "HIGH",
    historical: "Has occurred in past projects",
    status: "Active",
    lastUpdate: "25.07.2024",
    openDate: "15.02.2024",
    forecastDate: "31.12.2024",
    owner: "OWNER PADRÃO",
  },
  {
    riskId: 6,
    riskName: "Technological Obsolescence",
    riskDescription: "Outdated technology.",
    type: "STRATEGIC",
    statusMitigation: "CLOSED",
    probabilityScale: "LOW",
    impactScale: "CRITICAL",
    historical: "Technology upgraded regularly",
    status: "Inactive",
    lastUpdate: "30.03.2024",
    openDate: "01.01.2024",
    forecastDate: "31.03.2024",
    owner: "OWNER PADRÃO",
  }
];


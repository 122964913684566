import React from 'react';
import * as S from './styles';
import Typography from 'shared/components/Typography';
import Row from 'shared/components/Row';
import { useTheme } from 'styled-components';

interface Props {
  title: string;
  menu?: Array<{
    label: string;
    selected: boolean;
    onClick?(label?: string): void;
  }>
}

function PageHeader({
  title,
  menu,
}: Props) {
  const theme = useTheme();

  return (
    <S.Container>
      <Row>
        <Typography size='large' weight='bold'>{title}</Typography>
      </Row>
      <Row>
        {Array.isArray(menu) && menu.length > 0 && menu.map((item, index) => (
          <React.Fragment key={index}>
            <S.MenuItem onClick={() => item.onClick && item.onClick(item.label)} selected={item.selected}>
              {item.label}
            </S.MenuItem>
            {index < menu.length - 1 && (
              <Typography style={{ color: theme.colors.neutral.gray[300] }} weight='light' key={`separator-${index}`}>
                |
              </Typography>
            )}
          </React.Fragment>
        ))}
      </Row>
    </S.Container>
  );
}

export default PageHeader;

import React from 'react';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Background from 'shared/theme/components/Background/index';
import { moneyMask } from 'utils/moneyMask';
import { useTranslation } from "react-i18next";

const CustoComponent = ({ stat, ...rest }) => {
  const {
    title,
    value,
    percentValue,
    prefix,
    valueClassName,
    linkText,
    link,
    image,
    className
  } = stat;

  const { t } = useTranslation();

  return (
    <Card className={classNames(className, 'overflow-hidden')} style={{ paddingRight: 0 }} {...rest}>
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative" style={{minHeight: '168px'}}>
          <h5 className='fs-9'>{t(title)}</h5>
        <div
          className={classNames(
            valueClassName,
            'display-4 fs-3 fw-normal font-sans-serif'
          )}
          style={{ padding: '19px 0'}}
        >
          <span style={{fontSize: '25px'}}>
            {prefix}{moneyMask(value)}
          </span>
        </div>
        {percentValue && (
          <p className="fs-6 text-muted mb-0">{percentValue}</p>
        )}
      </Card.Body>
    </Card>
  );
};

// CustoComponent.propTypes = {
//   stat: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     value: PropTypes.number,
//     percentValue: PropTypes.string, 
//     decimal: PropTypes.bool,
//     suffix: PropTypes.string.isRequired,
//     prefix: PropTypes.string,
//     valueClassName: PropTypes.string,
//     linkText: PropTypes.string,
//     link: PropTypes.string,
//     badgeText: PropTypes.string,
//     badgeBg: PropTypes.string,
//     image: PropTypes.string,
//     className: PropTypes.string
//   })
// };

export default CustoComponent;
import React, { useRef, useState } from 'react';
import * as S from './styles';
import { ComponentHeader } from 'shared/layouts';


import Actual from './Actual';
import Update from './Update';
import Results from './Results';
import { Button } from 'shared/components';
import { ButtonRef } from 'shared/components/Button'
import { useTranslation } from 'react-i18next';
import { getUrlSearchParams, RitualManagementStatusRitual } from 'utils';

const Pages = {
  Actual,
  Update,
  Results,
}

function Operational() {
  const { t } = useTranslation();
  const statusRitual = getUrlSearchParams('statusRitual') as RitualManagementStatusRitual;
  const isNotDraft = statusRitual === 'Active' || statusRitual === 'History';


  const [page, setPage] = useState<'Actual' | 'Update' | 'Results'>('Update');
  
  /**
   * Save button Ref é a referencia para o botão que fica no header da página
   * usando a referencia é possivel passar os metodos para a página de Ubdate onde
   * lá é adicionado um event listner para configurar qual funcão executar no click do botão
   */
  const saveButtonRef = useRef<ButtonRef>(null);

  return (
    <>
      <ComponentHeader
        title='Timeline Progress Status'
        menuList={[
          // { label: 'Actual', value: 'Actual', onClick: v => setPage('Actual') },
          { label: t('Update'), value: 'Update', onClick: v => setPage('Update') },
          // { label: 'Results', value: 'Results', onClick: v => setPage('Results') },
        ]}
        topRight={
          <Button disabled={isNotDraft} ref={saveButtonRef}>Save</Button>
        }
      />
      <S.Container>
        {
          page === 'Actual' 
            ? <Pages.Actual />
            : page === 'Results'
              ? <Pages.Results />
              : page === 'Update'
                ? <Pages.Update saveButtonRef={saveButtonRef} />
                : null
        }
      </S.Container>
    </>
  )
}

export default Operational;
import styled from 'styled-components';
import {
  Table
} from 'shared/components';

export const Container = styled.div`

`;

export const ScrollX = styled.div`
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }
`;
import { Col, Row } from "antd"
import { IoGrid } from 'react-icons/io5';

import { Outlet, Link } from "react-router-dom";
// import { useEffect, useState } from "react";
import { PageHeader } from 'shared/layouts';
import { useTranslation } from "react-i18next";
import { Breadcrumb } from 'shared/components';
import { space } from "services";

const Portfolio = () => {
  const { t } = useTranslation();

  const routes = [
    { title: <IoGrid size={20} style={{ margin: 0 }} /> },
    {title: <Link to="/dashboard/mng">{t('Home')}</Link>},
    { title: t('Portfolio Mng') }
  ]

  return (
    <Row>
      <Breadcrumb separator=">" items={routes} />
      
      <Col span={24} className="route-page">
        <PageHeader
          title={t("Portfolio Mng")}
          navList={[
            { label: t('Gantt'), value: '/dashboard' },
            { label: t('List'), value: '/dashboard/list' },
          ]}
          navButtons={[
              { value: '/dashboard/financial', label: t('Portfolio Financial') }, 
              { value: '/dashboard/operational', label: t('Portfolio Operational') },
              { value: '/dashboard/business/0', label: t('Portfolio Strategy') },
          ]}
        />
        <div className="graph-card" size="small" style={{ marginTop: space[0] }}>
          <Outlet />
        </div>
      </Col>
    </Row>
  )
}

export default Portfolio;

import styled from 'styled-components';

export const ScrollX = styled.div`
  width: 100%;
  overflow-x: auto;
`;


export const Container = styled.section`
 background-color: #ffffff;
 padding: 1rem;
 margin: 1rem 0;
 border-radius: ${p => p.theme.border.radius.sm};
 box-shadow: 0px 0px 5px ${p => p.theme.colors.neutral.gray[200]};
`
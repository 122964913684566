const TotalInvestmentsData = {
  dataAxis: ['Orçamento Total', 'Forecast', 'Consumido', 'Disponível', 'Orçamento Extra', 'Total de Investimentos', 'Valor Teste', 'Valor Teste', 'Valor Teste', 'Orçamento', 'aleatorio', 'final'],
  dataValue: [32721, 1193, 3827, 7573, 2673, 41118, 6229, 23497, 54690, 4213, 4440, 4288],
  companyId: '3',
  totalInvestiment: 20804,
  totalBudget: 6512342,
  extraBudget: 53000,
  forecast: 42070,
  consumed: 94111,
  available: 20910,
};

const TotalInvestmentsMonthData = {
  dataAxis: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  dataValue: [992, 979, 175, 988, 658, 693, 312, 645, 413, 312, 124, 645],
  companyId: '2',
};


const TotalInvestmentsCapexData = {
  dataAxis: ['Orçamento Total', 'Forecast', 'Consumido', 'Disponível', 'Orçamento Extra', 'Total de Investimentos', 'Valor Teste', 'Valor Teste', 'Valor Teste'],
  dataValue: [932, 926, 559, 723, 27, 823, 97, 815, 606],
  companyId: '54',
  totalInvestiment: 984208,
  totalBudget: 53125,
  extraBudget: 292318,
  forecast: 787415,
  consumed: 87511,
  available: 99832,
};

const TotalInvestmentsCapexMonthData = {
  dataAxis: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  dataValue: [826, 795, 61, 549, 863, 582, 607, 561, 166, 950, 227, 201],
  companyId: '53',
};

const TotalInvestmentsOpexData = {
  dataAxis: ['Orçamento Total', 'Forecast', 'Consumido', 'Disponível', 'Orçamento Extra', 'Total de Investimentos', 'Valor Teste'],
  dataValue: [879, 596, 371, 793, 874, 151, 868],
  companyId: '32',
  totalInvestiment: 28490,
  totalBudget: 531236,
  extraBudget: 191125,
  forecast: 177222,
  consumed: 3298338,
  available: 187362,
};

const TotalInvestmentsOpexMonthData = {
  dataAxis: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  dataValue: [350, 231, 495, 968, 242, 305, 726, 238, 896, 7, 203, 866],
  companyId: '31',
};

export default {
  TotalInvestmentsData,
  TotalInvestmentsMonthData,
  TotalInvestmentsOpexData,
  TotalInvestmentsOpexMonthData,
  TotalInvestmentsCapexData,
  TotalInvestmentsCapexMonthData
};
import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { create } from 'zustand';
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';

export interface QueryTimelinePayload {
  user?: string | null;
}

export interface FormDataTimeline {
  planStartDate: string;
  planEndDate: string;
  phasePlanDays?: number;
  phaseOwner: string;
}

export interface CreateTimelineProps {
  portfolioId: number;
  programId: number;
  projectId: number;  
  startDate: string;
  endDate: string;
  owner: number;
}

interface CreateTimelineStore {
  timelineData: FormDataTimeline[];
  createTimeline: (query: QueryTimelinePayload, params: CreateTimelineProps[]) => Promise<AxiosResponse>;
  getTimelineData: (data: FormDataTimeline[]) => void;
}

export const useCreateTimeline = create<CreateTimelineStore>((set) => ({
  timelineData: [],
  createTimeline: async (query: QueryTimelinePayload, params: CreateTimelineProps[]): Promise<AxiosResponse> => {
    try {
      const response = await btoApi.post(`/api/ProjectTimeline/Save?User=${query.user}`, params);

      if (response.status === 200) {
        console.log('Timeline created successfully:', response);
        return response;
      } else {
        console.error('Error creating timeline:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create timeline:', error);
      throw error; 
    }
  },
  getTimelineData: (data: FormDataTimeline[]) => {
    const updatedData = data.map(item => {
      const phasePlanDays = calculateDateDiffInDays(item.planStartDate, item.planEndDate);
      return { ...item, phasePlanDays };
    });
  
    set({ timelineData: updatedData });
  }
}));


import {create} from 'zustand';

interface StatusModalState {
  statusModal: boolean;
  setStatusModal: (status: boolean) => void
}

export const useStatusModalStore = create<StatusModalState>((set) => ({
  statusModal: true,
  setStatusModal: (status: boolean) => set(() => ({ statusModal: status })),
}));


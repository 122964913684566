import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import PTBR from '../i18n/locales/pt/pt-br.json';
import ENUS from '../i18n/locales/en/en-us.json';

const resources = {
    'pt-BR': PTBR,
    'en-us': ENUS
}

i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: localStorage.language || navigator.language,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false
      }
    })
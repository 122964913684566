import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface NewProjectPayload {
  User: string;
}

export interface NewProjectResponse {
  companyId:               number;
  projectGroup:            string;
  initiativeType:          string;
  mainInitiative:          string;
  initiativeName:          string;
  summary:                 string;
  initiativeId:            string;
  sponsor:                 string;
  businessLeader:          string;
  itPartnerLeader:         string;
  itBusinessPatner:        string;
  keyUser:                 string;
  businessArea:            string;
  businessDriver:          string;
  businessBenefits:        string;
  techonologyBenefits:     string;
  plataformEnvolved:       string;
  businessProcessEnvolved: string;
  programme:               string;
  programmeId:             number;
  businessDriverId:        number;
  projectId:               string;
  projectManager:          string;
  name:                    string;
  typeId:                  number;
  type:                    string;
  startDate:               string;
  startPhaseBuild:         string;
  percentCompleteBuild:    number;
  startPhaseUat:           string;
  percentCompleteUat:      number;
  startPhaseCutover:       string;
  percentCompleteCutover:  number;
  goLiveDate:              string;
  endDate:                 string;
  actualPhase:             string;
  actualPhaseDescription:  string;
  actualStatus:            number;
  progressPlaned:          number;
  progressRealized:        number;
  issuesQuantity:          number;
  statusTime:              number;
  statusCoast:             number;
  kanbanId:                number;
  kanban:                  string;
  statusRisk:              number;
  statusIssue:             number;
  statusScope:             number;
  providers:               string;
  totalRequested:          number;
  totalInvestiment:        number;
  totalInvestimentCapex:   number;
  totalInvestimentOpex:    number;
  commitedBudget:          number;
  percentCommitedPlanned:  number;
  totalRunningCoast:       number;
  lastRitualDate:          string;
  cpi:                     number;
  spi:                     number;
  extraBudget:             number;
  forecast:                number;
  consumed:                number;
  available:               number;
  financialProgress:       number;
  operacionalProgress:     number;
  operationalProgress:     number;
  toWin:                   boolean;
  user:                    string;
  timeline:                Timeline[];
  financial:               Financial;
  digitalTransformation:    number;
}

export interface Financial {
    projectFinancial:      ProjectFinancial[];
    projectFinancialGroup: ProjectFinancialGroup[];
}

export interface ProjectFinancial {
    user:             string;
    projectId:        string;
    phaseName:        string;
    phaseExecutionId: number;
    ritualNumber:     number;
    startDate:        string;
    endDate:          string;
    finacialType:     string;
    totalOpex:        number;
    totalCapex:       number;
    totalForecast:    number;
    totalInvestments: number;
}

export interface ProjectFinancialGroup {
    project:          string;
    startDate:        string;
    endDate:          string;
    forecast:         number;
    totalCapex:       number;
    totalOpex:        number;
    totalInvestments: number;
}

interface Timeline {
  user:              string;
  projectId:         string;
  phaseName:         string;
  phaseExecutionId:  number;
  ritualNumber:      number;
  dateType:          string;
  startDate:         string;
  endDate:           string;
  progressPhaseReal: number;
}

export function newProject(params: NewProjectPayload): Promise<AxiosResponse<NewProjectResponse>>{
  return btoApi.get('/api/ProjectInformation/NewProject', { params });
}
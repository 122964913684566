import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input, ButtonCalendarInput } from "shared/components";

import * as S from './styles';
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSchemaRisks, schema } from "../..";
import { useForm } from "react-hook-form";
import { useGeneralDataSupport } from "shared/store-zustand/risks-project/getGeneralDataSupport";
import { riskList } from "../../data";
import { Owner, useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { projectOwnerIdToName } from "utils/convertIdToName/projectOwnerIdToName";

interface ModalEditRiskProps {
  projectId: string | undefined;
  riskId: number | null;
  editRiskModal: boolean;
  updateTrigger: boolean;
  setEditRiskModal: (state: boolean) => void;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRisks: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedRiskId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ModalEditRisk: React.FC<ModalEditRiskProps> = (props) => {
  const { projectId, editRiskModal, setEditRiskModal, setUpdateTrigger, riskId, updateTrigger, setSelectedRisks, setSelectedRiskId } = props;
  const { generalDataSupport } = useGeneralDataSupport();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [riskDataEdit, setRiskDataEdit] = useState<FormSchemaRisks>();
  const [creatingLoading, setCreatingLoading] = useState(false);

  const { register, handleSubmit, reset, clearErrors, formState: { errors } } = useForm<FormSchemaRisks>({
    resolver: yupResolver(schema(t)),
  });

  useEffect(() => {
    if (riskId !== null) {
      const riskData = riskList.find(risk => risk.riskId === riskId);

      setRiskDataEdit(riskData)
    }
  }, [riskId, updateTrigger, editRiskModal]);

  // const updateRiskDatabase = async () => {
  //   try {
  //     const newItem: UpdateRiskManagementRequest = {
  //       projectId: Number(projectId),
  //       RiskId: dataRisk.RiskId,
  //       name: dataRisk.name,
  //       RiskTypeId: convertTypeInTypeId(RiskTypeList, dataRisk.RiskType),
  //       owner: dataRisk.owner,
  //       RiskStatusId: convertStatusInStatusId(RiskStatusList, dataRisk.RiskStatus),
  //       user: getUser(),
  //       description: dataRisk.description,
  //     };

  //     const response = await updateRiskManagement(newItem);

  //     if (response.status === 200) {
  //       console.log('Risk updated successfully:', response.data);

  //       setUpdateTrigger(prev => !prev);
  //       setSelectedRisks(prevSelected => {
  //         const newSelected = new Set(prevSelected);
  //         newSelected.delete(dataRisk.RiskId);
  //         return newSelected;
  //       });
  //       setSelectedRiskId(null);
  //     } else {
  //       console.error('Error updating Risk:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error updating Risk:', error);
  //   }
  // };

  useEffect(() => {
    if (editRiskModal) {
      reset(); 
      clearErrors(); 
    }
  }, [editRiskModal, reset, clearErrors]); 

  const onSubmit = () => {
    setCreatingLoading(true);
    
    reset();
    clearErrors();
    setEditRiskModal(false);
    
    setCreatingLoading(false);
  };

  return (
    <Modal
      title={t(`Edit Risk Mng: Risk ${riskId}`)}
      open={editRiskModal}
      onCancel={() => setEditRiskModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setEditRiskModal(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: 12 }}
            onClick={handleSubmit(onSubmit)}
            loading={creatingLoading}
          >
            {t("Save")}
          </Button>
        </div>
      }
    >
      <Input
        {...register('riskName')}
        label={t('Risk Name') + ' *'}
        value={riskDataEdit?.riskName}
        error={!!errors?.riskName}
      />
      {errors.riskName && <S.ErrorMessage>{errors.riskName.message}</S.ErrorMessage>}
      <Input
        {...register('riskDescription')}
        label={t('Description') + ' *'}
        value={riskDataEdit?.riskDescription}
        error={!!errors?.riskDescription}
      />
      {errors.riskDescription && <S.ErrorMessage>{errors.riskDescription.message}</S.ErrorMessage>}
      <Select
        {...register('type')}
        options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
        label={t('Risk Type') + ' *'}
        value={riskDataEdit?.type}
        // reset={resetSelect}
        error={!!errors?.type}
      />
      {errors.type && <S.ErrorMessage>{errors.type.message}</S.ErrorMessage>}
      <Select
        {...register('statusMitigation')}
        options={generalDataSupport.statusMitigation.map((status: string) => ({ label: status, value: status }))}
        label={t('Status Mitigation') + ' *'}
        value={riskDataEdit?.statusMitigation}
        // reset={resetSelect}
        error={!!errors?.statusMitigation}
      />
      {errors.statusMitigation && <S.ErrorMessage>{errors.statusMitigation.message}</S.ErrorMessage>}
      <Select
        {...register('probabilityScale')}
        options={generalDataSupport.probabilityScale.map((probability: string) => ({ label: probability, value: probability }))}
        label={t('Probability Scale') + ' *'}
        value={riskDataEdit?.probabilityScale}
        // reset={resetSelect}
        error={!!errors?.probabilityScale}
      />
      {errors.probabilityScale && <S.ErrorMessage>{errors.probabilityScale.message}</S.ErrorMessage>}
      <Select
        {...register('impactScale')}
        options={generalDataSupport.impactScale.map((impact: string) => ({ label: impact, value: impact }))}
        label={t('Impact Scale') + ' *'}
        value={riskDataEdit?.impactScale}
        // reset={resetSelect}
        error={!!errors?.impactScale}
      />
      {errors.impactScale && <S.ErrorMessage>{errors.impactScale.message}</S.ErrorMessage>}
      <Input
        {...register('historical')}
        placeholder={t('Historical') + ' *'}
        value={riskDataEdit?.historical}
        error={!!errors?.historical}
      />
      {errors.historical && <S.ErrorMessage>{errors.historical.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('openDate')}
        placeholder={t('Open Date') + ' *'}
        value={riskDataEdit?.openDate}
        error={!!errors?.openDate}
      />
      {errors.openDate && <S.ErrorMessage>{errors.openDate.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('forecastDate')}
        placeholder={t('Forecast Date') + ' *'}
        value={riskDataEdit?.forecastDate}
        error={!!errors?.forecastDate}
      />
      {errors.forecastDate && <S.ErrorMessage>{errors.forecastDate.message}</S.ErrorMessage>}
      <Select
        {...register('owner')}
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.name }))}
        label={t('Owner') + ' *'}
        value={riskDataEdit?.owner}
        // reset={resetSelect}
        error={!!errors?.owner}
      />
      {errors.owner && <S.ErrorMessage>{errors.owner.message}</S.ErrorMessage>}
    </Modal>
  );
}

export default ModalEditRisk;



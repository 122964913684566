import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetRitualFinancialPayload {
  User:              string | null;
  ProjectId:         string | null;
  RitualNumber:      number | null;
}

export interface GetRitualFinancialResponse {
  phaseFinancialUpdate:   FinancialUpdate[];
  projectFinancialUpdate: FinancialUpdate[];
}

export interface FinancialUpdate {
  phaseExecutionId?: number;
  phase?:            string;
  startDate:         string | null;
  endDate:           string | null;
  forecast:          number;
  totalCapex:        number;
  totalOpex:         number;
  totalInvestments:  number;
  project?:          string;
}

export function getRitualFinancialDraft(params: GetRitualFinancialPayload): Promise<AxiosResponse<GetRitualFinancialResponse>> {
  return btoApi
    .get('/api/Ritual/GetRitualFinancial', { params });
}

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { Select, ModalSuccess, ComponentHeader, Button, Row, TextArea, Input, ButtonCalendarInput } from "shared/components";

import ModalCreateProject from "./components/ModalCreateGeneral";

import SelectList, { OptionType } from "shared/components/SelectList";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { projectTypeIdToName } from "utils/convertIdToName/projectTypeIdToName";
import { projectManagerIdToName } from "utils/convertIdToName/projectManagerIdToName";
import { projectOwnerIdToName } from "utils/convertIdToName/projectOwnerIdToName";
import { projectProgramIdToName } from "utils/convertIdToName/projectProgramIdToName";
import { TFunction } from "i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { calculateDateDiffInDays } from "utils/dates/calculateDateDiffInDays";

import * as S from './styles';

export interface AdditionalDetails {
  startDate: string;
  endDate: string;
  sponsors: string[];
  businessLeader: string[];
  itLeader: string[];
  projectKeyUser: string[];
  businessArea: string[];
  goLivePlanDate: string;
  projectProviders: string[];
  businessBenefits: string;
  technologyBenefits: string;
  processInvolved: string[];
  platformInvolved: string[];
  businessDrivers: string;
}

export const optionsSelected = [
  { id: 1, status: 'Option one' },
  { id: 2, status: 'Option two' },
  { id: 3, status: 'Option three' },
];

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  startDate: yup.string()
    .required(t('This field is required.')),
  endDate: yup.string()
    .required(t('This field is required.')),
  sponsors: yup.array().of(yup.string())
    .required(t('This field is required.')),
  businessLeader: yup.array().of(yup.string())
    .required(t('This field is required.')),
  itLeader: yup.array().of(yup.string())
    .required(t('This field is required.')),
  projectKeyUser: yup.array().of(yup.string())
    .required(t('This field is required.')),
  businessArea: yup.array().of(yup.string())
    .required(t('This field is required.')),
  goLivePlanDate: yup.string().required(t('This field is required.')),
  projectProviders: yup.array().of(yup.string())
    .required(t('This field is required.')),
  businessBenefits: yup.string().required(t('This field is required.')),
  technologyBenefits: yup.string().required(t('This field is required.')),
  processInvolved: yup.array().of(yup.string())
    .required(t('This field is required.')),
  platformInvolved: yup.array().of(yup.string())
    .required(t('This field is required.')),
  businessDrivers: yup.string()
    .required(t('This field is required.')),
}).required();

// type FormSchema = yup.InferType<ReturnType<typeof schema>>

const GeneralProject = () => {
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const navigate = useNavigate();
 
  const [newProjectModal, setNewProjectModal] = useState(true);

  const [resetSelect, setResetSelect] = useState(false);

  const [dates, setDates] = useState({
    startDate: '',
    endDate: ''
  })
  const [planDays, setPlanDays] = useState(0)

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<AdditionalDetails>({
    resolver: yupResolver(schema(t))
  });

  useEffect(() => {
    const diffDates = calculateDateDiffInDays(dates.startDate, dates.endDate)
    setPlanDays(diffDates)
  }, [dates])

  const onSubmit: SubmitHandler<AdditionalDetails> = (data) => {
    console.log(data)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setDates(prevState => ({ ...prevState, [name]: value }));

    if (name === 'startDate') {
      setValue(name, value);
    }

    if (name === 'endDate') {
      setValue(name, value);
    }
  };

  const projectProgram = projectProgramIdToName(fieldListStatus.programList, dataProject.programId);
  const projectType = projectTypeIdToName(fieldListStatus.projectTypeList, dataProject.projectTypeId);
  const projectManager = projectManagerIdToName(fieldListStatus.managerList, dataProject.managerId);
  const projectOwner = projectOwnerIdToName(fieldListStatus.ownerList, dataProject.ownerId);

  return (
    <>
      <ComponentHeader title={t('PMO: Create Project General')} menu={[{ label: t('General'), selected: true }]} />

      <S.ContainerForms>
        <S.FormRow>
          <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
            <S.InputContainer>
              <Input
                label={t('Program Name')}
                value={projectProgram}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Id')}
                value={dataProject.projectId?.toString()}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Name')}
                name='projectName'
                value={dataProject.name}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Type')}
                name='projectType'
                value={projectType}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Manager')}
                value={projectManager}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Owner')}
                value={projectOwner}
                readOnly
              />
            </S.InputContainer>
            <S.InputContainer>
              <ButtonCalendarInput
                {...register('startDate')}
                placeholder={t('Start Date') + ' *'}
                name="startDate"
                onChange={handleInputChange}
                maxDate={dates.endDate}
                error={!!errors.startDate}
              />
              {errors?.startDate && <S.ErrorMessage>{errors.startDate.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <ButtonCalendarInput
                {...register('endDate')}
                placeholder={t('End Date') + ' *'}
                name="endDate"
                onChange={handleInputChange}
                minDate={dates.startDate}
                error={!!errors.endDate}
              />
              {errors.endDate && <S.ErrorMessage>{errors.endDate.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('sponsors')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Sponsors') + ' *'}
                value={watch('sponsors')}
                onChange={value => setValue('sponsors', value)}
                error={!!errors.sponsors}
              />
              {errors.sponsors && <S.ErrorMessage>{errors.sponsors.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('businessLeader')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Business Leader') + ' *'}
                value={watch('businessLeader')}
                onChange={value => setValue('businessLeader', value)}
                error={!!errors.businessLeader}
              />
              {errors.businessLeader && <S.ErrorMessage>{errors.businessLeader.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('itLeader')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('It Leader') + ' *'}
                value={watch('itLeader')}
                onChange={value => setValue('itLeader', value)}
                error={!!errors.itLeader}
              />
              {errors.itLeader && <S.ErrorMessage>{errors.itLeader.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('projectKeyUser')} 
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Project Key User') + ' *'}
                value={watch('projectKeyUser')}
                onChange={value => setValue('projectKeyUser', value)}
                error={!!errors.projectKeyUser}
              />
              {errors.projectKeyUser && <S.ErrorMessage>{errors.projectKeyUser.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('businessArea')} 
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Business Area') + ' *'}
                value={watch('businessArea')}
                onChange={value => setValue('businessArea', value)}
                error={!!errors.businessArea}
              />
              {errors.businessArea && <S.ErrorMessage>{errors.businessArea.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <ButtonCalendarInput
                {...register('goLivePlanDate')}
                placeholder={t('Go-Live Plan Date') + ' *'}
                error={!!errors.goLivePlanDate}
              />
              {errors.goLivePlanDate && <S.ErrorMessage>{errors.goLivePlanDate.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('projectProviders')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Project Providers') + ' *'}
                value={watch('projectProviders')}
                onChange={value => setValue('projectProviders', value)}
                error={!!errors.projectProviders}
              />
              {errors.projectProviders && <S.ErrorMessage>{errors.projectProviders.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Input
                {...register('businessBenefits')}
                label={t('Business Benefitsts') + ' *'}
                error={!!errors.businessBenefits}
              />
              {errors.businessBenefits && <S.ErrorMessage>{errors.businessBenefits.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Input
                {...register('technologyBenefits')}
                label={t('Technology Benefits') + ' *'}
                error={!!errors.technologyBenefits}
              />
              {errors.technologyBenefits && <S.ErrorMessage>{errors.technologyBenefits.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList
                {...register('processInvolved')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Process Involved') + ' *'}
                value={watch('processInvolved')}
                onChange={value => setValue('processInvolved', value)}
                error={!!errors.processInvolved}
              />
              {errors.processInvolved && <S.ErrorMessage>{errors.processInvolved.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <SelectList 
                {...register('platformInvolved')}
                options={optionsSelected.map((option => ({ label: option.status, value: option.id.toString() })))}
                label={t('Plataform Involved') + ' *'}
                value={watch('platformInvolved')}
                onChange={value => setValue('platformInvolved', value)}
                error={!!errors.platformInvolved}
              />
              {errors.platformInvolved && <S.ErrorMessage>{errors.platformInvolved.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Select
                {...register('businessDrivers')}
                options={optionsSelected.map(option => ({ label: option.status, value: option.status }))}
                label={t('Business Drivers') + ' *'}
                reset={resetSelect}
                error={!!errors.businessDrivers}
              />
              {errors.businessDrivers && <S.ErrorMessage>{errors.businessDrivers.message}</S.ErrorMessage>}
            </S.InputContainer>
            <S.InputContainer>
              <Input
                label={t('Project Days') + ' *'}
                value={planDays.toString()}
                readOnly
              />
            </S.InputContainer>
          </div>
        </S.FormRow>
        <Row style={{ alignItems: 'flex-start' }}>
          <TextArea
            containerStyle={{ width: '100%', height: 150, marginBottom: 12 }}
            label={t('Summary')}
            value={dataProject.summary}
            readOnly
          />
        </Row>
      </S.ContainerForms>

      <S.ButtonContainerRow>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainerRow>

      <ModalCreateProject
        setNewProjectModal={setNewProjectModal}
      />
    </>
  );
};

export default GeneralProject;






import { PageHeader } from 'shared/layouts';
import { Breadcrumb, PageContainer, ComponentContainer, ComponentHeader } from 'shared/components';
import { IoGrid } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as S from './styles';


const Request = () => {
    const { t } = useTranslation();

    const iframeStyle: React.CSSProperties = {
        border: '0',
        borderRadius: '8px',
        width: '100%',
        height: '100vh',
    };

    return (
        <>
            <Breadcrumb separator=">" items={[
                { title: <IoGrid size={20} /> },
                { title: <Link to="/dashboard/mng">Home</Link> },
                { title: <Link to="/dashboard/help">{t('Help')}</Link> },
                { title: <Link to="/dashboard/help/requests">{t('Requests')}</Link> },
            ]} />
            <PageContainer>
                <PageHeader 
                    title='Requests'
                    navList={[
                        {label: 'Form', value: '/dashboard/help' },
                        {label: 'Requests', value: '/dashboard/help/request' },
                    ]}
                    containerStyle={{ position: 'sticky', left: 0, marginBottom: 10 }}
                />
                <ComponentContainer>
                    <ComponentHeader title='Requests' menu={[{ label: 'Requests List', selected: true }]} />

                    <S.Container>
                    <iframe 
                        src="https://view.monday.com/embed/7221070350-7f7047c85cc3188ff7edf48f515c638a?r=use1" 
                        width="100%" height="100vh" 
                        style={iframeStyle}>
                    </iframe>
                    </S.Container>
                </ComponentContainer>
            </PageContainer>
         </>
    );
}

export default Request;

import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListScopeStatusPayload {
    User?: string | null;
}

export interface GetListScopeStatusResponse {
  scopeStatusId: number;
  scopeStatus: string;
  user: string;
}

export function getListScopeStatus (params: GetListScopeStatusPayload): Promise<AxiosResponse<GetListScopeStatusResponse[]>> {
  return btoApi.get('/api/ScopeStatus/GetList', { params });
}
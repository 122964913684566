import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetAllInitiativesByKanbanPayload {
  User?:              string | null;
}

export interface GetAllInitiativesByKanbanResponse {
  kanbans: KanbanElement[];
}

interface KanbanElement {
  kanban:          KanbanEnum;
  qty:             number;
  budgetPlan:      number;
  budgetReal:      number;
  totalForecast:   number;
  businessDrivers: BusinessDriverElement[];
}

interface BusinessDriverElement {
  businessDriver: string;
  qty:            number;
  projectHealth:  string;
  budgetPlan:     number;
  budgetReal:     number;
  totalForecast   :number;
  initiatives:    Initiative[];
}

interface Initiative {
  companyId:               number;
  projectGroup:            string;
  initiativeType:          string;
  mainInitiative:          string;
  initiativeName:          string;
  summary:                 string;
  initiativeId:            string;
  sponsor:                 string;
  businessLeader:          string;
  itPartnerLeader:         string;
  itBusinessPatner:        string;
  keyUser:                 string;
  businessArea:            string;
  businessDriver:          string;
  businessBenefits:        string;
  techonologyBenefits:     string;
  plataformEnvolved:       string;
  businessProcessEnvolved: string;
  programme:               string;
  programmeId:             number;
  businessDriverId:        number;
  projectId:               string;
  name:                    string;
  type:                    string;
  startDate:               string;
  startPhaseBuild:         null;
  percentCompleteBuild:    number;
  startPhaseUat:           null;
  percentCompleteUat:      number;
  startPhaseCutover:       null;
  percentCompleteCutover:  number;
  goLiveDate:              string;
  endDate:                 string;
  actualPhase:             string;
  actualPhaseDescription:  string;
  progressPlaned:          number;
  progressRealized:        number;
  issuesQuantity:          number;
  statusTime:              number;
  statusCoast:             number;
  kanban:                  KanbanEnum;
  statusRisk:              number;
  statusIssue:             number;
  statusScope:             number;
  statusGeral:             number;
  providers:               string;
  totalRequested:          number;
  totalInvestiment:        number;
  totalInvestimentCapex:   number;
  totalInvestimentOpex:    number;
  commitedBudget:          number;
  percentCommitedPlanned:  number;
  totalRunningCoast:       number;
  lastRitualDate:          string;
  cpi:                     null;
  spi:                     null;
  extraBudget:             number;
  forecast:                number;
  consumed:                number;
  available:               number;
  financialProgress:       number;
  operacionalProgress:     number;
  operationalProgress:     number;
  toWin:                   boolean;
  otherDetailsInfo:        OtherDetailsInfo;
}

enum KanbanEnum {
  Backlog = "BACKLOG",
  Execution = "EXECUTION",
  Finished = "FINISHED",
  Planning = "PLANNING",
}

interface OtherDetailsInfo {
  detFinancial: null;
  risks:        null;
  issues:       null;
  impacts:      null;
  curveS:       null;
}


export function getAllInitiativesByKanban(params: GetAllInitiativesByKanbanPayload): Promise<AxiosResponse<GetAllInitiativesByKanbanResponse>> {
  if(!params || !params.User)
    return Promise.reject({error: 'Parametros não foram fornecidos'});
  
  return btoApi
    .get('/api/Initiative/GetAllInitiativesByKanban', { 
      params 
    });
}

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TabItem = styled.div<{ selected: boolean, upperCase?: boolean }>`
  /* padding: 8px; */
  margin-right: 8px;
  margin-left: 8px;
  padding-bottom: 4px;
  font-size: ${p => p.theme.font.size.sm};
  font-weight: ${p => p.theme.font.weight.regular};
  /* border-bottom: 1px solid ${p => p.theme.colors.neutral.gray['100']}; */
  border-bottom: 1px solid white;
  cursor: pointer;
  text-transform: ${p => p.upperCase ?  'uppercase' : 'unset'};
  ${p => p.selected && css`
    border-bottom: 1px solid ${p.theme.colors.primary['blue']};
  `}
`;
import React from "react";
import * as S from './style';
import { Input, InputProps } from "shared/components";

function InputSearch(props: InputProps) {
  return (
    <S.Container>
      <Input style={{ paddingLeft: 24}} {...props} />
      <S.Icon />
    </S.Container>
  );
}

export default InputSearch;

import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import * as S from './styles';
import { CSSProperties } from "styled-components";
import { Row, Select, TextArea, Typography, Input, SpinLoading } from 'shared/components';
import { ButtonRef } from 'shared/components/Button';
import { useTranslation } from "react-i18next";
import { getUser } from "services";
import { getActualStatus, GetActualStatusResponse } from "bto_now/api/initiatives/getActualStatus";
import {
  getBusinessDriverGroup,
  GetBusinessDriverGroupResponse,
  getProgrammeList,
  GetProgrammeListResponse,
  GetProjectResponse,
  getProjectType,
  GetProjectTypeResponse
} from "bto_now/api";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";

const inputContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 250,
  marginRight: 24,
  marginBottom: 12,
};

function GeneralProject() {
  const { projectId } = useParams();

  const [programmeList, setProgrammeList] = useState<GetProgrammeListResponse>();
  const [businessDriverGroup, setBusinessDriverGroup] = useState<GetBusinessDriverGroupResponse>();
  const [projectType, setProjectType] = useState<GetProjectTypeResponse>();
  const [actualStatusList, setActualStatusList] = useState<GetActualStatusResponse>();
  const [kanbanList] = useState([
    { id: 1, label: 'BACKLOG' },
    { id: 2, label: 'PLANNING' },
    { id: 3, label: 'EXECUTION' },
    { id: 4, label: 'FINISHED '}
  ]);

  const [digitalTransformationList] = useState([
    { id: 0, label: 'NO' },
    { id: 1, label: 'YES' },
  ]);

  const [loopList] = useState([
    { id: 1, label: 'BACKLOG' },
    { id: 2, label: 'PLANNING' },
    { id: 3, label: 'EXECUTION' },
    { id: 4, label: 'FINISHED '}
  ]);


  const [loading, setLoading] = useState(true);

  const [errors, setErrors] = useState({
    projectName: false,
    programmeName: false,
    businessDriver: false,
    kanban: false,
    projectType: false,
    actualStatus: false,
    digitalTransformation: false,
  });

  const { t } = useTranslation();

  const {
    saveButtonRef,
    project,
    setProject,
  } = useOutletContext<{
    saveButtonRef: React.RefObject<ButtonRef>,
    project: GetProjectResponse,
    setProject: React.Dispatch<React.SetStateAction<GetProjectResponse>>
  }>();

  useEffect(() => {
    async function fetchData() {
      try {
        const [programmeResponse, businessDriverResponse, projectTypeResponse, actualStatusResponse] = await Promise.all([
          getProgrammeList({ User: getUser() }),
          getBusinessDriverGroup({ User: getUser() }),
          getProjectType({ User: getUser() }),
          getActualStatus()
        ]);

        setProgrammeList(programmeResponse.data);
        setBusinessDriverGroup(businessDriverResponse.data);
        setProjectType(projectTypeResponse.data);
        setActualStatusList(actualStatusResponse.data);
      } catch (error) {
        console.error('Failed to load data:', error);
      } finally {
        setLoading(false); 
      }
    }

    fetchData();
  }, []);

  const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (name && project) {
      setProject({ ...project, [name]: value });
    }
  };

  const handleSelectChange = ({ target: { name, value } }: React.ChangeEvent<HTMLSelectElement>) => {
    if(name && project) {
      setProject({...project, [name]: Number(value) });
    }
  };

  function validForm() {
    const hasErrors = {
      businessDriver: !project?.businessDriverId,
      programmeName: !project?.programmeId,
      projectName: !project?.initiativeName,
      projectType: !project?.typeId,
      kanban: !project?.kanbanId,
      actualStatus: !project?.actualStatus,
      digitalTransformation: !project?.digitalTransformation,
    };
    
    setErrors(hasErrors);
  }

  useEffect(() => {
    saveButtonRef?.current?.removeEventListener("click", validForm);
    saveButtonRef?.current?.addEventListener("click", validForm);

    return () => {
      saveButtonRef?.current?.removeEventListener("click", validForm);
    };
  }, [saveButtonRef?.current, project]);

  if (loading) {
    return <SpinLoading />;
  }

  return (
    <>
      <Row style={{ justifyContent: 'space-between', marginBottom: 12, marginTop: 10 }}>
        <Typography weight='bold' size='medium'>General Information</Typography>
      </Row>
      <Row style={{ justifyContent: 'start' }}>
        <Input
          containerStyle={inputContainerStyle}
          label={t('Project Name') + ' *'}
          name='initiativeName'
          value={project?.initiativeName ?? ''}
          onChange={handleInputChange}
          error={errors.projectName}
        />
        <Select
          options={programmeList?.map(op => ({ label: op.programme, value: String(op.programmeId) }))}
          containerStyle={inputContainerStyle}
          label={t('Program Name') + ' *'}
          name='programmeId'
          value={String(project?.programmeId ?? '')}
          onChange={handleSelectChange}
          error={errors.programmeName}
        />
        <Select
          options={businessDriverGroup?.map(op => ({ label: op.businessDriver, value: String(op.businessDriverId) }))}
          containerStyle={inputContainerStyle}
          label={t('Business Drivers') + ' *'}
          name='businessDriverId'
          value={String(project?.businessDriverId ?? '')}
          onChange={handleSelectChange}
          error={errors.businessDriver}
        />
        <Select
          options={projectType?.map(op => ({ label: op.type, value: String(op.typeId) }))}
          containerStyle={inputContainerStyle}
          label={t('Project Type') + ' *'}
          name='typeId'
          value={String(project?.typeId ?? '')}
          onChange={handleSelectChange}
          error={errors.projectType}
        />
        <Select
          options={kanbanList.map(op => ({ label: op.label, value: String(op.id) }))}
          containerStyle={inputContainerStyle}
          label={t('Kanban List') + ' *'}
          name='kanbanId'
          value={String(project?.kanbanId ?? '')}
          onChange={handleSelectChange}
          error={errors.kanban}
        />
        <Select
          options={digitalTransformationList.map(op => ({ label: op.label, value: String(op.id) }))}
          containerStyle={inputContainerStyle}
          label={t('Digital Transformation') + ' *'}
          name='digitalTransformation'
          value={String(project?.digitalTransformation ?? '')}
          onChange={handleSelectChange}
          error={errors.digitalTransformation}
        />
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Select
          options={actualStatusList?.map(op => ({ label: op.actualStatusDescription, value: String(op.actualStatus) }))}
          containerStyle={inputContainerStyle}
          label={t('Actual Status') + ' *'}
          name='actualStatus'
          value={String(project?.actualStatus ?? '')}
          onChange={handleSelectChange}
          error={errors.actualStatus}
        />

        {/* the div below is not used, but it's here to fix temporary the issue with the Select component */}
        <div style={{display: 'none'}}>
          <Select
            options={loopList.map(op => ({ label: op.label, value: String(op.id) }))}
            containerStyle={inputContainerStyle}
            label={t('Loop') + ' *'}
            name='loopId'
            value={String(project?.kanbanId ?? '')}
            onChange={handleSelectChange}
          />
        </div>
      </Row>

      <Row style={{ justifyContent: 'space-between', marginBottom: 15 }}>
        <Typography weight='bold' size='medium'>Additional Details</Typography>
      </Row>
      <Row style={{alignItems: 'flex-start' }}>
        <Input 
          containerStyle={inputContainerStyle}
          label={t('Sponsor')}
          name='sponsor'
          value={project?.sponsor ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Business Area')}
          name='businessArea'
          value={project?.businessArea ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Business Leader')}
          name='businessLeader'
          value={project?.businessLeader ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('IT Partner Leader')}
          name='itPartnerLeader'
          value={project?.itPartnerLeader ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('IT Business Partner')}
          name='itBusinessPatner'
          value={project?.itBusinessPatner ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Key-User')}
          name='keyUser'
          value={project?.keyUser ?? ''}
          onChange={handleInputChange}
        />
        </Row>
        <Row style={{  alignItems: 'flex-start'}}>
        <Input
          containerStyle={inputContainerStyle}
          label={t('Project Manager')}
          name='projectManager'
          value={project?.projectManager ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Providers')}
          name='providers'
          value={project?.providers ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Platform Involved')}
          name='plataformEnvolved'
          value={project?.plataformEnvolved ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Business Process Involved')}
          name='businessProcessEnvolved'
          value={project?.businessProcessEnvolved ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Business Benefits')}
          name='businessBenefits'
          value={project?.businessBenefits ?? ''}
          onChange={handleInputChange}
        />
        <Input
          containerStyle={inputContainerStyle}
          label={t('Technology Benefits')}
          name='techonologyBenefits'
          value={project?.techonologyBenefits ?? ''}
          onChange={handleInputChange}
        />
      </Row>

      <Row style={{ alignItems: 'flex-start'}}>
        <TextArea
          containerStyle={{ width: '100%', height: 150, marginBottom: 12 }}
          label={t('Summary')}
          name="summary"
          value={project?.summary ?? ''}
          onChange={handleInputChange}
        />
      </Row>
    </>
  );
}

export default GeneralProject;
